import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Nav, navVertical } from 'nav-tree';
import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import { LoaderContext } from '@/contexts/loader/loader.context';
import { NavigationButtons, NoDataMessage, PageContent } from '.';
import { NavigationButtonsWrapper } from './NavigationButtonsWrapper';
import { Grid } from '@/components/shared/Grid';
import { FilterList } from '@/components/shared/Filter/FilterList/FilterList';
import {
    PacketCategoriesMenu,
    SubcategoriesMenu,
} from '@/components/shared/Filter/SubcategoriesMenu';
import { ProcessingLoader } from '@/components/shared/Loader';
import { StyledSingleDataWrapper } from '@/components/shared/Metadata/style';
import { METADATA_TYPES } from '@/components/shared/Metadata';
import { useInitialRefocus } from '@/hooks/nav-tree/use-initial-refocus.hook';
import { usePageRefocus } from '@/hooks/nav-tree/use-page-refocus.hook';
import { useCurrentGridFocus } from '@/hooks/nav-tree/use-current-grid-focus.hook';
import { useCatalogRightPanel } from '@/hooks/nav-tree/use-catalog-right-panel.hook';
import { useCatalogSubcategories } from '@/hooks/use-catalog-subcategories.hook';
import { useCatalogActiveItem } from '@/hooks/use-catalog-active-item-hook';
import { useEmptyCatalog } from '@/hooks/use-empty-catalog.hook';
import { LIST_TYPE } from '@/types/recommendation-list.type';
import {
    CatalogTitle,
    CatalogWrapper,
    StyledGrid,
    StyledMainNavigation,
    StyledPageContent,
} from './styles';
import { CatalogViewOwnProps } from './types';
import { useError } from '@/contexts/error/error.context';

export const CatalogView = ({
    config,
    placeholder,
    onCategoryView,
    isChronological,
    hideHeader = false,
}: CatalogViewOwnProps) => {
    const { setError } = useError();
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);

    const {
        fetcher,
        catalogParams,
        withLists,
        withFilters = false,
        withSubcategory,
        isMutable,
        withPacketCategories,
    } = config;
    const fetcherConfig = { catalogParams, withFilters };

    const [isFullyLoaded, setIsFullyLoaded] = useState<boolean>(false);
    const { subcategories, subcategoriesMenuTitle, buttonVariant, packetFilters } =
        useCatalogSubcategories(withSubcategory, withPacketCategories);
    const [changedFilters, setChangedFilters] = useState(false);
    const { showLoader } = useContext(LoaderContext);

    const {
        gridElements,
        columns,
        filterLists,
        isFetching,
        onFetchMore,
        fetchedAll,
        error,
        titleName,
    } = fetcher(fetcherConfig);

    useEffect(() => {
        if (error) {
            setError(error);
        }
    }, [error]);

    const focusRef = useRef<Nav>(null);
    const hasSubcategories = subcategories.getResults().length > 0;
    const haveContent = Boolean(gridElements.length);
    const isLoadingData = Boolean(isFetching) && !showLoader;
    const showPageContent = haveContent && isFullyLoaded;
    const noContent = !haveContent && isFullyLoaded;
    const lastElIndex = gridElements.length - 1;
    const enabled = isFullyLoaded && !changedFilters;

    const { activeItem, gridTitle, handleActiveItem } = useCatalogActiveItem(config, noContent);

    const { currentFocus, focusedIndex } = useCurrentGridFocus({ columns, lastElIndex, isMutable });

    const { showNoDataMessage, showEmptyCatalogPlaceholder } = useEmptyCatalog(
        haveContent,
        filterLists,
        isFetching,
        placeholder,
    );

    const {
        filtersOpen,
        handleFiltersOpen,
        showSubcategoriesMenu,
        handleSubcategoriesOpen,
        handleSubcategoryAction,
    } = useCatalogRightPanel({ focusRef });

    const shouldFetchMore = focusedIndex >= lastElIndex && !fetchedAll;

    useEffect(() => {
        if (isFetching) return;

        if (shouldFetchMore) {
            onFetchMore?.();
        } else {
            setIsFullyLoaded(true);
        }
    }, [isFetching, shouldFetchMore, onFetchMore]);

    useInitialRefocus({ focusRef, enabled, focusIndex: currentFocus });

    usePageRefocus({ focusRef });

    const catalog = useMemo(
        () => (
            <StyledGrid hideHeader={hideHeader}>
                <Grid
                    isChronological={isChronological}
                    data={gridElements}
                    col={columns}
                    rowCount={hideHeader ? 3 : 2}
                    onActiveElement={handleActiveItem}
                    onFetchMoreElements={onFetchMore}
                    defaultFocused={enabled}
                    lockNav={isFetching}
                    focusRef={focusRef}
                />
            </StyledGrid>
        ),
        [
            gridElements,
            isFetching,
            focusRef,
            columns,
            enabled,
            handleActiveItem,
            onFetchMore,
            isChronological,
        ],
    );

    const gridTitleMemo = useMemo(
        () => (
            <StyledSingleDataWrapper $type={METADATA_TYPES.PRIMARY}>
                {gridTitle}
            </StyledSingleDataWrapper>
        ),
        [activeItem, gridTitle],
    );

    const onFiltersAction = useCallback(() => setChangedFilters(true), []);

    const noContentComponent = useMemo(() => {
        if (showNoDataMessage) {
            return <NoDataMessage />;
        }

        if (showEmptyCatalogPlaceholder) {
            // @ts-ignore
            return <Nav defaultFocused>{placeholder}</Nav>;
        }

        return null;
    }, [placeholder, showEmptyCatalogPlaceholder, showNoDataMessage]);

    const showTitle = !!titleName && config.categoryName === 'collection';

    const title = useMemo(() => {
        return (
            <CatalogWrapper>
                <CatalogTitle>{titleName}</CatalogTitle>
            </CatalogWrapper>
        );
    }, [titleName]);

    return (
        <>
            <StyledMainNavigation
                func={navVertical}
                restoreLastFocus
                autofocusOn={isMagicMouseVisible ? ['mouseenter'] : undefined}
            >
                {noContentComponent}

                <NavigationButtonsWrapper>
                    <NavigationButtons
                        hasSubcategories={hasSubcategories}
                        mainButtonVariant={buttonVariant}
                        onCategoryView={onCategoryView}
                        onShowFilters={handleFiltersOpen}
                        onShowSubcategoriesMenu={handleSubcategoriesOpen}
                        subCategoriesLabel={subcategoriesMenuTitle}
                        withFilters={withFilters && !!filterLists.length}
                        withLists={withLists}
                        withSubcategory={!!withSubcategory}
                    />
                    {showTitle && title}
                </NavigationButtonsWrapper>

                <ProcessingLoader isFetching={isLoadingData} />

                {showPageContent && (
                    <StyledPageContent view={LIST_TYPE.SIMPLE} isCatalog hideHeader={hideHeader}>
                        {!hideHeader && activeItem && <PageContent actualVod={activeItem} />}
                        {gridTitle && gridTitleMemo}
                        {catalog}
                    </StyledPageContent>
                )}
            </StyledMainNavigation>
            {filtersOpen && (
                <FilterList
                    data={filterLists}
                    onAction={onFiltersAction}
                    onShowFilters={handleFiltersOpen}
                />
            )}

            {packetFilters && (
                <PacketCategoriesMenu
                    categories={packetFilters}
                    isVisible={showSubcategoriesMenu}
                    onAction={() => {
                        setChangedFilters(false);
                        handleSubcategoryAction();
                    }}
                    onShowMenu={handleSubcategoriesOpen}
                />
            )}
            <SubcategoriesMenu
                name={subcategoriesMenuTitle}
                subcategories={subcategories.getResults()}
                isVisible={showSubcategoriesMenu}
                onShowMenu={handleSubcategoriesOpen}
                onAction={handleSubcategoryAction}
                selectedCategoryId={withSubcategory?.subcategoryId}
            />
        </>
    );
};
