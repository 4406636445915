import {
    GetPaymentCardsOut,
    PaymentCardBrandSchema,
    PaymentCardExpirationDateSchema,
} from '@/interfaces/from-schemas/payments/getPaymentCardsOut';
import t from '@/lib/i18n';

export class PaymentCardModel {
    private operator: GetPaymentCardsOut[number]['operator'];
    private id: string;
    private maskedNumber: string;
    private brand: PaymentCardBrandSchema;
    private expirationDate: PaymentCardExpirationDateSchema;
    private default: boolean;

    constructor(definition: GetPaymentCardsOut[number]) {
        this.operator = definition.operator;
        this.id = definition.id;
        this.maskedNumber = definition.maskedNumber;
        this.brand = definition.brand;
        this.expirationDate = definition.expirationDate;
        this.default = definition.default || false;
    }

    public getOperator(): GetPaymentCardsOut[number]['operator'] {
        return this.operator;
    }

    public getId(): string {
        return this.id;
    }

    public getMaskedNumber(): string {
        return this.maskedNumber;
    }

    public getBrand(): PaymentCardBrandSchema {
        return this.brand;
    }

    public getExpirationDate(): PaymentCardExpirationDateSchema {
        return this.expirationDate;
    }

    public getExpirationText(): string {
        const text = t('components.purchases.valid-to');
        const { month, year } = this.expirationDate;
        const expirationText = `${text} ${('0' + month).slice(-2)}.${year}`;

        return expirationText;
    }

    public isCardExpired(): boolean {
        const { year, month } = this.expirationDate;
        const now = new Date();

        if (now.getFullYear() > year) {
            return true;
        }

        return now.getFullYear() === year && now.getMonth() + 1 > month;
    }

    public isDefault(): boolean {
        return this.default;
    }

    public getLabel(): string {
        return `${this.getMaskedNumber()}, ${this.getExpirationText()}`;
    }
}
