import { useContext, useEffect } from 'react';
import { RPCAuthContext, SessionAuthContext } from './auth.context';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useUserModule } from '../user-module/user-module.hooks';
import { useGetDeviceName } from '@/helpers/get-device-name/get-cac-name';

export const useAuth = () => useContext(RPCAuthContext);

export const useSessionAuth = () => useContext(SessionAuthContext);

export const useDeviceId = () => {
    const userModule = useUserModule();
    const { deviceName } = useGetDeviceName();

    useEffect(() => {
        const fpPromise = FingerprintJS.load();

        const setDeviceId = async () => {
            const fp = await fpPromise;
            const result = await fp.get();

            userModule.setDeviceId(result.visitorId);
        };

        setDeviceId();
        userModule.setDeviceName(deviceName);
    }, []);

    return { deviceId: userModule.getUserRecognition().getDeviceId() };
};
