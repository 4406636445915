import { VODControls } from './controls';
import { IVODPlayerProps } from './types';
import { PlayerBase } from './PlayerBase';
import { CPID } from '@/types/media.type';
import { useEffect, useMemo, useState } from 'react';
import { useBuildVodData } from '@/hooks/media/use-build-vod-data.hook';
import { StartPlayFMCButtons } from './controls/shared/StartPlayFMCButtons';
import { useIdle } from '@/hooks/use-idle.hook';
import { FreeAccessConfirmation } from './controls/shared/FreeAccess';
import { LOCAL_STORAGE_KEYS } from '@/types/storage.type';
import { useLocalStorage } from '@/hooks/use-local-storage.hook';
import useNetworkStatus from '@/hooks/use-network-status.hook';

const {
    player: {
        transition: { visibilityDelay },
    },
} = process.env;

export const VODPlayer = ({ mediaId, mediaDetails }: IVODPlayerProps) => {
    const [isFmcScreenVisible, setIsFmcScreenVisible] = useState(false);
    const [isWatchingBegin, setIsWatchingBegin] = useState(false);
    const { media, fmc } = useBuildVodData();
    const idle = useIdle(visibilityDelay);
    const [isFreeAccessVisible, setIsFreeAccessVisible] = useState(false);
    const [isFreeAccessConfirmation, setIsFreeAccessConfirmation] = useState(false);
    const [freeAccessDontAskAgain] = useLocalStorage(LOCAL_STORAGE_KEYS.FREE_ACCESS_DONT_ASK_AGAIN);
    const { isOnline } = useNetworkStatus();

    useEffect(() => {
        const findFmcId = fmc.find((el) => el.mediaId.id === mediaId);
        setIsFmcScreenVisible(!!findFmcId && isOnline);
        const freeAccess = media.isAskBeforePlayAction() && freeAccessDontAskAgain !== true;
        setIsFreeAccessVisible(!!freeAccess);
    }, [fmc, mediaId, isOnline]);
    const load = useMemo(
        () => ({
            media: {
                gmID: {
                    cpid: CPID.VOD_OR_MOVIE,
                    id: mediaId,
                },
            },
        }),
        [mediaId],
    );

    const actionKeepWatching = () => {
        setIsFmcScreenVisible(false);
    };

    const actionBeginWatching = () => {
        setIsFmcScreenVisible(false);
        setIsWatchingBegin(true);
    };
    const actionBeforePlay = () => {
        setIsFreeAccessVisible(false);
        setIsFreeAccessConfirmation(true);
    };

    if (isFreeAccessVisible) {
        return (
            <FreeAccessConfirmation
                actionBeforePlay={actionBeforePlay}
                text={media.getPlaybackConfirmationMessage()}
            />
        );
    }
    if (isFmcScreenVisible) {
        return (
            <StartPlayFMCButtons
                actionBeginWatching={actionBeginWatching}
                actionKeepWatching={actionKeepWatching}
                isVisible={!idle}
            />
        );
    }

    return (
        <PlayerBase
            load={load}
            mediaDetails={mediaDetails}
            isWatchingBegin={isWatchingBegin}
            isFmcScreenVisible={isFmcScreenVisible}
            isFreeAccessConfirmation={isFreeAccessConfirmation}
        >
            <VODControls />
        </PlayerBase>
    );
};
