import React, { useEffect, useMemo } from 'react';
import {
    Router as ReactRouterRouter,
    Switch,
    Route,
    generatePath,
    useLocation,
} from 'react-router-dom';
import { createHashHistory } from 'history';
import { IInteractiveRouter, IRouter, ROUTES } from '@/routing/types';
import { createRouteProperties, importAllPages } from './helpers';
import { ROUTES_CONFIG_STANDALONE } from './routes-standalone';
import { shouldRedirectTo404 } from '@/helpers/router/should-redirect-to-404.helper';
import {
    shouldTrimAccountHistory,
    shouldTrimPurchaseHistory,
    trimHistoryStack,
    trimHistoryStackAccount,
    trimHistoryStackPurchase,
} from './trim-history-helpers';

export { Link } from 'react-router-dom';

const history = createHashHistory();
let prevRoute = ROUTES.HOME;
let stack: ROUTES[] = [];

const routesCache = importAllPages(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (require as any).context('../../../pages-standalone/', true, /\/.*\/.*\.(tsx|ts)$/),
);

export const Router: IInteractiveRouter = {
    pushRoute: (routeName: ROUTES, params = {}, queryParams) => {
        if (shouldRedirectTo404(params)) {
            return Router.pushRoute(ROUTES.NOT_FOUND);
        }

        const route = ROUTES_CONFIG_STANDALONE.find(({ name }) => name === routeName);
        if (!route) {
            console.error(`No route for ${routeName}`);
            return;
        }

        if (shouldTrimPurchaseHistory(params)) {
            return trimHistoryStackPurchase(stack, prevRoute);
        }

        const path = generatePath(route.pattern, params);

        if (route.name === ROUTES.HOME) {
            prevRoute = ROUTES.HOME;
            stack = [];
        } else {
            prevRoute = stack[stack.length - 1];
            stack.push(route.name);
        }

        history.push({
            pathname: path,
            search: queryParams && new URLSearchParams(queryParams).toString(),
        });
    },

    back: () => {
        trimHistoryStack(stack, prevRoute);

        prevRoute = stack[stack.length - 1];
        stack.splice(-1, 1);
        history.go(-1);
    },

    reload: () => history.replace(history.location),

    replaceRoute: (routeName: ROUTES, params = {}, queryParams) => {
        const route = ROUTES_CONFIG_STANDALONE.find(({ name }) => name === routeName);
        if (!route) {
            console.error(`No route for ${routeName}`);
            return;
        }

        if (shouldTrimAccountHistory(params)) {
            trimHistoryStackAccount(stack, prevRoute);
        }

        const path = generatePath(route.pattern, params) as ROUTES;
        prevRoute = stack[stack.length - 1];
        stack.splice(-1, 1, route.name);

        history.replace({
            pathname: path,
            search: queryParams && new URLSearchParams(queryParams).toString(),
        });
    },
};

interface IRouterProviderProps {
    children: React.ReactNode;
}

export const RouterProvider = ({ children }: IRouterProviderProps) => (
    <ReactRouterRouter history={history}>
        {children}
        <Switch>
            {ROUTES_CONFIG_STANDALONE.map(({ page, pattern }) => {
                const PageComponent = routesCache[page]?.default;
                return <Route key={pattern} path={pattern} exact component={PageComponent} />;
            })}
        </Switch>
    </ReactRouterRouter>
);

export const useRouter = (): IRouter => {
    const { pathname, search } = useLocation();

    useEffect(() => {
        // Zabezpieczenie przed ręcznym wpisaniem w urla '/'
        if (pathname === '/') Router.pushRoute(ROUTES.HOME);
    }, [pathname]);

    return useMemo(
        () => createRouteProperties(pathname, search, prevRoute, stack),
        [pathname, search],
    );
};

export const useBlockBackAction = (enabled: boolean) => {
    useEffect(() => {
        if (!enabled) {
            return;
        }

        const listener = (event: PopStateEvent) => {
            event.stopImmediatePropagation();
            window.history.pushState(null, document.title, window.location.href);
        };

        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', listener);

        return () => {
            window.removeEventListener('popstate', listener);
        };
    }, [enabled]);
};

export const routes = ROUTES_CONFIG_STANDALONE;
