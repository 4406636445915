import { useKeyboardNavigationHook } from '@/components/shared/VirtualKeyboard/use-keyboard-navigation.hook';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import t from '@/lib/i18n';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import Keyboard from 'react-simple-keyboard';
import clamp from 'lodash/clamp';
import Backspace from './icons/backspace.svg';
import Shift from './icons/shift.svg';
import { layouts } from './layout';
import { KeypadContainer, VirtualKeyboardContainer, SubmitButton } from './styles';
import { SpecialKeysDefinition, VirtualKeyboardProps } from './types';
import { useHoverKey } from './use-hover-key.hook';
import { useKeyboardLayout } from './use-keyboard-layout.hook';

const insertCharacterHandler = (
    value: string,
    key: string,
    cursorPosition: number,
): [string, number] => [
    value.substr(0, cursorPosition) + key + value.substr(cursorPosition),
    cursorPosition + 1,
];

const backspaceHandler = (value: string, cursorPosition: number): [string, number] => [
    value.substr(0, cursorPosition - 1) + value.substring(cursorPosition),
    Math.max(cursorPosition - 1, 0),
];

const moveCursor = (value: string, cursorPosition: number): [string, number] => [
    value,
    clamp(cursorPosition, 0, value.length),
];

export const VirtualKeyboard = ({
    defaultHoveredButton = '1',
    setVisible,
    inputRef,
    setInputValue,
    format = (value, cursor) => [value, cursor],
    normalize = (value, cursor) => [value, cursor],
    submitLabel = t('ok-button-label'),
}: VirtualKeyboardProps) => {
    const [keyboardInstance, setKeyboardInstance] = useState<Keyboard>();

    const { layoutName, keyboardLayout, shiftLayoutChangeHandler, specialLayoutChangeHandler } =
        useKeyboardLayout();

    const { hoveredKey, hoveredPosition, previousPosition, hover } = useHoverKey(
        keyboardInstance,
        layoutName,
        defaultHoveredButton,
    );

    const domInput = inputRef?.current;
    const domInputValue = domInput?.value || '';
    const virtualKeyboardValue = keyboardInstance?.getInput();

    const inputName = useMemo(() => domInput?.name, [domInput]);

    useEffect(() => {
        if (domInputValue !== virtualKeyboardValue && domInputValue.length > 0) {
            onChange(domInputValue, keyboardInstance?.caretPosition);
        }
    }, [domInputValue, virtualKeyboardValue, keyboardInstance]);

    const onChange = useCallback(
        (value: string, cursorPos?: number) =>
            batchedUpdates(() => {
                const cursorPosition = cursorPos ?? value?.length ?? 0;
                setInputValue(value, cursorPosition);
                keyboardInstance?.setInput(value);
                keyboardInstance?.setCaretPosition(cursorPosition);
            }),
        [keyboardInstance, setInputValue],
    );

    const onKeyboardClose = useCallback(() => {
        setVisible(false);
        domInput?.blur();
    }, [domInput, setVisible]);

    const backKeyHandler = onKeyboardClose;

    const enterKeyHandler = useCallback(() => {
        const originalPair: [string, number] = [
            keyboardInstance?.getInput() || '',
            keyboardInstance?.caretPosition ?? domInputValue.length,
        ];
        const normalizedPair = normalize(...originalPair);
        const [normalizedValue, normalizedCursorPos] = normalizedPair;

        let updatedNormalizedPair: [string, number] = [...normalizedPair];

        switch (hoveredKey) {
            case SpecialKeysDefinition.ARROW_LEFT:
                updatedNormalizedPair = moveCursor(normalizedValue, normalizedCursorPos - 1);
                break;
            case SpecialKeysDefinition.ARROW_RIGHT:
                updatedNormalizedPair = moveCursor(normalizedValue, normalizedCursorPos + 1);
                break;
            case SpecialKeysDefinition.SUBMIT:
                return onKeyboardClose();
            case SpecialKeysDefinition.BACKSPACE:
                updatedNormalizedPair = backspaceHandler(normalizedValue, normalizedCursorPos);
                break;
            case SpecialKeysDefinition.SPACE:
                updatedNormalizedPair = insertCharacterHandler(
                    normalizedValue,
                    ' ',
                    normalizedCursorPos,
                );
                break;
            case SpecialKeysDefinition.SHIFT:
                return shiftLayoutChangeHandler();
            case SpecialKeysDefinition.SPECIAL_CHAR_SWITCH:
                return specialLayoutChangeHandler();
            default:
                updatedNormalizedPair = insertCharacterHandler(
                    normalizedValue,
                    hoveredKey,
                    normalizedCursorPos,
                );
        }

        const updatedPair = format(...updatedNormalizedPair);

        onChange(...updatedPair);
    }, [
        keyboardInstance,
        hoveredKey,
        onKeyboardClose,
        onChange,
        shiftLayoutChangeHandler,
        specialLayoutChangeHandler,
    ]);

    useKeyboardNavigationHook(
        keyboardLayout,
        hoveredKey,
        hoveredPosition,
        previousPosition,
        hover,
        enterKeyHandler,
        backKeyHandler,
    );

    return (
        <VirtualKeyboardContainer>
            <KeypadContainer>
                <Keyboard
                    inputName={inputName}
                    keyboardRef={setKeyboardInstance}
                    onChange={onChange}
                    useButtonTag
                    layout={layouts}
                    layoutName={layoutName}
                    display={{
                        '{backspace}': renderToString(<Backspace />),
                        '{shift}': renderToString(<Shift />),
                        '{=/#}': '=/#',
                    }}
                    onRender={() => {
                        // update hover key when layout change
                        hover(hoveredKey, hoveredPosition);
                    }}
                    mergeDisplay
                />
                <SubmitButton hovered={SpecialKeysDefinition.SUBMIT === hoveredKey}>
                    <span>{submitLabel}</span>
                </SubmitButton>
            </KeypadContainer>
        </VirtualKeyboardContainer>
    );
};
