import styled from 'styled-components';

export const StyledDot = styled.div`
    display: inline-block;
`;

export const StyledDate = styled.div`
    color: ${({ theme }) => theme.text.color.secondary};
    display: inline-block;
`;

export const EmptyLiveCollectionWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    border-radius: 2rem;
    overflow: hidden;
`;
