import { CPID } from '@/types/media.type';
import {
    GetCategoryWithFlatNavigationOut,
    ImageSourceSchema,
    FlatNavigationSchema,
    FilterSchema,
    FlatFilterListSchema,
} from '@/interfaces/from-schemas/navigation/getCategoryWithFlatNavigationOut';
import slug from 'slug';
import { addSlugToFliterLists } from '@/helpers/catalog/map-filters-to-urls.helper';

class CategoryWithFlatNavigationModel {
    private readonly cpid: CPID.CATEGORY_OR_PACK = 7;
    private readonly id: number = 0;
    private readonly name: string = '';
    private readonly description: string = '';
    private readonly genres: string[] = [];
    private readonly categoryPath: number[] = [];
    private readonly categoryNamesPath: string[] = [];
    private readonly keyCategoryId: number = 0;
    private readonly subCategoriesLabel: string = '';
    private readonly thumbnails: ImageSourceSchema[] = [];
    private readonly reporting: GetCategoryWithFlatNavigationOut['reporting'] = {};
    private readonly customData: GetCategoryWithFlatNavigationOut['customData'] = {};
    private readonly flatNavigation: FlatNavigationSchema = {};

    constructor(definition: GetCategoryWithFlatNavigationOut) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });
        }
    }

    public getCategoryNamesPath(): string[] {
        return this.categoryNamesPath;
    }

    public getCategoryPath(): number[] {
        return this.categoryPath;
    }

    public getDescription(): string {
        return this.description;
    }

    public getFlatNavigation(): ExtendedFlatNavigationSchema {
        const updatedFilterLists = addSlugToFliterLists(this.flatNavigation.filterLists);

        const updatedNavigation = {
            ...this.flatNavigation,
            filterLists: updatedFilterLists,
        };

        return updatedNavigation;
    }

    public getGenres(): string {
        return this.genres.join(', ');
    }

    public getName(): string {
        return this.name;
    }

    public getThumbnails(): ImageSourceSchema[] {
        return this.thumbnails;
    }

    public getId(): number {
        return this.id;
    }

    public getCpid(): number {
        return this.cpid;
    }
}

export default CategoryWithFlatNavigationModel;

interface ExtendedFilterSchema extends FilterSchema {
    slug: string;
}

interface ExtendedFlatFilterListSchema extends FlatFilterListSchema {
    filters: ExtendedFilterSchema[];
}

interface ExtendedFlatNavigationSchema extends FlatNavigationSchema {
    filterLists?: ExtendedFlatFilterListSchema[];
}
