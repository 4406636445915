import { fillIcon } from '@/helpers/fill-icon.helper';
import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const OptionListContainer = styled.div`
    width: 100%;
    height: 100vh;
    padding-bottom: 5rem;
`;

export const OptionListWrapper = styled(Nav as any)`
    max-height: 80vh;
    overflow: hidden;
    margin: 3rem 0;
`;

export const IconWrapper = styled.div`
    width: 3rem;
    height: 3rem;
    ${({ theme }) => fillIcon(theme.menu.iconColor)};
    margin: 0 auto;

    &:hover {
        ${({ theme }) => fillIcon(theme.menu.focusedIconColor)};
        cursor: pointer;
    }
`;
