import { SCROLL_DIRECTION } from '@/contexts/menu/types';
import { isServer } from '@/helpers/environment.helper';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

export const useSticky = (
    shouldMaintainVisibility: boolean | null,
    setShouldMaintainVisibility: Dispatch<SetStateAction<boolean | null>>,
) => {
    const [scrollDirection, setScrollDirection] = useState<SCROLL_DIRECTION>(SCROLL_DIRECTION.TOP);
    const yRef = useRef(!isServer ? window?.scrollY : 0);

    const updateScrollDirection = useCallback((currentY: any, prevY: any) => {
        if (currentY < 100) {
            setScrollDirection(SCROLL_DIRECTION.TOP);
        } else if (prevY > currentY) {
            setScrollDirection(SCROLL_DIRECTION.UP);
        } else if (prevY < currentY) {
            setScrollDirection(SCROLL_DIRECTION.DOWN);
        }
    }, []);

    const onScroll = useCallback(
        (e: any) => {
            const prevY = yRef.current;
            const currentY = e.currentTarget.scrollY;
            yRef.current = currentY;

            if (shouldMaintainVisibility === null) {
                updateScrollDirection(currentY, prevY);
            }
        },
        [updateScrollDirection],
    );

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [onScroll]);

    useEffect(() => {
        if (scrollDirection !== SCROLL_DIRECTION.TOP) {
            if (shouldMaintainVisibility) {
                setScrollDirection(SCROLL_DIRECTION.UP);
                setShouldMaintainVisibility(null);
            }

            if (shouldMaintainVisibility === false) {
                setScrollDirection(SCROLL_DIRECTION.DOWN);
                setShouldMaintainVisibility(null);
            }
        }
    }, [scrollDirection, setShouldMaintainVisibility]);

    return {
        isSticky: scrollDirection === SCROLL_DIRECTION.TOP,
        scrollDirection,
    };
};
