import { ErrorContainer } from './styles';
import { Message } from '@/components/shared/Message';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { IPlayerStatusError, PlayerErrorGroup } from '@/interfaces/player.interface';
import { ConcurrentPlaybackErrorOverlay } from './ConcurrentPlaybackErrorOverlay';
import { ErrorDescription } from '@/components/commons/Player/ErrorDescription';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';

interface IErrorOverlay {
    error: IPlayerStatusError;
}

export const ErrorOverlay = ({ error }: IErrorOverlay) => {
    if (!error) {
        return null;
    }

    if (error.group === PlayerErrorGroup.CONCURRENCY_LIMIT) {
        return <ConcurrentPlaybackErrorOverlay />;
    }

    if (
        error &&
        error.backendInfo &&
        error.backendInfo.userMessage &&
        error.backendInfo.errorCode
    ) {
        Router.pushRoute(
            ROUTES.CUSTOMER_CARE_ERROR_PAGE,
            { errorCode: String(error.backendInfo.errorCode) },
            {
                errorCodeMessageToDisplay: error.backendInfo.userMessage,
            },
        );
    }

    let title = `${t('player.error')} ${error?.code}`;

    if (error.group === PlayerErrorGroup.NETWORK) {
        title = t('player.error-title.NETWORK');
    }

    return (
        <>
            {(error.code || error.group) && (
                <ErrorContainer>
                    <Message
                        title={title}
                        descriptionJSX={<ErrorDescription />}
                        icon={ICONS.ALERT}
                    />
                </ErrorContainer>
            )}
        </>
    );
};
