import { GM_DEVICE_TYPE, GM_OS } from '@/types/platform.type';
import { getMatch } from './helpers';
import { MatchMap } from './types';

const deviceTypeDetectionMap: MatchMap<GM_DEVICE_TYPE> = [
    [/googlebot/i, GM_DEVICE_TYPE.Other],
    [/hisense/i, GM_DEVICE_TYPE.TV_Hisense],
    [/panasonic/i, GM_DEVICE_TYPE.TV_Panasonic],
    [/WhaleTV/i, GM_DEVICE_TYPE.Smart_TV],
    [/philips/i, GM_DEVICE_TYPE.Smart_TV],
    [
        /(tablet|ipad|playbook|silk)|(android(?!.*mobi))|(macintosh(.*?) fxios(.*?)\/)/i,
        GM_DEVICE_TYPE.Tablet,
    ],
    [/tablet(?! pc)/i, GM_DEVICE_TYPE.Tablet],
    [
        /Mobile|iPhone|iPod|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|hpwOS|webOS|Opera Mobi|Opera mini/,
        GM_DEVICE_TYPE.Mobile,
    ],
    [/nexus\s*[0-6].*/i, GM_DEVICE_TYPE.Mobile],
    [/galaxy nexus/i, GM_DEVICE_TYPE.Mobile],
    [/nexus\s*[0-6].*/i, GM_DEVICE_TYPE.Mobile],
    [/tizen 5\.[5-9]/i, GM_DEVICE_TYPE.TV_Samsung_2018],
    [/tizen [6-9]\.[0-9]/i, GM_DEVICE_TYPE.TV_Samsung_2018],
    [/tivo/i, GM_DEVICE_TYPE.TV_TIVO],
    [/xbox/i, GM_DEVICE_TYPE.XBOX],
    [GM_OS.MacOS, GM_DEVICE_TYPE.PC],
    [GM_OS.Windows, GM_DEVICE_TYPE.PC],
    [GM_OS.Linux, GM_DEVICE_TYPE.PC],
    [GM_OS.SRAF, GM_DEVICE_TYPE.Smart_TV],
    [GM_OS.Chromecast, GM_DEVICE_TYPE.Chromecast],
    [GM_OS.OSX, GM_DEVICE_TYPE.Mobile],
    [GM_OS.OperaTV, GM_DEVICE_TYPE.Smart_TV],
    [GM_OS.HbbTV, GM_DEVICE_TYPE.Smart_TV],
    [GM_OS.XboxOS, GM_DEVICE_TYPE.Console],
    [GM_OS.tvOS, GM_DEVICE_TYPE.Smart_TV],
    [GM_OS.Tizen, GM_DEVICE_TYPE.Smart_TV],
    [GM_OS.WebOS, GM_DEVICE_TYPE.Smart_TV],
    [GM_OS.UPC, GM_DEVICE_TYPE.Smart_TV],
];

export const recognizeDeviceType = (userAgent: string, os: GM_OS): GM_DEVICE_TYPE =>
    getMatch(deviceTypeDetectionMap, userAgent, os) ?? GM_DEVICE_TYPE.Other;
