import { AssetInterface, goToDetailsOptionsType } from '@/interfaces/asset.interface';
import { CategoryModel } from '../category/category.model';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { ListElementImageInterface } from '@/interfaces/list-element.interface';
import { coverUtil } from '@/utils/cover.util';
import { Router } from '@/routing';
import { MEDIA_TYPES } from '@/types/media.type';
import { ROUTES } from '@/routing/types';
import { GetCategoryOut } from '@/interfaces/from-schemas/navigation/getCategoryOut';
import slug from 'slug';
import { ImageSchema, ImageSourceSchema } from '@/interfaces/from-schemas/navigation/getProductOut';
import { backgroundUtil } from '@/utils/background.utils';

//TODO: zmiana na schemę z GM jak będzie działać
export interface StaffCollectionContentSchema {
    categoryNamesPath?: string[];
    categoryPath?: number[];
    category: GetCategoryOut;
    chronological?: boolean;
    cpid?: number;
    description: string;
    gallery: [];
    genres: [];
    grantExpression: string;
    id: number;
    images: [];
    keyCategoryId: number;
    name: string;
    reporting: {
        activityEvents: {
            contentItem: {
                type: string;
                value: string;
            };
        };
        gprism: {
            categoryPath: string;
        };
        planet: {
            content: string;
        };
        playerEvents: {
            contentItem: {
                type: string;
                value: string;
            };
        };
    };
    subCategoriesLabel: string;
    thumbnails: [];
    posters?: [];
}

export class StaffCollectionContentResultsModel implements AssetInterface {
    private ageGroup = 0;
    private allowDownload = false;
    private category: CategoryModel = {} as CategoryModel;
    private categoryNamesPath: string[] = [];
    private categoryPath: number[] = [];
    private countries: string[] = [];
    private cpid = 0;
    private name = '';
    private description = '';
    private duration = 0;
    private episodeTitle = '';
    private gallery = [];
    private genres: string[] = [];
    private grantExpression = '';
    private id = '';
    private images: ImageSchema[] = [];
    private mediaType: MEDIA_TYPES = MEDIA_TYPES.VOD;
    private posters: ImageSourceSchema[] = [];
    private product = {};
    private publicationDate = '';
    private published = false;
    private reporting: any;
    private serializationType = '';
    private shortTitle = '';
    private thumbnails: ImageSourceSchema[] = [];
    private title = '';
    private vote = '';
    public imageDisplayMode: IMAGE_DISPLAY_MODE = IMAGE_DISPLAY_MODE.THUMBNAILS;
    public categoryName = 'collection';
    public backgroundSrc;
    public isKeyCategory = false;

    constructor(definition: StaffCollectionContentSchema) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });

            this.category = this.buildCategoryModel(definition);
            this.backgroundSrc = backgroundUtil(this.thumbnails)?.src;

            // TODO: trzeba zrobić refactor, potwierdzić z biznesem kiedy ma być użyty autoplay w adresie.
            //Aktualnie doklejamy parametr autoplay dla kategorii kluczowej.
            this.isKeyCategory = (definition?.categoryNamesPath?.length || 0) > 0;
        }
    }
    private buildCategoryModel(definition: any) {
        if (definition.category) {
            return new CategoryModel(definition.category);
        }
        return new CategoryModel({
            categoryPath: definition.categoryPath,
            categoryNamesPath: definition.categoryNamesPath,
            cpid: definition.cpid,
            description: definition.description,
            genres: definition.genres,
            id: definition.id,
            name: definition.name,
        });
    }

    public getBackgroundSrc(): string | undefined {
        return this.backgroundSrc;
    }
    public getDescription() {
        return this.description;
    }
    public getType(): MEDIA_TYPES {
        return this.mediaType;
    }
    public getDetailsRoute(): ROUTES {
        if (this.isKeyCategory) {
            return ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY;
        }
        switch (this.getType()) {
            case MEDIA_TYPES.PACKET:
                return ROUTES.PACKET_DETAILS;
            case MEDIA_TYPES.MOVIE:
                return ROUTES.VOD_MOVIE_DETAILS;
            case MEDIA_TYPES.VOD:
                return this.hasSeason()
                    ? ROUTES.VOD_EPISODE_WITH_SEASON_DETAILS
                    : ROUTES.VOD_EPISODE_DETAILS;
            case MEDIA_TYPES.LIVE:
                return ROUTES.LIVE_DETAILS;
            case MEDIA_TYPES.TV:
                return ROUTES.CHANNEL_TV_DETAILS;
            default:
                return ROUTES.NOT_FOUND;
        }
    }
    public hasSeason() {
        return !!this.category && this.category.getCategoryPath().length >= 4;
    }
    public getImage(): ListElementImageInterface {
        const images = this.getThumbnails();
        const image = coverUtil(images, this.imageDisplayMode);

        return {
            src: image ? image.src : '',
            displayMode: this.imageDisplayMode,
        };
    }
    public getId() {
        return this.id;
    }
    public getMetadata(): string {
        return '';
    }
    public getRouteParams(): { [key: string]: any } {
        if (this.isKeyCategory) {
            return this.buildVodAutoplayRouteParams();
        }

        switch (this.getType()) {
            case MEDIA_TYPES.MOVIE:
                return this.buildMovieRouteParams();
            case MEDIA_TYPES.VOD:
                if (this.hasSeason()) {
                    return this.buildVodWithSeasonRouteParams();
                }
                return this.buildVodRouteParams();
            case MEDIA_TYPES.LIVE:
                return this.buildEventRouteParams();
            case MEDIA_TYPES.TV:
                return this.buildChannelRouteParams();
            // Nieobsługiwany mediaType

            default:
                return {};
        }
    }
    public buildChannelRouteParams() {
        return {
            channelTitle: slug(this.title),
            channelId: this.id,
        };
    }
    public buildMovieRouteParams() {
        return {
            title: slug(this.title),
            id: this.id,
        };
    }
    public buildVodWithSeasonRouteParams() {
        const categoryNamesPath = this.category?.getCategoryNamesPath();
        const seasonName = categoryNamesPath?.[3] ? slug(categoryNamesPath[3]) : undefined;
        return {
            ...this.buildVodRouteParams(),
            seasonName,
            seasonId: this.category?.getCategoryPath()[3],
        };
    }
    public buildEventRouteParams() {
        return {
            eventTitle: slug(this.title),
            eventId: this.id,
        };
    }
    public buildVodAutoplayRouteParams() {
        const categoryNamesPath = this.category.getCategoryNamesPath();
        const vodCategoryTitle = categoryNamesPath?.[2] ? slug(categoryNamesPath[2]) : undefined;
        const categoryId = this.id;
        return {
            vodCategoryTitle,
            categoryId,
        };
    }
    public hasFullCategory(): boolean {
        const categoryName = this.category?.getCategoryNamesPath()?.[2];
        const categoryId = this.category?.getCategoryPath()?.[2];
        return typeof categoryName !== 'undefined' && typeof categoryId !== 'undefined';
    }
    public buildVodRouteParams() {
        const categoryNamesPath = this.category?.getCategoryNamesPath();
        const categoryPath = this.category?.getCategoryPath();
        const mainCategory = categoryNamesPath?.[1] ? slug(categoryNamesPath[1]) : undefined;
        const vodCategoryTitle = categoryNamesPath?.[2] ? slug(categoryNamesPath[2]) : undefined;
        const vodCategoryId = categoryPath?.[2] ? categoryPath[2] : undefined;

        return {
            mainCategory,
            vodCategoryTitle,
            vodCategoryId,
            vodTitle: slug(this.title),
            vodId: this.id,
        };
    }

    public getCategoryName(): string {
        return this.category.getId().toString();
    }
    public getTitle() {
        const categoryNamesPath = this.category.getCategoryNamesPath();
        const isCategoryTitleAvailable = categoryNamesPath.length > 4;
        const categoryTitle = isCategoryTitleAvailable
            ? `${categoryNamesPath[2]} ${categoryNamesPath[3]}`
            : '';
        return this.title || categoryTitle;
    }
    public getThumbnails() {
        return this.thumbnails;
    }

    public getGallery() {
        return this.gallery;
    }

    public goToDetails(options?: goToDetailsOptionsType): void {
        const { trimPurchaseHistory, replaceRoute } = options || {};
        const route = this.getDetailsRoute();
        const params = this.getRouteParams();

        if (trimPurchaseHistory) {
            Object.assign(params, { trimPurchaseHistory });
        }

        replaceRoute ? Router.replaceRoute(route, params) : Router.pushRoute(route, params);
    }
}
