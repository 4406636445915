import React from 'react';
import { useParams } from 'react-router';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import useGetProduct from '@/api/navigation/use-get-product';
import { PRODUCT_SUBTYPES, PRODUCT_TYPES } from '@/types/media.type';
import { useGetPossessions } from '@/api/payments/use-get-possessions';
import { LoadingWrapper } from '@/components/shared/Loader';
import { PaymentSuccess } from '@/components/views/PaymentSuccess';
import { getMediaCpidFromProductId } from '@/helpers/product.helper';
import useGetMedia from '@/api/navigation/use-get-media';
import { PaymentSuccessDefaultMessage } from '@/components/views/PaymentSuccess/PaymentSuccessDefaultMessage';
import { useGetProductSubscriptions } from '@/api/payments/use-get-product-subscriptions';

interface RouteParams {
    id: string;
    type: PRODUCT_TYPES;
    subType: PRODUCT_SUBTYPES;
    offerId: string;
    optionId: string;
    possessionId: string;
}

const PurchaseStatus = () => {
    const { id, type, subType, offerId, optionId, possessionId } = useParams<RouteParams>();
    const baseProductId = { id, type, subType };

    const { data: possessions, isFetching: isPossessionFetching } = useGetPossessions();
    const possession = possessions?.find((possession) => possession.getId() === possessionId);
    const possessionProductId = possession?.getProductId();

    const possessionProductParams = possessionProductId && { product: possessionProductId };
    const { data: possessionProduct, isFetching: isProductFetching } =
        useGetProduct(possessionProductParams);

    const cpid = getMediaCpidFromProductId(baseProductId);
    const mediaParams = typeof cpid !== 'undefined' ? { mediaId: id, cpid } : undefined;
    const { data: media, isFetching: isMediaFetching } = useGetMedia(mediaParams);
    const { data: productSubscriptions, isFetching: isSubscriptionsFetching } =
        useGetProductSubscriptions();

    const isFetching =
        isPossessionFetching ||
        isProductFetching ||
        isMediaFetching ||
        isSubscriptionsFetching ||
        false;

    const getProductSubscription = () => {
        return productSubscriptions.find(
            (subscription) => subscription.getId() === possession?.getProductSubscriptionId(),
        );
    };
    const buildPaymentSuccess = () => {
        const possessionOffer = possessionProduct?.findOffer(offerId);
        const possessionOption = possessionOffer?.findOption(optionId);

        if (possession && possessionProduct && possessionOption) {
            const isPlusbill = possessionOption.isPlusbill();
            const productSubscription = getProductSubscription();
            return (
                <PaymentSuccess
                    possession={possession}
                    possessionProduct={possessionProduct}
                    baseProductId={baseProductId}
                    isPlusbill={isPlusbill}
                    media={media}
                    productSubscription={productSubscription}
                />
            );
        }

        return <PaymentSuccessDefaultMessage possessionProduct={possessionProduct} />;
    };

    return (
        <BaseLayout defaultBg fullscreen>
            <LoadingWrapper isFetching={isFetching}>{buildPaymentSuccess()}</LoadingWrapper>
        </BaseLayout>
    );
};

export default PurchaseStatus;
