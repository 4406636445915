import { getSubcategoriesPromise } from '@/api/navigation/category/get-subcategories';
import { getCategoryPromise } from '@/api/navigation/category/get-category';
import { getLatelyWatchedContentDataListPromise } from '@/api/user-content/use-get-lately-watched-content-data-list';
import { EnhancedContext } from '@/modules/enhance-context';
import { getCategoryContentPromise } from '@/api/navigation/category/get-category-content';
import { getSubcategoryId } from './get-subcategory-id.helper';

const { initialLimit } = process.env.pages.catalog;

export async function fetchMediaPromises(
    id: number,
    enhancedContext: EnhancedContext,
    categoryId?: number,
) {
    const { smartweb, query } = enhancedContext;
    const { getMediaApiPromise, rpcAuth } = smartweb;
    const { rpcSessionData } = rpcAuth;
    const { season } = query;
    let catid = id;
    let isChronological = false;

    if (categoryId) {
        const params = { catid: categoryId };

        const subcategoriesRaw = await getSubcategoriesPromise(params, getMediaApiPromise);
        const subCatId = getSubcategoryId(subcategoriesRaw || [], season || '');
        if (subCatId) catid = subCatId;

        const categoryResponse = await getCategoryPromise(params, getMediaApiPromise);
        isChronological = categoryResponse?.chronological || false;
    }

    const catParams = { catid, offset: 0, limit: initialLimit, filters: [] };
    await getCategoryContentPromise(catParams, getMediaApiPromise);

    if (rpcSessionData && categoryId) {
        await getLatelyWatchedContentDataListPromise(getMediaApiPromise, +categoryId);
    }

    return isChronological;
}
