import { ErrorContainer } from './styles';
import { usePlayerStatus } from '@/hooks/player/use-player-status';
import { Message } from '@/components/shared/Message';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { PlayerErrorGroup } from '@/interfaces/player.interface';
import { ConcurrentPlaybackErrorOverlay } from './ConcurrentPlaybackErrorOverlay';
import { ErrorDescription } from '@/components/commons/Player/ErrorDescription';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';

export const ErrorOverlay = () => {
    const { error } = usePlayerStatus();

    if (!error) {
        return null;
    }

    if (error?.group === PlayerErrorGroup.CONCURRENCY_LIMIT) {
        return <ConcurrentPlaybackErrorOverlay />;
    }

    if (error.backendInfo && error.backendInfo.userMessage && error.backendInfo.errorCode) {
        Router.pushRoute(
            ROUTES.CUSTOMER_CARE_ERROR_PAGE,
            { errorCode: String(error.backendInfo.errorCode) },
            {
                errorCodeMessageToDisplay: error.backendInfo.userMessage,
            },
        );
    }

    const title = `${t('player.error')} ${error.code}`;

    return (
        <>
            {error.code && (
                <ErrorContainer>
                    <Message
                        title={title}
                        descriptionJSX={<ErrorDescription />}
                        icon={ICONS.ALERT}
                    />
                </ErrorContainer>
            )}
        </>
    );
};
