export enum GUTTER {
    NONE = 0,
    XSMALL = 0.5,
    SLIGHT = 1.1,
    SMALL = 1.5,
    MEDIUM = 2,
    NORMAL = 3,
    LARGE = 4,
    BIG = 6,
    HUGE = 9,
}

export interface IGutterProps {
    gutter?: GUTTER;
}
