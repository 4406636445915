import { LIST_TYPE } from '@/types/recommendation-list.type';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import {
    Dot,
    IconGroup,
    LiveBadge,
    Metadata,
    METADATA_TYPES,
    OnAirBadge,
} from '@/components/shared/Metadata';
import { PageContentOwnProps } from '@/components/views/common/types';
import { useTimeout } from '@/hooks/use-timeout.hook';
import { LiveModel } from '@/models/live/live.model';
import { MediaListItemModel } from '@/models/media-list-item.model';
import { GUTTER } from '@/types/gutter.type';
import { METADATA_TYPE } from '@/types/media.type';
import React from 'react';
import { StyledContent, StyledData, StyledDot, StyledMetadataWrapper } from './styles';

const PageContentRaw = ({ actualVod, view }: PageContentOwnProps) => {
    const { timeout } = process.env.pages;

    const activeItem = useTimeout(actualVod, timeout);
    const asset = activeItem.getAsset?.();
    const isOnAir = activeItem.isOnAir?.();
    const isLive = activeItem.isLive?.();
    const isMetaData = activeItem.getMetadata?.(METADATA_TYPE.MAIN);

    return (
        <StyledContent>
            <StyledData view={view}>
                {isMetaData && (
                    <Metadata
                        data={activeItem.getMetadata(METADATA_TYPE.MAIN)}
                        type={METADATA_TYPES.PRIMARY}
                    />
                )}

                {isLive && <LiveBadge mode={METADATA_TYPES.PRIMARY} />}
                {isOnAir && asset instanceof LiveModel && (
                    <OnAirBadge mode={METADATA_TYPES.PRIMARY} />
                )}
                <MainText
                    text={activeItem.getTitle()}
                    mode={MAINTEXT_KIND.TITLE}
                    gutter={GUTTER.SMALL}
                />
                <MainText
                    isDescription
                    text={activeItem.getDescription() || asset?.getDescription()}
                    mode={MAINTEXT_KIND.DESCRIPTION}
                    gutter={GUTTER.SMALL}
                />
                {asset instanceof MediaListItemModel && view !== LIST_TYPE.PROMOBOX && (
                    <StyledMetadataWrapper>
                        <IconGroup icon={asset.getAgeGroup()} iconSize={2.0} />
                        {asset.getBottomMetadata().length > 0 && (
                            <StyledDot>
                                <Dot />
                            </StyledDot>
                        )}
                        <Metadata data={asset.getBottomMetadata()} type={METADATA_TYPES.TERTIARY} />
                    </StyledMetadataWrapper>
                )}
                {asset instanceof LiveModel && view !== LIST_TYPE.PROMOBOX && (
                    <IconGroup icon={asset.getAgeGroup?.() || 0} iconSize={2.4} />
                )}
            </StyledData>
        </StyledContent>
    );
};

export const PageContent = React.memo(PageContentRaw);
