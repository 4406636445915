import { useEffect, useReducer } from 'react';
import { ChannelSwitchAction, ChannelSwitchPhase } from '@/interfaces/player.interface';
import { usePlayerStatus } from './use-player-status';

export const usePlayerChannelSwitchPhase = (channelId: string) => {
    const { buffering } = usePlayerStatus();

    const [switchingPhase, dispatch] = useReducer(
        (state: ChannelSwitchPhase, action: ChannelSwitchAction) => {
            if (action === ChannelSwitchAction.CHANNEL_CHANGED) {
                return ChannelSwitchPhase.AWAITING_BUFFERING;
            }
            switch (state) {
                case ChannelSwitchPhase.AWAITING_BUFFERING:
                    if (action === ChannelSwitchAction.BUFFERING_START) {
                        return ChannelSwitchPhase.BUFFERING;
                    }
                    break;
                case ChannelSwitchPhase.BUFFERING:
                    if (action === ChannelSwitchAction.BUFFERING_END) {
                        return ChannelSwitchPhase.DONE;
                    }
                    break;
            }
            return state;
        },
        ChannelSwitchPhase.INITIAL,
    );

    useEffect(() => {
        dispatch(ChannelSwitchAction.CHANNEL_CHANGED);
    }, [channelId]);

    useEffect(() => {
        dispatch(
            buffering ? ChannelSwitchAction.BUFFERING_START : ChannelSwitchAction.BUFFERING_END,
        );
    }, [buffering]);

    return {
        switchingPhase,
        loadingEPGSlider: [ChannelSwitchPhase.BUFFERING, ChannelSwitchPhase.INITIAL].includes(
            switchingPhase,
        ),
    };
};
