import LoaderContextProvider from '@/contexts/loader/loader.context';
import styled, { ThemeProvider } from 'styled-components';
import NavigationRoot from '@/lib/NavigationRoot';
import { MainLoader } from '@/components/shared/Loader';
import { BackgroundImage, SHADE_MODE } from '@/components/shared/BackgroundImage';
import GlobalStyle from './GlobalStyle';
import React from 'react';
import { useAppContext } from '@/contexts/app/app.context';
import { switchTheme } from '@/helpers/switch-theme.helper';
import { Menu } from '@/components/shared/Menu';
import { EdgeShade } from '@/components/shared/EdgeShade';
import { Z_INDEX } from '@/targets/default/theme';
import { VirtualKeyboardContextProvider } from '@/contexts/keyboard/virtual.keyboard.context';
import logo from '@/public/polsat-box-go-stb/assets/logo-small.png';
import { HelpButton } from '../../Buttons';
import { Nav, navHorizontal } from 'nav-tree';
import { useGetAccurateParamsForHelpConfig } from '@/hooks/help/use-get-accuurate-params-for-help-config.hook';

const MainContainerStyled = styled.div`
    position: relative;
    z-index: ${Z_INDEX.BASE_LAYOUT};
    height: 100%;
`;
export const LogoStyled = styled.img.attrs(() => ({
    src: logo,
}))`
    z-index: 1;
    left: 5rem;
    max-height: 6rem;
    position: absolute;
    right: 0;
    top: 4.9rem;
`;

export const ButtonWrapper = styled(Nav as any)`
    position: absolute;
    bottom: 8rem;
    right: 8rem;
    z-index: ${Z_INDEX.BACK_BUTTON};
`;

const LogoT = () => {
    return <LogoStyled />;
};
const BaseLayout = ({
    children,
    fullscreen,
    showLogo,
    defaultBg,
    topShade,
    bottomShade,
    bgShade,
}: IOwnProps) => {
    const { theme: contextTheme } = useAppContext();

    const { contextType, contextValue, showButton } = useGetAccurateParamsForHelpConfig();

    return (
        <ThemeProvider theme={switchTheme(contextTheme)}>
            <GlobalStyle />
            <BackgroundImage defaultBg={defaultBg} bgShade={bgShade} />
            <VirtualKeyboardContextProvider>
                <NavigationRoot>
                    <LoaderContextProvider>
                        {/* @ts-ignore */}
                        <Nav style={{ height: '100%' }} func={navHorizontal} restoreLastFocus>
                            {!fullscreen && <Menu />}
                            {showLogo && <LogoT />}
                            <MainLoader />
                            <MainContainerStyled>
                                {topShade && <EdgeShade top />}
                                {children}
                                {bottomShade && <EdgeShade />}
                            </MainContainerStyled>
                        </Nav>
                        {/*TODO: ukrywamy na chwile guzik pomocy */}
                        {/* {showButton && (
                            <HelpButton contextType={contextType} contextValue={contextValue} />
                        )} */}
                    </LoaderContextProvider>
                </NavigationRoot>
            </VirtualKeyboardContextProvider>
        </ThemeProvider>
    );
};

export default BaseLayout;

interface IOwnProps {
    children: React.ReactNode;
    fullscreen?: boolean;
    defaultBg?: boolean;
    topShade?: boolean;
    bottomShade?: boolean;
    bgShade?: SHADE_MODE;
    showLogo?: boolean;
}
