export interface OneTrustAPIInterface {
    dataSubjectParams: {
        id: string;
        isAnonymous: boolean;
        token: string;
    };
    Init: () => void;
    ToggleInfoDisplay: () => void;
    LoadBanner: () => void;
    syncConsentProfile?: (userId: string, token: string, isAnonymous?: boolean) => void;
}

export class OneTrustService {
    private readonly reloadDelay = 1000;

    private get API(): OneTrustAPIInterface | null {
        // @ts-ignore INFO(ksyrytczyk): Brzydkie, ale przez dataSubjectParams nie ma jak wiarygodnie sprawdzić gotowość API
        return typeof window['OneTrust']?.Init === 'function' ? window['OneTrust'] : null;
    }

    public update(userId: string, token: string): void {
        const dataSubjectParams = {
            id: userId,
            token: token,
            isAnonymous: false,
        };

        // INFO(ksyrytczyk): Bardzo ważne przypisanie, aby nie stracić danych w czasie gdy biblioteka jeszcze się ładuje
        try {
            // @ts-ignore
            window['OneTrust'].dataSubjectParams = dataSubjectParams;
        } catch (err) {
            // Object dataSubjectParams doesn't exists
        }

        this.sync(userId, token);
    }

    public sync(userId: string, token: string): void {
        if (!this.API) {
            return;
        }

        const isAnonymous = !userId;
        // INFO(ksyrytczyk): metoda może nie występować - w panelu musi być załączona flaga SPA
        this.API.syncConsentProfile?.(userId, token, isAnonymous);
    }

    public init(): void {
        if (!this.API) {
            return;
        }

        const otConsentSdk = document.getElementById('onetrust-consent-sdk');

        if (otConsentSdk) {
            otConsentSdk.remove();
        }

        this.API.Init();

        setTimeout(() => {
            this.API?.LoadBanner();

            const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings');

            for (let i = 0; i < toggleDisplay.length; i++) {
                toggleDisplay[i].addEventListener('click', (event) => {
                    event.stopImmediatePropagation();
                    this.API?.ToggleInfoDisplay();
                });
            }
        }, this.reloadDelay);
    }

    public showPopup(): void {
        if (!this.API) {
            return;
        }

        this.API?.ToggleInfoDisplay();
    }
}
