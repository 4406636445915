import React, { RefObject, useEffect } from 'react';
import { Nav } from 'nav-tree';
import { ProcessingLoader } from '@/components/shared/Loader';
import {
    BottomBarContainer,
    ControlsContainer,
    OverlayContainer,
    SideBarContainer,
    TopBarContainer,
    CenterBarContainer,
    BottomBackground,
} from './shared/styles';
import { BufferingOverlay } from './shared/BufferingOverlay';
import { ErrorOverlay } from './shared/ErrorOverlay';
import { usePlayerRemoteControl } from '@/hooks/player/use-player-remote-control';
import { useDebouncedState } from '@/hooks/use-debounced-state.hook';
import { usePlayerStatus } from '@/hooks/player/use-player-status';

interface IControlsProps {
    isFetchingMediaDetails?: boolean;
    visible?: boolean;
    topBar?: React.ReactNode;
    centerBar?: React.ReactNode;
    bottomBar?: React.ReactNode;
    sideBar?: React.ReactNode;
    fixedOverlay?: React.ReactNode;
    overlay?: React.ReactNode;
    onDestroy?: () => void;
    bottomGradientPosition?: number;
    navRef?: RefObject<any>;
    mountCallback?: (isMounted: boolean) => void;
}

const ControlsBaseRaw = ({
    topBar,
    centerBar,
    bottomBar,
    sideBar,
    overlay,
    fixedOverlay,
    visible,
    onDestroy,
    isFetchingMediaDetails,
    bottomGradientPosition = 0,
    navRef,
    mountCallback,
}: IControlsProps) => {
    const {
        player: {
            transition: { visibilityDelay },
        },
    } = process.env;

    const isMounted = useDebouncedState(visible, (v) => (v ? 0 : visibilityDelay));
    const { error } = usePlayerStatus();

    useEffect(() => {
        if (!isMounted) {
            onDestroy?.();
        }
        if (mountCallback) {
            mountCallback(Boolean(isMounted));
        }
    }, [isMounted, onDestroy]);

    usePlayerRemoteControl({ isSideBarOpen: !!sideBar, onDestroy });

    if (error) {
        return <ErrorOverlay error={error} />;
    }

    return (
        // @ts-ignore
        <Nav ref={navRef}>
            <BufferingOverlay />
            {isMounted && (
                <ControlsContainer visible={visible}>
                    {bottomBar && <BottomBackground cover={bottomGradientPosition} />}
                    {topBar && <TopBarContainer>{topBar}</TopBarContainer>}
                    {centerBar && <CenterBarContainer>{centerBar}</CenterBarContainer>}
                    {bottomBar && <BottomBarContainer>{bottomBar}</BottomBarContainer>}
                    {sideBar && <SideBarContainer>{sideBar}</SideBarContainer>}
                    {overlay && <OverlayContainer>{overlay}</OverlayContainer>}
                </ControlsContainer>
            )}
            {fixedOverlay && <OverlayContainer passThrough>{fixedOverlay}</OverlayContainer>}
            <ProcessingLoader isFetching={Boolean(isFetchingMediaDetails)} />
        </Nav>
    );
};

export const ControlsBase = React.memo(ControlsBaseRaw);
