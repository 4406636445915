import { GetProductIn } from '@/interfaces/from-schemas/navigation/getProductIn';
import { CPID } from '@/types/media.type';
export interface DeepLinkParams {
    id?: string;
    cmd: string;
    chanid?: string;
    packid?: string;
    catid?: string;
    offerid?: string;
    token?: string;
}
export const getMediaParams = (params: DeepLinkParams) => {
    // live
    if (params.id && params.cmd === 'startlive') {
        return {
            cpid: CPID.EVENT_OR_CHANNEL as 0,
            mediaId: params.id,
        };
    }
    //vod
    if (params.id) {
        return {
            cpid: CPID.VOD_OR_MOVIE as 1,
            mediaId: params.id,
        };
    }
    //channel-tv
    if (params.chanid) {
        return {
            cpid: CPID.EVENT_OR_CHANNEL as 0,
            mediaId: params.chanid,
        };
    }
};
export const getPacketParams = (params: DeepLinkParams) => {
    if (params?.packid && params?.cmd === 'gotopack') {
        return {
            product: {
                type: 'multiple',
                subType: 'packet',
                id: params.packid,
            },
        } as GetProductIn;
    }
};

export const getOfferParams = (params: DeepLinkParams) => {
    if (params?.packid && params?.cmd === 'showoffer') {
        return {
            product: {
                type: 'multiple',
                subType: 'packet',
                id: params.packid,
            },
        } as GetProductIn;
    }
};

export const getCategoryParams = (params: DeepLinkParams) => {
    if (params?.catid && params?.cmd === 'gotocat') {
        return { catid: Number(params?.catid) };
    }
    if (params?.id && params?.cmd === 'startprogram') {
        return { catid: Number(params?.id) };
    }
};
