import React, { useMemo } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { buildCollectionsListConfig } from '@/helpers/pages/build-collections-list-config.helper';
import { RecommendationView } from '@/components/views/common';
import { useExitAppConfirmation } from '@/hooks/use-exit-app-confirmation';
import { useRedirectToDeepLink } from '@/hooks/use-redirect-to-deep-link';
import { useGetCollectionsList } from '@/api/navigation/recommendations/use-get-collections-list';

const Home = () => {
    const home = process.env.pages.home;
    const { placeType } = home;
    const listsParams = { place: { type: placeType, value: '' } };
    const collectionsConfig = useMemo(() => buildCollectionsListConfig(home), [home]);

    useRedirectToDeepLink();

    useExitAppConfirmation(true);
    const { collectionsList, isFetching: isFetchingCollectionsList } = useGetCollectionsList(
        collectionsConfig,
        listsParams,
    );

    return (
        <BaseLayout>
            <RecommendationView
                config={collectionsConfig}
                params={listsParams}
                collectionsList={collectionsList}
                isFetchingCollectionsList={isFetchingCollectionsList}
            />
        </BaseLayout>
    );
};

export default Home;
