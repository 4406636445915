import React, { useContext, useEffect, useMemo } from 'react';
import { PlayableButton } from '@/components/shared/Buttons';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { ICONS } from '@/components/shared/Icon';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { ProcessingLoader } from '@/components/shared/Loader';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { Message } from '@/components/shared/Message';
import { IconGrid } from '@/components/views/Profile/IconGrid';
import { ReferrerContext } from '@/contexts/referrer/referrer.context';
import { useBackAction } from '@/hooks/use-back-action';
import { useWatchAs } from '@/hooks/use-watch-as.hook';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import { useExitAppConfirmation } from '@/hooks/use-exit-app-confirmation';
import { MESSAGE_SIZE } from '@/components/shared/Message/styles';

const MAX_PROFILES = process.env.user.maxProfiles;

const WatchAs = () => {
    const { referrer, setReferrer } = useContext(ReferrerContext);
    const {
        error: errorToDisplay,
        isLoading,
        profiles,
        selectProfile,
        selectProfileById,
        sessionProfile,
    } = useWatchAs();

    const numProfiles = profiles.length;
    const hasSingleProfile = numProfiles === 1;
    const allowAddNewProfile = numProfiles < process.env.user.maxProfiles;
    const isFromAccountPage = referrer === ROUTES.ACCOUNT;

    useExitAppConfirmation();
    useBackAction(() => null, !sessionProfile);

    useEffect(() => {
        if (!isFromAccountPage && hasSingleProfile) {
            selectProfile(profiles[0]);
        }
    }, [referrer, numProfiles]);

    const noProfiles = (
        <>
            <MainText
                text={t('pages.profile.watch-as.header.add')}
                mode={MAINTEXT_KIND.PAGE_TITLE}
                gutter={GUTTER.BIG}
            />

            <Message
                messageSize={MESSAGE_SIZE.LG}
                description={[
                    t('pages.profile.watch-as.description-1'),
                    t('pages.profile.watch-as.description-2'),
                ]}
            />

            <FormContainer>
                <PlayableButton
                    text={t('button.profile.add-profile')}
                    icon={ICONS.PLUS}
                    onButtonAction={() => {
                        setReferrer(ROUTES.PROFILE_WATCH_AS);
                        return Router.pushRoute(ROUTES.PROFILE_ADD);
                    }}
                    defaultFocused
                />
            </FormContainer>

            <Message
                messageSize={MESSAGE_SIZE.LG}
                description={t('pages.profile.watch-as.description-3')}
            />
        </>
    );

    const withProfiles = useMemo(() => {
        return (
            <>
                <MainText
                    text={t('pages.profile.watch-as.header.watch')}
                    mode={MAINTEXT_KIND.PAGE_TITLE}
                    gutter={GUTTER.BIG}
                />

                <IconGrid
                    data={profiles.map((profileData) => {
                        return {
                            iconId: profileData.profileId,
                            name: profileData.name,
                            iconUrl: profileData.avatarUrl,
                        };
                    })}
                    col={MAX_PROFILES}
                    selectProfileById={selectProfileById}
                />

                <FormContainer>
                    {allowAddNewProfile && (
                        <PlayableButton
                            text={t('button.profile.add-profile')}
                            onButtonAction={() => {
                                setReferrer(ROUTES.PROFILE_WATCH_AS);
                                return Router.pushRoute(ROUTES.PROFILE_ADD);
                            }}
                            data-testing="profile.add-profiles"
                        />
                    )}
                    <PlayableButton
                        text={t('button.profile.edit-profiles')}
                        onButtonAction={() => {
                            setReferrer(ROUTES.PROFILE_WATCH_AS);
                            return Router.pushRoute(ROUTES.PROFILE_SELECT_TO_EDIT);
                        }}
                        data-testing="profile.edit-profiles"
                    />
                </FormContainer>
            </>
        );
    }, [profiles, allowAddNewProfile]);

    const renderBody = () => {
        if (isLoading) {
            return <ProcessingLoader isFetching={true} />;
        }

        if (hasSingleProfile && !isFromAccountPage) {
            return null;
        }

        return numProfiles > 0 ? withProfiles : noProfiles;
    };

    return (
        <BaseLayout fullscreen defaultBg>
            <FullscreenNavContainer
                body={renderBody()}
                fullBody={numProfiles > 0}
                error={errorToDisplay}
            />
        </BaseLayout>
    );
};

export default WatchAs;
