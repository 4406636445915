// @ts-nocheck
// TODO: Add typescript support
import { KEYS } from './keys';

const getSpecialKeys = () => {
    try {
        return {
            [KEYS.REWIND]: VK_REWIND,
            [KEYS.PAUSE]: VK_PAUSE,
            [KEYS.FORWARD]: VK_FAST_FWD,
            [KEYS.PLAY]: VK_PLAY,
            [KEYS.STOP]: VK_STOP,
        };
    } catch (e) {
        return {};
    }
};

export const SaphiKeys = getSpecialKeys;
