import { Icon } from '@/components/shared/Icon';
import React from 'react';
import { ListElementTopMarkingsWrapper } from '@/components/shared/ListElement/Markings/style';
import { ListElementTopMarkingsProps } from '@/components/shared/ListElement/Markings/types';
import { useMarkingsIcons } from '@/hooks/use-markings-icon';

const ListElementTopMarkings = ({ asset, grantExpression }: ListElementTopMarkingsProps) => {
    const iconsName = useMarkingsIcons(asset, grantExpression);

    const getIcons = () => {
        return iconsName.map((iconName, index) => <Icon name={iconName} key={index} />);
    };
    return <ListElementTopMarkingsWrapper>{getIcons()}</ListElementTopMarkingsWrapper>;
};

export const ListElementTopMarkingsMemo = React.memo(ListElementTopMarkings);
