import {
    ThumbnailImage,
    ThumbnailContainer,
    ThumbnailPlaceholder,
    ThumbnailsContainer,
    ThumbnailsInnerContainer,
    Thumbnail,
} from './styles';
import { useEffect, useState } from 'react';
import { usePlayerInstance } from '@/hooks/player/use-player-instance';
import isNumber from 'lodash/isNumber';
import { usePlayerGuiStateContext } from '@/hooks/player/use-player-gui-state';

const DEFAULT_THUMBNAIL_WIDTH = 320;
const DEFAULT_THUMBNAIL_HEIGHT = 180;

export const Thumbnails = () => {
    const player = usePlayerInstance();
    const { pendingSeek, seekHandlePosition } = usePlayerGuiStateContext();

    const [thumbnail, setThumbnail] = useState<IPlayer.Thumbnail>();
    const [placeholder, setPlaceholder] = useState(true);

    const time = pendingSeek?.desiredTime;
    const thumbnailWidth = thumbnail?.width || DEFAULT_THUMBNAIL_WIDTH;
    const thumbnailHeight = thumbnail?.height || DEFAULT_THUMBNAIL_HEIGHT;

    const buildThumbnailPosition = () => {
        const thumbnailCenter = thumbnailWidth / 2;
        const thumbailPosition = seekHandlePosition - thumbnailCenter;

        return Math.max(thumbailPosition, 0);
    };

    useEffect(() => {
        if (player.isThumbnailsSupported?.() && isNumber(time)) {
            setPlaceholder(true);

            player.getThumbnail(time).then((newThumbnail) => {
                if (newThumbnail) {
                    setThumbnail(newThumbnail);
                    setPlaceholder(false);
                }
            });
        }
    }, [player, time]);

    if (!thumbnail) {
        return null;
    }

    return (
        <ThumbnailsContainer isVisible={isNumber(time)} $height={thumbnailHeight}>
            <ThumbnailsInnerContainer>
                <ThumbnailContainer
                    $width={thumbnailWidth}
                    $height={thumbnailHeight}
                    position={buildThumbnailPosition()}
                >
                    <Thumbnail $width={thumbnailWidth} $height={thumbnailHeight} highlight>
                        {placeholder && <ThumbnailPlaceholder />}
                        <ThumbnailImage
                            src={thumbnail.src}
                            $width={thumbnail.tileWidth}
                            $height={thumbnail.tileHeight}
                            positionX={thumbnail.positionX}
                            positionY={thumbnail.positionY}
                        />
                    </Thumbnail>
                </ThumbnailContainer>
            </ThumbnailsInnerContainer>
        </ThumbnailsContainer>
    );
};
