import { useState } from 'react';
import { Nav } from 'nav-tree';
import { Icon } from '../../Icon';
import { Button, BUTTON_TYPE } from '..';
import { StyledIconWrapper, StyledButtonContent, StyledText } from './styles';
import { PlayableButtonProps } from './types';
import { GUTTER } from '@/types/gutter.type';

const isTransparentOnInactive = process.env.playableButton.isTransparentOnInactive;

export const PlayableButton = ({
    defaultFocused = false,
    disabled = false,
    func,
    onButtonAction,
    navId,
    onNav,
    text,
    icon,
    style,
    overlay,
    transparentOnInactive = isTransparentOnInactive,
    gutter = GUTTER.NORMAL,
    'data-testing': dataTesting,
    variant,
    className,
    onlyIcon = false,
    onlyText = false,
    title,
}: PlayableButtonProps): JSX.Element => {
    const [btnActive, setBtnActive] = useState<boolean>(false);

    const onButtonNav = (isActive: boolean) => {
        setBtnActive(isActive);

        if (onNav) {
            onNav(isActive);
        }
    };

    const onBtnEnter = () => {
        if (disabled) {
            return;
        }
        onButtonAction();
    };

    const buildPlayableButton = () => {
        return (
            <Button
                data-testing={dataTesting}
                fullWidth
                btnType={BUTTON_TYPE.PRIMARY}
                active={!disabled && btnActive}
                disabled={disabled}
                className={className}
                title={text || title || ''}
            >
                {overlay}
                <StyledButtonContent
                    active={!disabled && btnActive}
                    onlyIcon={onlyIcon || !text}
                    transparentOnInactive={transparentOnInactive}
                    variant={variant}
                    className="playable-button-content"
                >
                    {icon && (
                        <>
                            {!onlyText && (
                                <StyledIconWrapper active={btnActive}>
                                    <Icon name={icon} />
                                </StyledIconWrapper>
                            )}
                            {!onlyIcon && text && <StyledText>{text}</StyledText>}
                        </>
                    )}

                    {!onlyIcon && !icon && text}
                </StyledButtonContent>
            </Button>
        );
    };

    return (
        // @ts-ignore
        <Nav
            func={func}
            onNav={onButtonNav}
            onEnterDown={onBtnEnter}
            onClick={onBtnEnter}
            navId={navId}
            defaultFocused={defaultFocused}
            autofocusOn={['focus', 'mouseenter']}
            style={{
                ...style,
                marginBottom: `${gutter}rem`,
            }}
        >
            {buildPlayableButton()}
        </Nav>
    );
};
