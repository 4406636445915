import React, { useCallback } from 'react';
import t from '@/lib/i18n';
import { Icon, ICONS } from '@/components/shared/Icon';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { IconGroup, Metadata, METADATA_TYPES } from '@/components/shared/Metadata';
import { StyledIconWrapper } from '@/components/shared/Metadata/style';
import { MediaButtons } from '@/components/views/MediaButtons';
import { GUTTER } from '@/types/gutter.type';
import {
    ButtonGroupContainer,
    ContentContainer,
    InfoText,
    InfoTextContainer,
    MainTextContainer,
    MetadataContainer,
    PlatformsContainer,
    PlatformsMetadataContainer,
    VerticalLine,
} from './styles';
import { MediaButtonInterface } from '@/interfaces/media-button.interface';
import { useRightPanel } from '@/contexts/right-panel/right-panel.context';
import { PacketPageContentProps } from './types';

const PacketPageContentRaw = ({ packet, focusRef, hide }: PacketPageContentProps) => {
    const lastButtonIndex = packet.buttons.length - 1;
    const { rightPanelOpen, setRightPanelOpen } = useRightPanel();
    const showVerticalLine =
        packet.platforms.length > 0 && packet.metadata.length > 0 && packet.locationRestrictionText;

    const handleButtonAction = useCallback(
        (button: MediaButtonInterface, index: number) => {
            if (lastButtonIndex === index) {
                setRightPanelOpen(true);
            } else {
                button.onButtonAction();
            }
        },
        [lastButtonIndex, setRightPanelOpen],
    );

    const handleRightPanel = (isActive: boolean) => {
        if (isActive && rightPanelOpen) {
            setRightPanelOpen(false);
        }
    };
    return (
        <ContentContainer hide={hide}>
            <Metadata data={t('pages.packet.packet')} type={METADATA_TYPES.PRIMARY} />
            <MainTextContainer>
                <MainText text={packet.name} mode={MAINTEXT_KIND.TITLE} gutter={GUTTER.SMALL} />
                <MainText text={packet.description} mode={MAINTEXT_KIND.DESCRIPTION} />
            </MainTextContainer>

            <PlatformsMetadataContainer>
                <PlatformsContainer>
                    <IconGroup icon={ICONS.PACKET} iconSize={2.9} noMargin />
                </PlatformsContainer>

                <MetadataContainer>
                    {packet.locationRestrictionText && (
                        <Metadata
                            data={packet.locationRestrictionText}
                            type={METADATA_TYPES.PACKET}
                        />
                    )}

                    {packet.metadata.length > 0 && !packet.buyAccessText && (
                        <>
                            {showVerticalLine && <VerticalLine />}
                            <Metadata
                                data={packet.metadata()}
                                type={METADATA_TYPES.PACKET}
                                isSafeHTML
                            />
                        </>
                    )}
                </MetadataContainer>
            </PlatformsMetadataContainer>

            <ButtonGroupContainer>
                {packet.buyAccessText && (
                    <InfoTextContainer>
                        <StyledIconWrapper $size={4}>
                            <Icon name={ICONS.INFO_CIRCLE_FILLED} />
                        </StyledIconWrapper>

                        <InfoText>{packet.buyAccessText}</InfoText>
                    </InfoTextContainer>
                )}
                <MediaButtons
                    buttons={packet.buttons}
                    focusRef={focusRef}
                    onRightPanel={handleRightPanel}
                    onButtonAction={handleButtonAction}
                />
            </ButtonGroupContainer>
        </ContentContainer>
    );
};

export const PacketPageContent = React.memo(PacketPageContentRaw);
