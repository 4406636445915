export const size: { [key: string]: number } = {
    mobileS: 320,
    mobileM: 480,
    tablet: 800,
    laptop: 1025,
    laptopM: 1440,
    desktop: 1540,
    ultraWide: 3440,
};

export const additionalSize = {
    player: 600,
    settings: 1180,
    laptopS: 1280,
    menu: 1650,
    fullhd: 1920,
    qhd: 2560,
};

export const device = {
    mobileS: `(min-width: ${size.mobileS}px)`,
    mobileM: `(min-width: ${size.mobileM}px)`,
    tablet: `(min-width: ${size.tablet}px)`,
    laptop: `(min-width: ${size.laptop}px)`,
    laptopM: `(min-width: ${size.laptopM}px)`,
    desktop: `(min-width: ${size.desktop}px)`,
    ultraWide: `(min-width: ${size.ultraWide}px)`,
};

export const settingsAdditionalBreakPoint = `(min-width: ${additionalSize.settings}px)`;
export const playerAdditionalBreakPoint = `(min-width: ${additionalSize.player}px)`;
export const additionalBreakPointLaptopS = `(min-width: ${additionalSize.laptopS}px)`;
export const additionalBreakPointFullhd = `(min-width: ${additionalSize.fullhd}px)`;
export const additionalBreakPointQhd = `(min-width: ${additionalSize.qhd}px)`;

export const responsiveSizes = {
    globalPadding: [1.7, 3, 5, null, 11.1, 11.1, 26],
    listElementWidth: [80, 50, 33.3, 25, 20, 20, 16.7],
    listElementPosterWidth: [45, 25, 25, 16.7, 16.7, 16.7, 14.28],
    promobox: {
        circleImageRight: [0],
        circleImageTop: [-4, null, 15, 17, 4, 24],
        circleImageWidth: [88, null, 48, 38, 37, 36],
        copyrightButtonContentMarginTop: [0.8, null, 1.7, 1.8, null, 2.1],
        copyrightMarginBottom: [1, null, 0],
        copyrightMarginLeft: [0, null, 1.8, 1.9, null, 3],
        descriptionMarginBottom: [1, null, 0],
        height: [45.3, 53.6, 68.4],
        justifyButtonContent: ['center', null, 'left'],
        metadata: {
            genreMarginBottom: [0.5, null, null, 0.4, null, 0.5],
            titleMarginBottom: [null, null, 0.5, 0.6, 0.5, 0.7],
            descriptionLineHeight: [null, 1.6, 1.75, 1.6, null, 1.7],
        },
        textAlign: ['center', null, 'left'],
        titleMarginBottom: [0.1, 0.3, 0.5, 0.6, 0.5, 0.8],
        textContentWidth: [null, null, 46.3, 55, null, 68.5],
    },
    offerList: {
        titleFontSize: [2.2],
        descriptionFontSize: [1.3],
        titleMarginBottom: [1.9],
        descriptionMarginBottom: [2.2],
    },
    footer: {
        categoryNavItemLinkPadding: [1.8, 1.5, 1.9, 1.7, 1.5, 1.7],
        navContainerPadding: [1.7, 2, 3, 2, 3.8],
        containerPadding: [1.7, 0, null, 1.7],
        simplifiedFooter: {
            footerWrapperPaddingTop: [1.5, 1.6],
            footerWrapperPaddingBottom: [1.2, 1.3],
            footerItemsWrapperMargin: [2.2, 0],
            navItemMargin: [2.2, 4.5],
            flexDirection: ['column', 'row'],
            navItemPadding: [2.2, 0],
        },
    },
    button: {
        primary: {
            iconWidth: [1.0, 1.2, 1.4, 1.5],
            iconHeight: [1.2, 1.4, 1.6, 1.7],
            borderRadius: [2.1, null, null, 2.5],
            width: [13.6, null, null, 16.5],
            height: [4.4],
        },
        back: {
            iconWidth: [1.1],
            iconHeight: [1.8],
            borderRadius: [2.3],
            width: [4.6],
            height: [4.6],
        },
        secondary: {
            iconWidth: [0.9, null, 1.1],
            iconHeight: [1.0, 1.1, 1.3],
        },
        promobox: {
            iconWidth: [1.0, 1.2, 1.4, 1.5],
            iconHeight: [1.2, 1.4, 1.6, 1.7],
            borderRadius: [2.1, null, null, 2.5],
            width: [13.6, null, null, 16.5],
            height: [4.2, null, null, 5.1],
        },
        menu: {
            width: [2.8, 3.1, 13.7, 13.4],
            height: [2.8, 3.2, 3.5, 3.6],
            borderRadius: [1.4, 1.6, 1.7],
            iconTop: [-0.4, -0.2, 0],
            iconLeft: [-0.4, -0.2, 0],
        },
        footerAction: {
            iconWidth: [1.0, 1.2, 1.4, 1.5],
            iconHeight: [1.2, 1.4, 1.6, 1.7],
            borderRadius: [0, 2.1, null, 2.5],
            width: [13.6, null, null, 16.5],
            height: [4.4],
            hover: ['unset', 'scale(1.1)'],
        },
        payment: {
            iconWidth: [1.0, 1.2, 1.4, 1.5],
            iconHeight: [1.2, 1.4, 1.6, 1.7],
            borderRadius: [0, 2.2],
            width: ['100%', 'auto'],
        },
        productCardBuy: {
            paddingVertical: [1.15, null, null, 1.35],
            paddingHorizontal: [2, null, null, 2.35],
            iconHeight: [1.6, null, 1.8, 2],
        },
        slider: {
            margin: [0.7, 1, 1.5],
            size: [4.7, 6.2],
            iconWidth: [1, 1.3],
            iconHeight: [1.8, 2.2],
        },
    },
    navigationButtons: {
        marginBottom: [2, 2, 2.9, 2.9, 3.9, 4],
    },
    columnLayout: {
        flexDirection: ['column', null, null, 'row'],
        flexDirectionColumnReverse: ['column-reverse', null, null, 'row'],
        width: [30, 45, 55, 60],
        borderBottom: [0.1, null, null, 0],
        borderRight: [0, null, null, 0.1],
        paddingLeftRight: [0.5, 2, 5],
        marginBottom: [4, null, null, 0],
        innerWrapperWidth: [25, 37],
    },
    account: {
        width: [26, 42, 67, 67, 70, 70, 100],
    },
    ruleButtons: {
        alignItems: ['center', 'flex-end'],
        height: [0, 25],
        width: {
            single: ['100%', 'unset'],
            multiple: ['50%', 'unset'],
        },
        padding: {
            bottom: {
                withFooter: [14, 4],
                withoutFooter: [4.4, 2],
            },
            wrapper: {
                bottom: [5, 6, 7, 10],
            },
        },
        margin: {
            top: [9.6, 0],
            left: [0, 2],
            right: [0, 2],
        },
    },
    payments: {
        stepContentPaddingTop: [3],
        iconSize: [1.5, null, 1.9, null, 2.1],
    },
    mediaDetails: {
        mediaDetailsWrapper: [null, 38, 46, 54, null, 67],
        containerTop: [5, 15, 20.6, 35.4, 37.1, 40.6],
        containerPadding: [0, 2, 0],
        textAlign: ['center', 'center', 'left'],
        circleImageRight: [0],
        circleImageWidth: [88, null, 48, 38, 37, 36],
        circleImageTop: [-20, -15, -10, 3, 5, 32],
        primaryMargin: [0.5, 0.6, null, 0.4, null, 0.8],
        titleMargin: [0.1, 0.1, 0.4, 0.4, 0.4, 0.6],
        notPurchasableMarginBottom: [1.4, 2.2, 2.4, 2.5],
        durationWrapperTopMargin: [1.7, null, 1, 1.7],
        buttonsContainerTopMargin: [0.4, null, 1.8, null, 2, 3],
        metadataDetailsContainerTopMargin: [0.7, 1, 1.4],
        fullDetails: {
            description: {
                lineHeight: [1.8, 2.2, 2.8, null, null, 3],
            },
            containerWidth: [null, null, 45.8, 53.2, null, 66.6],
            backButtonMarginBottom: [4.2, 7.6, 25.4, 5.2, 17.2, 13.4],
            backButtonMarginTop: [1.7, 2.9, null, null, null, 4.4],
            primaryMargin: [1, 0.4, 0.5, null, null, 0.6],
            titleMargin: [1.7, null, 1.3, 2.5, null, 1.5],
            containerMarginBottom: [1.6, 1.8, 2.3, null, null, 1.6],
            paddingInTable: [0.9, 2.3, 2.9],
        },
    },
    packetDetails: {
        packetCardDetailsMarginBottom: [null, null, 4.1, 3.5, 5.1, 5.7],
    },
    input: {
        big: {
            height: [4.4, null, 5.1, null, 5.8],
        },
    },
    search: {
        iconSize: [1.9, null, 2.2, null, 2.5],
        iconWrapperRight: [1.8, 2.8, 3.8, 4.8, 27],
        inputWidth: [65, 52, 35, 45, 66.4],
        resultsMarginTop: [10, 11.1, 12, 13, 15],
        subtitleMargin: [1.2, 1.7],
        wrapperPaddingBottom: [null, 2.5, 2.2, 3.4, 3.8],
        wrapperPaddingTop: [1, null, 0.9, null, 0.6, 1.1],
    },
    dropDownList: {
        width: [15, 20, 25, null, 30],
    },
    rules: {
        wrapperPaddingTop: [9.8, null, 13.4],
    },
    poloniaLanding: {
        stripesLeft: {
            bottom: [-23, null, -8, null, -5],
            left: [-50, -73, -64, -70, -49, -45],
        },
        stripesRight: {
            top: [-15, null, -10, null, -5],
            right: [-70, null, null, null, -49, -45],
        },
    },
    haveCode: {
        lineHeight: [2.2, 2.4],
    },
};

export const responsiveFonts = {
    collectionTitle: [1.5, 1.6, 1.9, null, null, 2.1],
    listElementTitle: [1.3, 1.4, 1.5, null, null, 1.6],
    listElementMetadata: [1.2, 1.3, 1.4],
    title: [2.4, 2.6, 3.6, 4.2, null, 4.8],
    description: [1.3, null, 1.5, 1.6, null, 1.8],
    promobox: {
        title: [2.4, 2.6, 3.6, 4.2, null, 4.8],
        description: [1.3, null, 1.5, 1.6, null, 1.8],
        copyright: [1, 1.2, null, null, null, 1.6],
    },
    metadata: [1.3, 1.5, 1.6, null, null, 1.8],
    footer: {
        categoryNavItemLink: [1.1, 1.3],
        simplifiedFooter: {
            link: [1.2, 1.3],
            copyright: [1.1],
        },
    },
    button: {
        primary: [1.5, 1.6],
        magicLink: [1.3, 1.4, 1.5, 1.6],
        footerAction: [1.4, 1.6],
        secondary: [1.2, 1.3, 1.5, null, null, 1.6],
        promobox: [1.7, null, null, 2],
        productCardBuy: [1.7, null, null, 2],
    },
    payments: {
        title: [1.6, null, 1.7, null, 1.9],
        description: [1.3, null, 1.5, 1.6, null, 1.8],
        productSize: [1.3],
        tertiary: [1.1],
        imageWidth: [10, 12],
        imageHeight: [12, 14],
    },
    mediaDetails: {
        table: [1.2, null, 1.3],
    },
    search: {
        subtitle: [1.5, 1.6, 1.9, null, null, 2.1],
        input: [1.4, null, 1.6, null, 1.8],
    },
    tabs: {
        title: [1.3, 1.5, 1.7],
    },
    account: {
        optionTitle: [1.6, null, 1.8],
        optionDescription: [1.3, null, 1.4, null],
        consents: {
            title: [1.6, null, 1.8],
            hiddenText: [1.3, null, 1.4],
        },
    },
    haveCode: {
        hint: [1.3, 1.4],
    },
    goodbye: {
        subtitle: [1.6, 1.9],
    },
    collection: {
        title: [0.9, 0.9, 0.9, 0.9, 1.1, 1.1, 1.1],
        subtitle: [1.3, 1.3, 1.3, 1.3, 1.6, 1.6, 1.6],
    },
};
