import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { PlayableButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { useRouter } from '@/routing';
import { Message } from '@/components/shared/Message';
import React, { useCallback, useRef } from 'react';
import { SetNewEmail } from './SetNewEmail';
import { GUTTER } from '@/types/gutter.type';
import { Nav, navVertical } from 'nav-tree';
import { buildHighlightSpan } from '@/helpers/highlight-translation.helper';
import { redirectToLogin } from '@/helpers/redirect-to-login.helper';

export const AddAccountEmailForm = () => {
    const focusRef = useRef(null);
    const { query } = useRouter();
    const { changeEmail, email, emailSent } = query;

    const messageDetails = useCallback(() => {
        if (email) {
            return {
                title: t(
                    emailSent
                        ? 'pages.register.check-email.header'
                        : 'pages.register.check-email.header-email-unconfirmed',
                ),
                description: t(
                    emailSent
                        ? 'pages.register.check-email.description-with-email-sent'
                        : 'pages.register.check-email.description-with-email',
                    {
                        email,
                        ...buildHighlightSpan('highlight'),
                    },
                ),
            };
        }

        return {
            title: t('pages.register.check-email.header'),
            description: t('pages.register.check-email.description'),
        };
    }, [email, emailSent]);

    const body = (
        // @ts-ignore
        <Nav ref={focusRef} func={navVertical}>
            <Message
                icon={ICONS.INFO_CIRCLE}
                title={messageDetails().title}
                innerHtmlDescription={messageDetails().description}
                gutter={GUTTER.BIG}
            />
            <FormContainer>
                <PlayableButton
                    text={t('ok-button-label')}
                    onButtonAction={redirectToLogin}
                    defaultFocused
                />
            </FormContainer>
            <Message
                description={[
                    t('pages.add-account-email.check-email.footer-desc-1'),
                    t('pages.add-account-email.check-email.footer-desc-2'),
                    t('pages.add-account-email.check-email.footer-desc-4'),
                ]}
                gutter={GUTTER.BIG}
            />

            {changeEmail && <SetNewEmail focusRef={focusRef} />}
        </Nav>
    );

    return <FullscreenNavContainer body={body} />;
};
