import { Nav } from 'nav-tree';
import React, { useState } from 'react';
import { StepContainer } from './styles';
import { StepProps } from './types';

export const Step = ({ label, selected, isFocusAvailable, onAction }: StepProps) => {
    const [focused, setFocused] = useState(false);

    return (
        <StepContainer selected={selected} focused={focused} isFocusAvailable={isFocusAvailable}>
            {isFocusAvailable && (
                // @ts-ignore
                <Nav onNav={setFocused} onEnterDown={onAction} onClick={onAction}>
                    {label}
                </Nav>
            )}
            {!isFocusAvailable && label}
        </StepContainer>
    );
};
