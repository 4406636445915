/**
 ** SCROLL_THRESHOLD - A margin of error to account for potential inaccuracies in scroll position calculations.
 ** This helps to ensure that small discrepancies due to browser differences, rounding issues, or dynamic content
 */
export const EPG_CONSTANTS = {
    DATE_SEPARATOR_OFFSET: 2,
    FEATURED_ROW_HEIGHT: 92,
    MOBILE_STICKY_BAR_HEIGHT: 67,
    OVERLAY_WIDTH: 36,
    SCROLL_THRESHOLD: 1,
    START_HOUR_AFTERNOON: 14,
    START_HOUR_EVENING: 20,
    START_HOUR_MORNING: 6,
    OVERLAY_DATA_TYPE: {
        PREVIEW: 'preview',
        LIST: 'list',
    } as const,
};

export type OverlayDataType =
    (typeof EPG_CONSTANTS.OVERLAY_DATA_TYPE)[keyof typeof EPG_CONSTANTS.OVERLAY_DATA_TYPE];

/**
 ** These z-index values are used throughout the EPG component.
 ** The topmost layer, represented by EPG_CONTAINER is defined in the main theme.ts file.
 ** All other values in this object are relative to the EPG_CONTAINER's z-index.
 */
export const EPG_Z_INDEX = {
    BOTTOM_LAYER: -1,
    DESKTOP_FEATURED_ROW_INNER: 2,
    DESKTOP_MENU_BAR: 2,
    DESKTOP_PAGINATION_NAV_WRAPPER: 3,
    FIRST_LAYER: 1,
    MOBILE_HOUR_NAV_WRAPPER: 2,
    MOBILE_INNER_WRAPPER: 3,
    MOBILE_MAIN_NAV: 3,
    MOBILE_STICKY_BAR: 2,
    MOBILE_STICKY_BAR_STICKY: 5,
    PREVIEW_PROGRAM_OVERLAY: 2,
    PROGRAM_OVERLAY: 2,
} as const;
