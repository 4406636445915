import React, { useContext, useEffect, useMemo } from 'react';

import { FOCUSED_COLUMN_INDEX } from '@/constants/epg-table';
import { useIncrementColumnContext } from '@/contexts/epg-table/increment-column.context';
import { LoaderContext } from '@/contexts/loader/loader.context';
import { useIsLastElementFullyVisible } from '@/hooks/epg-table/use-is-last-element-fully-visible';
import { useIsLastPage } from '@/hooks/epg-table/use-is-last-page.hook';
import { usePageBoundary } from '@/hooks/epg-table/use-page-boundary.hook';
import { useChannelsAccessMap } from '@/hooks/player/use-channels-access-map';

import { RenderChannels } from './RenderChannels';
import { EPGTableContentProps } from './types';

const EPGTableContentRaw = ({ allChannels, focusRef }: EPGTableContentProps) => {
    const { channelsAccessMap } = useChannelsAccessMap(allChannels);
    const { showLoader, setShowLoader } = useContext(LoaderContext);

    const { focusedColIndex } = useIncrementColumnContext();

    const hasFiltersChanged = focusedColIndex === FOCUSED_COLUMN_INDEX.filtersChanged;
    const {
        pageBoundary: { startIndex, endIndex },
    } = usePageBoundary(allChannels.length, hasFiltersChanged);

    const channels = useMemo(
        () => allChannels.slice(startIndex, endIndex),
        [allChannels, endIndex, startIndex],
    );

    const { isLastPage } = useIsLastPage(allChannels.length, endIndex);

    useIsLastElementFullyVisible(channels.length, isLastPage);

    useEffect(() => {
        if (showLoader) {
            setShowLoader(false);
        }
    }, [showLoader, setShowLoader]);

    return (
        <RenderChannels
            channels={channels}
            channelsAccessMap={channelsAccessMap}
            focusRef={focusRef}
        />
    );
};

export const EPGTableContent = React.memo(EPGTableContentRaw);
