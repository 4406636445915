import { fillIcon } from '@/helpers/fill-icon.helper';
import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

export const ItemWrapper = styled(Nav as any)<{ withSubcategory?: boolean }>`
    margin-bottom: 3rem;

    ${(props) =>
        props.withSubcategory &&
        css`
            margin-left: 51px;
        `};
`;
export const Item = styled.div<{ active: boolean }>`
    font-size: 2.4rem;
    display: inline;
    letter-spacing: 0.1rem;
    color: ${({ theme }) => theme.text.color.secondary};
    ${(props) =>
        props.active &&
        css`
            color: ${(props) => props.theme.menu.activeColor};
            font-weight: 500;
        `};
`;

export const IconWrapper = styled.div`
    width: 8px;
    height: 14px;
    ${({ theme }) => fillIcon(theme.menu.activeColor)};
    margin-left: 1rem;
    display: inline-block;
`;
export const IconDotWrapper = styled.div`
    width: 0.8rem;
    height: 0.8rem;
    ${({ theme }) => fillIcon(theme.customerCare.iconColor)};
    margin-right: 1.6rem;
`;
