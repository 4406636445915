import { Z_INDEX } from '@/targets/default/theme';
import styled from 'styled-components';
import { Nav } from 'nav-tree';
import { FiltersNameProps } from './types';
import { fillIcon } from '@/helpers/fill-icon.helper';

export const FilterListRootContainer = styled(Nav as any)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: ${Z_INDEX.FILTERS_CONTAINER};
    width: 60rem;
    padding: 5rem;
`;
export const FilterListWrapperStyledComponent = styled(Nav as any)`
    max-height: 79%;
    overflow: hidden;
    margin: 3rem 0;
`;

export const RightOpenFilterStyled = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, #000000 85%);
    width: 100%;
    z-index: ${Z_INDEX.FILTERS_OPEN};
`;
export const FilterName = styled.div<FiltersNameProps>`
    font-size: 3rem;
    color: #ffffff;
    font-weight: 700;
    padding-top: ${(props) => props.index && '3rem'};
    padding-bottom: 3rem;
`;
export const IconWrapper = styled.div`
    width: 3rem;
    height: 3rem;
    ${({ theme }) => fillIcon(theme.menu.iconColor)};
    margin: 0rem auto;

    &:hover {
        ${({ theme }) => fillIcon(theme.menu.focusedIconColor)};
        cursor: pointer;
    }
`;
export const ButtonNavWrapper = styled.div`
    padding-top: 3rem;
`;
