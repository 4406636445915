import { useBuy } from '@/api/payments/use-buy';
import { LoadingWrapper } from '@/components/shared/Loader';
import { useCheckOrderStatus } from '@/hooks/use-check-order-status';
import { NonInteractiveTransactionProps } from './types';
import { PAYMENT_STATUS } from '@/interfaces/payments.interface';
import t from '@/lib/i18n';
import React, { useEffect, useState } from 'react';
import { useDebouncedEffect } from '@/hooks/use-debounced-effect.hook';
import { ButtonContainer, CenterContainer } from './styles';
import { Message } from '@/components/shared/Message';
import { ICONS } from '@/components/shared/Icon';
import { PlayableButton } from '@/components/shared/Buttons';
import { useGetOptionData } from '@/api/payments/use-get-option-data';
import {
    buildBuyParams,
    buildCpwalletBuyParams,
    buildOptionDataParams,
    buildPaymentCardBuyParams,
} from '@/helpers/payments/request-params-builder.helper';

const ORDER_STATUS_TIMEOUT = 300000; // 5 min

export const NonInteractiveTransaction = ({
    product,
    offer,
    option,
    orderId,
    paymentCard,
    isCpwallet,
    onPaymentSuccess,
    onPaymentError,
    onSetStepsVisibility,
    onSetProductCardVisibility,
    onExitPaymentPath,
}: NonInteractiveTransactionProps) => {
    const [isTimeoutMessageVisible, setTimeoutMessageVisibility] = useState(false);

    const optionDataParams = buildOptionDataParams(product, offer, option, orderId);
    const { data: optionData, isFetching: isOptionDataFetching } =
        useGetOptionData(optionDataParams);

    const buyParams = (() => {
        if (!optionData) {
            return undefined;
        }

        if (isCpwallet) {
            return buildCpwalletBuyParams(product, offer, option, orderId, optionData);
        }

        if (paymentCard) {
            return buildPaymentCardBuyParams(product, offer, option, orderId, paymentCard);
        }

        return buildBuyParams(product, offer, option, orderId);
    })();

    const { data: buy, isFetching: isBuyFetching } = useBuy(buyParams);
    const isBuySuccess = buy && buy?.status > 0;

    const orderStatusParams = isBuySuccess && !isTimeoutMessageVisible ? orderId : undefined;
    const { paymentStatus, possessionId } = useCheckOrderStatus(orderStatusParams);

    const isFetching =
        isOptionDataFetching || isBuyFetching || paymentStatus === PAYMENT_STATUS.PENDING;
    const isPaymentNotAvailable = !isOptionDataFetching && !isBuyFetching && !isBuySuccess;

    useEffect(() => {
        if (isPaymentNotAvailable) {
            onPaymentError();
        }
    }, [isPaymentNotAvailable]);

    useEffect(() => {
        if (paymentStatus === PAYMENT_STATUS.SUCCESS && possessionId) {
            onPaymentSuccess(possessionId);
        }

        if (paymentStatus === PAYMENT_STATUS.ERROR) {
            const cpwalletDescription = isCpwallet
                ? t('payment-steps.cpwallet-error-description')
                : undefined;
            onPaymentError(cpwalletDescription);
        }
    }, [paymentStatus, possessionId, isCpwallet]);

    useEffect(() => {
        if (isFetching) {
            onSetStepsVisibility(false);
        }
    }, [isFetching]);

    useDebouncedEffect(
        () => {
            if (isFetching) {
                setTimeoutMessageVisibility(true);
                onSetProductCardVisibility(false);
            }
        },
        { timeout: ORDER_STATUS_TIMEOUT },
        [isFetching],
    );

    if (isTimeoutMessageVisible) {
        return (
            <CenterContainer>
                <Message
                    icon={ICONS.INFO_CIRCLE}
                    title={t('payment-steps.timeout-message-title')}
                    description={t('payment-steps.timeout-message-description')}
                />
                <ButtonContainer>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={onExitPaymentPath}
                        defaultFocused
                    />
                </ButtonContainer>
            </CenterContainer>
        );
    }

    return (
        <LoadingWrapper
            isFetching={isFetching}
            calculateBottom={false}
            highlight
            text={t('payment-steps.payment-verification')}
        />
    );
};
