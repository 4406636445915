import { IconButton } from '@/components/shared/Buttons/PlayableButton/IconButton';
import { ICONS } from '@/components/shared/Icon';
import React from 'react';
import { CloseButtonProps } from './types';
import t from '@/lib/i18n';

export const CloseButton = ({ onButtonAction }: CloseButtonProps) => {
    return (
        <IconButton
            icon={ICONS.X}
            size={'small'}
            onButtonAction={onButtonAction}
            title={t('WCAG.aria-label.close')}
        />
    );
};
