import BootstrapClient from '@/components/shared/BootstrapClient/bootstrap-client';
import { AppPauseResume } from '@/components/shared/AppPauseResume';
import { PlayerLoader } from '@/components/shared/PlayerLoader';
import { createQueryClient } from '@/hooks/use-get-media-api.hook';
import 'intersection-observer';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'target/css/global.scss';
import { RouterProvider } from './routing';
import './standalone.scss';
import { RedirectManagerProvider } from '@/routing/redirect-manager';
import MagicMouseContextProvider from '@/contexts/mouse/magic.mouse.context';
import { useSingleRef } from '@/hooks/use-single-ref.hook';
import CookieContextProvider from '@/contexts/cookies/cookies.context';
import ConfigurationContextProvider, {
    ConfigurationUpdater,
} from '@/contexts/configuration/configuration.context';
import OnetrustContextProvider from '@/contexts/onetrust/onetrust.context';
import PlatformContextProvider from '@/contexts/platform/platform.context';
import UserModuleProvider from '@/contexts/user-module/user-module.context';
import AuthContextProvider from '@/contexts/auth/auth.context';
import PageContextProvider from '@/contexts/page/page.context';
import IncrementContextProvider from '@/contexts/increment/increment.context';
import RecoContextProvider from '@/contexts/reco/reco.context';
import PacketContextProvider from '@/contexts/packet/packet.context';
import CatalogContextProvider from '@/contexts/catalog/catalog.context';
import SearchContextProvider from '@/contexts/search/search.context';
import { ClearContextManager } from '@/components/shared/ClearContextManager';
import StatsContextProvider from '@/contexts/stats/stats.context';
import RightPanelContextProvider from '@/contexts/right-panel/right-panel.context';
import ZonedRedirectProvider from '@/routing/ZonedRedirectProvider';
import BackgroundContextProvider from '@/contexts/background/background.context';
import { HelpConfigurationContextProvider } from '@/contexts/customer-care/helpConfiguration.context';
import ErrorContextProvider from '@/contexts/error/error.context';
import CustomerCareErrorPageProvider from '@/contexts/error/CustomerCareErrorPageProvider';
import EPGTableContextProvider from '@/contexts/epg-table/epg-table.context';
import ClientContextTokenProvider from '@/contexts/client-context-token/client-context-token.context';

const NavFocusWrapper = ({ children }: { children: React.ReactNode }) => {
    return (
        <IncrementContextProvider>
            <RecoContextProvider>
                <CatalogContextProvider>
                    <RightPanelContextProvider>
                        <PageContextProvider>
                            <SearchContextProvider>
                                <PacketContextProvider>
                                    <BackgroundContextProvider>
                                        <EPGTableContextProvider>
                                            {children}
                                        </EPGTableContextProvider>
                                    </BackgroundContextProvider>
                                </PacketContextProvider>
                            </SearchContextProvider>
                        </PageContextProvider>
                    </RightPanelContextProvider>
                </CatalogContextProvider>
            </RecoContextProvider>
        </IncrementContextProvider>
    );
};

function App() {
    const queryClient = useSingleRef(() => createQueryClient());
    return (
        <CookieContextProvider>
            <QueryClientProvider client={queryClient}>
                <ConfigurationContextProvider>
                    <PlatformContextProvider>
                        <OnetrustContextProvider>
                            <MagicMouseContextProvider>
                                <UserModuleProvider>
                                    <HelpConfigurationContextProvider>
                                        <ErrorContextProvider>
                                            <AuthContextProvider>
                                                <ClientContextTokenProvider>
                                                    <ConfigurationUpdater>
                                                        <StatsContextProvider>
                                                            <BootstrapClient>
                                                                <NavFocusWrapper>
                                                                    <RouterProvider>
                                                                        <CustomerCareErrorPageProvider>
                                                                            <RedirectManagerProvider>
                                                                                <ZonedRedirectProvider>
                                                                                    <PlayerLoader />
                                                                                    <AppPauseResume />
                                                                                    <ClearContextManager />
                                                                                </ZonedRedirectProvider>
                                                                            </RedirectManagerProvider>
                                                                        </CustomerCareErrorPageProvider>
                                                                    </RouterProvider>
                                                                </NavFocusWrapper>
                                                            </BootstrapClient>
                                                        </StatsContextProvider>
                                                    </ConfigurationUpdater>
                                                </ClientContextTokenProvider>
                                                <ReactQueryDevtools position="bottom-right" />
                                            </AuthContextProvider>
                                        </ErrorContextProvider>
                                    </HelpConfigurationContextProvider>
                                </UserModuleProvider>
                            </MagicMouseContextProvider>
                        </OnetrustContextProvider>
                    </PlatformContextProvider>
                </ConfigurationContextProvider>
            </QueryClientProvider>
        </CookieContextProvider>
    );
}

export default App;
