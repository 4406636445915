/* eslint-disable no-unused-vars */
export enum Z_INDEX {
    MODAL = 999,
    ALERT_MESSAGE = 100,
    BASE_LAYOUT = 10,
    BCG_IMAGE = 1,
    EDGE_SHADE = 1,
    EPG_CONTAINER = 4,
    FILTERS_ALERT = 20,
    FILTERS_CONTAINER = 12,
    FILTERS_OPEN = 10,
    FOOTER_CONTAINER = 3,
    LIST_ELEMENT = 2,
    LIST_ELEMENT_ACTIVE = 20,
    LOADER_WRAPPER = 40,
    MENU_CONTAINER = 12,
    BACK_BUTTON = 12,
    SEARCH_INPUTS_CONTAINER = 12,
    SEARCH_WRAPPER = 21,
    SEARCH_ICON_WRAPPER = 22,
    META_DATA = 2,
    PANELS = 2,
    PLAYER_CONTROLS_1 = 1,
    PLAYER_CONTROLS_2 = 2,
    PLAYER_CONTROLS_3 = 3,
    PLAYER_CONTROLS_4 = 4,
    PROGRESS = 10,
    SIGN_IN_CONTAINER = 1,
    SLIDER_BACKGROUND = -1,
    SLIDER_BUTTON = 50,
    SLIDER_HIDE = 5,
    STANDARD_COMPONENT = 1,
    PAYMENTS_SHADE = 1,
    PAYMENTS_FOOTER = 2,
    STICKY = 100,
    VIRTUAL_KEYBOARD = 100,
    ACCOUNT_MENU_LAYOUT = 100,
    ICON_WRAPPER = 2,
    CLOSE_DYNAMIC_BUTTON = 2,
    CATALOG_TITLE = 5,
}

export default {
    dark: {
        text: {
            color: {
                whiteTone0: '#ffffff',
                primary: '#ffffff',
                secondary: '#a9aeb4',
                metadata: '#A9AEB4',
                metadataSmall: '#8a8f94',
                copyright: '#35383a',
                loader: '#8a8f94',
                loaderHighlight: '#fff',
                avatar: '#a9aeb4',
                ageRestriction: '#AAAEB4',
                highlight: '#85bf00',
                translationHighlight: '#ffffff',
                onAir: '#dc3545',
                error: '#ff0000',
                rulesHeader: '#ffffff',
                rulesText: '#ffffff',
                paymentTitle: '#7f8387',
                paymentDescription: '#7f8387',
                separator: '#36393c',
                gdpr: '#6A6B6F',
            },
            size: {
                ageRestriction: '2rem',
                avatar: '2.2rem',
                copyright: '1.8rem',
                description: '2.6rem',
                epgPreview: {
                    metadata: '2rem',
                    title: '3.4rem',
                },
                gdpr: '1.1rem',
                gridElement: '2.2rem',
                header: '6.0rem',
                loader: '2.6rem',
                loaderDescription: '2.6rem',
                menuItemBrowser: '1.6rem',
                metadata: {
                    medium: '2.2rem',
                    small: '1.9rem',
                    xsmall: '1.3rem',
                    xxsmall: '1.2rem',
                },
                packetMetadata: '2rem',
                pageDescription: '2.4rem',
                pageSubtitle: '3.2rem',
                pageTitle: '4.6rem',
                pageCustomerCareTitle: '4.4rem',
                productName: '3.8rem',
                productType: '2.0rem',
                rulesDescription: '2.3rem',
                rulesHeader: '3.2rem',
                rulesLineHeight: '5.8rem',
                rulesTitle: '4.6rem',
                subtitle: '2.8rem',
                title: '8.6rem',
                contentHeader: '2.8rem',
                contentParapgraph: '1.3rem',
                contentLineHeight: '2.3rem',
                formPlaceholder: '1.5rem',
                formError: '1.2rem',
                formTip: '1.1rem',
                formTipLineHeight: '2.1rem',
                categoryTitle: '2.2rem',
                subCategoryTitle: '1.6rem',
                categoryLink: '1.3rem',
                articleText: '1.3rem',
            },
            fontFamily: 'Montserrat',
        },
        footer: {
            background: '#0c0c0d',
            categoryIconColor: '#3b3e40',
            categoryLinkColor: '#868b8f',
            categoryHeaderColor: '#c9cfd6',
            circleBgColor: '#000000',
            linkColor: '#868b8f',
            socialIconColor: '#4d5153',
            svgFill: '#a9aeb4',
        },
        loadingWrapper: {
            childOpacity: 0.5,
        },
        background: '#181818',
        shadowBgColor: 'rgba(0, 0, 0, 0.6)',
        boxShadow: '-.3rem .4rem 2.1rem -.2rem rgba(0, 0, 0, 0.75)',
        boxShadowHover: '0 .8rem 1.6rem 0 rgba(0, 0, 0, 0.2)',
        progressBarColor: '#a8ef2d',
        progressBarWrapperColor: '#444444',
        vodImageHeight: '35.0rem',
        cardFooterBackground: 'inherit',
        cardFooterFontSize: '2.8rem',
        mainColor: '#84CA0E',
        notification: {
            background: {
                error: 'red',
                success: '#fff4e6',
            },
        },
        backgroundImage: {
            transitionValue: 'opacity 0.4s ease-in-out;',
            customBackgroundColor: 'none',
        },
        avatar: {
            backgroundColor: '#1b1b1d',
            borderColor: '#5f5f5f',
        },
        ageRestriction: {
            noAgeBgColor: '#77B852',
            age7BgColor: '#568FBB',
            age12BgColor: '#CFD149',
            age16BgColor: '#E49B1E',
            age18BgColor: '#CD4D4A',
            borderColor: '#5F5F5F',
        },
        button: {
            primaryBorderColor: '#2e2f30',
            bgColor: '#0000005A',
            secondaryBorderColor: 'transparent',
            loginBorderColor: '#85bf00',
            activeBorderColor: '#85bf00',
            hoverBorderColor: '#85bf00',
            paymentSecondaryHoverBorderColor: '#545557',
            transparentHoverBorderColor: '#545557',
            navBgColor: '#1b1b1c',
            navBorderColor: '#1f2021',
            navFontColor: '#ffffff',
            primaryBgColor: 'transparent',
            secondaryBgColor: '#070b0c',
            loginBgColor: '#85bf00',
            activeBgColor: '#85bf00',
            hoverBgColor: '#85bf00',
            timerBgColor: 'rgba(27, 27, 28, 0.8)',
            timerHoverBgColor: 'rgba(0, 0, 0, 0.8)',
            paymentPrimaryHoverBgColor: '#9adb00',
            progressOverlayColor: '#4e6900',
            polsatBoxBgColor: '#f39200',
            timerProgressOverlayColor: 'rgba(157, 162, 171, 0.3)',
            facebookBgColor: '#296a98',
            googleBgColor: '#d63f3f',
            appleBgColor: '#dde1e6',
            tableBgColor: '#9da2ab',

            primaryFontColor: '#a9aeb4',
            secondaryFontColor: '#a9aeb4',
            activeFontColor: '#000',
            disabledFontColor: '#595D61',
            hoverFontColor: '#000',
            transparentBgHoverFontColor: '#85bf00',
            transparentBorderHoverColor: '#545557',
            paymentPrimaryFontColor: '#000',
            paymentSecondaryFontColor: '#a9aeb4',
            timerFontColor: '#fff',

            iconBgColor: '#000',
            transitionValue: 'all 0.2s linear',

            disabled: {
                color: '#6a6e72',
                backgroundColor: 'rgba(30, 30, 30, 0.9)',
            },
            playable: {
                primary: {
                    bgColor: 'rgba(27, 27, 28, 0.8)',
                    activeBgColor: '#85bf00',
                },
            },
        },
        slider: {
            button: {
                zIndex: Z_INDEX.SLIDER_BUTTON,
                color: 'rgba(0, 0, 0, 0.8)',
                colorActive: 'rgba(0, 0, 0, 1)',
                svgFill: '#a9aeb4',
                svgFillActive: '#ffffff',
            },
            arrowButton: {
                backgroundColor: '#000000',
                borderColor: '#000000',
                iconColor: '#ffffff',
            },
            sideHideZIndex: Z_INDEX.SLIDER_HIDE,
            backgroundZIndex: Z_INDEX.SLIDER_BACKGROUND,
            hideOpacity: 0.2,
        },
        progressBar: {
            rootBgColor: '#36393C',
            contentBgColor: '#99c400',
            beforeBgColor: '#070708',
            transitionValue: 'transform, 2s ease-out, -webkit-transform',
        },
        columnLayout: {
            separatorColor: '#202020',
        },
        listElement: {
            activeBgColor: '#202020',
            activeBorder: '.5rem solid #5f5f5f',
            activeShadow: ' 0 0 2.4rem 0 rgba(0, 0, 0, 0.5)',
            activeTransform: {
                sx: '1.09',
                sy: '1.1',
            },
            activeZIndex: Z_INDEX.LIST_ELEMENT_ACTIVE,
            color: '#ffffff',
            gradient: 'linear-gradient(0deg,rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%)',
            imageHeight: {
                thumbnails: '56.4%', // 56.4% = 100 / (w / h) = 100 / (250 / 141)
                posters: '147.8%', // 147.8% = 100 / (w / h) = 100 / (205 / 303)
            },
            nonActiveBgColor: '#0a0a0a',
            nonActiveTransform: '5x solid transparent',
            nonActiveZindex: Z_INDEX.LIST_ELEMENT,
        },
        offerListElement: {
            imageHeight: {
                thumbnails: '56.4%',
                posters: '143.8%',
            },
        },
        consentsList: {
            activeItem: '#222324',
            fontFamily: 'Montserrat',
        },
        filerListItem: {
            activeItem: '#222324',
            selectedItem: '#2a2a2b',
            color: '#a9aeb4',
            fontSize: '2.8rem',
        },
        switchCheckbox: {
            switchLabelBgColorBeforeChecked: '#767e82',
            switchLabelBgColorAfterChecked: '#695203',
            dotBgColorAfterChecked: '#85bf00',
            dotBgColorBeforeChecked: '#b9b9b9',
            dotSize: 1.8,
            transition: 'all 0.3s linear',
            transitionBeforeChecked: 'all 0.3s cubic-bezier(0, 0, 0, 1.09)',
        },
        menu: {
            color: '#f1f1f1',
            bgColor: '#000000',
            activeColor: '#85bf00',
            iconColor: '#75797d',
            activeIconColor: '#c7cdd4',
            focusedIconColor: '#85bf00',
            switchBgColor: '#282b2e',
            switchHoverBgColor: '#44484d',
            prefixFontColor: '#868b8f',
            scrollbar: '#474747',
            scrollbarBorderColor: '#000',
            profileDetailsMenuBgColor: 'rgba(26, 27, 28, 0.9)',
            copyright: '#3b3e40',
        },
        dropdown: {
            borderColor: '#1f2021',
            backgroundColor: 'rgba(26, 27, 28, 0.95)',
            scrollbarBorderColor: 'transparent',
            scrollbar: '#767e82',
            footerBorder: '#313233',
        },
        filterDropdown: {
            nameColor: '#ffffff',
            listItemColor: '#a9aeb4',
            clearButtonColor: '#5b5c5e',
            clearButtonHoverColor: '#85bf00',
            clearButtonActiveColor: '#ffffff',
        },
        alertMessage: {
            color: '#ffffff',
            bgColorInfo: '#1b1b1c',
            bgColorError: '#ff0000',
        },
        input: {
            nonActiveFocusBgColor: '#232222',
            activeFocusBgColor: '#84CA0E',
            activeFocusIconWrapperColor: '#232222',
            placeholderColor: '#a9aeb4',
            textColor: '#c6c9cc',
            blacktextColor: '#000000',
            errorBgColor: '	#FF0000',
            size: '2rem',
            iconWrapperColor: '#424345',
        },
        message: {
            iconColor: '#85bf00',
            titleColor: '#fff',
            descriptionColor: '#a9aeb4',
            iconSize: '10rem',
            titleSize: '4.6rem',
            descriptionSize: '2.6rem',
            descriptionLineHeight: '4rem',
        },
        facebook: {
            fontFamily: 'Montserrat',
            textColor: '#90949c',
            primaryColor: '#3c6499',
            background: {
                container: 'white',
                code: '#fafafa',
                cancelButton: '#ddd',
            },
        },
        player: {
            liveButtonBackground: '#1b1b1c',
            activeOptionBackground: '#222324',
            progressBar: {
                fill: '#85bf00',
                buffer: '#44464a',
                background: '#2a2a2b',
                handle: '#ffffff',
                adBreak: '#ffffff',
            },
            lockIcon: {
                color: '#27272b',
            },
            adTimeBg: 'rgba(0, 0, 0, 0.8)',
            playbackTimeColor: '#979ba1',
            playbackTimeLiveColor: '#ff0000',
            playbackTimeLiveBg: '#ff0000',
            soundButton: {
                volumePanelBg: '#1a1b1c',
                volumePanelBorderColor: '#1f2021',
                soundBarBg: '#262829',
                soundBarVolumeBg: '#9c9fa1',
            },
            castBtnColor: '#85bf00',
            optionsPanelBg: 'rgba(26, 27, 28, 0.95)',
            highContrast: '#ffee00',
        },
        iconButton: {
            fill: '#fff',
            fillHover: '#85bf00',
            primaryBg: 'rgba(157, 162, 171, 0.12)',
            primaryBgHover: 'rgba(157, 162, 171, 0.3)',
            primaryFill: '#fff',
            primaryFillDisabled: 'rgba(255, 255, 255, .3)',
        },
        dropDownList: {
            color: '#7f8387',
            selectedOptionBgColor: '#1b1b1c',
            selectedOptionHoverBgColor: '#252526',
            selectedOptionBorderColor: '#1f2021',
            iconColor: '#fff',
            iconHoverColor: '#85bf00',
            listBgColor: '#1b1b1c',
            listBorderColor: '#1f2021',
            listItemHoverBgColor: 'rgba(255, 255, 255, 0.02)',
            listItemActiveColor: '#85bf00',
        },
        purchaseButton: {
            promotionColor: '#85bf00',
            trialColor: '#e29811',
            textColor: '#7f8387',
            activeTextColor: '#85bf00',
            borderColor: '#2e2f30',
            activeBorderColor: '#85bf00',
            hoverBorderColor: '#a9aeb4',
            defaultColor: '#ccd0d5',
        },
        purchasePacketButton: {
            priceDetailColor: '#d9dfe2',
        },
        paymentBottomBar: {
            descriptionColor: '#fff',
            iconColor: '#535759',
        },
        paymentRulesDropdown: {
            textColor: '#FFFFFF',
            activeColor: '#85bf00',
            fontSize: '1.4rem',
        },
        paymentSteps: {
            fontFamily: 'Montserrat',
            fontSize: '2.1rem',
            fontWeight: '500',
            textColor: '#6c7074',
            borderColor: '#2e2f30',
            selectedBgColor: '#1b1d1f',
            selectedTextColor: '#a9aeb4',
            focusedBgColor: '#85bf00',
            focusedTextColor: '#000',
        },
        copyright: {
            textColor: '#4d5154',
        },
        paymentMethodStep: {
            annotationsColor: '#ccd0d5',
            paymentCardLabelColor: '#7f8387',
            paymentCardLabelExpiredColor: '#fd0002',
            paymentCardButtonColor: '#a9aeb4',
            paymentCardButtonBgColor: '#1b1b1c',
            paymentCardButtonHoverBgColor: '#252526',
        },
        paymentCheckoutStep: {
            arrowColor: '#545759',
            shadeBg: 'linear-gradient(0deg, #000 0%, transparent 100%)',
            borderColor: '#2e2f30',
            defaultTextColor: '#6c7074',
            labelColor: '#ccd0d5',
            descriptionColor: '#7f8387',
            errorRuleColor: '#ff0000',
            activeRuleColor: '#85bf00',
        },
        paymentTransactionStep: {
            highlight: '#85bf00',
            descriptionColor: '#7f8387',
            formTitleColor: '#fff',
        },
        paymentSuccess: {
            highlight: '#fff',
        },
        placeholder: {
            backgroundColor: '#0d0e0f',
            arrowColor: `#1d1d1f`,
        },
        edgeShade: {
            startColor: '#000000ff',
            endColor: '#00000000',
            startStep: '30%',
            endStep: '100%',
            height: '25rem',
        },
        edgeShadeLight: {
            startStep: '0%',
            endStep: '100%',
            height: '20rem',
        },
        mediaDetails: {
            iconColor: '#85bf00',
            liveButtonBgColor: '#141414',
        },
        premiere: {
            backgroundColor: 'rgba(50, 50, 50, 0.2)',
            color: '#6a6e72',
        },
        formNewsletter: {
            titleColor: '#fff',
            subtitleColor: '#a9aeb4',
            toggleDescriptionColor: '#7f8387',
            titleSize: '2rem',
            subtitleSize: '1.6rem',
            toggleDescriptionSize: '1.2rem',
            borderColor: `rgba(255, 255, 255, 0.1)`,
        },
        promobox: {
            top: '76.9rem',
        },
        virtualKeyboard: {
            backgroundColor: '#384248',
            buttonColor: '#afb3b5',
            hoverColor: '#535a60',
            buttonSize: '7rem',
            buttonFontSize: '2.5rem',
            height: '45rem',
            spaceButtonWidth: '48rem',
            iconSize: '2.5rem',
            submitButton: {
                fontSize: '3rem',
                color: '#30c6b4',
            },
        },
        tvRating: {
            ageGroup0: '#6fb858',
            ageGroup7: '#4c8fb9',
            ageGroup12: '#cdce55',
            ageGroup16: '#e89b32',
            ageGroup18: '#d54e4d',
        },
        tabs: {
            title: {
                borderColor: '#262728',
                activeTextColor: '#fff',
                textColor: '#a9aeb4',
                padding: '1.3rem 3.5rem 1.1rem 3.5rem',
            },
            content: {
                topPadding: '6rem',
                borderColor: '#262728',
            },
        },
        avatarCarousel: {
            iconBgColor: '#1b1b1c',
            iconBorderColor: '#1f2021',
            svgFillActive: '#ffffff',
        },
        table: {
            headerFontSize: '1.5rem',
            headerFontColor: '#a9aeb4',
            headerBorderColor: '#525253',
            rowBorderColor: '#202021',
            cellFontSize: '1.5rem',
            cellFontColor: '#717478',
        },
        myPurchases: {
            boxPrimaryFontColor: '#fff',
            boxPrimaryFontSize: '2.1rem',
            boxSecondaryFontColor: '#7f8387',
            boxSecondaryFontSize: '1.8rem',
            boxHeaderFontSize: '1.8rem',
            boxSubHeaderFontSize: '1.4rem',
            boxFontColorActive: '#85bf00',
            boxButtonFontColor: '#7f8387',
            boxButtonFontColorActive: '#85bf00',
            boxButtonFontSize: '1.6rem',
            borderColor: '#202021',
            activeBorderColor: '#85bf00',
            paymentCardNumberFontColor: '#7f8387',
            paymentCardNumberFontSize: '1.4rem',
            paymentCardLabelExpiredFontColor: '#fd0002',
            paymentCardLabelExpiredFontSize: '1.4rem',
        },
        myAccess: {
            horizontalLine: '#202022',
            button: {
                svgFill: '#3b3e40',
            },
        },
        tokenInfo: {
            nativeLoginButtonSize: '2.4rem',
            nativeLoginButtonColor: '#a9aeb4',
            separatorColor: '#252526',
            instructionLabelSize: '2.5rem',
            instructionLabelColor: '#a9aeb4',
            instructionStepColor: '#7f8387',
            instructionStepBackground: '#252526',
        },
        customerCare: {
            iconColor: '#707478',
            modalZIndex: Z_INDEX.MODAL,
            background: '#000',
        },
        loginByQrCode: {
            separatorColor: '#a9aeb4',
            separatorLineBgColor: '#2e2f30',
            instructionStepColor: '#a9aeb4',
            instructionStepValueBgColor: '#111214',
        },
        iconFill: {
            primary: '#fff',
            primaryHover: '#000',
        },
        welcomeBody: {
            headerWidth: '80rem',
        },
        epgTable: {
            activeCellColor: '#0b0c0c',
            indicatorFill: '#5c6064',
            mainBorderColor: '#312726',
            preview: {
                metadataFontSize: '2rem',
                programTitleFontSize: '3.4rem',
            },
            dateSeparator: {
                iconShadow: 'drop-shadow(0px 2px 3px #000000)',
                textShadow: '0px 2px 3px #000000',
                lineColor: '#423506',
            },
        },
        epg: {
            epgSlotBorderColor: '#252628',
            evenProgramRow: '#111112',
            highlight: '#ffd900',
            highlight2: '#ffc700',
            dim: '#6c7074',
            channelName: '#d9d9d9',
            stickyChannelNameColor: '#515457',
            programTitle: '#d9d9d9',
            btnTextColor: '#d9d9d9',
            btnBgColorActive: '#f4bf02',
            desktopButtonsGroupBgColor: '#1a1a1b',
            btnBorderColor: '#1f2021',
            scrollableNavBtnTextColor: '#6c7074',
            emptyProgramPreviewBgColor: '#000',
            stickyNavGradient:
                'linear-gradient(to bottom, rgba(7, 7, 8, 0.95) 0%, rgba(7, 7, 8, 0.95) 27%, rgba(7, 7, 8, 0.65) 50%, rgba(7, 7, 8, 0) 100%)',
            mobileEvenRow: 'rgba(19, 19, 20, 0.8)',
            featuredRowShadow: '0 0 70px 30px #070708',
            channelDetails: {
                arrowLeftIconColor: '#6f7377',
            },
            programOverlay: {
                bgColor: '#1a1b1c',
                borderColor: '#1f2021',
                mainColor: '#fff',
                descriptionColor: '#a9aeb4',
            },
            programPreview: {
                image: {
                    overlay: 'rgba(0, 0, 0, 0.85)',
                    gradient:
                        'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 87%, rgba(0, 0, 0, 1) 100%)',
                },
                imageOverlay: 'rgba(0, 0, 0, 0.85)',
                borderGradient:
                    'linear-gradient(to top, rgba(37, 38, 40), rgba(37, 38, 40, 0.4) 40%, rgba(0, 0, 0, 0) 100%) 1 100%',
                stickyBorderGradient:
                    'linear-gradient(to top, rgba(37, 38, 40), rgba(0, 0, 0, 0.4) 100%) 1 100%',
            },
            paginationNavBtnIconFill: '#a9aeb4',
            paginationNavBtnIconFillDisabled: '#27282a',
            paginationNavBtnIconFillHover: '#fff',
            dateSeparator: {
                iconShadow: 'drop-shadow(0px 2px 3px #000000)',
                textShadow: '0px 2px 3px #000000',
                lineColor: '#423506',
            },
        },
        collection: {
            bgColor: '#060506',
            borderColor: ' #b6b9bd',
        },
        salesList: {
            accordionButtonColor: '#a9aeb4',
            accordionButtonHoverColor: '#ffc700',
            salesListElementTextColor: '#fff',
            salesListIndicatorColor: '#48505a',
            salesListPriceDescriptionColor: '#636f7c',
        },
    },

    light: {
        text: {
            color: {
                whiteTone0: '#ffffff',
                primary: '#000000',
                secondary: '#56514b',
                metadata: '#938f8b',
                metadataSmall: '#75706b',
                copyright: '#35383a',
                loader: '#6c7074',
                loaderHighlight: '#fff',
                avatar: '#a9aeb4',
                onAir: '#dc3545',
                error: '#ff0000',
                rulesHeader: '#ffffff',
                rulesText: '#ffffff',
                paymentTitle: '#7f8387',
                paymentDescription: '#7f8387',
                separator: '#36393c',
                gdpr: '#6A6B6F',
            },
            size: {
                title: '8.6rem',
                header: '6.0rem',
                pageTitle: '4.6rem',
                pageCustomerCareTitle: '4.4rem',
                pageSubtitle: '3.2rem',
                pageDescription: '2.4rem',
                description: '2.6rem',
                gridElement: '2.2rem',
                subtitle: '2.8rem',
                metadata: '2.2rem',
                metadataSmall: '1.9rem',
                copyright: '1.8rem',
                loader: '2.0rem',
                avatar: '2.2rem',
                ageRestriction: '2rem',
                rules: '2.3rem',
                productName: '3.8rem',
                productType: '2.0rem',
                rulesTitle: '4.6rem',
                rulesHeader: '3.2rem',
                rulesDescription: '2.3rem',
                rulesLineHeight: '5.8rem',
                gdpr: '1rem',
                contentHeader: '2.8rem',
                contentParapgraph: '1.3rem',
                contentLineHeight: '2.3rem',
                formPlaceholder: '1.5rem',
                formError: '1.2rem',
                formTip: '1.1rem',
                formTipLineHeight: '2.1rem',
                categoryTitle: '2.2rem',
                subCategoryTitle: '1.6rem',
                categoryLink: '1.3rem',
                articleText: '1.3rem',
            },
            fontFamily: 'Montserrat',
        },
        footer: {
            background: '#0c0c0d',
            categoryIconColor: '#3b3d3f',
            categoryLinkColor: '#868b8f',
            circleBgColor: '#000000',
            linkColor: '#3b3e40',
            socialIconColor: '#4d5153',
            svgFill: '#a9aeb4',
        },
        loadingWrapper: {
            childOpacity: 0.5,
        },
        background: '#e7e7e7',
        shadowBgColor: 'rgba(0, 0, 0, 0.6)',
        boxShadow: '-.3rem .4rem 2.1rem -.2rem rgba(0, 0, 0, 0.75)',
        boxShadowHover: '0 .8rem 1.6rem 0 rgba(0, 0, 0, 0.2)',
        progressBarColor: '#a8ef2d',
        progressBarWrapperColor: '#444444',
        vodImageHeight: '35.0rem',
        cardFooterBackground: 'inherit',
        cardFooterFontSize: '2.8rem',
        mainColor: '#84CA0E',
        notification: {
            background: {
                error: 'red',
                success: '#fff4e6',
            },
        },
        backgroundImage: {
            transitionValue: 'opacity 0.4s ease-in-out;',
            customBackgroundColor: 'none',
        },
        avatar: {
            borderColor: '#5f5f5f',
        },
        button: {
            primaryBorderColor: '#2e2f30',
            secondaryBorderColor: 'transparent',
            disabledBorderColor: '#2e2f30',
            activeBorderColor: '#85bf00',
            hoverBorderColor: '#85bf00',
            paymentSecondaryHoverBorderColor: '#545557',
            navBgColor: '#1b1b1c',
            navBorderColor: '#1f2021',
            navFontColor: '#ffffff',
            primaryBgColor: 'transparent',
            secondaryBgColor: '#070b0c',
            activeBgColor: '#85bf00',
            hoverBgColor: '#85bf00',
            timerBgColor: 'rgba(27, 27, 28, 0.8)',
            timerHoverBgColor: 'rgba(0, 0, 0, 0.8)',
            paymentPrimaryHoverBgColor: '#9adb00',
            progressOverlayColor: '#4e6900',
            polsatBoxBgColor: '#f39200',
            timerProgressOverlayColor: 'rgba(157, 162, 171, 0.3)',
            facebookBgColor: '#296a98',
            googleBgColor: '#d63f3f',
            appleBgColor: '#dde1e6',
            tableBgColor: '#9da2ab',
            disabled: '#1B1B1CCC',

            primaryFontColor: '#a9aeb4',
            secondaryFontColor: '#a9aeb4',
            activeFontColor: '#000',
            disabledFontColor: '#1B1B1CCC',
            hoverFontColor: '#000',
            transparentBgHoverFontColor: '#85bf00',
            transparentBorderHoverColor: '#545557',
            paymentPrimaryFontColor: '#000',
            paymentSecondaryFontColor: '#a9aeb4',
            timerFontColor: '#fff',

            iconBgColor: '#000',
            transitionValue: 'all 0.2s linear',
            playable: {
                primary: {
                    bgColor: 'rgba(27, 27, 28, 0.8)',
                    activeBgColor: '#85bf00',
                },
            },
        },
        slider: {
            button: {
                zIndex: Z_INDEX.SLIDER_BUTTON,
                color: '#000000',
                svgFill: '#a9aeb4',
                svgFillActive: '#ffffff',
            },
            arrowButton: {
                backgroundColor: '#000000',
                borderColor: '#000000',
                iconColor: '#ffffff',
            },
            sideHideZIndex: Z_INDEX.SLIDER_HIDE,
            backgroundZIndex: Z_INDEX.SLIDER_BACKGROUND,
            hideOpacity: 0.2,
        },
        progressBar: {
            rootBgColor: '#36393C',
            contentBgColor: '#99c400',
            beforeBgColor: '#070708',
            transitionValue: 'transform, 2s ease-out, -webkit-transform',
        },
        columnLayout: {
            separatorColor: '#202020',
        },
        listElement: {
            activeBgColor: '#202020',
            activeBorder: '.5rem solid #5f5f5f',
            activeShadow: ' 0 0 2.4rem 0 rgba(0, 0, 0, 0.5)',
            activeTransform: {
                sx: '1.09',
                sy: '1.1',
            },
            activeZIndex: Z_INDEX.LIST_ELEMENT_ACTIVE,
            color: '#ffffff',
            gradient: 'linear-gradient(0deg,rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%)',
            imageHeight: {
                thumbnails: '56.4%', // 56.4% = 100 / (w / h) = 100 / (250 / 141)
                posters: '147.8%', // 147.8% = 100 / (w / h) = 100 / (205 / 303)
            },
            nonActiveBgColor: '#0a0a0a',
            nonActiveTransform: '5x solid transparent',
            nonActiveZindex: Z_INDEX.LIST_ELEMENT,
        },
        offerListElement: {
            imageHeight: {
                thumbnails: '56.4%',
                posters: '143.8%',
            },
        },
        consentsList: {
            activeItem: '#222324',
            fontFamily: 'Montserrat',
        },
        filerListItem: {
            activeItem: '#222324',
            selectedItem: '#2a2a2b',
            color: '#a9aeb4',
            fontSize: '2.8rem',
        },
        switchCheckbox: {
            switchLabelBgColorBeforeChecked: '#767e82',
            switchLabelBgColorAfterChecked: '#695203',
            dotBgColorBeforeChecked: '#b9b9b9',
            dotBgColorAfterChecked: '#85bf00',
            transition: 'all 0.3s linear',
            transitionBeforeChecked: 'all 0.3s cubic-bezier(0, 0, 0, 1.09)',
        },
        menu: {
            color: '#f1f1f1',
            bgColor: '#000000',
            activeColor: '#85bf00',
            iconColor: '#75797d',
            activeIconColor: '#c7cdd4',
            focusedIconColor: '#85bf00',
            switchBgColor: '#282b2e',
            switchHoverBgColor: '#44484d',
            prefixFontColor: '#868b8f',
            scrollbar: '#474747',
            scrollbarBorderColor: '#000',
            profileDetailsMenuBgColor: 'rgba(26, 27, 28, 0.9)',
            copyright: '#3b3e40',
        },
        dropdown: {
            borderColor: '#1f2021',
            backgroundColor: 'rgba(26, 27, 28, 0.95)',
            scrollbarBorderColor: 'transparent',
            scrollbar: '#767e82',
            footerBorder: '#313233',
        },
        filterDropdown: {
            nameColor: '#ffffff',
            listItemColor: '#a9aeb4',
            clearButtonColor: '#5b5c5e',
            clearButtonHoverColor: '#85bf00',
            clearButtonActiveColor: '#ffffff',
        },
        alertMessage: {
            color: '#ffffff',
            bgColorInfo: '#1b1b1c',
            bgColorError: '#ff0000',
        },
        input: {
            nonActiveFocusBgColor: '#232222',
            activeFocusBgColor: '#84CA0E',
            activeFocusIconWrapperColor: '#232222',
            textColor: '#b1abab',
            blacktextColor: '#000000',
            errorBgColor: '	#FF0000',
            size: '2rem',
            iconWrapperColor: '#424345',
        },
        message: {
            iconColor: '#85bf00',
            titleColor: '#fff',
            descriptionColor: '#a9aeb4',
            iconSize: '10rem',
            titleSize: '4.6rem',
            descriptionSize: '2.6rem',
            descriptionLineHeight: '4rem',
        },
        facebook: {
            fontFamily: 'Montserrat',
            textColor: '#90949c',
            primaryColor: '#3c6499',
            background: {
                container: 'white',
                code: '#fafafa',
                cancelButton: '#ddd',
            },
        },
        player: {
            activeOptionBackground: '#222324',
            progressBar: {
                fill: '#85bf00',
                buffer: '#44464a',
                background: '#2a2a2b',
                handle: '#ffffff',
                adBreak: '#ffffff',
            },
            lockIcon: {
                color: '#27272b',
            },
            soundButton: {
                volumePanelBg: '#1a1b1c',
                volumePanelBorderColor: '#1f2021',
                soundBarBg: '#262829',
                soundBarVolumeBg: '#9c9fa1',
            },
            castBtnColor: '#85bf00',
        },
        dropDownList: {
            color: '#7f8387',
            selectedOptionBgColor: '#1b1b1c',
            selectedOptionHoverBgColor: '#252526',
            selectedOptionBorderColor: '#1f2021',
            iconColor: '#fff',
            iconHoverColor: '#85bf00',
            listBgColor: '#1b1b1c',
            listBorderColor: '#1f2021',
            listItemHoverBgColor: 'rgba(255, 255, 255, 0.02)',
            listItemActiveColor: '#85bf00',
        },
        purchaseButton: {
            promotionColor: '#85bf00',
            trialColor: '#e29811',
            textColor: '#7f8387',
            activeTextColor: '#85bf00',
            borderColor: '#2e2f30',
            activeBorderColor: '#85bf00',
            hoverBorderColor: '#a9aeb4',
            defaultColor: '#ccd0d5',
        },
        paymentBottomBar: {
            descriptionColor: '#fff',
            iconColor: '#535759',
        },
        paymentRulesDropdown: {
            textColor: '#FFFFFF',
            activeColor: '#85bf00',
            fontSize: '1.4rem',
        },
        paymentSteps: {
            fontFamily: 'Montserrat',
            fontSize: '2.1rem',
            fontWeight: '500',
            textColor: '#6c7074',
            borderColor: '#2e2f30',
            selectedBgColor: '#1b1d1f',
            selectedTextColor: '#a9aeb4',
            focusedBgColor: '#85bf00',
            focusedTextColor: '#000',
        },
        copyright: {
            textColor: '#4d5154',
        },
        paymentMethodStep: {
            annotationsColor: '#ccd0d5',
            paymentCardLabelColor: '#7f8387',
            paymentCardLabelExpiredColor: '#fd0002',
            paymentCardButtonColor: '#a9aeb4',
            paymentCardButtonBgColor: '#1b1b1c',
            paymentCardButtonHoverBgColor: '#252526',
        },
        paymentCheckoutStep: {
            arrowColor: '#545759',
            shadeBg: 'linear-gradient(0deg, #000 0%, transparent 100%)',
            borderColor: '#2e2f30',
            defaultTextColor: '#6c7074',
            labelColor: '#ccd0d5',
            descriptionColor: '#7f8387',
            errorRuleColor: '#ff0000',
            activeRuleColor: '#85bf00',
        },
        paymentTransactionStep: {
            highlight: '#85bf00',
            descriptionColor: '#7f8387',
            formTitleColor: '#fff',
        },
        paymentSuccess: {
            highlight: '#fff',
        },
        placeholder: {
            backgroundColor: '#0d0e0f',
            arrowColor: `#1d1d1f`,
        },
        edgeShade: {
            startColor: '#000000ff',
            endColor: '#00000000',
            startStep: '30%',
            endStep: '100%',
            height: '25rem',
        },
        edgeShadeSmall: {
            startStep: '30%',
            endStep: '100%',
            height: '25rem',
        },
        mediaDetails: {
            iconColor: '#85bf00',
            liveButtonBgColor: '#141414',
        },
        formNewsletter: {
            titleColor: '#fff',
            subtitleColor: '#a9aeb4',
            toggleDescriptionColor: '#7f8387',
            titleSize: '2rem',
            subtitleSize: '1.6rem',
            toggleDescriptionSize: '1.2rem',
            borderColor: `rgba(255, 255, 255, 0.1)`,
        },
        promobox: {
            top: '84.5rem',
        },
        virtualKeyboard: {
            backgroundColor: '#384248',
            buttonColor: '#afb3b5',
            hoverColor: '#535a60',
            buttonSize: '7rem',
            buttonFontSize: '2.5rem',
            height: '45rem',
            spaceButtonWidth: '48rem',
            iconSize: '2.5rem',
            submitButton: {
                fontSize: '3rem',
                color: '#30c6b4',
            },
        },
        tabs: {
            title: {
                borderColor: '#262728',
                activeTextColor: '#fff',
                textColor: '#a9aeb4',
                padding: '1.3rem 3.5rem 1.1rem 3.5rem',
            },
            content: {
                topPadding: '6rem',
                borderColor: '#262728',
            },
        },
        avatarCarousel: {
            iconBgColor: '#1b1b1c',
            iconBorderColor: '#1f2021',
            svgFillActive: '#ffffff',
        },
        table: {
            headerFontSize: '1.5rem',
            headerFontColor: '#a9aeb4',
            headerBorderColor: '#525253',
            rowBorderColor: '#202021',
            cellFontSize: '1.5rem',
            cellFontColor: '#717478',
        },
        myPurchases: {
            boxPrimaryFontColor: '#fff',
            boxPrimaryFontSize: '2.1rem',
            boxSecondaryFontColor: '#7f8387',
            boxSecondaryFontSize: '1.8rem',
            boxHeaderFontSize: '1.8rem',
            boxSubHeaderFontSize: '1.4rem',
            boxFontColorActive: '#85bf00',
            boxButtonFontColor: '#7f8387',
            boxButtonFontColorActive: '#85bf00',
            boxButtonFontSize: '1.6rem',
            borderColor: '#202021',
            activeBorderColor: '#85bf00',
            paymentCardNumberFontColor: '#7f8387',
            paymentCardNumberFontSize: '1.4rem',
            paymentCardLabelExpiredFontColor: '#fd0002',
            paymentCardLabelExpiredFontSize: '1.4rem',
        },
        myAccess: {
            horizontalLine: '#202021',
            button: {
                svgFill: '#3b3e40',
            },
        },
        tokenInfo: {
            nativeLoginButtonSize: '2.4rem',
            nativeLoginButtonColor: '#a9aeb4',
            separatorColor: '#252526',
            instructionLabelSize: '2.5rem',
            instructionLabelColor: '#a9aeb4',
            instructionStepColor: '#7f8387',
            instructionStepBackground: '#252526',
        },
        customerCare: {
            iconColor: '#707478',
            modalZIndex: Z_INDEX.MODAL,
            background: '#e7e7e7',
        },
        loginByQrCode: {
            separatorColor: '#a9aeb4',
            separatorLineBgColor: '#2e2f30',
            instructionStepColor: '#a9aeb4',
            instructionStepValueBgColor: '#111214',
        },
        iconFill: {
            primary: '#fff',
            primaryHover: '#000',
        },
        welcomeBody: {
            headerWidth: '80rem',
        },
        epgTable: {
            activeCellColor: '#0b0c0c',
            indicatorFill: '#5c6064',
            mainBorderColor: '#312726',
            preview: {
                metadataFontSize: '2rem',
                programTitleFontSize: '3.4rem',
            },
            dateSeparator: {
                iconShadow: 'drop-shadow(0px 2px 3px #000000)',
                textShadow: '0px 2px 3px #000000',
                lineColor: '#423506',
            },
        },
        epg: {
            epgSlotBorderColor: '#252628',
            evenProgramRow: '#111112',
            highlight: '#ffd900',
            highlight2: '#ffc700',
            dim: '#6c7074',
            channelName: '#d9d9d9',
            stickyChannelNameColor: '#515457',
            programTitle: '#d9d9d9',
            btnTextColor: '#d9d9d9',
            btnBgColorActive: '#f4bf02',
            desktopButtonsGroupBgColor: '#1a1a1b',
            btnBorderColor: '#1f2021',
            scrollableNavBtnTextColor: '#6c7074',
            emptyProgramPreviewBgColor: '#000',
            stickyNavGradient:
                'linear-gradient(to bottom, rgba(7, 7, 8, 0.95) 0%, rgba(7, 7, 8, 0.95) 27%, rgba(7, 7, 8, 0.65) 50%, rgba(7, 7, 8, 0) 100%)',
            mobileEvenRow: 'rgba(19, 19, 20, 0.8)',
            featuredRowShadow: '0 0 70px 30px #070708',
            channelDetails: {
                arrowLeftIconColor: '#6f7377',
            },
            programOverlay: {
                bgColor: '#1a1b1c',
                borderColor: '#1f2021',
                mainColor: '#fff',
                descriptionColor: '#a9aeb4',
            },
            programPreview: {
                image: {
                    overlay: 'rgba(0, 0, 0, 0.85)',
                    gradient:
                        'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 87%, rgba(0, 0, 0, 1) 100%)',
                },
                imageOverlay: 'rgba(0, 0, 0, 0.85)',
            },
            paginationNavBtnIconFill: '#a9aeb4',
            paginationNavBtnIconFillDisabled: '#27282a',
            paginationNavBtnIconFillHover: '#fff',
            dateSeparator: {
                iconShadow: 'drop-shadow(0px 2px 3px #000000)',
                textShadow: '0px 2px 3px #000000',
                lineColor: '#423506',
            },
        },
        collection: {
            bgColor: '#060506',
            borderColor: ' #b6b9bd',
        },
        salesList: {
            accordionButtonColor: '#a9aeb4',
            accordionButtonHoverColor: '#ffc700',
            salesListElementTextColor: '#fff',
            salesListIndicatorColor: '#48505a',
            salesListPriceDescriptionColor: '#636f7c',
        },
    },
};
