import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const Wrapper = styled(Nav as any)`
    text-align: start;
    height: 100vh;
    width: 100%;
    padding: 12rem 0;
`;
export const Header = styled.div`
    display: flex;
    justify-content: center;
`;
export const CategoryWrapper = styled.div`
    margin-bottom: 6rem;
`;
