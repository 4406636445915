import { MainText } from '@/components/shared/MainText';
import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const ErrorMessageWrapper = styled(Nav as any)`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 40rem;
    padding-right: 40rem;
`;
export const ButtonContainer = styled.div`
    width: 448px;
    margin-bottom: 73px;
`;
export const StyledMainText = styled(MainText)`
    font-size: 44px;
`;
export const HelpItemsWrapper = styled.div`
    width: 100%;
`;
