import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const ProfileAvatarContainer = styled.div`
    margin: auto;
    width: fit-content;
    padding-bottom: 5rem;
`;

export const ProfileBackButtonWrapper = styled(Nav as any)`
    position: absolute;
    top: 7.6rem;
    left: 8rem;
`;
