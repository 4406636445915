import useDeleteProfile from '@/api/auth/use-delete-profile';
import useGetProfiles from '@/api/auth/use-get-profiles';
import { PlayableButton } from '@/components/shared/Buttons';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { Message } from '@/components/shared/Message';
import { useGetCurrentAuthSession } from '@/hooks/use-get-current-auth-session';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';
import { useProfilePageInitialProps } from '@/helpers/router/use-profile-page-initial-props';
import { useSessionProfile } from '@/contexts/profile/profile.hooks';
import { MESSAGE_SIZE } from '@/components/shared/Message/styles';

const RemoveProfile = () => {
    const { profileId } = useProfilePageInitialProps();
    const { error: sessionError } = useGetCurrentAuthSession();
    const [sessionProfile] = useSessionProfile();
    const { data: profiles, error: profilesError, isFetching } = useGetProfiles();
    const [removeProfile, { error: deleteError }] = useDeleteProfile();

    const isLastProfile = profiles.length == 1;
    const isRemovingCurrentProfile = sessionProfile?.profileId === profileId;
    const errorToDisplay =
        sessionError?.getUserMessage() ||
        profilesError?.getUserMessage() ||
        deleteError?.getUserMessage();

    const onDelete = async () => {
        if (isLastProfile) {
            Router.replaceRoute(ROUTES.PROFILE_REMOVE_DEFAULT, { profileId });
        } else {
            const result = await removeProfile({ profileId });

            if (result.ok) {
                Router.pushRoute(
                    isRemovingCurrentProfile
                        ? ROUTES.PROFILE_WATCH_AS
                        : ROUTES.PROFILE_REMOVE_SUCCESS,
                );
            }
        }
    };

    const body = (
        <>
            <Message
                messageSize={MESSAGE_SIZE.LG}
                title={t('pages.profile.remove.header')}
                description={t('pages.profile.remove.description')}
                gutter={GUTTER.BIG}
            />
            <FormContainer>
                <PlayableButton
                    text={t('cancel-button-label')}
                    onButtonAction={() => Router.back()}
                    defaultFocused
                />
                <PlayableButton text={t('ok-button-label')} onButtonAction={onDelete} />
            </FormContainer>
        </>
    );

    return (
        <BaseLayout fullscreen defaultBg>
            <FullscreenNavContainer body={isFetching ? null : body} error={errorToDisplay} />
        </BaseLayout>
    );
};

export default RemoveProfile;
