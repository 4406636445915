import { useState, useEffect, useContext } from 'react';
import { Router } from '@/routing';
import { IParams, ROUTES } from '@/routing/types';
import { HomeMenuContext } from '@/contexts/menu/home.menu.context';
import { useRouterQuery } from '@/hooks/use-router-query.hook';
import { botDetector } from '@/helpers/seo/bot-detector.helper';

export const useGetCategoryId = () => {
    const { isBot } = botDetector();
    const { category }: IParams = useRouterQuery();
    const { subcategories, isFetching } = useContext(HomeMenuContext);

    const [categoryId, setCategoryId] = useState<number>();

    useEffect(() => {
        if (!isFetching && category) {
            const currentCategory = subcategories?.getCategoryName(category);

            if (currentCategory) {
                setCategoryId(currentCategory.getId());
            } else if (!isBot) {
                Router.pushRoute(ROUTES.NOT_FOUND);
            }
        }
    }, [isFetching, category, subcategories]);

    return { categoryId };
};
