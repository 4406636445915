import useLogin from '@/api/auth/use-login';
import { ERROR_CODES_GET_MEDIA } from '@/constants/error-codes-get-media';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { withNetia } from '@/helpers/auth/auth-providers.helper';
import { loginUser } from '@/helpers/auth/login-user.helper';
import { buildMsisdnForLogin, isPhoneNumber } from '@/helpers/phone-number.helper';
import t from '@/lib/i18n';
import { yupResolver } from '@/lib/yupResolver';
import { useRouter } from '@/routing';
import { AUTH_PROVIDERS } from '@/types/auth-providers.type';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import useRegister from '@/api/auth/register';
import { useRodoContext } from '@/contexts/rodo/rodo.context';
import { useAllowSubmit } from '../form/use-allow-submit.hook';
import { redirectToTargetRoute } from '@/helpers/router/redirect-to-target-route.helper';

export interface NativeLoginFormData {
    email: string;
    password: string;
}

const schema = Yup.object().shape({
    email: Yup.string().required(t('form.validate.email-required')),
    password: Yup.string().required(t('form.validate.password-required')),
});

export const useNativeLoginForm = () => {
    const userModule = useUserModule();
    const { query } = useRouter();

    const [captcha, setCaptcha] = useState<string | null>(null);
    const [login, { error, isFetching: isLoginFetching, reset }] = useLogin(captcha);
    const { showModal } = useRodoContext();

    const { sessionExpired } = query;

    const { register, handleSubmit, errors } = useForm<NativeLoginFormData>({
        resolver: yupResolver(schema),
    });
    const [registerUser, { isFetching: isRegistrationFetching }] = useRegister();

    const isFetching = useMemo(
        () => isLoginFetching || isRegistrationFetching,
        [isLoginFetching, isRegistrationFetching],
    );

    const captchaRequired = error?.getCode() === ERROR_CODES_GET_MEDIA.B2C_CAPTCHA_VALUE_REQUIRED;
    const emailRequired = error?.getCode() === ERROR_CODES_GET_MEDIA.B2C_EMAIL_REQUIRED;
    const emailConfirmationRequired =
        error?.getCode() === ERROR_CODES_GET_MEDIA.B2C_EMAIL_CONFIRMATION_REQUIRED;
    const isCaptchaSolved = captcha && captcha.length > 0;
    const allowSubmit = useAllowSubmit(isFetching || (captchaRequired && !isCaptchaSolved));

    const onSubmit = handleSubmit(async ({ email, password }) => {
        if (!allowSubmit) return;

        const isPhone = isPhoneNumber(email);
        let loginParams;

        if (withNetia) {
            loginParams = { login: email, password, authProvider: AUTH_PROVIDERS.NETIA as const };
            loginUser(loginParams, userModule, login, registerUser, AUTH_PROVIDERS.NETIA, query);
        } else if (isPhone) {
            const msisdn = buildMsisdnForLogin(email);
            loginParams = { msisdn, password };
            loginUser(loginParams, userModule, login, undefined, AUTH_PROVIDERS.PLUS, query);
        } else if (query.flowContext) {
            loginParams = { login: email, password };
            const resultLogin = await login(loginParams, { flowContext: query.flowContext });
            if (resultLogin.ok) {
                redirectToTargetRoute(query);
            }
        } else {
            loginParams = { login: email, password };

            loginUser(loginParams, userModule, login, undefined, undefined, query);
        }

        if (captchaRequired) {
            window.grecaptcha.reset();
            setCaptcha(null);
        }
    });

    useEffect(() => {
        reset();
    }, [showModal]);

    useEffect(() => {
        if (sessionExpired) {
            userModule.clearAuthSession();
        }
    }, [sessionExpired]);

    return {
        registerForm: register,
        loginError: error,
        formErrors: errors,
        onSubmit,
        setCaptcha,
        isFetching,
        sessionExpired,
        captchaRequired,
        emailRequired,
        emailConfirmationRequired,
    };
};
