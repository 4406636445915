import { useCallback, useState } from 'react';
import { Link, IconWrapper, ContactLinkWrapper } from './styles';
import { Router, useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';
import { Icon, ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import React from 'react';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { BUTTON_ID } from '@/types/button-id.type';

interface ContactLinkProps {
    errorCode?: string;
    contextValue?: string;
    contextType?: string;
}
export const ContactLink = ({ errorCode, contextValue, contextType }: ContactLinkProps) => {
    const [activeLink, setActiveLink] = useState<boolean>(false);

    const userModule = useUserModule();

    const isLogged = userModule.isLogged();
    const email = process.env.contacts.helpdeskEmail.label;
    const goToContactWithUsPage = () => {
        Router.pushRoute(ROUTES.CONTACT_WITH_US, {}, { errorCode, contextType, contextValue });
    };

    return (
        <>
            <ContactLinkWrapper>
                {/* TODO była mowa na demo aby było to widoczne dla niezalogowanych-ale na razie sotawiałm bez zmian
                 zostawiam bez zmian dlatego że:
                 1. jesli na tv ma byc widoczne dla niezalogowanych a www dla zalogowanych musimy stworzyc nowy Route dla tv
                 2. przy obecnym ukryciu warunku isLogged i tak kieruja na strone do zalogowania
                 czekamny na grafiki formularza i bedzie podejmowana decyzja */}
                {isLogged ? (
                    <>
                        <p>{t('pages.customer-care.no-answer')}</p>
                        <Link
                            onNav={(isActive: any) => setActiveLink(isActive)}
                            active={activeLink}
                            autofocusOn={['focus', 'mouseenter']}
                            onEnterDown={goToContactWithUsPage}
                            onClick={goToContactWithUsPage}
                            navId={BUTTON_ID.CC_CONTACT_LINK}
                        >
                            {t('pages.customer-care.contact-with-us')}
                            <IconWrapper>
                                <Icon name={ICONS.ARROW_RIGHT} />
                            </IconWrapper>
                        </Link>
                    </>
                ) : (
                    <p>
                        {t('pages.customer-care.contact-us')} {email}
                    </p>
                )}
            </ContactLinkWrapper>
        </>
    );
};
