export enum DATA_TESTING {
    ACCOUNT = 'account',
    DISCONNECT = 'disconnect',
    CONNECT = 'connect',
    POSSESSIONS = 'possessions',
    ACCESS = 'access',
    RULES = 'rules',
    MENU = 'menu',
    RIGHT_MENU = 'right-menu',
    PROMOBOX = 'promobox',
    LINK = 'link',
    BUTTON = 'button',
    SLIDER = 'slider',
    PLACEHOLDER = 'placeholder',
    WITH_OFFER = 'with-offer',
    COLLECTION = 'collection',
    OFFER = 'offer',
    FOOTER = 'footer',
    NAVIGATION_BUTTON = 'navigation-button',
    WRAPPER = 'wrapper',
    GRID = 'grid',
    FILTER = 'filter',
    DROPDOWN = 'dropdown',
    MESSAGE = 'message',
    FORM = 'form',
    TEXT_INPUT = 'text-input',
    TEXTAREA = 'textarea',
    TEXT = 'text',
    IMAGE = 'image',
    ID = 'id',
    RECOMMENDATIONS = 'recommendations',
    LIVE = 'live',
    COLUMN = 'column',
    ROW = 'row',
    ERROR = 'error',
    LABEL = 'label',
    PACKET = 'packet',
    CONTENT = 'content',
    DETAILS = 'details',
    PAYMENT = 'payment',
    PLAYER = 'player',
    TABS = 'tabs',
}
