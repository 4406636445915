import { StyledTruncatedDesc, StyledTruncatedText } from './style';
import { TruncatedTextProps } from './types';

const TruncatedText = ({ text, lines, isDescription }: TruncatedTextProps) => {
    if (isDescription) {
        return <StyledTruncatedDesc dangerouslySetInnerHTML={{ __html: text }} />;
    }
    return <StyledTruncatedText dangerouslySetInnerHTML={{ __html: text }} lines={lines} />;
};

export default TruncatedText;
