import {
    ORDER_STATUS,
    PAYMENT_STATUS,
    PAYMENT_STEPS,
    PaymentsDataInterface,
    PricingPlanDataInterface,
    UPGRADE_STATUS,
    RedirectToFirstPaymentStepInterface,
    RedirectToPaymentStepInterface,
    StatsDataToRedirect,
} from '@/interfaces/payments.interface';
import { ProductIdInterface } from '@/interfaces/product-id-interface';
import { DotpayPaymentCardRegistrationModel } from '@/models/payments/dotpay-payment-card-registration.model';
import { OptionDataModel } from '@/models/payments/option-data.model';
import { PaymentCardModel } from '@/models/payments/payment-card.model';
import { PrePurchaseOfferModel } from '@/models/payments/pre-purchase-offer.model';
import { ProductOfferModel } from '@/models/products/product-offer.model';
import { Router } from '@/routing';
import { IParams, ROUTES } from '@/routing/types';
import { generatePath } from 'react-router-dom';
import { findPatternByRouteName } from './router/find-pattern-by-route-name.helper';
import { getNavigationParams } from './router/get-navigation-params.helper';
import { isAuthorizedOnlyPaymentStep } from './router/is-authorized-only-payment-step.helper';
import { buildNextUrl, getNextUrl } from './url.helper';
import { GTM_SESSION_KEYS } from '@/constants/dataLayer';

export const INITIAL_STATUS_REFETCH_DELAY = 1000;
export const MAX_STATUS_REFETCH_DELAY = 60000;

export function redirectToFirstPaymentStep({
    productId,
    selectOfferId,
    selectOfferPricingPlanId,
    withNext,
    extractParams,
}: RedirectToFirstPaymentStepInterface): void | { route: ROUTES; params: IParams } {
    const nextUrl = withNext ? buildNextUrl() : undefined;

    if (extractParams) {
        return redirectToPaymentStep({
            step: PAYMENT_STEPS.STEP_1,
            productId,
            selectOfferId,
            selectOfferPricingPlanId,
            nextUrl,
            extractParams,
        });
    }

    redirectToPaymentStep({
        step: PAYMENT_STEPS.STEP_1,
        productId,
        selectOfferId,
        selectOfferPricingPlanId,
        nextUrl,
    });
}

export function redirectToNextPaymentStep(
    currentStep: PAYMENT_STEPS,
    productId: ProductIdInterface,
    replaceRoute?: boolean,
    nextUrl?: string,
): void {
    const steps = Object.values(PAYMENT_STEPS);
    const currentStepIndex = steps.indexOf(currentStep);
    const nextStep = steps[currentStepIndex + 1];

    if (nextStep) {
        redirectToPaymentStep({ step: nextStep, productId, nextUrl, replaceRoute });
    }
}

export function redirectToPaymentStep({
    step,
    productId,
    selectOfferId,
    selectOfferPricingPlanId,
    nextUrl,
    replaceRoute,
    extractParams,
}: RedirectToPaymentStepInterface): void | { route: ROUTES; params: IParams } {
    const currentNextUrl = getNextUrl();
    const next = nextUrl || currentNextUrl;
    const { id, type, subType: subtype } = productId;
    let params: IParams = { id, type, subtype, step };

    if (selectOfferId) {
        params = { ...params, selectOfferId };
    }

    if (selectOfferPricingPlanId) {
        params = { ...params, selectOfferPricingPlanId };
    }

    if (next) {
        params = { ...params, next };
    }

    const destination = findPatternByRouteName(ROUTES.PURCHASE);

    if (destination) {
        const path = generatePath(destination, params);
        const target = encodeURIComponent(window.location.origin + path);
        params = { ...params, target };
    }

    if (extractParams) {
        return { route: ROUTES.PURCHASE, params };
    }

    const redirect = replaceRoute ? Router.replaceRoute : Router.pushRoute;
    redirect(ROUTES.PURCHASE, params);
}

export function redirectLoginToContinue(query: IParams) {
    const params = getNavigationParams(query);

    const href = window.location.href.split('step')[0];
    const target = `${href}step-2`;
    Object.assign(params, { target: encodeURIComponent(target) });

    Router.pushRoute(ROUTES.LOGIN_TO_CONTINUE, params);
}

type PaymentPathUrl = `${string}/step-${number}`;

export function redirectToPaymentFirstStep(target: PaymentPathUrl, next: string) {
    if (!isAuthorizedOnlyPaymentStep(target)) return;

    const href = target.split('step')[0];
    const redirectUrl = `${href}step-1`;
    const nextParam = `next=${encodeURIComponent(next)}`;

    Router.pushRoute(`${decodeURIComponent(redirectUrl)}?${nextParam}` as ROUTES);
}

export function redirectToPaymentRules(
    productId: ProductIdInterface,
    offerId: string,
    offerPricingPlanId?: string,
): void {
    const { id, type, subType } = productId;
    const params: IParams = { id, type, subType, offerId };

    if (offerPricingPlanId) {
        Router.pushRoute(ROUTES.PURCHASE_PRICING_PLAN_RULES, { ...params, offerPricingPlanId });
        return;
    }

    Router.pushRoute(ROUTES.PURCHASE_OFFER_RULES, params);
}

export function redirectToPaymentRule(
    productId: ProductIdInterface,
    offerId: string,
    ruleId: number,
    offerPricingPlanId?: string,
): void {
    const { id, type, subType } = productId;
    const params: IParams = { id, type, subType, offerId, ruleId };

    if (offerPricingPlanId) {
        Router.pushRoute(ROUTES.PURCHASE_PRICING_PLAN_RULE, { ...params, offerPricingPlanId });
        return;
    }

    Router.pushRoute(ROUTES.PURCHASE_OFFER_RULE, params);
}

export function redirectToPurchaseStatusPage(
    productId: ProductIdInterface,
    offerId: string,
    optionId: string,
    possessionId: string,
    statsData?: StatsDataToRedirect,
): void {
    const { id, type, subType } = productId;

    if (statsData) {
        sessionStorage.setItem(GTM_SESSION_KEYS.HIT_TRANSACTION_DATA, JSON.stringify(statsData));
    }

    Router.pushRoute(ROUTES.PURCHASE_STATUS, {
        id,
        type,
        subType,
        offerId,
        optionId,
        possessionId,
    });
}

export function redirectToBuyUrl(optionData: OptionDataModel): void {
    const buyUrl = optionData.getBuyUrl();

    if (buyUrl) {
        window.location.href = buyUrl;
    }
}

export function redirectToPaymentCardRegistrationUrl(
    paymentCardRegistration: DotpayPaymentCardRegistrationModel,
): void {
    const paymentCardRegistrationUrl = paymentCardRegistration.getPaymentCardRegistrationUrl();

    if (paymentCardRegistrationUrl) {
        window.location.href = paymentCardRegistrationUrl;
    }
}

export function buildOrderStatusUrl(
    productId: ProductIdInterface,
    offerId: string,
    optionId: string,
    orderId: string,
): string {
    const locationOrigin = `${window.location.protocol}//${window.location.host}`;
    const { id, type, subType } = productId;

    return `${locationOrigin}/order-status/${id}/${type}/${subType}/${offerId}/${optionId}/${orderId}`;
}

export function buildPaymentCardRegistrationStatusUrl(paymentCardRegistrationId: string): string {
    const locationOrigin = `${window.location.protocol}//${window.location.host}`;
    const nextUrl = buildNextUrl();

    return `${locationOrigin}/payment-card-status/${paymentCardRegistrationId}/?next=${nextUrl}`;
}

export function statusToOrderStatus(status?: number): ORDER_STATUS | undefined {
    switch (status) {
        case -3:
            return ORDER_STATUS.TIMEOUT;

        case -2:
            return ORDER_STATUS.CANCELED;

        case -1:
            return ORDER_STATUS.FAILURE;

        case 0:
            return ORDER_STATUS.SUCCESS;

        case 1:
            return ORDER_STATUS.ORDER_CREATED;

        case 2:
        case 3:
            return ORDER_STATUS.IN_PROGRESS;

        default:
            return undefined;
    }
}

export function statusToPaymentStatus(status?: number): PAYMENT_STATUS | undefined {
    if (typeof status !== 'number') {
        return undefined;
    }

    if (status === 0) {
        return PAYMENT_STATUS.SUCCESS;
    }

    if (status < 0) {
        return PAYMENT_STATUS.ERROR;
    }

    return PAYMENT_STATUS.PENDING;
}

export function statusToUpgradeStatus(status?: number): UPGRADE_STATUS | undefined {
    if (typeof status !== 'number') {
        return undefined;
    }

    if (status === 0) {
        return UPGRADE_STATUS.SUCCESS;
    }

    if (status < 0) {
        return UPGRADE_STATUS.ERROR;
    }

    return UPGRADE_STATUS.PENDING;
}

export function isAvailableToRefetchStatus(paymentStatus?: PAYMENT_STATUS): boolean {
    return paymentStatus === PAYMENT_STATUS.PENDING;
}

export function isAvailableToRefetchUpgradeStatus(upgradeStatus?: UPGRADE_STATUS): boolean {
    return upgradeStatus === UPGRADE_STATUS.PENDING;
}

export function calculateStatusRefetchDelay(prevDelay: number): number {
    return prevDelay < MAX_STATUS_REFETCH_DELAY ? prevDelay * 2 : prevDelay;
}

export function preparePaymentsData(
    paymentsData?: PaymentsDataInterface,
    step?: PAYMENT_STEPS,
): PaymentsDataInterface {
    if (!paymentsData) {
        return {};
    }

    const {
        selectedOfferId,
        selectedOfferPricingPlanId,
        selectedOptionId,
        selectedPaymentCardId,
        orderId,
    } = paymentsData;

    switch (step) {
        case PAYMENT_STEPS.STEP_1:
            return { selectedOfferId, selectedOfferPricingPlanId };

        case PAYMENT_STEPS.STEP_2:
            return {
                selectedOfferId,
                selectedOfferPricingPlanId,
                selectedOptionId,
                selectedPaymentCardId,
            };

        case PAYMENT_STEPS.STEP_3:
            return {
                selectedOfferId,
                selectedOfferPricingPlanId,
                selectedOptionId,
                selectedPaymentCardId,
                orderId,
            };

        case PAYMENT_STEPS.STEP_4:
            return { ...paymentsData };

        default:
            return {};
    }
}

export function findPaymentCard(
    paymentCards: PaymentCardModel[],
    id?: string,
): PaymentCardModel | undefined {
    return paymentCards.find((paymentCard) => paymentCard.getId() === id);
}

export function buildPricingPlansData(
    offer: ProductOfferModel | PrePurchaseOfferModel,
): PricingPlanDataInterface[] {
    const pricingPlansData: PricingPlanDataInterface[] = [];
    const isOfferOrOptionsRulesExists = offer.isOfferOrOptionsRulesExists();
    const duration = offer.getAccessText();

    offer.getOfferPricingPlans().forEach((offerPricingPlan) => {
        const minPrice = offerPricingPlan.getMinPriceText();

        if (minPrice) {
            const pricingPlan = offerPricingPlan.getPricingPlan();
            const name = pricingPlan.getName();
            const isRulesExists = isOfferOrOptionsRulesExists || pricingPlan.isRulesExists();

            pricingPlansData.push({
                offerPricingPlan,
                name,
                minPrice,
                isRulesExists,
                duration,
            });
        }
    });

    return pricingPlansData;
}

export function filterOffer(
    offer: PrePurchaseOfferModel,
    isPurchaseAvailable?: boolean,
    isUpgradeAvailable?: boolean,
) {
    if (!offer.isOptionsExists()) {
        return false;
    }

    const isPurchaseNotAvailable = isPurchaseAvailable === false;
    const isOfferUpgradeAvailable = offer.isUpgradeAvailable();

    return isPurchaseNotAvailable ? isUpgradeAvailable && isOfferUpgradeAvailable : true;
}
