import { CatalogView, CombinedView, RecommendationView } from '@/components/views/common';
import { CombinedViewOwnProps } from '@/components/views/common/types';
import { useCombinedView } from '@/components/views/common/use-combined-view.hook';
import React, { useEffect, useLayoutEffect } from 'react';
import { EPGTableView } from './EPGTableView';
import { useHourRangeContext } from '@/contexts/epg-table/hour-range.context';
import { useIncrementColumnContext } from '@/contexts/epg-table/increment-column.context';
import { useTablePageContext } from '@/contexts/epg-table/table-page.context';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { useQueryParam } from '@/hooks/use-query-param.hook';
import { QUERY_PARAM } from '@/types/query-string.type';

export const ChannelsCombinedView = ({
    collectionsConfig,
    catalogConfig,
    collectionsParams,
    collectionsList,
    isFetchingCollectionsList,
}: CombinedViewOwnProps) => {
    const { isCatalog: isCatalog, onCategoryView } = useCombinedView(
        collectionsConfig,
        catalogConfig,
    );
    const { resetContext: resetHourContext } = useHourRangeContext();
    const { handleStateReset: resetIncrementColumnContext } = useIncrementColumnContext();
    const { resetContext: resetTablePageContext } = useTablePageContext();

    const isEPG = useQueryParam({
        param: QUERY_PARAM.EPG,
        mode: 'boolean',
    })[0];

    useLayoutEffect(() => {
        if (isEPG) {
            onCategoryView();
        }
    }, [isEPG]);

    useEffect(() => {
        if (!isEPG) {
            batchedUpdates(() => {
                resetTablePageContext();
                resetIncrementColumnContext(0);
                resetHourContext();
            });
        }
    }, [isEPG]);

    if (isEPG) {
        return <EPGTableView onCategoryView={onCategoryView} withoutButton />;
    }
    return (
        <CombinedView
            hideHeader
            collectionsConfig={collectionsConfig}
            catalogConfig={catalogConfig}
            collectionsParams={collectionsParams}
            showCatalog={!!isCatalog}
            collectionsList={collectionsList}
            isFetchingCollectionsList={isFetchingCollectionsList}
        />
    );
};
