import { VirtualKeyboardContextContext } from '@/contexts/keyboard/virtual.keyboard.context';
import { Router } from '@/routing';
import throttle from 'lodash/throttle';
import { Nav, NavFuncInterface, NAVIGATION_KEYS, NavTree, navVertical } from 'nav-tree';
import * as React from 'react';
import config from 'target/config';
import { InputManager } from '@/input';

interface Props {
    children?: JSX.Element;
    func?: NavFuncInterface;
    className?: string;
}

class NavigationRoot extends React.Component<Props> {
    static contextType = VirtualKeyboardContextContext;
    private readonly navTree?: NavTree;

    constructor(props: Props) {
        super(props);
        this.navTree = new NavTree();
    }

    public componentDidMount() {
        if (config.spatialNavigation) {
            window.addEventListener('keydown', this.keydownHandler);
            window.addEventListener('wheel', this.wheelHandler, { passive: true });
        }
    }

    public componentWillUnmount() {
        if (config.spatialNavigation) {
            window.removeEventListener('keydown', this.keydownHandler);
            window.removeEventListener('wheel', this.wheelHandler);
        }
    }

    public render() {
        const { children, func, className } = this.props;

        return (
            // @ts-ignore
            <Nav
                tree={this.navTree}
                func={func || navVertical}
                className={className}
                style={{ height: '100%' }}
            >
                {children}
            </Nav>
        );
    }

    private wheelHandler = (event: WheelEvent) => {
        const { enabled: virtualKeyboardEnabled, isVisible: virtualKeyboardVisible }: any =
            this.context;

        if (virtualKeyboardEnabled && virtualKeyboardVisible) {
            return false;
        }

        if (this.navTree) {
            const delta = -1 * event.deltaY;
            const key = delta < 0 ? NAVIGATION_KEYS.DOWN : NAVIGATION_KEYS.UP;

            this.navTree.resolve(key);
        }
    };

    private keydownHandler = throttle(
        (e: KeyboardEvent) => {
            const { enabled: virtualKeyboardEnabled, isVisible: virtualKeyboardVisible }: any =
                this.context;

            if (virtualKeyboardEnabled && virtualKeyboardVisible) {
                return false;
            }

            const { DOWN, UP, LEFT, RIGHT, ENTER, BACK, DONE, CANCEL, BACKSPACE } =
                InputManager.KEY;
            let key;
            const { nodeName } = e.target as Element;
            const isTextField = nodeName.toLowerCase() === 'input';

            switch (e.keyCode) {
                case DOWN:
                    key = NAVIGATION_KEYS.DOWN;
                    break;

                case UP:
                    key = NAVIGATION_KEYS.UP;
                    break;

                case LEFT:
                    if (!isTextField) {
                        key = NAVIGATION_KEYS.LEFT;
                    }
                    break;

                case RIGHT:
                    if (!isTextField) {
                        key = NAVIGATION_KEYS.RIGHT;
                    }
                    break;

                case ENTER:
                    if (isTextField) {
                        (e.target as HTMLInputElement).blur();
                    } else {
                        key = NAVIGATION_KEYS.ENTER;
                    }
                    break;

                case BACK:
                    {
                        if (BACK !== BACKSPACE || !isTextField) {
                            e.stopPropagation();
                            e.preventDefault();
                            Router.back();
                        }
                    }
                    break;

                case DONE:
                case CANCEL:
                    {
                        if (isTextField) {
                            (e.target as HTMLInputElement).blur();
                        }
                    }
                    break;

                default:
            }

            if (key && this.navTree) {
                e.preventDefault();
                this.navTree.resolve(key);
            }
        },
        config.features.navThrottle,
        { leading: true, trailing: false },
    );
}

export default NavigationRoot;
