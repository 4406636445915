import { FlatFilterListSchema, InputFilterSchema } from '@/interfaces/filters-interface';
import { finalFilterList } from './final-filter-list.helper';

export const buildFiltersForApi = (
    data: FlatFilterListSchema[] | undefined,
    checkedFilters: string[],
    isEPG?: boolean,
) => {
    if (!Array.isArray(data)) {
        return [];
    }

    const filters = data.map((list) => list.filters);
    const flatFilters = filters.reduce((acc, val) => acc.concat(val), []);
    const result = finalFilterList(flatFilters, checkedFilters, isEPG);

    return result;
};
