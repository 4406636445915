import { SubcategoriesMenuItemProps } from '@/components/shared/Filter/SubcategoriesMenu/types';
import React, { useContext, useState } from 'react';
import { Nav } from 'nav-tree';
import { MagicMouseContext } from '../../../../contexts/mouse/magic.mouse.context';
import { FilterMenuItem } from '../styles';
import { useRouter } from '@/routing';

const SubcategoriesMenuItemMemo = ({
    subcategory,
    onAction,
    selected,
}: SubcategoriesMenuItemProps) => {
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);

    const [active, setActive] = useState(false);
    const router = useRouter();

    const isIdenticalCategory = subcategory.getId() === parseInt(router.query.subcategoryId);

    const onItemEnter = () => {
        if (!isIdenticalCategory) {
            subcategory.goToSubcategory();
        }

        onAction?.();
    };

    return (
        // @ts-ignore
        <Nav
            navId={`${subcategory.getId()}`}
            onNav={(isActive: any) => setActive(isActive)}
            onEnterDown={onItemEnter}
            onClick={onItemEnter}
            autofocusOn={isMagicMouseVisible ? ['mouseenter', 'focus'] : ['focus']}
        >
            <FilterMenuItem active={active} selected={selected}>
                {subcategory.getTitle()}
            </FilterMenuItem>
        </Nav>
    );
};

export const SubcategoriesMenuItem = React.memo(SubcategoriesMenuItemMemo);
