import { buildNextUrl } from '@/helpers/url.helper';
import { AssetInterface, goToDetailsOptionsType } from '@/interfaces/asset.interface';
import { StaffCollectionSchema } from '@/interfaces/from-schemas/navigation/getStaffRecommendationsListItemsOut';
import { ListElementImageInterface } from '@/interfaces/list-element.interface';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { COLLECTION_TYPE, CPID, MEDIA_TYPES } from '@/types/media.type';

export class CollectionModel implements AssetInterface {
    public imageDisplayMode = IMAGE_DISPLAY_MODE.THUMBNAILS;

    private readonly cpid: CPID = CPID.COLLECTION;
    private readonly customData: StaffCollectionSchema['customData'];
    private readonly description: string = '';
    private readonly gallery: StaffCollectionSchema['gallery'];
    private readonly id: string = '';
    private readonly name: string = '';
    private readonly type: string = COLLECTION_TYPE.NORMAL;
    private readonly mediaType: MEDIA_TYPES = MEDIA_TYPES.COLLECTION;

    constructor(definition: StaffCollectionSchema) {
        Object.keys(definition).forEach((name: string) => {
            // @ts-ignore
            this[name] = definition[name];
        });
    }

    public getId(): string {
        return this.id;
    }

    public getTitle(): string {
        return this.name;
    }

    public getDescription(): string {
        return this.description;
    }

    public getType(): MEDIA_TYPES {
        return this.mediaType;
    }

    public getMetadata(): string {
        return `${t('collection')}`;
    }

    public buildCollectionRouteParams() {
        const collectionId = this.id.toString();
        const next = buildNextUrl();
        return {
            collectionId: collectionId,
            next,
        };
    }

    public getRouteParams(): { [key: string]: any } {
        switch (this.getType()) {
            case MEDIA_TYPES.COLLECTION:
                return this.buildCollectionRouteParams();
            default:
                return {};
        }
    }

    public getDetailsRoute(): ROUTES {
        switch (this.getType()) {
            case MEDIA_TYPES.COLLECTION:
                return ROUTES.COLLECTION;
            default:
                return ROUTES.NOT_FOUND;
        }
    }

    public goToDetails(options?: goToDetailsOptionsType): void {
        const { trimPurchaseHistory } = options || {};

        const route = this.getDetailsRoute();
        const params = this.getRouteParams();

        if (trimPurchaseHistory) {
            Object.assign(params, { trimPurchaseHistory });
        }

        Router.pushRoute(route, params);
    }

    public getBackgroundSrc() {
        return '';
    }

    public getGallery() {
        return this.gallery;
    }

    public getImage(): ListElementImageInterface {
        return {
            src: '',
            displayMode: this.imageDisplayMode,
        };
    }

    public getThumbnails(): any {
        return '';
    }
}
