import { useMemo } from 'react';

export const useLoadHorizontalRow = ({
    focusedRowIndex,
    rowIndex,
    isSearch,
    rowCount = 2,
}: {
    focusedRowIndex: number;
    rowIndex: number;
    isSearch?: boolean;
    rowCount?: number;
}) => {
    const isPrev = focusedRowIndex - 1 === rowIndex;
    const isCurr = focusedRowIndex === rowIndex;
    const isNext = focusedRowIndex + 1 === rowIndex;

    const searchRow = isSearch && focusedRowIndex + 2 === rowIndex;
    const customRow = focusedRowIndex + (rowCount - 1) === rowIndex;

    return useMemo(
        () => isPrev || isCurr || isNext || searchRow || customRow,
        [focusedRowIndex, rowIndex],
    );
};
