import { useIdle } from '@/hooks/use-idle.hook';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ReactNode, useState } from 'react';
import { PlayableDynamicButton } from '../../../Buttons';
import { ICONS } from '../../../Icon';
import { ControlsBase } from '../ControlsBase';
import { PlaybackTime } from '../shared/PlaybackTime';
import { PlayerProgressBar } from '../shared/PlayerProgressBar';
import { PlayPauseButton } from '../shared/PlayPauseButton';
import { FlexItem, NavFlexBox } from '../shared/styles';
import { AdControls } from '../AdControls/AdControls';
import { SIDEBAR_TYPE } from '../shared/types';
import { TextAudioPanel } from './TextAudioPanel';
import { SettingsPanel } from '../shared/SettingsPanel';
import { usePlaybackOptions } from '@/hooks/player/use-playback-options';
import { usePlayerStatus } from '@/hooks/player/use-player-status';
import { useClosePlayerSideBar } from '@/hooks/player/use-close-player-sidebar';
import { MediaTitle } from '../shared/MediaTitle';
import { BUTTON_ID } from '@/types/button-id.type';
import { isAdsVisible } from '@/helpers/player.helper';
import { MediaRelatedList } from '@/components/shared/MediaRelatedList';
import React from 'react';
import { ROUTES } from '@/routing/types';
import { PlayNextButton } from '../shared/PlayNextButton';
import { Nav } from 'nav-tree';
import { usePlayerInstance } from '@/hooks/player/use-player-instance';

interface IVODTopBarProps {
    setOpenSidebar: (setter: (v: SIDEBAR_TYPE) => SIDEBAR_TYPE) => void;
    isAfterPlayback?: boolean;
}

const VODTopBar = ({ setOpenSidebar, isAfterPlayback }: IVODTopBarProps) => {
    const { hasAudioOptions, hasBothOptions, hasTextOptions, hasVideoOptions } =
        usePlaybackOptions();

    const instance = usePlayerInstance();
    const isCustomerCareButton = process.env.isCustomerCare;

    let optionButtonLabel: string | undefined;
    let optioButtonDataTesting: string | undefined;
    if (hasBothOptions) {
        optionButtonLabel = t('audio-and-subtitles-button-label');
        optioButtonDataTesting = 'audio-and-subtitles-button-label';
    } else if (hasAudioOptions) {
        optionButtonLabel = t('audio-button-label');
        optioButtonDataTesting = 'audio-button-label';
    } else if (hasTextOptions) {
        optionButtonLabel = t('subtitles-button-label');
        optioButtonDataTesting = 'subtitles-button-label';
    }

    const onButtonAction = () => {
        instance.user.pause();
        return Router.back();
    };
    return (
        <NavFlexBox restoreLastFocus>
            <FlexItem allowOverflow>
                <PlayableDynamicButton
                    icon={ICONS.ARROW_LEFT}
                    text={t('back-button-label')}
                    onButtonAction={onButtonAction}
                    data-testing="back-button-label"
                />
            </FlexItem>
            {!isAfterPlayback && optionButtonLabel && (
                <FlexItem paddingLeft="1rem" allowOverflow>
                    <PlayableDynamicButton
                        navId={BUTTON_ID.PLAYER_SIDEBAR_PANEL_AUDIO_BTN}
                        icon={ICONS.SUBS}
                        text={optionButtonLabel}
                        onButtonAction={() =>
                            setOpenSidebar((visible) =>
                                visible === SIDEBAR_TYPE.TEXT_AUDIO
                                    ? SIDEBAR_TYPE.NONE
                                    : SIDEBAR_TYPE.TEXT_AUDIO,
                            )
                        }
                        data-testing={optioButtonDataTesting}
                    />
                </FlexItem>
            )}
            {!isAfterPlayback && (hasVideoOptions || hasTextOptions) && (
                <FlexItem paddingLeft="1rem" allowOverflow>
                    <PlayableDynamicButton
                        navId={BUTTON_ID.PLAYER_SIDEBAR_PANEL_SETTINGS_BTN}
                        icon={ICONS.SETTINGS}
                        text={t('settings-button-label')}
                        onButtonAction={() =>
                            setOpenSidebar((visible) =>
                                visible === SIDEBAR_TYPE.SETTINGS
                                    ? SIDEBAR_TYPE.NONE
                                    : SIDEBAR_TYPE.SETTINGS,
                            )
                        }
                    />
                </FlexItem>
            )}

            {isCustomerCareButton && (
                <FlexItem allowOverflow paddingLeft="1rem">
                    <PlayableDynamicButton
                        icon={ICONS.QUESTION_MARK}
                        text={t('home-menu.url.help')}
                        onButtonAction={() =>
                            Router.pushRoute(
                                ROUTES.CUSTOMER_CARE_HELP,
                                {},
                                {
                                    contextType: 'player_offline',
                                    contextValue: '',
                                },
                            )
                        }
                        data-testing="help-button-label"
                    />
                </FlexItem>
            )}
        </NavFlexBox>
    );
};

const VODBottomBarRaw = ({ isMounted }: { isMounted: boolean }) => {
    return (
        // @ts-ignore
        <Nav>
            <NavFlexBox defaultFocused horizontalMargin="11rem" gutter="5rem">
                {isMounted && (
                    <>
                        <FlexItem allowOverflow>
                            <PlayPauseButton defaultFocused />
                        </FlexItem>
                        <FlexItem paddingLeft="6rem" grow shrink>
                            <MediaTitle />
                        </FlexItem>
                    </>
                )}
                <FlexItem allowOverflow>
                    <PlayNextButton isBottomBarVisible={isMounted} />
                </FlexItem>
            </NavFlexBox>
            {isMounted && (
                <NavFlexBox horizontalMargin="11rem" gutter="7rem">
                    <FlexItem grow shrink allowOverflow>
                        <PlayerProgressBar seekable />
                    </FlexItem>
                    <FlexItem paddingLeft="5rem">
                        <PlaybackTime />
                    </FlexItem>
                </NavFlexBox>
            )}
        </Nav>
    );
};
const VODBottomBar = React.memo(VODBottomBarRaw);

const VODContentControls = ({ isAfterPlayback }: { isAfterPlayback?: boolean }) => {
    const [isMounted, setIsMounted] = useState(false);
    const {
        player: {
            transition: { idleDelay },
        },
    } = process.env;
    const idle = useIdle(idleDelay);

    const { openSidebar, setOpenSidebar, handleClose, navRef } = useClosePlayerSideBar();

    let sidebar: ReactNode;
    if (isAfterPlayback) {
        sidebar = null;
    } else {
        switch (openSidebar) {
            case SIDEBAR_TYPE.SETTINGS:
                sidebar = <SettingsPanel onClose={handleClose} />;
                break;
            case SIDEBAR_TYPE.TEXT_AUDIO:
                sidebar = <TextAudioPanel onClose={handleClose} />;
                break;
            default:
                sidebar = null;
                break;
        }
    }

    return (
        <ControlsBase
            onDestroy={() => setOpenSidebar(SIDEBAR_TYPE.NONE)}
            visible={!idle || isAfterPlayback}
            topBar={<VODTopBar setOpenSidebar={setOpenSidebar} isAfterPlayback={isAfterPlayback} />}
            centerBar={isAfterPlayback && <MediaRelatedList />}
            bottomBar={<VODBottomBar isMounted={isMounted} />}
            sideBar={sidebar}
            navRef={navRef}
            mountCallback={setIsMounted}
        />
    );
};

export const VODControls = () => {
    const { state, phase } = usePlayerStatus();
    const isAfterPlayback = phase === 'AFTER_PLAYBACK';

    if (isAdsVisible(state)) {
        return <AdControls />;
    }

    return <VODContentControls isAfterPlayback={isAfterPlayback} />;
};
