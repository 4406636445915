import React from 'react';
import t from '@/lib/i18n';
import { PageContainer, MessageContainer, IconWrapper, Title, ButtonWrapper } from './styles';
import { Icon, ICONS } from '@/components/shared/Icon';
import { navVertical } from 'nav-tree';
import { PlayableButton } from '@/components/shared//Buttons';
import { AddPaymentCardMessageProps } from './types';

export const AddPaymentCardMessage = ({ onButtonAction }: AddPaymentCardMessageProps) => {
    return (
        <PageContainer func={navVertical}>
            <MessageContainer>
                <IconWrapper>
                    <Icon name={ICONS.INFO_CIRCLE_FILLED}></Icon>
                </IconWrapper>
                <Title>
                    {t('components.purchases.add-payments-card-www', {
                        targetLink: process.env.url,
                    })}
                </Title>
            </MessageContainer>
            <ButtonWrapper>
                <PlayableButton
                    text={t('ok-button-label')}
                    onButtonAction={onButtonAction}
                    defaultFocused
                />
            </ButtonWrapper>
        </PageContainer>
    );
};
