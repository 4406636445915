import { usePausablePlayable } from '@/hooks/player/use-pausable-playable';
import { usePlayerInstance } from '@/hooks/player/use-player-instance';
import { IconButton } from '../../../Buttons/PlayableButton/IconButton';
import { ICONS } from '../../../Icon';
import t from '@/lib/i18n';
import { usePlayerGuiStateContext } from '@/hooks/player/use-player-gui-state';

interface IPlayPauseButtonProps {
    defaultFocused?: boolean;
}

export const PlayPauseButton = ({ defaultFocused }: IPlayPauseButtonProps) => {
    const instance = usePlayerInstance();
    const { playable, pausable } = usePausablePlayable();
    const { pendingSeek } = usePlayerGuiStateContext();

    const onButtonAction = () => {
        if (pendingSeek) {
            instance.user.skip(pendingSeek.desiredTime);
            instance.user.play();
            return;
        }

        if (playable) {
            instance.user.play();
            return;
        }

        instance.user.pause();
    };

    if (!playable && !pausable) {
        return null;
    }

    return (
        <IconButton
            defaultFocused={defaultFocused}
            icon={playable ? ICONS.PLAY : ICONS.PAUSE}
            onButtonAction={onButtonAction}
            data-testing="play.button"
            title={playable ? t('WCAG.aria-label.play') : t('WCAG.aria-label.pause')}
        />
    );
};
