import React, { useMemo } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { CombinedView } from '@/components/views/common';
import { buildCollectionsListConfig } from '@/helpers/pages/build-collections-list-config.helper';
import { buildCatalogConfig } from '@/helpers/pages/build-catalog-config.helper';
import { useExitAppConfirmation } from '@/hooks/use-exit-app-confirmation';
import { useRouterQuery } from '@/hooks/use-router-query.hook';
import { useGetCollectionsList } from '@/api/navigation/recommendations/use-get-collections-list';

const Collection = () => {
    const query = useRouterQuery() as { collectionId: string };
    const collection = process.env.pages.collection;
    const collectionId = query?.collectionId;
    const { placeType, categoryName } = collection;
    const collectionsConfig = useMemo(
        () => buildCollectionsListConfig({ placeType, categoryName }),
        [placeType, categoryName],
    );
    const catalogConfig = useMemo(
        () => buildCatalogConfig(placeType, { collectionId: +collectionId, categoryName }),
        [placeType, collectionId, categoryName],
    );
    const collectionsParams = { place: { type: placeType, value: collectionId } };

    const { collectionsList, isFetching: isFetchingCollectionsList } = useGetCollectionsList(
        collectionsConfig,
        collectionsParams,
    );
    const noData = !collectionsList.length && !isFetchingCollectionsList;

    useExitAppConfirmation(true);

    return (
        <BaseLayout>
            <CombinedView
                hideHeader
                collectionsConfig={collectionsConfig}
                catalogConfig={catalogConfig}
                collectionsParams={collectionsParams}
                showCatalog={!!collectionId}
                collectionsList={collectionsList}
                isFetchingCollectionsList={isFetchingCollectionsList}
            />
        </BaseLayout>
    );
};

export default Collection;
