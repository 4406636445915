import { PlayableDynamicButton } from '@/components/shared/Buttons';
import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

export const FullDetailsContentContainer = styled.div<{ withScroll?: boolean }>`
    position: absolute;
    left: 15rem;

    ${({ withScroll }) =>
        withScroll
            ? css`
                  bottom: 14rem;
              `
            : css`
                  top: 14rem;
                  width: 90%;
              `}
`;

export const MainMetadataContainer = styled.div<{ withScroll?: boolean }>`
    ${({ withScroll }) =>
        withScroll &&
        css`
            position: relative;
            top: 14rem;
        `}
`;
export const AbsolutePositionDynamicButton = styled(PlayableDynamicButton)<{
    fixed?: boolean;
}>`
    position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
    right: 0rem;
    z-index: 2;
    top: -6rem;
`;

export const ShortMessageWrapper = styled(Nav as any)`
    font-size: 2.6rem;
    width: 100%;
`;

export const ShortMessageContent = styled.div`
    display: flex;
    justify-content: center;
    width: 60%;
    margin: 0 auto;
`;

export const ContactLinkWrapper = styled(Nav as any)`
    width: 60%;
    margin: 0px auto;
    padding-top: 6rem;
`;
export const ScrollableContactLinkWrapper = styled.div`
    padding-bottom: 16rem;
`;
