import useGetChannelList from '@/api/navigation/channels/use-get-channel-list';
import useGetChannelsCurrentProgram from '@/api/navigation/channels/use-get-channel-list-current-program';
import useGetChannelListFlatNavigation from '@/api/navigation/channels/use-get-channel-list-flat-navigation';
import { CHANNELS_PROGRAM_LIMIT } from '@/configs/channels.config';
import { buildElementsList } from '@/helpers/build-elements-list.helper';
import { updateCatalogParams } from '@/helpers/pages/update-catalog-params.helper';
import { usePaginatedGrid } from '@/hooks/page-catalog/use-paginated-grid.hook';
import { useCatalogParams } from '@/hooks/use-catalog-params.hook';
import { GetTvChannelsIn } from '@/interfaces/from-schemas/navigation/getTvChannelsIn';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { CatalogFetcherConfig } from '@/types/page-catalog.type';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { getChannelsIds } from './helpers';
import { FlatFilterListSchema } from '@/interfaces/filters-interface';
import { addSlugToFliterLists } from '@/helpers/catalog/map-filters-to-urls.helper';

export const useFetchChannels = ({ withPagination }: CatalogFetcherConfig) => {
    const { col } = process.env.grid;
    const { data: flatFilterLists }: { data: FlatFilterListSchema[] } =
        useGetChannelListFlatNavigation({});

    const filterLists = addSlugToFliterLists(flatFilterLists);

    const { params, setParams, clearGrid, setClearGrid } = useCatalogParams({
        filterLists,
        withPagination,
    });
    const [gridElements, setGridElements] = useState<ListElementInterface[]>([]);

    const { data: fetchedChannels, isFetching: isFetchingChannels } = useGetChannelList(
        withPagination ? {} : (params as GetTvChannelsIn),
    );

    const channelIds = useMemo(() => getChannelsIds(fetchedChannels), [fetchedChannels]);
    const currentProgramParams = !!channelIds.length && {
        channelIds,
        offset: 0,
        limit: CHANNELS_PROGRAM_LIMIT,
    };

    const { data: fetchedProgram, isFetching: isFetchingProgram } = useGetChannelsCurrentProgram(
        currentProgramParams,
        false,
    );

    const total = useMemo(() => fetchedChannels.getTotal(), [fetchedChannels]);
    const fetchedAll = useMemo(() => params.offset + params.limit >= total, [params, total]);
    const isFetching = isFetchingProgram || isFetchingChannels;

    const channelList = useMemo(
        () =>
            buildElementsList(fetchedChannels, fetchedProgram.getResults(), {
                showMetadata: true,
            }),
        [fetchedChannels, fetchedProgram],
    );

    useEffect(() => {
        if (isFetching) return;

        if (!channelList) {
            setGridElements([]);
            return;
        }

        if (channelList) {
            const clear = clearGrid || withPagination;
            setGridElements(clear ? channelList : (prev) => [...prev, ...channelList]);
        }

        setClearGrid(false);
    }, [isFetching, channelList.length]);

    const onFetchMore = useCallback(
        () => setParams(updateCatalogParams({ params, total })),
        [params, total],
    );

    const paginatedData = usePaginatedGrid({ data: fetchedChannels.getResults() });

    return {
        gridElements: withPagination ? paginatedData : gridElements,
        columns: col,
        filterLists: flatFilterLists,
        isFetching,
        fetchedAll,
        onFetchMore,
    };
};
