import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CatalogContext } from '@/contexts/catalog/catalog.context';
import { FiltersData } from '@/components/shared/Filter/FilterList/types';
import { buildFiltersForApi } from '@/helpers/Filters/build-filters-for-api.helper';
import { getFlatFiltersValues } from '@/helpers/Filters/get-flat-filter-values.helper';

export const useCheckedFilters = ({ data, onAction }: FiltersData) => {
    const { grid, setGrid } = useContext(CatalogContext);
    const { filters: filtersCtx } = grid;

    const flatFiltersCtx = useMemo(() => getFlatFiltersValues(filtersCtx), [filtersCtx]);

    const [checkedFilters, setCheckedFilters] = useState<string[]>(flatFiltersCtx || []);

    const filtersForApi = useMemo(
        () => buildFiltersForApi(data, checkedFilters),
        [data, checkedFilters],
    );

    const newFilters = flatFiltersCtx.length !== checkedFilters.length;

    useEffect(() => {
        if (newFilters) {
            onAction();

            setGrid((prev) => ({
                ...prev,
                activeIndex: 0,
                rowIndex: 0,
                colIndex: 0,
                filters: filtersForApi,
            }));
        }
    }, [filtersForApi, newFilters]);

    const toggleFilterState = useCallback((value: string) => {
        setCheckedFilters((prev) => {
            return prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value];
        });
    }, []);

    const onClearFilters = useCallback(() => setCheckedFilters([]), []);

    return { checkedFilters, onClearFilters, toggleFilterState };
};
