import { WatchedContentDataSchema } from '@/interfaces/from-schemas/user-content/getLatelyWatchedContentDataListOut';
import { StaffCollectionContentResultsModel } from '@/models/collection/staff-collection-content-results.model';
import { StaffCollectionContentModel } from '@/models/collection/staff-collection-content.model';
import { ListElementModel } from '@/models/list-element/list-element.model';

import { DATA_POSITION, TITLE_TYPE } from '@/types/list-element-metadata.type';

export const buildCollectionCatalogCollection = (
    data: StaffCollectionContentModel,
    dataPosition?: DATA_POSITION,
    followMeContent?: WatchedContentDataSchema[],
    titleType?: TITLE_TYPE,
) => {
    const { col } = process.env.grid;
    let columns;
    const collections = [];

    if (data.getResults().length) {
        const newCollection = data.getResults().map((result) => {
            const staffCollectionContent = new StaffCollectionContentResultsModel(result);

            const gridElement = new ListElementModel(staffCollectionContent);
            dataPosition && gridElement.setDataPosition(dataPosition);
            titleType && gridElement.setTitleType(TITLE_TYPE.FULL);
            gridElement.updateFromFollowMeContent(staffCollectionContent.getId(), followMeContent);
            return gridElement;
        });
        collections.push(newCollection);
    }

    return { newCollection: collections.flat(), columns: columns || col };
};
