import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

export const IconWrapper = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.avatar.backgroundColor};
    border-radius: 50%;
    display: flex;
    height: 17rem;
    justify-content: center;
    width: 17rem;
    svg {
        fill: ${({ theme }) => theme.text.color.secondary};
        width: 7.6rem;
    }
`;

export const AddProfileCircleWrapper = styled(Nav as any)<{ $active: boolean }>`
    display: inline-block;
    margin: 2rem;
    cursor: pointer;
    ${({ $active }) =>
        $active &&
        css`
            ${IconWrapper} {
                background-color: ${({ theme }) => theme.button.activeBgColor};
                border: 0.5rem solid ${({ theme }) => theme.avatar.borderColor};
                transform: scale(1.2);
            }
            svg {
                fill: ${({ theme }) => theme.iconFill.primaryHover};
            }
            span {
                top: 1.7rem;
            }
        `}
`;

export const AddProfileCircleText = styled.span`
    color: ${({ theme }) => theme.text.color.avatar};
    display: inline-block;
    font-size: ${({ theme }) => theme.text.size.avatar};
    padding-top: 1.5rem;
    position: relative;
`;
