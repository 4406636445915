import { buildMediaQuery } from '@/helpers/media-query.helper';
import { device, responsiveFonts, responsiveSizes } from '@/targets/default/mediaQuery';
import { IGutterProps } from '@/types/gutter.type';
import styled, { css, ThemeProps } from 'styled-components';
import {
    MAINTEXT_KIND,
    MAINTEXT_RESPONSIVE_KIND,
    StyledMainTextProps,
    StyledTruncatedTextProps,
} from './types';

const buildFont = (mode?: MAINTEXT_KIND) => {
    switch (mode) {
        case MAINTEXT_KIND.TITLE:
            return css`
                font-size: ${({ theme }) => theme.text.size.title};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 900;
            `;
        case MAINTEXT_KIND.HEADER:
            return css`
                font-size: ${({ theme }) => theme.text.size.header};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 900;
            `;
        case MAINTEXT_KIND.DESCRIPTION:
            return css`
                font-size: ${({ theme }) => theme.text.size.description};
                color: ${({ theme }) => theme.text.color.secondary};
                font-weight: 400;
            `;
        case MAINTEXT_KIND.COPYRIGHT:
            return css`
                font-size: ${({ theme }) => theme.text.size.copyright};
                color: ${({ theme }) => theme.text.color.secondary};
                font-weight: 400;
            `;
        case MAINTEXT_KIND.SUBTITLE:
            return css`
                font-size: ${({ theme }) => theme.text.size.subtitle};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
            `;
        case MAINTEXT_KIND.LIST_ELEMENT:
            return css`
                font-size: ${({ theme }) => theme.text.size.gridElement};
                color: ${({ theme }) => theme.listElement.color};
                font-weight: 400;
            `;
        case MAINTEXT_KIND.PAGE_TITLE:
            return css`
                font-size: ${({ theme }) => theme.text.size.pageCustomerCareTitle};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
            `;
        case MAINTEXT_KIND.PAGE_CUSTOMER_CARE_TITLE:
            return css`
                font-size: ${({ theme }) => theme.text.size.pageCustomerCareTitle};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
            `;
        case MAINTEXT_KIND.PAGE_DESCRIPTION:
            return css`
                font-size: ${({ theme }) => theme.text.size.pageDescription};
                color: ${({ theme }) => theme.text.color.secondary};
                font-weight: 400;
            `;
        case MAINTEXT_KIND.PAGE_SUBTITLE:
            return css`
                font-size: ${({ theme }) => theme.text.size.pageSubtitle};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
            `;
        case MAINTEXT_KIND.PRODUCT_NAME:
            return css`
                font-size: ${({ theme }) => theme.text.size.productName};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 800;
            `;
        case MAINTEXT_KIND.PRODUCT_TYPE:
            return css`
                font-size: ${({ theme }) => theme.text.size.productType};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
            `;
        case MAINTEXT_KIND.PAYMENT_STEP_TITLE:
            return css`
                font-size: ${({ theme }) => theme.text.size.pageSubtitle};
                color: ${({ theme }) => theme.text.color.paymentTitle};
                font-weight: 400;
            `;
        case MAINTEXT_KIND.PAYMENT_STEP_DESCRIPTION:
            return css`
                font-size: ${({ theme }) => theme.text.size.pageDescription};
                color: ${({ theme }) => theme.text.color.paymentDescription};
                font-weight: 400;
            `;
        case MAINTEXT_KIND.GDPR:
            return css`
                font-size: ${({ theme }) => theme.text.size.gdpr};
                color: ${({ theme }) => theme.text.color.gdpr};
                font-weight: 400;
                max-width: 32rem;
                text-align: left;
                line-height: 1.5;
                margin-left: auto;
                margin-right: auto;
            `;
        case MAINTEXT_KIND.GDPR_MAGIC_LINK:
            return css`
                font-size: 1.4rem;
                color: #7f8387;
                text-align: left;
                font-weight: 300;

                a {
                    text-decoration: none;
                }
            `;
        case MAINTEXT_KIND.METADATA_SEASON_EPISODE_TITLE:
            return css`
                font-weight: 600;
                font-size: 2.6rem;
                margin-bottom: 0.3rem;
                margin-top: -0.9rem;
            `;
        case MAINTEXT_KIND.NEW_COLLECTION_TITLE:
            return css`
                font-size: ${({ theme }) => theme.text.size.copyright};
                color: #c3cad5;
                font-weight: 700;
                text-transform: uppercase;
                z-index: 1;
            `;
        case MAINTEXT_KIND.NEW_COLLECTION_SUBTITLE:
            return css`
                font-size: ${({ theme }) => theme.text.size.description};
                color: #c3cad5;
                font-weight: 700;
                z-index: 1;
            `;
    }
};

const buildResponsiveFont = (mode?: MAINTEXT_RESPONSIVE_KIND) => {
    switch (mode) {
        case MAINTEXT_RESPONSIVE_KIND.COLLECTION_TITLE:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.collectionTitle)}
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
            `;
        case MAINTEXT_RESPONSIVE_KIND.LIST_ELEMENT_TITLE:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.listElementTitle)}
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
            `;
        case MAINTEXT_RESPONSIVE_KIND.LIST_ELEMENT_METADATA:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.listElementMetadata)}
                color: ${({ theme }) => theme.text.color.metadata};
                font-weight: 500;
            `;
        case MAINTEXT_RESPONSIVE_KIND.PROMOBOX_TITLE:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.promobox.title)};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 900;
            `;
        case MAINTEXT_RESPONSIVE_KIND.PROMOBOX_DESCRIPTION:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.promobox.description)};
                ${buildMediaQuery(
                    'margin-bottom',
                    responsiveSizes.promobox.descriptionMarginBottom,
                )};
                color: ${({ theme }) => theme.text.color.secondary};
                font-weight: 400;
            `;
        case MAINTEXT_RESPONSIVE_KIND.PRODUCT_TYPE:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.payments.productSize)}
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
            `;
        case MAINTEXT_RESPONSIVE_KIND.PRODUCT_NAME:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.payments.productSize)}
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 800;
            `;
        case MAINTEXT_RESPONSIVE_KIND.PAYMENT_TITLE:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.payments.title)}
                color: ${({ theme }) => theme.text.color.paymentTitle};
                font-weight: 400;
                text-align: center;
                @media ${device.mobileM} {
                    text-align: left;
                }
            `;
        case MAINTEXT_RESPONSIVE_KIND.PAYMENT_DESCRIPTION:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.payments.description)}
                color: ${({ theme }) => theme.text.color.paymentDescription};
                font-weight: 400;
                text-align: center;
                padding-top: 1rem;
                @media ${device.mobileM} {
                    text-align: left;
                }
            `;
        case MAINTEXT_RESPONSIVE_KIND.TITLE:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.title)};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 900;
            `;
        case MAINTEXT_RESPONSIVE_KIND.DESCRIPTION:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.description)};
                ${buildMediaQuery(
                    'margin-bottom',
                    responsiveSizes.promobox.descriptionMarginBottom,
                )};
                color: ${({ theme }) => theme.text.color.secondary};
                font-weight: 400;

                ${buildMediaQuery(
                    'line-height',
                    responsiveSizes.mediaDetails.fullDetails.description.lineHeight,
                )};
            `;
        case MAINTEXT_RESPONSIVE_KIND.METADATA_TITLE:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.title)};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 900;
                ${buildMediaQuery('margin-bottom', responsiveSizes.mediaDetails.titleMargin)};
            `;

        case MAINTEXT_RESPONSIVE_KIND.METADATA_SEASON_EPISODE_TITLE:
            return css`
                font-weight: 400;
                font-size: 1.4rem;
                ${buildMediaQuery('font-size', responsiveFonts.metadataSeasonEpisodeTitle)};
                margin-bottom: 0.4rem;
            `;

        case MAINTEXT_RESPONSIVE_KIND.FULL_DETAILS_METADATA_TITLE:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.title)};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 900;
                ${buildMediaQuery(
                    'margin-bottom',
                    responsiveSizes.mediaDetails.fullDetails.titleMargin,
                )};
            `;

        case MAINTEXT_RESPONSIVE_KIND.SEARCH_SUBTITLE:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.search.subtitle)};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
                ${buildMediaQuery('margin-bottom', responsiveSizes.search.subtitleMargin)}
            `;

        case MAINTEXT_RESPONSIVE_KIND.ACCOUNT_OPTION_TITLE:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.account.optionTitle)};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
            `;

        case MAINTEXT_RESPONSIVE_KIND.ACCOUNT_OPTION_DESCRIPTION:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.account.optionDescription)};
                color: ${({ theme }) => theme.text.color.secondary};
                font-weight: 400;
            `;
        case MAINTEXT_RESPONSIVE_KIND.CHECK_CODE_SUBTITLE:
            return css`
                font-size: 1.5rem;
                color: ${({ theme }) => theme.text.color.secondary};
                font-weight: 400;
            `;
        case MAINTEXT_RESPONSIVE_KIND.NEW_COLLECTION_TITLE:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.collection.title)}
                color: #c3cad5;
                font-weight: 700;
                text-transform: uppercase;
                z-index: 1;
                margin-bottom: 0.5rem;
            `;
        case MAINTEXT_RESPONSIVE_KIND.NEW_COLLECTION_SUBTITLE:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.collection.subtitle)}
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 500;
                z-index: 1;
            `;
    }
};
const buildMarginBottom = (mode?: MAINTEXT_RESPONSIVE_KIND) => {
    switch (mode) {
        case MAINTEXT_RESPONSIVE_KIND.PROMOBOX_TITLE:
            return css`
                ${buildMediaQuery('margin-bottom', responsiveSizes.promobox.titleMarginBottom)};
            `;
        case MAINTEXT_RESPONSIVE_KIND.CHECK_CODE_SUBTITLE:
            return css`
                margin-bottom: 0.7rem;
            `;
    }
};
const StyledMainText = styled.div<StyledMainTextProps & IGutterProps & ThemeProps<any>>`
    ${({ $mode, responsive }) =>
        responsive
            ? buildResponsiveFont($mode as MAINTEXT_RESPONSIVE_KIND)
            : buildFont($mode as MAINTEXT_KIND)}
    ${({ $mode, responsive, gutter }) =>
        responsive
            ? buildMarginBottom($mode as MAINTEXT_RESPONSIVE_KIND)
            : css`
                  margin-bottom: ${gutter}rem;
              `}
    ${({ centered }) =>
        centered &&
        css`
            text-align: center;
            padding: 0 3.5rem;
        `}
`;

const StyledTruncatedText = styled.span<StyledTruncatedTextProps>`
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    white-space: normal;
    -webkit-line-clamp: ${({ lines }) => lines};
    -webkit-box-orient: vertical;
`;
const StyledTruncatedDesc = styled.span`
    white-space: normal;
    display: block;
    overflow: hidden;
    width: 100%;
    line-height: 2.6rem;
    max-height: 5.2rem;
`;

export { StyledMainText, StyledTruncatedText, StyledTruncatedDesc };
