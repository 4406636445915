import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useCheckProductAccess from '@/api/drm/use-check-product-access';
import { useGetPossessions } from '@/api/payments/use-get-possessions';
import { useGetPrePurchaseData } from '@/api/payments/use-get-pre-purchase-data';
import { useGetProductSubscriptions } from '@/api/payments/use-get-product-subscriptions';
import useGetProduct from '@/api/navigation/use-get-product';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { ProcessingLoader } from '@/components/shared/Loader';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { PacketDetails } from '@/components/views/PacketDetails';
import { RouteParams } from '@/components/views/PacketDetails/types';
import { buildPacketDetailsModel } from '@/helpers/packet.helper';
import { useGetCurrentAuthSession } from '@/hooks/use-get-current-auth-session';
import { PacketDetailsPageProps } from '@/interfaces/packet-details.interface';
import { ProductIdSchema } from '@/interfaces/from-schemas/navigation/getProductOut';
import t from '@/lib/i18n';
import { useError } from '@/contexts/error/error.context';

const PacketDetailsPage = () => {
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const { setError } = useError();
    const { packetId } = useParams<RouteParams>();
    const product: ProductIdSchema = { id: packetId, type: 'multiple', subType: 'packet' };

    const {
        data: packet,
        isFetching: isFetchingPacket,
        error: packetError,
    } = useGetProduct({
        product,
    });
    const {
        data: productAccess,
        isFetching: isFetchingProductAccess,
        error: productAccessError,
    } = useCheckProductAccess({
        product,
    });
    const {
        data: productSubscriptions,
        isFetching: isFetchingProductSubscriptions,
        error: productSubscriptionsError,
    } = useGetProductSubscriptions(false);

    const {
        data: possessions,
        isFetching: isFetchingPossessions,
        error: possessionsError,
    } = useGetPossessions(false);
    const {
        data: prePurchaseData,
        isFetching: isFetchingPrePurchaseData,
        error: prePurchaseDataError,
    } = useGetPrePurchaseData({
        product,
        withoutMinPriceProducts: true,
        withoutUserPaymentsData: true,
    });
    const { data: authSession, error: authSessionError } = useGetCurrentAuthSession();

    const isDataFetched = [
        isFetchingPacket,
        isFetchingProductAccess,
        isFetchingProductSubscriptions,
        isFetchingPossessions,
        isFetchingPrePurchaseData,
    ].every((data) => data === false);

    const error =
        packetError ||
        productAccessError ||
        productSubscriptionsError ||
        prePurchaseDataError ||
        possessionsError ||
        authSessionError;
    useEffect(() => {
        if (isDataFetched) setIsFetching(false);
    }, [isDataFetched]);

    const renderDetails = () => {
        const packetDetails: PacketDetailsPageProps = {
            packet,
            productAccess,
            prePurchaseData,
            possessions,
            productSubscriptions,
            authSession,
        };

        const packetDetailsModel = buildPacketDetailsModel(packetDetails);

        if (error) {
            setError(error);
        }
        if (isFetching) {
            return <ProcessingLoader isFetching />;
        } else if (!packetDetailsModel) {
            return (
                <MainText
                    text={t('pages.packet.packet-does-not-exist')}
                    mode={MAINTEXT_KIND.PAGE_TITLE}
                />
            );
        }

        return <PacketDetails packet={packetDetailsModel} />;
    };

    return <BaseLayout fullscreen>{renderDetails()}</BaseLayout>;
};

export default PacketDetailsPage;
