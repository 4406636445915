import { ImageSourceSchema } from '@/interfaces/from-schemas/navigation/getCategoryContentWithFlatNavigationOut';
import { CPID } from '@/types/media.type';
import { GetCategoryOut } from '@/interfaces/from-schemas/navigation/getCategoryOut';
import { AssetInterface, goToDetailsOptionsType } from '@/interfaces/asset.interface';
import { CustomDataInterface } from '@/interfaces/custom-data.interface';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { coverUtil } from '@/utils/cover.util';
import { ListElementImageInterface } from '@/interfaces/list-element.interface';
import { backgroundUtil } from '@/utils/background.utils';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import slug from 'slug';

// Indeks kategorii głównej w tablicy `categoryNamesPath`, którą dostajemy z GM, np. [PBG, Film]
const MAIN_CATEGORY_INDEX = 2;

export class CategoryModel implements AssetInterface {
    public imageDisplayMode = IMAGE_DISPLAY_MODE.THUMBNAILS;

    private readonly cpid: CPID = CPID.CATEGORY_OR_PACK;
    private readonly description: string = '';
    private readonly genres: string[] = [];
    private readonly id: number = 0;
    private readonly chronological?: boolean;
    private readonly reporting: GetCategoryOut['reporting'] = {};
    private readonly thumbnails: ImageSourceSchema[] = [];
    private readonly categoryNamesPath: string[] = [];
    private readonly categoryPath: number[] = [];
    private readonly keyCategoryId: number = 0;
    private readonly name: string = '';
    private readonly subCategoriesLabel: string = '';
    private readonly customData: GetCategoryOut['customData'];
    private readonly backgroundSrc?: string;
    private readonly gallery?: GetCategoryOut['gallery'];
    private readonly grantExpression: string = '';

    constructor(definition: GetCategoryOut) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });
        }

        this.backgroundSrc = backgroundUtil(this.thumbnails)?.src;
    }

    public getThumbnails(): ImageSourceSchema[] {
        return this.thumbnails;
    }

    public getGenres(): string {
        return this.genres.join(', ');
    }

    public getDescription(): string {
        return this.description;
    }

    public isThumbnailsExists(): boolean {
        return this.getThumbnails().length > 0;
    }

    public isChronological(): boolean {
        return !!this.chronological;
    }

    public getMediaKey(): string {
        return `${this.id}|${this.cpid}`;
    }

    public getId(): number {
        return this.id;
    }

    public getCpid(): number {
        return this.cpid;
    }

    public getCategoryNamesPath(): string[] {
        return this.categoryNamesPath;
    }

    public getCategoryPath(): number[] {
        return this.categoryPath;
    }

    public getKeyCategoryId(): number {
        return this.keyCategoryId;
    }

    public getTitle(): string {
        return this.name;
    }

    public getSubCategoriesLabel(): string {
        return this.subCategoriesLabel;
    }

    public getPath(): string {
        const urlTitle = this.name.split(' ').join('-');

        // potrzebny helper do budowania urli
        return `${urlTitle}/${this.cpid}/${this.id}`;
    }

    public getMetadata(): string {
        return this.getGenres();
    }

    public getDetailedMetadata(): string {
        // TODO(ksyrytczyk): Trzeba się upewnić czy możemy tutaj przejść na gettera isMainCategory() i poprawić.
        const mainCategory = this.categoryNamesPath[1];

        // TODO(ksyrytczyk): Na pierwszej pozycji powinien być portal (np. PBG) - do weryfikacji.
        return [mainCategory, this.getGenres()].filter(Boolean).join(', ');
    }

    public getImage(): ListElementImageInterface {
        const images = this.getThumbnails();
        const image = coverUtil(images, this.imageDisplayMode);

        return {
            // TODO: brakuje funkcji do pobrania placeholdera
            src: image ? image.src : '',
            displayMode: this.imageDisplayMode,
        };
    }

    public get isMainCategory(): boolean {
        return this.categoryNamesPath.length === MAIN_CATEGORY_INDEX;
    }

    public buildMainCategoryRouteParams() {
        const categoryName = this.categoryNamesPath[1];
        return {
            category: categoryName ? slug(categoryName) : undefined,
        };
    }

    public buildVodAutoplayRouteParams() {
        const vodCategoryTitle = this.categoryNamesPath[2];
        return {
            vodCategoryTitle: vodCategoryTitle ? slug(vodCategoryTitle) : undefined,
            categoryId: this.id,
        };
    }

    public getDetailsRoute(): ROUTES {
        return this.isMainCategory ? ROUTES.VOD : ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY;
    }

    public getRouteParams(): any {
        return this.isMainCategory
            ? this.buildMainCategoryRouteParams()
            : this.buildVodAutoplayRouteParams();
    }

    public goToDetails(options?: goToDetailsOptionsType): void {
        const replaceRoute = options?.replaceRoute;
        const route = this.getDetailsRoute();
        const params = this.getRouteParams();

        replaceRoute ? Router.replaceRoute(route, params) : Router.pushRoute(route, params);
    }

    public getCustomData(): CustomDataInterface {
        return this.customData;
    }

    public getBackgroundSrc(): string | undefined {
        return this.backgroundSrc;
    }

    public getGallery() {
        return this.gallery;
    }

    public getGrantExpression(): string {
        return this.grantExpression;
    }
}
