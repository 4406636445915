import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useGetRecommendationListElements } from '@/api/navigation/recommendations/use-get-recommendation-list-elements';
import { ProcessingLoader } from '@/components/shared/Loader';
import { PageContext } from '@/contexts/page/page.context';
import { GUTTER } from '@/types/gutter.type';
import { LIST_TYPE } from '@/types/recommendation-list.type';

import { Slider } from '../Slider';
import { StyledCollectionWrapper } from './styles';
import { CollectionOwnProps } from './types';

const Collection = ({
    params,
    onLoadedData,
    onActiveItem,
    onActiveRow,
    rowIndex,
    list,
    config,
}: CollectionOwnProps) => {
    const { singleCollection, defaultFocused, withPackets } = config;
    const activeRowIndex = withPackets ? -1 : rowIndex;
    const listType = useMemo(() => (list.getLayout().value || LIST_TYPE.SIMPLE) as LIST_TYPE, [
        list,
    ]);
    const { refocusPage } = useContext(PageContext);

    const [loadNav, setLoadNav] = useState<boolean>(false);

    const {
        collection,
        isFetching,
        hasData,
        fetchedAll,
        handleFetchMore,
    } = useGetRecommendationListElements(list, params);
    useEffect(() => {
        if (!isFetching && !refocusPage) {
            if (hasData) {
                onLoadedData(true, rowIndex);
                onActiveRow((prev) => {
                    return prev === null ? activeRowIndex : prev;
                });
                setLoadNav(true);
            } else {
                onLoadedData(false, rowIndex);
            }
        }
    }, [isFetching, refocusPage]);

    const handleSliderNav = useCallback((isActive: boolean) => {
        if (isActive) {
            onActiveRow(rowIndex);
        }
    }, []);

    const sliderConfig = useMemo(
        () => ({
            rowIndex,
            list,
            defaultFocused,
            fetchedAll,
            collectionLength: collection.length,
            withPackets,
        }),
        [rowIndex, list, defaultFocused, fetchedAll, collection.length, withPackets],
    );

    const slider = useMemo(
        () => (
            <>
                <ProcessingLoader gutter={GUTTER.BIG} isFetching={Boolean(isFetching)} />
                <Slider
                    collectionElements={collection}
                    onFetchMore={handleFetchMore}
                    onSliderNav={handleSliderNav}
                    lockNav={isFetching}
                    onActiveItem={onActiveItem(listType)}
                    config={sliderConfig}
                    showPlaceholders={!collection.length}
                    data-testing={`collection.${rowIndex}`}
                />
            </>
        ),
        [collection, isFetching, sliderConfig],
    );

    if (!loadNav) return null;

    if (singleCollection) {
        return <StyledCollectionWrapper>{slider}</StyledCollectionWrapper>;
    }

    return slider;
};

export const CollectionMemo = React.memo(Collection);
