import { useAdaptiveSeekWithRemote } from '@/hooks/player/use-adaptive-seek';
import { usePlayerGuiStateContext } from '@/hooks/player/use-player-gui-state';
import { usePlayerStatus } from '@/hooks/player/use-player-status';
import { usePlayerTimeMarkers } from '@/hooks/player/use-player-time-markers';
import { SEEKING_DIR } from '@/interfaces/player.interface';
import { NAVIGATION_KEYS } from 'nav-tree';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    ProgressBarContainer,
    ProgressBarFilled,
    ProgressBarSeekHandle,
    ProgressBarAdBreakIndicator,
    SeekableProgressBarContainer,
    ProgressBarRange,
} from './styles';
import { IPlayerProgressBarProps } from './types';
import { usePlayerInstance } from '@/hooks/player/use-player-instance';
import { calculateSeekTimeByPercentage } from '@/helpers/player.helper';

const SeekablePlayerProgressBar = () => {
    const rangeInputRef = useRef<HTMLInputElement | null>(null);
    const seekHandleRef = useRef<HTMLDivElement | null>(null);
    const [seekValue, setSeekValue] = useState(0);

    const {
        playback: { adBreaks },
    } = usePlayerStatus();
    const { percentagePosition, adaptiveSeek } = useAdaptiveSeekWithRemote();
    const {
        currentTime,
        skipRange: { start, end },
    } = usePlayerTimeMarkers();
    const {
        pendingSeek,
        tmpPlaybackCurrentTime,
        setTmpPlaybackCurrentTime,
        setSeekHandlePosition,
    } = usePlayerGuiStateContext();
    const player = usePlayerInstance();
    const [position, setPosition] = useState(0);

    useEffect(() => {
        setPosition(tmpPlaybackCurrentTime);
    }, [tmpPlaybackCurrentTime]);

    useEffect(() => {
        setPosition(currentTime);
    }, [currentTime]);

    useEffect(() => {
        const $seekHandle = seekHandleRef.current;

        if ($seekHandle) {
            const seekHandleCenter = $seekHandle.offsetWidth / 2;
            const seekHandlePosition = $seekHandle.offsetLeft + seekHandleCenter;
            setSeekHandlePosition(seekHandlePosition);
        }
    }, [position]);

    const navFunc = useCallback(
        (key: NAVIGATION_KEYS) => {
            switch (key) {
                case NAVIGATION_KEYS.LEFT:
                    try {
                        adaptiveSeek(SEEKING_DIR.BACK);
                    } catch (e) {
                        console.log(e);
                    }
                    return null;
                case NAVIGATION_KEYS.RIGHT:
                    try {
                        adaptiveSeek(SEEKING_DIR.FORWARD);
                    } catch (e) {
                        console.log(e);
                    }
                    return null;
                default:
                    return false;
            }
        },
        [adaptiveSeek],
    );

    const onProgressBarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const percentageValue = Math.round(e.target.valueAsNumber);
        const seekTime = calculateSeekTimeByPercentage(percentageValue, start, end);

        setSeekValue(percentageValue);
        setTmpPlaybackCurrentTime(seekTime);
    };

    const onMouseDownHandler = () => {
        player.pause();
    };

    const onMouseUpHandler = useCallback(() => {
        const seekTime = calculateSeekTimeByPercentage(seekValue, start, end);
        player.user.skip(seekTime);
        rangeInputRef.current?.blur();
    }, [seekValue]);

    const onEnterDown = () => {
        if (pendingSeek) {
            player.user.skip(pendingSeek.desiredTime);
            player.user.play();
        }
    };

    const AdBreaks = useCallback(() => {
        return (
            <>
                {adBreaks.map((adBreak) => (
                    <ProgressBarAdBreakIndicator
                        key={adBreak}
                        percentagePosition={percentagePosition(adBreak)}
                    />
                ))}
            </>
        );
    }, [adBreaks]);
    return (
        <SeekableProgressBarContainer
            func={navFunc}
            autofocusOn={['focus', 'mouseenter']}
            onEnterDown={onEnterDown}
        >
            <ProgressBarFilled percentagePosition={percentagePosition(position)} />
            <ProgressBarSeekHandle
                ref={seekHandleRef}
                percentagePosition={percentagePosition(position)}
            />
            <AdBreaks />
            <ProgressBarRange
                ref={rangeInputRef}
                type="range"
                min="0"
                max="100"
                step="1"
                onChange={onProgressBarChange}
                onMouseUp={onMouseUpHandler}
                onMouseDown={onMouseDownHandler}
            />
        </SeekableProgressBarContainer>
    );
};

export const PlayerProgressBar = ({ seekable }: IPlayerProgressBarProps) => {
    if (seekable) {
        return <SeekablePlayerProgressBar />;
    }

    return (
        <ProgressBarContainer>
            <ProgressBarFilled percentagePosition={100} />
        </ProgressBarContainer>
    );
};
