import { redirectToPurchaseStatusPage } from '@/helpers/payment.helper';
import { usePaymentsData } from '@/hooks/payments/use-payments-data';
import { useSelectedPaymentCard } from '@/hooks/payments/use-selected-payment-card';
import { isStbTargets } from '@/constants/portal-recognition';
import React, { useEffect } from 'react';
import { Blik } from './Blik';
import { NonInteractiveTransaction } from './NonInteractiveTransaction';
import { PinCodeVerification } from './PinCodeVerification';
import { Sms } from './Sms';
import { TransactionStepContainer } from './styles';
import { TransactionStepProps } from './types';
import { useHelpConfigurationContext } from '@/contexts/customer-care/helpConfiguration.context';

export const TransactionStep = ({
    product,
    onPaymentError,
    onSetStepsVisibility,
    onSetProductCardVisibility,
    onExitPaymentPath,
    baseProductId,
}: TransactionStepProps) => {
    const { selectedOfferId, selectedOptionId, orderId } = usePaymentsData();
    const selectedOffer = product.findOffer(selectedOfferId);
    const selectedOption = selectedOffer?.findOption(selectedOptionId);
    const selectedPaymentCard = useSelectedPaymentCard();
    const { setPaymentType } = useHelpConfigurationContext();

    useEffect(() => {
        if (selectedOption) {
            setPaymentType(selectedOption.getType());
        }
    }, [selectedOption]);

    const onPaymentSuccess = (possessionId: string) => {
        if (selectedOfferId && selectedOptionId) {
            redirectToPurchaseStatusPage(
                baseProductId,
                selectedOfferId,
                selectedOptionId,
                possessionId,
            );
        }
    };
    const buildBlik = () => {
        if (!selectedOffer || !selectedOption || !orderId) {
            return null;
        }
        return (
            <Blik
                product={product}
                offer={selectedOffer}
                option={selectedOption}
                orderId={orderId}
                onPaymentSuccess={onPaymentSuccess}
                onPaymentError={onPaymentError}
                onSetStepsVisibility={onSetStepsVisibility}
            />
        );
    };

    const buildSms = () => {
        if (!selectedOffer || !selectedOption || !orderId) {
            return null;
        }

        return (
            <Sms
                product={product}
                offer={selectedOffer}
                option={selectedOption}
                orderId={orderId}
                onPaymentSuccess={onPaymentSuccess}
                onPaymentError={onPaymentError}
            />
        );
    };

    const buildPinCodeVerification = () => {
        if (!selectedOffer || !selectedOption || !orderId) {
            return null;
        }
        return (
            <PinCodeVerification
                product={product}
                offer={selectedOffer}
                option={selectedOption}
                orderId={orderId}
                onPaymentSuccess={onPaymentSuccess}
                onPaymentError={onPaymentError}
                onSetStepsVisibility={onSetStepsVisibility}
                onSetProductCardVisibility={onSetProductCardVisibility}
                onExitPaymentPath={onExitPaymentPath}
            />
        );
    };

    const buildNonInteractiveTransaction = (isCpwallet = false) => {
        if (!selectedOffer || !selectedOption || !orderId || !selectedPaymentCard) {
            return null;
        }
        return (
            <NonInteractiveTransaction
                product={product}
                offer={selectedOffer}
                option={selectedOption}
                orderId={orderId}
                paymentCard={selectedPaymentCard}
                isCpwallet={isCpwallet}
                onPaymentSuccess={onPaymentSuccess}
                onPaymentError={onPaymentError}
                onSetStepsVisibility={onSetStepsVisibility}
                onSetProductCardVisibility={onSetProductCardVisibility}
                onExitPaymentPath={onExitPaymentPath}
            />
        );
    };

    const buildTransationContent = () => {
        switch (selectedOption?.getType()) {
            case 'dotpay-blik':
            case 'plusbank-blik':
            case 'payu-blik':
                return buildBlik();

            case 'sms':
                return buildSms();

            case 'netia':
                if (isStbTargets) {
                    // Do usunięcia jak przestaniemy wspierać STB
                    return buildPinCodeVerification();
                }

                return buildNonInteractiveTransaction();

            case 'dotpay-card':
            case 'plusbill':
                return buildNonInteractiveTransaction();

            case 'cpwallet':
                return buildNonInteractiveTransaction(true);

            default:
                // error
                return null;
        }
    };

    return <TransactionStepContainer>{buildTransationContent()}</TransactionStepContainer>;
};
