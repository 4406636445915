import { fillIcon } from '@/helpers/fill-icon.helper';
import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';
import { IGutterProps } from '../../../../../types/gutter.type';
import { InputFocusStyledProps, SuffixProps } from './types';

export const InputWrapper = styled('div')<InputFocusStyledProps>`
    display: flex;
    flex-grow: 1;
    border-radius: 3rem;
    box-shadow: none;
    overflow: hidden;
    align-items: center;
    width: 100%;
    background: ${({ theme }) => theme.input.nonActiveFocusBgColor};
`;

export const IconWrapper = styled('div')<InputFocusStyledProps>`
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
    ${({ theme }) => fillIcon(theme.input.iconWrapperColor)};
    stroke: transparent;
`;

const inputStyle = css`
    filter: none;
    flex-grow: 1;
    padding: 1.6rem 2rem;
    margin-right: 3rem;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: ${(props) => props.theme.input.size};
    color: ${(props) => props.theme.input.textColor};
    font-weight: 500;
    -webkit-text-fill-color: ${(props) => props.theme.input.textColor};
    box-shadow: 0 0 0 1000px ${(props) => props.theme.input.nonActiveFocusBgColor} inset;
    -webkit-box-shadow: 0 0 0 1000px ${(props) => props.theme.input.nonActiveFocusBgColor} inset;
`;

export const Input = styled('input')<InputFocusStyledProps>`
    ${inputStyle}
    &::placeholder {
        text-align: ${(props) => props.placeholderDisplay};
        color: ${(props) =>
            props.focus ? props.theme.input.blacktextColor : props.theme.input.textColor};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active,
    &:-webkit-autofill::first-line,
    &:-webkit-autofill:focus {
        ${inputStyle}
    }
`;

export const Label = styled(Nav as any)<IGutterProps>`
    display: block;
    margin-bottom: ${({ gutter }) => gutter}rem;
    width: 100%;
    &.nav-focused ${InputWrapper} {
        background: ${({ theme }) => theme.input.activeFocusBgColor};
    }
    &.nav-focused ${IconWrapper} {
        ${({ theme }) => fillIcon(theme.input.activeFocusIconWrapperColor)};
    }
    &.nav-focused ${Input} {
        &,
        &::first-line {
            color: ${({ theme }) => theme.input.blacktextColor};
            -webkit-text-fill-color: ${({ theme }) => theme.input.blacktextColor};
            box-shadow: 0 0 0 1000px ${({ theme }) => theme.input.activeFocusBgColor} inset;
            -webkit-box-shadow: 0 0 0 1000px ${({ theme }) => theme.input.activeFocusBgColor} inset;
        }
    }
`;

export const Suffix = styled('div')<SuffixProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 auto;
    height: 4.8rem;
    width: auto;
    ${({ portal }) =>
        portal === 'pg' &&
        css`
            margin-left: '.8rem';
        `};
    ${({ portal, iconPosition }) =>
        portal === 'pbg' &&
        css`
            margin-left: ${iconPosition ? '-5.75rem' : '.8rem'};
        `};
    ${({ portal, iconPosition }) =>
        portal !== 'pg' &&
        portal !== 'pbg' &&
        css`
            margin-left: ${iconPosition ? '6.75rem' : '.8rem'};
        `};
    };
    position: ${(props) => (props.iconPosition ? 'relative' : '')};
    left: ${(props) => (props.iconPosition ? '25rem' : '0')};
`;

export const ErrorWrapper = styled.div`
    width: 2rem;
    height: 2rem;
    ${({ theme }) => fillIcon(theme.input.errorBgColor)};
    stroke: transparent;
`;

export const Error = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 auto;
    height: 4.8rem;
    width: auto;
    margin-right: 4%;
`;
