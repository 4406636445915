import { ReactNode } from 'react';

/* eslint-disable no-unused-vars */
export enum ICONS {
    ACCESSIBILITY_FEATURES_AUDIO_DESCRIPTION = 'accessibility-features-ad',
    ACCESSIBILITY_FEATURES_SIGN_LANGUAGE = 'accessibility-features-jm',
    ACCESSIBILITY_FEATURES_SUBTITLES = 'accessibility-features-n',
    ALERT = 'alert',
    APPLE = 'apple',
    ARROW_DOWN = 'arrow-down',
    ARROW_LEFT = 'arrow-left',
    ARROW_RIGHT = 'arrow-right',
    ARROW_RIGHT_CIRCLE = 'arrow-right-circle',
    ARROW_UP = 'arrow-up',
    BASKET = 'basket',
    BASKET_CIRCLE = 'basket-circle',
    CALENDAR = 'calendar',
    CARD = 'card',
    CAST_BTN = 'cast-btn',
    CATALOG_BTN = 'catalog-btn',
    CHECK = 'check',
    CHECK_CIRCLE = 'check-circle',
    CHECK_MARK = 'check-mark',
    CHECK_WITH_DASH = 'check-with-dash',
    CHILDREN = 'children',
    CLOCK = 'clock',
    CLOCK_REDO = 'clock-redo',
    CLOSE = 'close',
    DOT = 'dot',
    DOWNLOAD = 'download',
    EMAIL = 'email',
    EMAIL_CIRCLE = 'email-circle',
    ERROR404 = '404',
    ERROR500 = '500',
    EXIT = 'exit',
    EXIT_DOOR = 'exit-door',
    EXIT_DOOR_ARROW_RIGHT = 'exit-door-arrow-right',
    FACEBOOK = 'facebook',
    FACEBOOK_LETTER = 'facebook-letter',
    FAN_ZONE = 'fan-zone',
    FILTERS = 'filters',
    FOOTBALL = 'football',
    FOOTER_PLUS = 'footer-plus',
    FORWARD = 'forward',
    FULLSCREEN_CLOSE = 'fullscreen-close',
    FULLSCREEN_OPEN = 'fullscreen-open',
    GOOGLE = 'google',
    HAMBURGER_MENU = 'hamburger-menu',
    HBO = 'hbo',
    HEART = 'heart',
    HEART_CIRCLE = 'heart-circle',
    HEART_DELETE = 'heart-delete',
    HOME = 'home',
    INFO = 'info',
    INFO2 = 'info2',
    INFO_CIRCLE = 'info-circle',
    INFO_CIRCLE_FILLED = 'info-circle-filled',
    INSTAGRAM = 'instagram',
    IPOLSAT_BOX = 'ipolsat-box',
    KEY = 'key',
    KEY_CIRCLE = 'key-circle',
    LIVE = 'live',
    LOCK_CIRCLE = 'lock-circle',
    LOCK_CLOSED = 'lock-closed',
    LOCK_FILLED = 'lock-filled',
    LONG_ARROW_LEFT = 'long-arrow-left',
    MEDIA_TYPE_LIVE = 'media-type-live',
    MEDIA_TYPE_TV = 'media-type-tv',
    MEDIA_TYPE_VOD = 'media-type-vod',
    MICROPHONE = 'microphone',
    MOBILE = 'mobile',
    MOVIE = 'movie',
    MUTE = 'mute',
    PACKET = 'media-type-packet',
    PACKETS = 'packets',
    PARENT_CONTROL_0 = 'parent-control-0',
    PARENT_CONTROL_12 = 'parent-control-12',
    PARENT_CONTROL_16 = 'parent-control-16',
    PARENT_CONTROL_18 = 'parent-control-18',
    PARENT_CONTROL_7 = 'parent-control-7',
    PASSWORD = 'password',
    PAUSE = 'pause',
    PC = 'pc',
    PENCIL = 'pencil',
    PERSON = 'person',
    PERSON_CIRCLE = 'person-circle',
    PERSON_PLUS_CIRCLE = 'person-plus-circle',
    PERSON_REMOVE = 'person-remove',
    PHONE = 'phone',
    PHONE_CIRCLE = 'phone-circle',
    PIN_CIRCLE = 'pin-circle',
    PLAY = 'play',
    PLAY_FORWARD = 'play-forward',
    PLUS = 'plus',
    PLUS_LOGO = 'plus-logo',
    POLSAT_BOX = 'polsat-box',
    POLSAT_BOX_SMALL = 'polsat-box-small',
    PRELONG = 'prelong',
    QR_CODE_POLSATBOXGO = 'qr-code-polsatboxgo',
    QR_CODE_POLSATGO = 'qr-code-polsatgo',
    QUALITY_HD = 'quality-hd',
    RENEW = 'renew',
    SEARCH = 'search',
    SEEK_BACKWARD_10 = 'seek-backward-10',
    SEEK_FORWARD_10 = 'seek-forward-10',
    SERIES = 'series',
    SETTINGS = 'settings',
    SHOW_PASSWORD = 'show-password',
    SIMPLE_PLAY = 'simple-play',
    SOUND = 'sound',
    SPIN_LEFT = 'spin-left',
    SPIN_RIGHT = 'spin-right',
    STAR = 'star',
    STB = 'stb',
    SUBS = 'subs',
    SUCCESS_CIRCLE = 'success-circle',
    SYGNET_IPLA = 'sygnet_ipla',
    SYGNET_PBG = 'sygnet_pbg',
    TIKTOK = 'tiktok',
    TV = 'tv',
    TV_BIG = 'tv-big',
    TV_RATING_SYSTEM_0 = 'tv-rating-system-0',
    TV_RATING_SYSTEM_12 = 'tv-rating-system-12',
    TV_RATING_SYSTEM_16 = 'tv-rating-system-16',
    TV_RATING_SYSTEM_18 = 'tv-rating-system-18',
    TV_RATING_SYSTEM_7 = 'tv-rating-system-7',
    UNDERAGE_CLASSIFICATION_DRUGS = 'underage-classification-n',
    UNDERAGE_CLASSIFICATION_SEX = 'underage-classification-s',
    UNDERAGE_CLASSIFICATION_VIOLENCE = 'underage-classification-p',
    UNDERAGE_CLASSIFICATION_VULGARISMS = 'underage-classification-w',
    WALLET = 'wallet',
    WARNING_CIRCLE = 'warning-circle',
    WARNING_CIRCLE_FILLED = 'warning-circle-filled',
    X = 'x',
    YOUTUBE = 'youtube',
    QUESTION_MARK = 'question-mark',
}

export enum ICON_MODE {
    IDLE = 'IDLE',
    WAITING = 'WAITING',
    ACTIVE = 'ACTIVE',
}

export interface IconProps {
    name: ICONS | string;
    mode?: ICON_MODE;
}

export interface IconCastProps {
    children: ReactNode;
    iconMode?: ICON_MODE;
}

export interface IconCastContainerProps {
    activeBowIndex?: number;
    mode: ICON_MODE;
}

export enum ICON_SIZE {
    TV = 94,
    VOD = 41,
    LIVE = 48,
    DEFAULT = 41,
}
