import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { PageContext } from '@/contexts/page/page.context';
import { CatalogContext } from '@/contexts/catalog/catalog.context';
import { buildFocusedIndex } from '@/helpers/nav-tree/build-focused-index.helper';
import { buildFocusArray } from '@/helpers/nav-tree/build-focus-array.helper';
import { useRouter } from '@/routing';

export const useCurrentGridFocus = ({
    columns,
    lastElIndex = 0,
    isMutable = false,
}: {
    columns: number;
    lastElIndex?: number;
    isMutable?: boolean;
}) => {
    const { asPath } = useRouter();
    const { action } = useHistory();
    const { refocusPage } = useContext(PageContext);
    const { grid, setGrid } = useContext(CatalogContext);
    const { focusMap } = grid;

    const initFocus = focusMap[asPath] || ['1', '1'];
    const revisitGrid = useMemo(
        () => Object.keys(focusMap).filter((v) => v === asPath).length > 0,
        [focusMap, asPath],
    );
    const resetGridMap = useCallback(() => {
        const newMap = Object.assign(focusMap, { [asPath]: ['1', '1'] });
        setGrid((prev) => ({ ...prev, focusMap: newMap }));
    }, []);

    const currentFocus = useMemo(() => {
        if (revisitGrid && action !== 'POP') {
            resetGridMap();
            return ['1', '1'];
        }

        return focusMap[asPath] || ['1', '1'];
    }, [focusMap, asPath, revisitGrid, action, resetGridMap]);

    const focusedIndex = useMemo(() => buildFocusedIndex(initFocus, columns), [initFocus, columns]);

    const focusLastArr = useMemo(
        () => buildFocusArray(lastElIndex, columns),
        [lastElIndex, columns],
    );

    const deletedGridLastElement = isMutable && focusedIndex > lastElIndex;

    useEffect(() => {
        if (!revisitGrid || refocusPage) {
            resetGridMap();
        }
    }, [refocusPage]);

    if (deletedGridLastElement) {
        return { focusedIndex: lastElIndex, currentFocus: focusLastArr };
    }

    return { currentFocus, focusedIndex };
};
