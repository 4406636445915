import AdbClient from '@/types/stb/AdbClient';
import AdbErrorHandler from '@/types/stb/AdbErrorHandler';
import AdbException from '@/types/stb/AdbException';
import AdbSetting from '@/types/stb/AdbSetting';
import AdbToken from '@/types/stb/AdbToken';
import { init as adbInit } from '@/public/polsat-box-go-stb/assets/scripts/adb';
import { STBDRMType } from '@/types/stb/StbProperties';
import { isStbTargets } from '@/constants/portal-recognition';

/**
 * Shimmed window object which is populated by browser internals
 */
declare global {
    interface Window {
        stb: {
            JSONErrorHandler?: AdbErrorHandler;
            infoSvc?: {
                getTokenValue: (token: AdbToken) => string;
                verifyTokenValue: (
                    token: AdbToken,
                    actionValue: any,
                    resultListener: Object,
                ) => void;
            };
            cpNagraLicenseRequester?: {
                getDeviceUniqueId: () => string;
            };
            settings?: {
                getValue: (setting: AdbSetting) => any;
            };
            defaultUserData?: any;
            deviceId: string;
            stbDRMType: STBDRMType;
        };
        fbAsyncInit: () => void;
    }
}

/**
 * Watch out, ADB is an unexplored land, typings should prevent you from most errors,
 * but they're not canonical and mostly inferred from the informations provided
 * by docs information if something is not catched with AdbError,
 * you will have information about it inside the device logs.
 */
export class InternalAdbClient implements AdbClient {
    exceptionManager: ExceptionManager = new ExceptionManager();

    protected errorHandler: AdbErrorHandler = ($event) => {
        const { message, exception } = $event.detail;
        console.log(`ADBException occurred: ${message}`);
        this.exceptionManager.grabException(exception);
    };

    init() {
        adbInit();
        window.addEventListener('ADBException' as any, ($event: CustomEvent) =>
            this.errorHandler($event.detail),
        );
    }
    setErrorHandler(handler: AdbErrorHandler) {
        this.errorHandler = handler;
    }

    async getTokenValue(token: AdbToken): Promise<string> {
        return this.withErrorContext((resolve) =>
            resolve(window.stb.infoSvc?.getTokenValue(token)),
        );
    }

    async getNagra2DeviceUniqueId(): Promise<string> {
        return this.withErrorContext((resolve) => {
            resolve(window.stb.cpNagraLicenseRequester?.getDeviceUniqueId());
        });
    }

    async verifyTokenValue(token: AdbToken, actionValue: string): Promise<any> {
        return this.withErrorContext((resolve: any, reject: any) => {
            const timeout = setTimeout(() => {
                reject('Setting check timed out');
            }, 5000);
            const listener = {
                onInfoSvcActionResult: (result: any) => {
                    clearTimeout(timeout);
                    resolve(result);
                },
            };

            window.stb.infoSvc?.verifyTokenValue(token, actionValue, listener);
        });
    }

    async getSettingValue(setting: AdbSetting): Promise<string> {
        return this.withErrorContext((resolve: any) =>
            resolve(window.stb.settings?.getValue(setting)),
        );
    }

    async withErrorContext(callback: (resolve: Function, reject: Function) => any): Promise<any> {
        const context = this.exceptionManager.startContext();
        return new Promise((resolve, reject) => {
            callback(
                (result: any) => {
                    context.resolve();
                    if (context.hasExceptions()) {
                        reject(context.exceptions);
                    } else {
                        resolve(result);
                    }
                },
                (rejectResult: any) => {
                    context.resolve();
                    reject(rejectResult);
                },
            );
        });
    }
}

class ExceptionManager {
    contexts: AdbExceptionContext[] = [];

    startContext() {
        return new AdbExceptionContext(this);
    }

    resolveContext(context: AdbExceptionContext) {
        this.contexts.splice(this.contexts.indexOf(context), 1);
    }

    grabException(exception: any) {
        this.contexts.forEach((context) => context.addExceptions(exception));
    }
}
class AdbExceptionContext {
    manager: ExceptionManager;
    exceptions: AdbException[] = [];

    constructor(manager: ExceptionManager) {
        this.manager = manager;
    }

    resolve() {
        this.manager.resolveContext(this);
    }

    addExceptions(error: any) {
        this.exceptions.push(error);
    }

    hasExceptions() {
        return this.exceptions.length > 0;
    }
}

let clientAdb: InternalAdbClient | undefined;
if (isStbTargets) {
    clientAdb = new InternalAdbClient();
    clientAdb.init();
}

export { clientAdb };
