import React from 'react';
import { AvatarStyled, AvatarWrapper, AvatarName } from './styles';
import { AvatarProps } from './types';
import { truncateString } from '@/helpers/truncate-string.helper';
import DefaultAvatar from '@/public/shared/assets/default-avatar.png';
import { protectURL } from '@/helpers/rewrite-protocol.helper';

export const Avatar = ({
    url = DefaultAvatar.src || (DefaultAvatar as unknown as string), // TODO wdrożenie next/images
    $size,
    active,
    text,
    responsive,
    sliderStyle,
}: AvatarProps) => {
    const titleAttr = text && text.length > process.env.user.maxProfileNameLength ? text : '';

    return (
        <AvatarWrapper $size={$size} responsive={responsive} sliderStyle={sliderStyle}>
            <AvatarStyled
                $size={$size}
                active={active}
                src={protectURL(url)}
                alt={text ? text : 'Avatar'}
                responsive={responsive}
                sliderStyle={sliderStyle}
            />
            {text && (
                <AvatarName responsive={responsive} title={titleAttr}>
                    {truncateString(text, process.env.user.maxProfileNameLength)}
                </AvatarName>
            )}
        </AvatarWrapper>
    );
};
