import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

import { CheckSlowDevice } from '@/helpers/check-slow-device.helper';
import { convertPxToRem } from '@/helpers/convert-px-to-rem.helper';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';

import { SliderButtonsInterface, SliderElementsInterface, SliderInterface } from './types';

const buildHorizontalSlide = ({ activeItemId, width }: SliderElementsInterface) => {
    if (width && activeItemId !== undefined) {
        return css`
            transform: translateX(-${activeItemId * width}rem);
            transition: transform 0.3s linear;
        `;
    }
};

const calculateSliderButtonsPosition = ({ sliderHeight }: SliderButtonsInterface) => {
    const pos = sliderHeight ? sliderHeight / 2 - 40 : 0;

    return `${pos}px`;
};

export const StyledSlider = styled(Nav).attrs<SliderInterface>(({ height }) => {
    return {
        style: {
            height: height ? `${convertPxToRem(height)}` : 'auto',
        },
    };
})`
    padding-top: 4.3rem;
    padding-left: 15rem;
    margin-bottom: 1rem;
`;

export const StyledSliderElements = styled.div<SliderElementsInterface>`
    white-space: nowrap;
    padding: ${({ imageDisplayMode }) =>
        imageDisplayMode === IMAGE_DISPLAY_MODE.POSTERS ? '1.5rem 0' : '3.5rem 0'};
    opacity: ${({ hide, theme }) => (hide ? theme.loadingWrapper.childOpacity : 1)};
    transition: ${CheckSlowDevice(`opacity 0.4s linear 0.2s`)};

    ${(props) => buildHorizontalSlide(props)};

    > * {
        display: inline-block;
        vertical-align: text-top;
    }
`;

export const SliderButtonsStyled = styled.div<SliderButtonsInterface>`
    display: flex;
    position: relative;
    height: 0;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    top: ${(props) => calculateSliderButtonsPosition(props)};
`;

export const SliderArrowButton = styled.div`
    z-index: 100;
    cursor: pointer;

    width: 8rem;
    height: 8rem;

    background-color: ${({ theme }) => theme.slider.arrowButton.backgroundColor};
    border: 0.1rem solid;
    border-color: ${({ theme }) => theme.slider.arrowButton.borderColor};
    border-radius: 50%;

    align-items: center;
    justify-content: center;
    display: flex;

    svg {
        width: 3rem;
        height: 3rem;
        fill: ${({ theme }) => theme.slider.arrowButton.iconColor};
    }

    &:hover {
        transform: ${({ theme }) => theme.listElement.activeTransform};
    }
`;

export const ButtonLeft = styled(SliderArrowButton)`
    margin-right: auto;
    margin-left: 18rem;
    transform: rotate(180deg);

    &:hover {
        transform: ${({ theme }) => theme.listElement.activeTransform + 'rotate(180deg)'};
    }
`;

export const ButtonRight = styled(SliderArrowButton)`
    margin-left: auto;
    margin-right: 9rem;
`;
