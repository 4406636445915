import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

import { COL_WIDTH } from '@/constants/epg-table';

export const CombinedCell = styled(Nav as any)<{ height: string }>(
    ({ theme, height }) => css`
        height: ${height};
        width: ${COL_WIDTH}rem;
        position: relative;
        border-bottom: 0.1rem solid ${theme.epgTable.mainBorderColor};
        overflow: hidden;
    `,
);
