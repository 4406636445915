import isEqual from 'lodash/isEqual';
import { IRouter, RouteInterface } from '@/routing/types';
import { getLastStringFromUrl } from './get-last-string-from-url.helper';

export const checkItemActive = (router: IRouter, label: string, routeData?: RouteInterface) => {
    const menuItem = getLastStringFromUrl(router);
    const focusLabel = menuItem === label;

    if (routeData?.params) {
        const { category, collectionId } = routeData.params;

        if (category) {
            return isEqual(category, router.query['category']);
        }

        if (collectionId) {
            return isEqual(collectionId, router.query['collectionId']);
        }
    }

    return focusLabel;
};
