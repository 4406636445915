import { Nav } from 'nav-tree';
import styled from 'styled-components';

import { fillIcon } from '@/helpers/fill-icon.helper';

import { InfoTextContainerProps, StyledMediaTypeIconWrapperProps, InfoTextProps } from './types';

export const MediaDetailsContainer = styled(Nav as any)`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const ContentContainer = styled.div`
    position: absolute;
    bottom: 14rem;
    left: 15rem;
    right: 80rem;
`;

export const MetadataContainer = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 0.5rem;
`;

export const MetaDataIconsWrapper = styled.div`
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
        margin-right: 0.7rem;
    }
`;

export const MetadataWrapper = styled.div``;

export const MetadataIconWrapper = styled.div`
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
        margin-right: 0.7rem;
    }

    &:not(:empty) + ${MetadataWrapper} {
        margin-left: 1.8rem;
    }
`;

export const StyledMediaTypeIconWrapper = styled.span<StyledMediaTypeIconWrapperProps>`
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    height: 2.9rem;
    font-size: 0;
`;

export const LicenseAccessDurationWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-left: 1.8rem;

    ${MetadataWrapper} {
        margin-left: unset;
    }
`;

export const ButtonGroupContainer = styled.div`
    display: flex;
    margin-top: 2.2rem;
`;

export const ProgressContainer = styled.div`
    display: flex;
    align-items: center;
    width: 50rem;
    margin: 2rem 0;
`;

export const ProgressBarFmcWrapper = styled.div`
    display: inline-block;
    width: 20rem;
    margin-left: 1.3rem;
    margin-right: 1.3rem;
`;

export const ProgressBarContainer = styled.div`
    flex: 1;
    width: 20rem;
    margin-left: 1.3rem;
    margin-right: 1.3rem;
`;

export const InfoTextContainer = styled.div<InfoTextContainerProps>`
    align-items: center;
    display: flex;
    margin-bottom: 3rem;
    margin-right: ${({ largeMargin }) => (largeMargin ? 6 : 1)}rem;
`;

export const PremiereBtnContainer = styled(InfoTextContainer)`
    background-color: ${({ theme }) => theme.mediaDetails.liveButtonBgColor};
    border-radius: 2.1rem;
    padding: 0.2rem 4.2rem;
    font-size: 2.4rem;
    font-weight: 500;
`;

export const InfoText = styled.div<InfoTextProps>`
    color: ${({ highlight, theme }) =>
        highlight ? theme.text.color.primary : theme.text.color.secondary};
    font-size: 2rem;
    font-weight: 400;
    margin-left: 0.5rem;
    white-space: nowrap;
`;

export const IconWrapper = styled.div`
    ${({ theme }) => fillIcon(theme.mediaDetails.iconColor)};
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
`;

export const TableWrapper = styled.div`
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.1;
    margin-bottom: -1rem;
    margin-left: 8rem;
`;

export const TableDetailsTitle = styled.td`
    color: ${(props) => props.theme.text.color.metadataSmall};
    text-transform: uppercase;
    min-width: 26rem;
    display: flex;
    padding-bottom: 1rem;
`;

export const TableDetailsInfo = styled.td`
    color: ${(props) => props.theme.text.color.primary};
    padding-bottom: 1rem;
`;

export const CallToActionWrapper = styled(Nav as any)<{ active: boolean }>`
    width: 56.2rem;
    border-radius: 0.6rem;
    filter: drop-shadow(1.532px 1.286px 20px rgba(0, 0, 0, 0.35));
    background-color: ${({ theme }) => theme.collection.bgColor};
    border: 0.1rem solid
        ${(props) =>
            props.active ? props.theme.button.activeBgColor : props.theme.collection.borderColor};
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-top: 1.7rem;
    position: fixed;
    bottom: 17.5rem;
    right: 7.2rem;
`;
export const CollectionItemBg = styled.div<{ imgSrc?: string }>`
    display: flex;
    width: 70%;
    height: 100%;
    flex-direction: column;
    position: absolute;
    flex-basis: 70%;
    background-image: url(${({ imgSrc }) => imgSrc});
    opacity: 0.6;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(0.2rem);
`;
export const CollectionBg = styled.div`
    opacity: 0.6;
    background-color: #000000;
    position: absolute;
    border-top-left-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
    width: 70%;
    height: 100%;
`;

export const CollectionItem = styled.div<{ imgSrc?: string }>`
    display: flex;
    flex-direction: column;
    flex-basis: 70%;
    justify-content: center;
    text-align: left;
`;
export const ImageItem = styled.div<{ imgSrc?: string }>`
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    background-image: url(${({ imgSrc }) => imgSrc});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
`;

export const ButtonsContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3rem;
`;
