import { ICONS } from '@/components/shared/Icon';
import { calculateProgress } from '@/helpers/calculate-progressbar-for-channel.helper';
import {
    formatDate,
    getTodayLabel,
    getTomorrowLabel,
    minutesLeftFromNow,
} from '@/helpers/date.helper';
import { buildHighlightSpan } from '@/helpers/highlight-translation.helper';
import { IGetMediaMutateFn } from '@/hooks/use-get-media-api.type';
import {
    CategorySchema,
    ImageSourceSchema,
} from '@/interfaces/from-schemas/navigation/getMediaListOut';
import { WatchedContentDataSchema } from '@/interfaces/from-schemas/user-content/getLatelyWatchedContentDataListOut';
import { MediaButtonInterface } from '@/interfaces/media-button.interface';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { IParams, ROUTES } from '@/routing/types';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { MEDIA_ACCESS_STATUS } from '@/types/media.type';
import { CPID, MEDIA_TYPES } from '@/types/media.type';
import { ChannelProgramTvModel } from '../channels/channel-program-tv.model';
import { MediaListItemModel } from '../media-list-item.model';
import { CheckProductAccessModel } from '../payments/check-product-access.model';
import { PrePurchaseDataModel } from '../payments/pre-purchase-data.model';
import { replaceDomain } from '@/helpers/seo.helper';
import { isChannelTv, isLive } from '@/helpers/product.helper';
import { BUTTON_ID } from '@/types/button-id.type';
import { goToDetailsOptionsType } from '@/interfaces/asset.interface';
import { buildNextUrl, getNextUrl } from '@/helpers/url.helper';
import { isStandalone } from '@/constants/portal-recognition';

const portal = process.env.portal;

export class MediaDetailsModel {
    public isInFavorites = false;
    public isChronological = false;
    public isTvProgramItem = false;

    protected accessType?: string;
    protected currentProgramTvModel?: ChannelProgramTvModel;
    protected nextProgramTvModel?: ChannelProgramTvModel;

    constructor(
        protected mediaListItemModel: MediaListItemModel,
        private checkProductAccessModel?: CheckProductAccessModel,
        private prePurchaseDataModel?: PrePurchaseDataModel,
        programTvListModel?: ChannelProgramTvModel[],
    ) {
        if (checkProductAccessModel) {
            this.accessType = this.checkAccessType();
        }
        if (programTvListModel) {
            const [currentProgramTv, nextProgramTv] = programTvListModel;
            this.currentProgramTvModel = currentProgramTv;
            this.nextProgramTvModel = nextProgramTv;
        }
    }

    public static backButtonAction(): void {
        Router.back();
    }

    public get isForAdults(): boolean {
        return this.mediaListItemModel.getAgeGroup() >= 18;
    }

    public get isLive(): boolean {
        const productId = this.mediaListItemModel.getProductId();

        return productId ? isLive(productId) || isChannelTv(productId) : false;
    }

    public get id(): string {
        return this.mediaListItemModel.getId();
    }

    public get cpid(): CPID {
        return this.mediaListItemModel.getCpid();
    }

    public get getCategory(): CategorySchema | null {
        return this.mediaListItemModel.getCategory();
    }

    public get isCategoryChronological(): boolean {
        return !!this.getCategory?.chronological;
    }

    public get mainCategoryId(): number | undefined {
        return this.getCategory?.categoryPath[2];
    }

    public get categoryId(): number | undefined {
        return this.getCategory?.id;
    }

    public get keyCategoryId(): number | undefined {
        return this.getCategory?.keyCategoryId;
    }

    public getBackgroundSrc(imgWidth?: number, imgHeight?: number): string {
        return this.mediaListItemModel.getBackgroundSrc(imgWidth, imgHeight);
    }

    public get thumbnails(): ImageSourceSchema[] | undefined {
        return this.mediaListItemModel.getThumbnails();
    }

    public get seasonThumbnails(): ImageSourceSchema[] | undefined {
        return this.mediaListItemModel.getSeasonThumbnails();
    }

    public get title(): string {
        return this.currentProgramTvModel?.getTitle() ?? this.mediaListItemModel.getTitle();
    }

    public get shortTitle(): string {
        return this.mediaListItemModel.getShortTitle();
    }

    public get description(): string {
        return (
            this.currentProgramTvModel?.getDescription() ?? this.mediaListItemModel.getDescription()
        );
    }

    public get ageGroup(): number {
        return this.mediaListItemModel.getAgeGroup();
    }

    public get accessibilityFeatures() {
        if (this.currentProgramTvModel) {
            return this.currentProgramTvModel.getAccessibilityFeatures();
        }
        return this.mediaListItemModel.getAccessibilityFeatures();
    }

    public get underageClassification() {
        if (this.currentProgramTvModel) {
            return this.currentProgramTvModel.getUnderageClassification();
        }
        return this.mediaListItemModel.getUnderageClassification();
    }

    public get metadata(): string[] {
        return this.mediaListItemModel.getBottomMetadata();
    }

    public get limitedMetadata(): string[] {
        return this.mediaListItemModel.getBottomMetadata(true);
    }

    public get duration(): string {
        return this.mediaListItemModel.getDurationWithText();
    }

    public get mediaTypeForRelatedList(): MEDIA_TYPES {
        return this.mediaListItemModel.getType();
    }

    public get favoritesType(): string {
        const isChannel = this.mediaTypeForRelatedList === MEDIA_TYPES.TV;
        const favoritesType = isChannel ? 'favorites.channels' : 'favorites';

        return favoritesType;
    }

    public get mediaType(): ICONS {
        const type = this.mediaListItemModel.getType();
        switch (type) {
            case 'tv':
                return ICONS.MEDIA_TYPE_TV;
            case 'vod':
                return ICONS.MEDIA_TYPE_VOD;
            case 'live':
                return ICONS.MEDIA_TYPE_LIVE;
            default:
                return ICONS.MEDIA_TYPE_VOD;
        }
    }

    public get mediaListTitle(): string {
        return this.mediaListItemModel.getTitle();
    }

    public get subTitleData(): string[] {
        const data: string[] = [];
        const isLive = this.mediaListItemModel.getIsLive();
        let hasStartEndTime = false;

        if (isLive) {
            data.push(this.startLiveInfo);
        }

        if (this.currentProgramTvModel && this.nextProgramTvModel) {
            const { HOURS } = FORMAT_DATE_MODE;
            const channelTitle = this.shortTitle;
            const programGenre = this.currentProgramTvModel.getGenre();
            const programStartTime = this.currentProgramTvModel.getStartTime(HOURS);
            const programEndTime = this.nextProgramTvModel.getStartTime(HOURS);
            hasStartEndTime = !!programStartTime && !!programEndTime;
            data.push(channelTitle, programGenre, `${programStartTime} - ${programEndTime}`);
        } else {
            const genres = this.mediaListItemModel.getGenres();
            data.push(genres);
        }

        if (this.mediaListItemModel.getIsOnAir() || hasStartEndTime) {
            data.push(
                t('models.media-details.on-air-highlighted', buildHighlightSpan('text-danger')),
            );
        }

        return data.filter((item) => item.length);
    }

    public get licenseLocationInfo(): string {
        return this.mediaListItemModel.getLicenseLocationText();
    }

    public get licenseEndDateInfo(): string {
        const licenseEndDate = this.mediaListItemModel.getLicenseEndDate(
            FORMAT_DATE_MODE.DATE_WITH_YEAR,
        );
        return licenseEndDate ? `${t('models.media-details.date-info')} ${licenseEndDate}` : '';
    }

    public getLicenseEndDate(format: FORMAT_DATE_MODE): string {
        return this.mediaListItemModel.getLicenseEndDate(format);
    }

    public getPublicationDate(format?: FORMAT_DATE_MODE): string {
        return format
            ? formatDate(this.mediaListItemModel.getPublicationDate(), format)
            : this.mediaListItemModel.getPublicationDate();
    }

    public buildPremiereBannerText(hours: number, minutes: number): string {
        return `${hours > 0 ? t(`components.premiere.premiere-hours-left`, { hours }) : ''} ${
            minutes === 0
                ? '<1' + t(`components.premiere.premiere-minutes-left`, { minutes: '' })
                : t(`components.premiere.premiere-minutes-left`, { minutes })
        }`;
    }

    public get subType(): string {
        return this.prePurchaseDataModel?.getProduct().subType || '';
    }

    public get accessMetadata(): string[] {
        //jesli jest specialAccess to wyswietlamy statusUserMessage , nie ma dzielenia na to czy ma dostęp czy nie
        if (this.checkProductAccessModel?.isSpecialAccess()) {
            const date = this.checkProductAccessModel?.getValidTo(FORMAT_DATE_MODE.FULL_DATE) || '';
            const updatedMessage = this.statusUserMessage.replace('%%validTo%%', date);

            return [updatedMessage];
        }
        switch (this.accessType) {
            case MEDIA_ACCESS_STATUS.HAS_ACCESS:
                return [this.statusUserMessage];
            case MEDIA_ACCESS_STATUS.NO_ACCESS:
                return [this.productAccessText];
            default:
                return [];
        }
    }

    public get productAccessText(): string {
        const productAccessText = this.prePurchaseDataModel?.getProductAccessText();

        return productAccessText || '';
    }

    protected checkAccessType(): string {
        const hasAccess = this.hasAccess();

        if (hasAccess) {
            return MEDIA_ACCESS_STATUS.HAS_ACCESS;
        } else {
            return MEDIA_ACCESS_STATUS.NO_ACCESS;
        }
    }

    public hasAccess(): boolean {
        return this.checkProductAccessModel?.hasAccess() || false;
    }

    public get currency(): string | undefined {
        return this.prePurchaseDataModel?.getMinPriceProduct()?.minPrice?.currency;
    }

    public get minPrice(): number {
        return this.prePurchaseDataModel?.getMinPriceProduct()?.minPrice?.value || 0;
    }

    public get purchaseAvailabilityInfo(): string | undefined {
        return this.prePurchaseDataModel?.getPurchaseAvailabilityUserMessage();
    }

    public get minPriceInfo(): string {
        return (
            this.prePurchaseDataModel?.getMinPricePurchaseText() || t('models.media-details.buy')
        );
    }

    public get statusUserMessage(): string {
        const isMovie = this.mediaListItemModel.getIsMovie();

        if (isMovie && this.checkProductAccessModel?.getValidTo()) {
            return t('product-access.vod.active-with-date', {
                date: this.checkProductAccessModel.getValidTo(FORMAT_DATE_MODE.FULL_DATE),
                ...buildHighlightSpan(),
            });
        }

        return this.checkProductAccessModel?.getStatusUserMessage() || '';
    }

    public get shortDescriptionLines(): number {
        // Maksymalna ilośc linii skróconego opisu
        return process.env.mediaDetails.shortDescriptionLines;
    }

    public get fullDescriptionLines(): number {
        // Maksymalna ilośc linii pełnego opisu
        return process.env.mediaDetails.fullDescriptionLines;
    }

    public get showVerticalLine(): boolean {
        const isLicenseInfoVisible = !!this.licenseLocationInfo;

        return isLicenseInfoVisible && this.accessMetadata.length > 0;
    }

    public get minutesLeftInfo(): string {
        const endTime = this.nextProgramTvModel?.getStartTime();
        const minutes = endTime && minutesLeftFromNow(endTime);

        return minutes ? t('minutes-left', { minutes }) : '';
    }

    public get progress(): number | null {
        const startTime = this.currentProgramTvModel?.getStartTime();
        const endTime = this.nextProgramTvModel?.getStartTime();

        return startTime && endTime ? calculateProgress({ startTime, endTime }) : null;
    }

    public get platformsIcons(): ICONS[] {
        const icons = this.mediaListItemModel.getPlatforms().map((platform: string) => {
            switch (platform) {
                case 'mobile':
                    return ICONS.MOBILE;

                case 'pc':
                    return ICONS.PC;

                case 'tv':
                    return ICONS.TV;

                case 'stb':
                    return ICONS.STB;

                default:
                    return null;
            }
        });

        return <ICONS[]>icons.filter((icon) => icon !== null);
    }

    public get buyOnWwwInfo(): string | undefined {
        if (
            this.hasAccess() ||
            this.prePurchaseDataModel?.isPurchaseAvailable() ||
            this.checkProductAccessModel?.isSpecialAccessStatus()
        ) {
            return undefined;
        }

        return this.purchaseAvailabilityInfo;
    }

    public get isStartLiveInfoVisible(): boolean {
        const hasAccess = this.hasAccess();
        const isLive = this.mediaListItemModel.getIsLive();
        const isOnAir = this.mediaListItemModel.getIsOnAir();

        return hasAccess && isLive && !isOnAir;
    }

    public get startLiveInfo(): string {
        const isOnAir = this.mediaListItemModel.getIsOnAir();
        const publicationDate = this.mediaListItemModel.getPublicationDate();
        const isTodayOrTomorrow =
            getTodayLabel(publicationDate) || getTomorrowLabel(publicationDate);
        const date = formatDate(publicationDate, FORMAT_DATE_MODE.LIVE_ARABIC_FORMAT);
        const time = formatDate(publicationDate, FORMAT_DATE_MODE.HOURS);

        if (isOnAir) return `${time}`;

        return isTodayOrTomorrow ? `${isTodayOrTomorrow} • ${time}` : `${date} • ${time}`;
    }

    public get buttons(): MediaButtonInterface[] {
        switch (this.mediaListItemModel.getType()) {
            case MEDIA_TYPES.VOD:
                return this.vodButtons;

            case MEDIA_TYPES.MOVIE:
                return this.movieButtons;

            case MEDIA_TYPES.LIVE:
                return this.liveButtons;

            case MEDIA_TYPES.TV:
                return this.channelTvButtons;

            default:
                return [];
        }
    }

    private get vodButtons(): MediaButtonInterface[] {
        if (this.playOrBuyButton) {
            return [this.playOrBuyButton, this.fullDetailsButton, this.favoritesButton];
        }

        return [this.fullDetailsButton, this.favoritesButton];
    }

    private get movieButtons(): MediaButtonInterface[] {
        if (this.playOrBuyButton) {
            return [this.playOrBuyButton, this.fullDetailsButton, this.favoritesButton];
        }

        return [this.fullDetailsButton, this.favoritesButton];
    }

    private get liveButtons(): MediaButtonInterface[] {
        if (this.playOrBuyButton) {
            return [this.playOrBuyButton, this.fullDetailsButton];
        }

        return [this.fullDetailsButton];
    }

    private get channelTvButtons(): MediaButtonInterface[] {
        const buttons: MediaButtonInterface[] = [];

        if (this.playOrBuyButton) {
            buttons.push(this.playOrBuyButton);
        }

        buttons.push(this.fullDetailsButton);

        if (process.env.mediaDetails.isFavoriteButtonOnTvChannelsVisible) {
            buttons.push(this.favoritesButton);
        }

        return buttons;
    }

    public get moreActionButtons(): MediaButtonInterface[] {
        const buttons = [this.fullDetailsButton];

        const mediaType = this.mediaListItemModel.getType();
        const isFavoritesButton = process.env.isFavoritesButton;

        if (
            mediaType === MEDIA_TYPES.VOD ||
            mediaType === MEDIA_TYPES.MOVIE ||
            mediaType === MEDIA_TYPES.TV
        ) {
            isFavoritesButton && buttons.push(this.favoritesButton);
        }

        return buttons;
    }

    protected get isPurchasable() {
        return this.prePurchaseDataModel?.isPurchaseAvailable();
    }

    private get checkHasAccess() {
        return this.hasAccess();
    }

    public get canBuyBeforePremiere() {
        return this.isPurchasable && !this.checkHasAccess;
    }

    public get playOrBuyButton(): MediaButtonInterface | undefined {
        if (this.hasAccess()) {
            if (this.mediaListItemModel.getIsLive() && !this.mediaListItemModel.getIsOnAir()) {
                return undefined;
            }

            return this.playButton;
        }
        if (
            this.prePurchaseDataModel?.isFutureOfferPublicationDate() &&
            this.checkProductAccessModel?.isDeniedStatus()
        ) {
            return;
        }
        if (this.checkProductAccessModel?.isDeniedStatus()) {
            return this.deniedButton;
        }
        if (this.checkProductAccessModel?.isPlusAction()) {
            return this.plusActionButton;
        }

        if (this.isPurchasable) {
            return this.buyButton;
        }

        return undefined;
    }

    public goToAddPhonePage = () => {
        const productId = this.mediaListItemModel.getProductId();
        const next = buildNextUrl();

        return Router.pushRoute(ROUTES.FREE_ACCESS_PLUS_ZONE_ADD_PHONE, { next });
    };

    public get deniedButton(): MediaButtonInterface {
        const text = 'Materiał niedostepny';
        return {
            id: BUTTON_ID.DENIED,
            text: text,
            onButtonAction: () => {},
            'data-testing': 'models.media-details.denied-action-btn',
        };
    }

    public get plusActionButton(): MediaButtonInterface {
        const text = this.checkProductAccessModel?.getActionButtonText() || 'Zaloguj';
        return {
            id: BUTTON_ID.PLAY,
            text: text,
            onButtonAction: () =>
                isStandalone
                    ? Router.pushRoute(ROUTES.FREE_ACCESS_PLUS_ZONE)
                    : this.goToAddPhonePage(),
            'data-testing': 'models.media-details.bplus-action-btn',
        };
    }

    protected get playButton(): MediaButtonInterface {
        return {
            route: this.getWatchParams().route,
            linkParams: { ...this.getWatchParams().params, next: getNextUrl() || buildNextUrl() },
            id: BUTTON_ID.PLAY,
            text: t('models.media-details.watch'),
            onButtonAction: () => this.goToWatch(),
            'data-testing': 'models.media-details.watch',
            isPlayButton: true,
        };
    }

    public get buyButton(): MediaButtonInterface {
        return {
            route: this.getPaymentsPath()?.route,
            linkParams: { ...this.getPaymentsPath()?.params, next: getNextUrl() || buildNextUrl() },
            id: BUTTON_ID.BASKET,
            text: this.minPriceInfo,
            onButtonAction: () => this.goToPaymentsPath(),
            'data-testing': 'models.media-details.buy',
        };
    }

    private get trailerButton(): MediaButtonInterface {
        return {
            id: BUTTON_ID.TRAILER,
            icon: ICONS.PLAY,
            text: t('models.media-details.watch-trailer'),
            onButtonAction: () => this.goToWatchTrailer(),
            'data-testing': 'models.media-details.watch-trailer',
        };
    }

    private get fullDetailsButton(): MediaButtonInterface {
        return {
            route: this.getFullDetailsLinkParams().route,
            linkParams: this.getFullDetailsLinkParams().params,
            id: BUTTON_ID.INFO,
            icon: ICONS.INFO,
            text: t('models.media-details.more'),
            onButtonAction: () => this.goToFullDetails(),
            'data-testing': 'models.media-details.more',
        };
    }

    public get favoritesButton(): MediaButtonInterface {
        const favoritesType = this.favoritesType;

        return {
            id: BUTTON_ID.HEART,
            icon: this.isInFavorites ? ICONS.HEART_DELETE : ICONS.HEART,
            text: this.isInFavorites ? t(`${favoritesType}.delete`) : t(`${favoritesType}.add`),
            onButtonAction: (mutate) => this.mutateFavorites(mutate),
            isFavorites: true,
            'data-testing': 'models.media-details.add-to-favorites',
        };
    }

    public goToSubcategory(subcategoryId: number) {
        this.mediaListItemModel.goToSubcategory(subcategoryId);
    }

    private goToDetails(options?: goToDetailsOptionsType): void {
        this.mediaListItemModel.goToDetails(options);
    }

    public get fullDetailsRoute(): ROUTES {
        return this.mediaListItemModel.getFullDetailsRoute();
    }

    public get routeParams(): { [key: string]: any } {
        return this.mediaListItemModel.getRouteParams();
    }

    public getFullDetailsLinkParams() {
        return this.mediaListItemModel.getFullDetailsLinkParams();
    }

    public goToFullDetails(): void {
        this.mediaListItemModel.goToFullDetails();
    }

    private getPaymentsPath(): void | { route: ROUTES; params: IParams } {
        return this.mediaListItemModel.getPaymentsPath();
    }

    private goToPaymentsPath(): void {
        this.mediaListItemModel.goToPaymentsPath();
    }

    protected getWatchParams() {
        return this.mediaListItemModel.getWatchParams();
    }

    protected goToWatch(): void {
        this.mediaListItemModel.goToWatch();
    }

    private goToWatchTrailer(): void {
        // TODO: przekierowanie do playera z uruchomionym zwiastunem
    }

    private mutateFavorites(mutate?: IGetMediaMutateFn<any, any>): void {
        if (!mutate) return;

        const type = this.mediaListItemModel.getType();
        const id = this.mediaListItemModel.getId();
        const keyCategoryId = this.mediaListItemModel.getKeyCategoryId();

        const params = {
            favorite: {},
        };

        switch (type) {
            case 'tv':
                Object.assign(params.favorite, { type: 'channel', value: `${id}` });
                break;
            case 'movie':
                Object.assign(params.favorite, { type: 'vod', value: `${id}` });
                break;
            case 'vod':
                if (!this.isChronological) {
                    Object.assign(params.favorite, { type: 'vod', value: `${id}` });
                } else {
                    Object.assign(params.favorite, { type: 'category', value: `${keyCategoryId}` });
                }
                break;
        }

        mutate(params);
    }

    public followMeContent(fmc?: WatchedContentDataSchema): string {
        if (!fmc) return '';

        const duration = this.mediaListItemModel.getDuration();
        const lastDuration = Math.floor(fmc.lastDuration / 60);

        if (duration) {
            const minutes = duration - lastDuration;
            return minutes > 0 ? t('minutes-left', { minutes }) : '';
        }

        const notSeenPercent = 100 - fmc.lastPercent;
        const seenDuration = fmc.lastDuration;
        const seenPercent = fmc.lastPercent;
        const sec = (notSeenPercent * seenDuration) / seenPercent;
        const minutes = Math.floor(sec / 60);

        return minutes ? t('minutes-left', { minutes }) : '';
    }

    public get episodeTitle(): string {
        return this.mediaListItemModel.getEpisodeTitle();
    }

    public get episodeNo(): number {
        return this.mediaListItemModel.getEpisodeNumber();
    }

    private seoTitle(): string {
        const customData = this.mediaListItemModel.getCustomData();
        let title;

        if (!!customData?.custom_attributes && Array.isArray(customData?.custom_attributes))
            customData.custom_attributes.forEach((attribute) => {
                if (Object.prototype.hasOwnProperty.call(attribute, 'title_seo')) {
                    title = attribute.title_seo;
                }
            });
        return title
            ? replaceDomain(title)
            : `${this.mediaListItemModel.getTitle()} - ${t('domain-url')} `;
    }

    private seoDescription(): string {
        const customData = this.mediaListItemModel.getCustomData();
        let description;

        if (!!customData?.custom_attributes && Array.isArray(customData?.custom_attributes))
            customData.custom_attributes.forEach((attribute) => {
                if (Object.prototype.hasOwnProperty.call(attribute, 'description_seo')) {
                    description = attribute.description_seo;
                }
            });

        return description ? description : this.mediaListItemModel.getDescription();
    }

    public get getSeoAttributes(): { description: string; title: string } {
        return { title: this.seoTitle(), description: this.seoDescription() };
    }

    public getStaffCollections() {
        return this.mediaListItemModel.getStaffCollections();
    }

    public getIsStaffCollections(): boolean {
        return !!this.mediaListItemModel.getStaffCollections();
    }

    public getStaffCollectionGallery() {
        return this.mediaListItemModel.getStaffCollectionsGallery();
    }

    public getStaffCollectionId(): number | undefined {
        return this.mediaListItemModel.getStaffCollectionId();
    }

    public getStaffCollectionName(): string | undefined {
        return this.mediaListItemModel.getStaffCollectionName();
    }

    public getCallToActions(): any {
        return this.checkProductAccessModel?.getCallToActions();
    }

    public isDeniedStatus(): boolean {
        return this.checkProductAccessModel?.isDeniedStatus() || false;
    }

    public isPlusAction(): boolean {
        return this.checkProductAccessModel?.isPlusAction() || false;
    }

    public isBeforePlayAction(): boolean {
        return this.checkProductAccessModel?.isPlusAction() || false;
    }

    public isHelpText(): boolean {
        return this.checkProductAccessModel?.isHelpText() || false;
    }

    public getHelpText(): string {
        return this.checkProductAccessModel?.getHelpText() || '';
    }

    public isAskBeforePlayAction(): boolean {
        return this.checkProductAccessModel?.isAskBeforePlayAction() || false;
    }

    public getPlaybackConfirmationMessage(): string {
        return this.checkProductAccessModel?.getPlaybackConfirmationMessage() || '';
    }

    public isFutureOfferPublicationDate(): boolean {
        return this.prePurchaseDataModel?.isFutureOfferPublicationDate() || false;
    }

    public getFutureOfferPublicationDate(): string {
        return this.prePurchaseDataModel?.getFutureOfferPublicationDate() || '';
    }
}
