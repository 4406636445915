import { WatchedContentDataSchema } from '@/interfaces/from-schemas/user-content/getLatelyWatchedContentDataListOut';
import { ChronologicalNonChronologicalModel } from '@/models/category/chronologicalNonChronological.model';
import { MediaDetailsModel } from '@/models/media/media-details.model';

export interface MediaDetailsProps {
    media: MediaDetailsModel;
    fmc?: WatchedContentDataSchema;
}

export interface MediaFullDetailsProps {
    media: MediaDetailsModel;
    cnc?: ChronologicalNonChronologicalModel;
    isMainCategory?: boolean;
    fmc?: WatchedContentDataSchema;
}

export interface InfoTextContainerProps {
    largeMargin?: boolean;
}

export interface InfoTextProps {
    highlight?: boolean;
}
export interface StaffCollectionCallToActionCallToActionProps {
    imgSrc?: string;
    collectionId: number;
    staffCollectioTitle: string;
}

export const ELEMENT_WIDTH = 562;
