import React, { useContext, useMemo } from 'react';
import { useListElementClicked } from '@/hooks/use-list-element-clicked';
import { ELEMENT_CLICK_TYPE } from '@/interfaces/element-click-type.interface';
import { LIST_TYPE } from '@/types/recommendation-list.type';
import { ListElement, OfferListElement } from '../ListElement';
import { PromoboxElement } from '../PromoboxListElement';
import { SliderElementsOwnProps, SliderConfigInterface, SliderContext } from './types';
import { MEDIA_TYPES } from '@/types/media.type';

const useGetElementComponent = (config: SliderConfigInterface, activeRow: number | null) => {
    const { rowIndex, list } = config;
    const layoutType = list?.getLayout().value;
    const isPromoboxLayout = layoutType === LIST_TYPE.PROMOBOX;

    const returnPromobox = Number(rowIndex) <= Number(activeRow) && isPromoboxLayout;
    return useMemo(() => (returnPromobox ? PromoboxElement : ListElement), [returnPromobox]);
};

const SliderElementsRaw = ({
    collectionElements,
    onActiveElement,
    onActiveListElement,
    config,
}: SliderElementsOwnProps) => {
    const { enabled: lazyLoading } = process.env.lazyLoadingImages;
    const { rowIndex, defaultFocused = true, list } = config;

    const { activeRow, collectionActiveIndex } = useContext(SliderContext);

    const Component = useGetElementComponent(config, activeRow);
    const focusedRow = rowIndex === activeRow;

    const { onListElementClickedHandler } = useListElementClicked(
        ELEMENT_CLICK_TYPE.RECOMMENDATION,
        config,
    );
    const isOfferListType = list?.isOfferListType();
    return useMemo(
        () => (
            <>
                {list && isOfferListType && (
                    <OfferListElement
                        itemData={list}
                        setActiveElement={onActiveListElement}
                        defaultFocused={true}
                        focusedRow={focusedRow}
                    />
                )}
                {collectionElements.map((element, index: number) => {
                    const focusedIndex = index === collectionActiveIndex;
                    const isFocused = defaultFocused && focusedRow && focusedIndex;
                    const lazy = lazyLoading && !list?.isPromobox();
                    const customData = list?.getCustomData();
                    const recommendationItem = element.getRecommendationItem?.();
                    const onItemClickedHandler = () => {
                        return onListElementClickedHandler(
                            element,
                            recommendationItem?.getListItemIndex(),
                        );
                    };

                    return (
                        <Component
                            key={index}
                            itemData={element}
                            setActiveElement={onActiveElement}
                            defaultFocused={isFocused}
                            focusedRow={focusedRow}
                            lazy={lazy}
                            index={index}
                            customData={customData}
                            data-testing={`collection.element.${index}`}
                            onItemClickedHandler={onItemClickedHandler}
                        />
                    );
                })}
            </>
        ),
        [collectionElements, Component, focusedRow],
    );
};

export const SliderElements = React.memo(SliderElementsRaw);
