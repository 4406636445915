/*
 * Ten kod pochodzi z libki https://github.com/react-hook-form/resolvers/blob/master/src/yup.ts
 * Jest wykopiowany, żeby pozbyć się problemu niepoprawnego zarządzanie budowaniem paczek po stronie biblioteki
 * powodujące naprzemiennie error w buildzie next lub w runtimie standalone.
 *
 * Możliwe, że w V2 rozwiążą te problemy: https://github.com/react-hook-form/resolvers/pull/108
 */

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Resolver, transformToNestObject, FieldValues } from 'react-hook-form';
import Yup from 'yup';

const parseErrorSchema = (error: Yup.ValidationError, validateAllFieldCriteria: boolean) => {
    return Array.isArray(error.inner) && error.inner.length
        ? error.inner.reduce((previous: Record<string, any>, { path, message, type }) => {
              // @ts-ignore
              const previousTypes = (previous[path] && previous[path].types) || {};
              const key = path || type;

              return {
                  ...previous,
                  ...(key
                      ? {
                            [key]: {
                                ...(previous[key] || {
                                    message,
                                    type,
                                }),
                                ...(validateAllFieldCriteria
                                    ? {
                                          types: {
                                              ...previousTypes,
                                              // @ts-ignore
                                              [type]: previousTypes[type]
                                                  ? // @ts-ignore
                                                    [...[].concat(previousTypes[type]), message]
                                                  : message,
                                          },
                                      }
                                    : {}),
                            },
                        }
                      : {}),
              };
          }, {})
        : {
              // @ts-ignore
              [error.path]: { message: error.message, type: error.type },
          };
};

export const yupResolver =
    <TFieldValues extends FieldValues>(
        schema: any,
        options: any = {
            abortEarly: false,
        },
    ): Resolver<TFieldValues> =>
    async (values, context, validateAllFieldCriteria = false) => {
        try {
            if (options.context && process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.warn(
                    "You should not used the yup options context. Please, use the 'useForm' context object instead",
                );
            }
            return {
                values: await schema.validate(values, {
                    ...options,
                    context,
                }),
                errors: {},
            };
        } catch (e: any) {
            const parsedErrors = parseErrorSchema(
                e as Yup.ValidationError,
                validateAllFieldCriteria,
            );
            return {
                values: {},
                errors: transformToNestObject(parsedErrors),
            };
        }
    };
