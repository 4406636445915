import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const NavButtons = styled(Nav as any)`
    display: flex;
`;

export const ButtonContainer = styled.div`
    white-space: nowrap;
    padding: 0 1rem;

    :first-child {
        padding: 0 1rem 0 0;
    }

    :last-child {
        padding: 0 0 0 1rem;
    }
`;
