import { LIST_TYPE } from '@/types/recommendation-list.type';
import { CollectionsListConfig } from '@/components/shared/CollectionsList/types';
import { FlatFilterListSchema } from '@/interfaces/from-schemas/navigation/getCategoryContentWithFlatNavigationOut';
import { GetChannelsCurrentProgramOut } from '@/interfaces/from-schemas/navigation/getChannelsCurrentProgramOut';
import { GetStaffRecommendationsListsIn } from '@/interfaces/from-schemas/navigation/getStaffRecommendationsListsIn';
import { GetTvChannelsOut } from '@/interfaces/from-schemas/navigation/getTvChannelsOut';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { ReactNode } from 'react';
import { CatalogParamsType } from '@/types/page-catalog.type';
import ResponseErrorModel from '@/models/response-error.model';
import { DATA_POSITION } from '@/types/list-element-metadata.type';
import { ListInterface } from '@/interfaces/list.interface';
import { StaffRecommendationListModel } from '@/models/recommendations/staff-recommendation-list.model';

export interface CatalogConfig {
    fetcher: (catalogParams: any) => FetcherReturnInterface;
    catalogParams: CatalogParamsType;
    withSubcategory?: SubcategoryParams;
    withPacketCategories?: boolean;
    withLists?: boolean;
    withFilters?: boolean;
    withNavButtons?: boolean;
    fallbackMetadataPosition?: DATA_POSITION;
    isMutable?: boolean;
    disableBg?: boolean;
    collectionId?: string;
    categoryName?: string;
}

export interface ChannelsInitialProps {
    lists: GetTvChannelsOut;
    ssrInitialCollection: GetChannelsCurrentProgramOut;
}

export interface CombinedViewOwnProps {
    collectionsConfig: CollectionsListConfig;
    catalogConfig: CatalogConfig;
    collectionsParams: GetStaffRecommendationsListsIn | false;
    showCatalog?: boolean;
    hideHeader?: boolean;
    categoryName?: string;
    collectionsList: StaffRecommendationListModel[];
    isFetchingCollectionsList?: boolean;
}

export interface RecommendationViewOwnProps {
    config: CollectionsListConfig;
    params: GetStaffRecommendationsListsIn | false;
    onCategoryView?: () => void;
    onNoLists?: () => void;
    withCatalog?: boolean;
    recommendedChannelsNavigation?: ReactNode;
    collectionId?: string;
    collectionsList: StaffRecommendationListModel[];
    isFetchingCollectionsList?: boolean;
}

interface FetcherReturnInterface {
    gridElements: ListElementInterface[];
    columns: number;
    filterLists: FlatFilterListSchema[];
    isFetching?: boolean;
    fetchedAll?: boolean;
    noData?: boolean;
    onFetchMore: (offset?: number) => void;
    error?: ResponseErrorModel;
    titleName?: string;
}

export interface SubcategoryParams {
    subcategoryId: number;
    keyCategoryId: number;
}

export interface CatalogViewOwnProps {
    config: CatalogConfig;
    onCategoryView?: () => void;
    placeholder?: ReactNode;
    packetNavigation?: ReactNode;
    channelsNavigation?: ReactNode;
    categoryName?: string;
    isChronological?: boolean;
    hideHeader?: boolean;
}

export enum CATEGORY_CATALOG {
    CATALOG = 'catalog',
}

export type CATEGORY_VIEWS = LIST_TYPE | CATEGORY_CATALOG;

export interface PageContentOwnProps {
    actualVod: ListElementInterface | ListInterface;
    view?: CATEGORY_VIEWS;
}

export interface NavigationButtonsOwnProps {
    hasSubcategories?: boolean;
    isCatalog?: boolean;
    mainButtonVariant?: NavigationButtonsVariant;
    onCategoryView?: () => void;
    onShowFilters?: (show: boolean) => void;
    onShowSubcategoriesMenu?: (show: boolean) => void;
    withFilters?: boolean;
    withLists?: boolean;
    withSubcategory?: boolean;
    withCatalog?: boolean;
    withoutButton?: boolean;
    /**
     * Required for {@link NavigationButtonsVariant.SUBCATEGORY}
     */
    subCategoriesLabel?: string;
}

export enum NavigationButtonsVariant {
    CATALOG = 'catalog',
    RECOMMENDED = 'recommended',
    /**
     * Make sure to provide [subCategoriesLabel]{@link NavigationButtonsOwnProps.subCategoriesLabel}
     * as {@link NavigationButtons} attribute
     */
    SUBCATEGORY = 'subcategory',
    PACKET_SUBCATEGORY = 'packet-subcategory',
}

export interface ViewInterface {
    view?: CATEGORY_VIEWS;
    isCatalog?: boolean;
    hideHeader?: boolean;
}

export interface NavigationButtonsWrapperProps {
    children: React.ReactNode;
}
