import { Nav } from 'nav-tree';
import styled from 'styled-components';
import { StepContainerProps } from './types';

export const PaymentStepsContainer = styled(Nav as any)`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const BackButtonContainer = styled.div``;

export const StepsContainer = styled.div`
    display: flex;
    align-items: center;
    height: 5.4rem;
    border: 0.15rem solid ${({ theme }) => theme.paymentSteps.borderColor};
    border-radius: 2.7rem;
`;

export const StepContainer = styled.div<StepContainerProps>`
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 2.7rem;
    font-size: ${({ theme }) => theme.paymentSteps.fontSize};
    font-weight: ${({ theme }) => theme.paymentSteps.fontWeight};
    color: ${({ theme }) => theme.paymentSteps.textColor};
    padding: 0 3.5rem;

    ${({ isFocusAvailable }) =>
        isFocusAvailable &&
        `
        &:hover {
            cursor: pointer;
        }
    `}

    ${({ selected, theme }) =>
        selected &&
        `
            height: 6.2rem;
            background: ${theme.paymentSteps.selectedBgColor};
            color: ${theme.paymentSteps.selectedTextColor};
        `}

    ${({ focused, theme }) =>
        focused &&
        `
            height: 6.2rem;
            background: ${theme.paymentSteps.focusedBgColor};
            color: ${theme.paymentSteps.focusedTextColor};
        `}
`;
