import { CollectionsListConfig } from '@/components/shared/CollectionsList/types';
import { CatalogConfig } from '@/components/views/common/types';
import { CatalogContext } from '@/contexts/catalog/catalog.context';
import { LoaderContext } from '@/contexts/loader/loader.context';
import { PageContext } from '@/contexts/page/page.context';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { useRouterQuery } from '@/hooks/use-router-query.hook';

export const useCombinedView = (
    collectionsConfig: CollectionsListConfig,
    catalogConfig: CatalogConfig,
) => {
    const { setShowLoader } = useContext(LoaderContext);

    const { isCatalog, setIsCatalog, noLists, setNoLists } = useContext(PageContext);

    const { setGrid } = useContext(CatalogContext);

    const catId = catalogConfig.catalogParams.catid;

    const query = useRouterQuery() as { collectionId: string };
    const collectionId = query?.collectionId;

    useEffect(() => {
        setShowLoader(true);
    }, []);

    const onCategoryView = useCallback(() => {
        batchedUpdates(() => {
            setShowLoader(true);
            setIsCatalog((prev) => !prev);
            setGrid((prev) => ({ ...prev, filters: [], rowIndex: 0, colIndex: 0 }));
            setNoLists?.(false);
        });
    }, [isCatalog]);

    const handleNoLists = useCallback(() => {
        setIsCatalog(true);
        setNoLists(true);
    }, []);

    const updatedCatalogConfig = useMemo(
        () => (noLists ? { ...catalogConfig, withLists: false } : catalogConfig),
        [catId, collectionId, noLists, collectionsConfig],
    );

    return {
        isCatalog,
        updatedCatalogConfig,
        onCategoryView,
        handleNoLists,
    };
};
