import React from 'react';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { ElementAfter, ElementBefore, ListElementProgressBarWrapper } from './styles';
import { isStandalone } from '@/constants/portal-recognition';
import { progressToPercentage } from '@/helpers/progress-to-percentage.helper';

const ListElementProgressBarRaw = ({
    itemData,
    isActiveEpisode,
}: {
    itemData: ListElementInterface;
    isActiveEpisode?: boolean;
}) => {
    const progress = itemData.getProgress();
    const isProgressBarVisible = itemData.getVisibilityProgressBar();
    const isDoneWatching = progress === 100;
    const isProgress = progress !== 1;
    if (typeof progress !== 'undefined' && isProgressBarVisible) {
        return (
            <div style={{ display: 'flex', width: '100%' }}>
                <ElementBefore
                    isProgress={isProgress}
                    isActiveEpisode={isActiveEpisode}
                    isStandalone={isStandalone}
                />
                <ListElementProgressBarWrapper
                    progress={progressToPercentage(progress)}
                    isActiveEpisode={isActiveEpisode}
                />
                <ElementAfter
                    isDoneWatching={isDoneWatching}
                    isActiveEpisode={isActiveEpisode}
                    isStandalone={isStandalone}
                />
            </div>
        );
    }

    return null;
};

export const ListElementProgressBar = React.memo(ListElementProgressBarRaw);
