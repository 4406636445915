import { ListElementInterface } from '@/interfaces/list-element.interface';
import { protectURL } from './rewrite-protocol.helper';
import { ListInterface } from '@/interfaces/list.interface';

export const getBackgroundImage = (item: ListElementInterface, disableBg = false): string => {
    const backGroundSrc =
        item.getRecommendationItem?.().getBackgroundSrc() ||
        item.getAsset().getBackgroundSrc() ||
        '';

    return disableBg ? '' : protectURL(backGroundSrc);
};

export const getBackgroundListImage = (item: ListInterface, disableBg = false): string => {
    const backGroundSrc = item.getImageForListItem().src || '';

    return disableBg ? '' : protectURL(backGroundSrc);
};
