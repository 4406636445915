import { useGetFavoritesWithFlatNavigation } from '@/api/navigation/favorites/get-favorites-with-flat-navigation';
import { useFavoritesContext } from '@/contexts/favorites/favorites.context';
import { buildFavoritesCollection } from '@/helpers/pages/build-favorites-collection.helper';
import { updateCatalogParams } from '@/helpers/pages/update-catalog-params.helper';
import { useCatalogParams } from '@/hooks/use-catalog-params.hook';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { CPID } from '@/types/media.type';
import { useCallback, useEffect, useMemo, useState } from 'react';

const { col } = process.env.grid;

export const useFetchFavorites = () => {
    const [gridElements, setGridElements] = useState<ListElementInterface[]>([]);

    const { favorites } = useFavoritesContext();
    const filterLists = useMemo(
        () => favorites?.getFlatNavigation()?.filterLists || [],
        [favorites],
    );

    const { params, setParams, clearGrid, setClearGrid, clearGridFilters } = useCatalogParams({
        filterLists,
    });

    const { data: fetchedFavorites, isFetching } = useGetFavoritesWithFlatNavigation({
        ...params,
        cpids: [CPID.VOD_OR_MOVIE, CPID.CATEGORY_OR_PACK],
    });

    useEffect(() => {
        if (isFetching) return;

        if (fetchedFavorites.getCount() === 0) {
            return clearGridFilters();
        }

        const results = buildFavoritesCollection(fetchedFavorites);

        if (results?.length) {
            setGridElements((prev) => (clearGrid ? results : [...prev, ...results]));
        } else {
            setGridElements([]);
        }

        setClearGrid(false);
    }, [isFetching, fetchedFavorites, clearGridFilters]);

    const total = fetchedFavorites.getTotal();
    const fetchedAll = useMemo(() => params.offset + params.limit >= total, [params, total]);

    const onFetchMore = useCallback(
        () => setParams(updateCatalogParams({ params, total })),
        [params, total, setParams],
    );

    return {
        gridElements,
        columns: col,
        filterLists,
        isFetching,
        onFetchMore,
        fetchedAll,
    };
};
