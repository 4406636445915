import { Nav, navHorizontal } from 'nav-tree';
import React from 'react';

import { PLAYABLE_VARIANT } from '@/components/shared/Buttons/PlayableButton/types';
import { ICONS } from '@/components/shared/Icon';
import { formatDate } from '@/helpers/date.helper';
import t from '@/lib/i18n';
import { BUTTON_ID } from '@/types/button-id.type';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { GUTTER } from '@/types/gutter.type';

import * as Styled from './styles';
import { EPGTimeFilterButtonsProps, FilterButtonAction } from './types';

const EPGTimeFilterButtonsRaw = ({
    isToday,
    handleBtnAction,
    handleFiltersPanel,
    date,
}: EPGTimeFilterButtonsProps) => {
    return (
        // @ts-ignore
        <Nav func={navHorizontal} onNav={handleFiltersPanel}>
            <Styled.TopButtons>
                <Styled.FilterButton
                    icon={ICONS.CALENDAR}
                    navId={BUTTON_ID.DAY_PICKER_BTN}
                    onButtonAction={() => handleBtnAction('dayPicker')}
                    text={
                        isToday
                            ? t('pages.epg-table.today')
                            : formatDate(date.toISOString(), FORMAT_DATE_MODE.LIVE_ARABIC_FORMAT)
                    }
                    variant={PLAYABLE_VARIANT.PRIMARY}
                />

                {buttonActions.map(({ action, textKey, gutter }) => (
                    <Styled.FilterButton
                        key={action}
                        onButtonAction={() => handleBtnAction(action)}
                        text={t(textKey)}
                        variant={PLAYABLE_VARIANT.PRIMARY}
                        gutter={gutter}
                    />
                ))}
            </Styled.TopButtons>
        </Nav>
    );
};

export const EPGTimeFilterButtons = React.memo(EPGTimeFilterButtonsRaw);

const buttonActions: { action: FilterButtonAction; textKey: string; gutter?: GUTTER }[] = [
    { action: 'now', textKey: 'pages.epg-table.now' },
    { action: 'morning', textKey: 'pages.epg-table.morning' },
    { action: 'afternoon', textKey: 'pages.epg-table.afternoon', gutter: GUTTER.NONE },
    { action: 'evening', textKey: 'pages.epg-table.evening' },
];
