import { ActivityProps, MenuContainerIconProps, POSITION, StyledIconProps } from './types';
import styled, { css } from 'styled-components';
import { Nav } from 'nav-tree';
import { Z_INDEX } from '@/targets/default/theme';
import logo from '@/public/assets/shared/logo.png';
import logoSmall from '@/public/assets/shared/logo-small.png';
import { fillIcon } from '@/helpers/fill-icon.helper';

export const LeftPanelStyled = styled.div<ActivityProps>`
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 15%,
        rgba(0, 0, 0, 0) 100%
    );
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${Z_INDEX.MENU_CONTAINER};

    ${({ active }) =>
        active &&
        css`
            background: linear-gradient(
                to right,
                rgba(0, 0, 0, 0.95) 0%,
                rgba(0, 0, 0, 0.95) 16%,
                rgba(0, 0, 0, 0) 71%,
                rgba(0, 0, 0, 0) 100%
            );
        `}
`;

export const Logo = styled.img<ActivityProps>`
    left: 0;
    margin-left: auto;
    margin-right: auto;
    max-height: 6rem;
    position: absolute;
    right: 0;
    top: 4.9rem;
`;

export const LogoStyled = styled(Logo).attrs<ActivityProps>(({ active }) => ({
    src: active ? logo : logoSmall,
}))`
    ${({ active }) =>
        active &&
        css`
            left: 5.5rem;
            right: auto;
        `}
`;

export const NavContainerStyled = styled(Nav as any)`
    bottom: 7.5rem;
    overflow: hidden;
    position: absolute;
    top: 17.1rem;
    width: 100%;
`;

export const MenuContainerStyled = styled.div<ActivityProps>`
    bottom: 0;
    left: 0;
    position: fixed;
    top: 0;
    width: ${({ active }) => (active ? '55rem' : '15rem')};
    z-index: ${Z_INDEX.MENU_CONTAINER};
`;

export const MenuContainerIconStyled = styled.div<MenuContainerIconProps>(({ theme, position }) => {
    const top = position === POSITION.TOP ? '13rem' : 'auto';
    const bottom = position === POSITION.BOTTOM ? '4rem' : 'auto';

    return css`
        ${fillIcon(theme.menu.iconColor)};
        bottom: ${bottom};
        cursor: pointer;
        font-size: 3rem;
        height: 3.1rem;
        left: 5.5rem;
        position: absolute;
        top: ${top};

        svg {
            height: 1.7rem;
        }

        &:hover {
            ${fillIcon(theme.menu.focusedIconColor)};
        }
    `;
});

const buildActiveStyle = ({ focused, active, theme }: ActivityProps) => {
    return css`
        ${focused && fillIcon(theme.menu.focusedIconColor)};
        ${active && fillIcon(theme.menu.activeIconColor)};
        color: ${focused && theme.menu.focusedIconColor};
        font-size: ${focused ? '3.7rem' : '3rem'};

        &:hover {
            ${active && fillIcon(theme.menu.activeIconColor)};
            color: ${focused && theme.menu.focusedIconColor};
        }
    `;
};

export const MenuItemStyled = styled.div<ActivityProps>`
    align-items: center;
    color: ${(props) => props.theme.menu.color};
    cursor: default;
    display: flex;
    line-height: 3rem;
    padding: 2.5rem 0 2.5rem 5.8rem;
    ${({ theme }) => fillIcon(theme.menu.iconColor)};
    ${(props) => buildActiveStyle(props)};

    &:hover {
        cursor: pointer;
    }
`;

export const MenuProfileItemStyled = styled.div`
    display: flex;
    align-items: center;
    padding: 2.5rem 0 2.5rem 5.1rem;
    cursor: default;
    height: 10rem;

    &:hover {
        cursor: pointer;
    }
`;

export const MenuItemIconStyled = styled.div`
    font-size: 3.1rem;
    height: 3.1rem;
    margin-right: 4.8rem;
    position: relative;
    width: 3.1rem;
`;

export const MenuProfileItemIconStyled = styled.div`
    margin-right: 4rem;
`;

export const ProfileLabelStyled = styled.div<ActivityProps>`
    font-size: 3.7rem;
    color: ${({ theme }) => theme.menu.color};
    ${({ active, theme }) =>
        active &&
        `
        color: ${theme.menu.focusedIconColor};
    `}

    &:hover {
        color: ${({ theme }) => theme.menu.focusedIconColor};
    }
`;

export const SubLabelStyled = styled.div`
    font-size: 2rem;
    padding-left: 0.2rem;
`;

export const MenuProfileLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    white-space: nowrap;
`;

export const StyledIcon = styled.div<StyledIconProps>(({ active, focused, icon, theme }) => {
    const activeStyles = active && `background-color: ${theme.menu.activeIconColor}`;

    const focusedStyles = focused && `background-color: ${theme.menu.focusedIconColor}`;

    const mask = `
        mask: url(${icon}) no-repeat center / contain;
        background-color: ${theme.menu.iconColor}
    `;

    return css`
        width: 100%;
        height: 100%;
        ${mask};
        ${activeStyles};
        ${focusedStyles};
    `;
});
