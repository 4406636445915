import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

import { StaffCollectionWithFlatNavigationModel } from '@/models/collection/staff-collection-with-flat-navigation.model';
import { useMemo } from 'react';

const useGetStaffCollectionWithFlatNavigation = (params: { collectionId: number }) => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.FETCH_STAFF_COLLECTION_FLAT_NAVIGATION,
        params,
    );

    const { data, isFetching, error } = useGetMediaApi(keyHook, { appendAuthData: true });
    return {
        data: useMemo(() => new StaffCollectionWithFlatNavigationModel(data), [data]),
        error,
        isFetching,
    };
};

export default useGetStaffCollectionWithFlatNavigation;
