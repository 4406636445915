import { useEffect, useState } from 'react';
import { useConfiguration } from '@/contexts/configuration/configuration.hooks';
import { GalleryImageItemSchema } from '@/interfaces/from-schemas/system/getPartnersOut';
import isMatch from 'lodash/isMatch';
import { protectURL } from '@/helpers/rewrite-protocol.helper';
import { size } from '@/targets/default/mediaQuery';
import { generateCdnSrc } from '@/helpers/gallery';
import { getDefaultTheme } from '@/helpers/switch-theme.helper';

export const GALLERY_IMAGE_AVAILABLE_WIDTHS = [
    size.mobileS,
    size.mobileM,
    size.tablet,
    size.laptop,
    1200,
    size.laptopM,
    size.desktop,
    size.ultraWide,
];

export enum GALLERY_IMAGE_ASPECT {
    WIDESCREEN = 16 / 9,
    VERTICAL = 2 / 3,
    SQUARE = 1,
}

export enum GALLERY_IMAGE_DISPLAY {
    STANDARD = 'standard',
    BACKGROUND = 'background',
}

export enum GALLERY_BACKGROUND_EFFECT {
    TRANSPARENT = 'transparent',
    NOT_TRANSPARENT = 'not_transparent',
}

export interface IUseGalleryImage {
    image: {
        src: string;
        fallbackSrc: string;
    };
}

export interface IUseGalleryImageConfig {
    aspect?: GALLERY_IMAGE_ASPECT;
    customParams?: {
        display?: GALLERY_IMAGE_DISPLAY;
        backgroundEffect?: GALLERY_BACKGROUND_EFFECT;
    };
    widthTolerance?: Record<string, number>;
}

export const useGalleryImage = (
    width: number,
    gallery?: GalleryImageItemSchema[],
    config?: IUseGalleryImageConfig,
): IUseGalleryImage => {
    const configuration = useConfiguration();
    const [src, setSrc] = useState<string>('');
    const [fallbackSrc, setFallbackSrc] = useState<string>('');
    const aspect = config?.aspect || GALLERY_IMAGE_ASPECT.WIDESCREEN;
    const theme = getDefaultTheme();

    const getSrcFromFallback = (fallbackSrc: string) => {
        if (width === 0 || !configuration?.imgGeneratorCDN) {
            return fallbackSrc;
        }
        const toleranceKey = GALLERY_IMAGE_AVAILABLE_WIDTHS.filter((value) => value <= width).pop();
        const tolerance = toleranceKey ? Number(config?.widthTolerance?.[toleranceKey] || 0) : 0;

        const bestWidth =
            GALLERY_IMAGE_AVAILABLE_WIDTHS.find((value) => value >= width + tolerance) ||
            GALLERY_IMAGE_AVAILABLE_WIDTHS[GALLERY_IMAGE_AVAILABLE_WIDTHS.length - 1];

        return generateCdnSrc(
            new URL(fallbackSrc).pathname,
            bestWidth,
            configuration.imgGeneratorCDN,
        );
    };

    useEffect(() => {
        let collection: GalleryImageItemSchema[] = [];

        if (gallery) {
            collection = gallery.filter(
                (image) => image.aspect.width / image.aspect.height === aspect,
            );

            if (config?.customParams) {
                collection = collection.filter((image) =>
                    isMatch(
                        image.customParams as Record<string, unknown>,
                        { ...config?.customParams, appTheme: theme } as Record<string, unknown>,
                    ),
                );
            }

            // w przypadku gdy istnieje jakikolwiek obraz przefiltrowany po aspekcie i customParams sprawdzamy resolutions
            if (collection[0]) {
                const availableResolution = collection[0].resolutions?.find(
                    (image) => image.width >= width,
                );

                setFallbackSrc(protectURL(availableResolution?.url || collection[0].src));
            }
        }
    }, [width, aspect, gallery]);

    useEffect(() => {
        // gdy już znamy fallbackSrc możemy wygenerować url do generowania obrazków dla zdefiniowanych szerokości
        if (fallbackSrc) {
            setSrc(getSrcFromFallback(fallbackSrc));
        }
    }, [width, fallbackSrc]);

    return { image: { src, fallbackSrc } };
};
