import { CollectionsList } from '@/components/shared/CollectionsList';
import { LoaderContext } from '@/contexts/loader/loader.context';
import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import { PageContext } from '@/contexts/page/page.context';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { navVertical } from 'nav-tree';
import React, { useMemo, useState, useContext, useEffect } from 'react';
import { NavigationButtons, PageContent } from '.';
import { NavigationButtonsWrapper } from './NavigationButtonsWrapper';
import {
    StyledCollectionsList,
    StyledMainContainer,
    StyledMainNavigation,
    StyledPageContent,
} from './styles';
import { RecommendationViewOwnProps } from './types';
import { ListInterface } from '@/interfaces/list.interface';

export const RecommendationViewRaw = ({
    config,
    params,
    onCategoryView = () => null,
    onNoLists = () => null,
    withCatalog = false,
    collectionsList,
    isFetchingCollectionsList,
}: RecommendationViewOwnProps) => {
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);
    const { setShowLoader } = useContext(LoaderContext);
    const { listType, setListType } = useContext(PageContext);
    const [activeItem, setActiveItem] = useState<ListElementInterface | ListInterface>();
    useEffect(() => {
        if (!!activeItem || !isFetchingCollectionsList) setShowLoader(false);
    }, [activeItem, isFetchingCollectionsList]);

    const returnCollectionsList = useMemo(() => {
        if (params) {
            return (
                <StyledCollectionsList>
                    <CollectionsList
                        params={params}
                        onActiveItem={setActiveItem}
                        onListType={setListType}
                        onNoLists={onNoLists}
                        config={config}
                        collectionsList={collectionsList}
                        isFetchingCollectionsList={isFetchingCollectionsList}
                    />
                </StyledCollectionsList>
            );
        }
    }, [params, config]);

    const recommendationView = useMemo(
        () => (
            <StyledMainContainer
                restoreLastFocus
                autofocusOn={isMagicMouseVisible ? ['mouseenter'] : undefined}
            >
                <StyledPageContent view={listType}>
                    {activeItem && <PageContent actualVod={activeItem} view={listType} />}
                    {returnCollectionsList}
                </StyledPageContent>
            </StyledMainContainer>
        ),
        [listType, activeItem, params, returnCollectionsList],
    );

    const catalogView = useMemo(
        () => (
            <StyledMainNavigation
                func={navVertical}
                restoreLastFocus
                autofocusOn={isMagicMouseVisible ? ['mouseenter'] : undefined}
            >
                <NavigationButtonsWrapper>
                    <NavigationButtons onCategoryView={onCategoryView} />
                </NavigationButtonsWrapper>

                <StyledPageContent view={listType}>
                    {activeItem && <PageContent actualVod={activeItem} view={listType} />}
                    {returnCollectionsList}
                </StyledPageContent>
            </StyledMainNavigation>
        ),
        [listType, activeItem, params, returnCollectionsList, onCategoryView],
    );

    if (withCatalog) {
        return catalogView;
    }

    return recommendationView;
};

export const RecommendationView = React.memo(RecommendationViewRaw);
