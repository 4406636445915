import { PlayableButton, PlayableDynamicButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { Nav, navHorizontal } from 'nav-tree';
import React from 'react';
import { mainButtonIcon, mainButtonText } from './helpers';
import { NavigationButtonsOwnProps, NavigationButtonsVariant } from './types';

const NavigationButtonsRaw = ({
    hasSubcategories,
    mainButtonVariant = NavigationButtonsVariant.RECOMMENDED,
    onCategoryView,
    onShowFilters = () => null,
    onShowSubcategoriesMenu,
    subCategoriesLabel,
    withFilters = false,
    withLists = true,
    withSubcategory = false,
    withCatalog = false,
    withoutButton = false,
}: NavigationButtonsOwnProps) => {
    const isPacketSubcategory = mainButtonVariant === NavigationButtonsVariant.PACKET_SUBCATEGORY;
    const hasSeasons = subCategoriesLabel === t('seasons');
    const hasSeries = subCategoriesLabel === t('series');
    const handleButtonsFocus = (isActive: boolean) => {
        if (isActive) {
            onShowFilters(false);
            onShowSubcategoriesMenu?.(false);
        }
    };

    const changeFilters = () => onShowFilters(true);

    const mainButtonAction = () => {
        switch (mainButtonVariant) {
            case NavigationButtonsVariant.CATALOG:
            case NavigationButtonsVariant.RECOMMENDED:
                onCategoryView?.();
                break;
            case NavigationButtonsVariant.SUBCATEGORY:
            case NavigationButtonsVariant.PACKET_SUBCATEGORY:
                onShowSubcategoriesMenu?.(true);
                break;
        }
    };

    const buildMainButton = () => (
        <PlayableDynamicButton
            onNav={handleButtonsFocus}
            onButtonAction={mainButtonAction}
            text={mainButtonText(mainButtonVariant, subCategoriesLabel)}
            icon={mainButtonIcon(mainButtonVariant)}
            isFullyVisible
            style={{ display: 'inline-block', marginRight: '1.2rem' }}
        />
    );

    const buildPacketSubcategoryButton = () => (
        <PlayableDynamicButton
            onNav={handleButtonsFocus}
            onButtonAction={mainButtonAction}
            text={mainButtonText(mainButtonVariant)}
            icon={mainButtonIcon(mainButtonVariant)}
            isFullyVisible
            style={{ display: 'inline-block', marginRight: '1.2rem' }}
        />
    );

    const buildFilterButton = () => (
        <PlayableDynamicButton
            onNav={handleButtonsFocus}
            onButtonAction={changeFilters}
            text={t('pages.vod.filter')}
            icon={ICONS.FILTERS}
            isFullyVisible
            style={{ display: 'inline-block', verticalAlign: 'top' }}
            data-testing="pages.vod.filter"
        />
    );

    const buildBackButton = () => (
        <PlayableButton
            onNav={handleButtonsFocus}
            onButtonAction={Router.back}
            icon={ICONS.ARROW_LEFT}
            style={{ display: 'inline-block', marginRight: '1.2rem' }}
        />
    );

    if (hasSeasons || hasSeries) {
        return (
            <Nav func={navHorizontal}>
                {buildBackButton()}
                {hasSubcategories && buildMainButton()}
                {withLists && withFilters && buildFilterButton()}
            </Nav>
        );
    }

    if (withSubcategory) {
        return <Nav func={navHorizontal}>{buildBackButton()}</Nav>;
    }

    if (withCatalog) {
        return (
            <Nav func={navHorizontal}>
                {buildMainButton()}
                {withFilters && buildFilterButton()}
            </Nav>
        );
    }

    if (!withLists && !withFilters) {
        if (isPacketSubcategory) {
            return (
                <Nav func={navHorizontal}>
                    {buildBackButton()}
                    {buildPacketSubcategoryButton()}
                </Nav>
            );
        }
        return null;
    }

    if (withLists && withFilters && !withoutButton) {
        return (
            <Nav func={navHorizontal}>
                {buildMainButton()}
                {buildFilterButton()}
            </Nav>
        );
    }

    if (isPacketSubcategory && withFilters) {
        return (
            <Nav func={navHorizontal}>
                {buildPacketSubcategoryButton()}
                {buildFilterButton()}
            </Nav>
        );
    }

    if (withFilters) {
        return buildFilterButton();
    }
    if (withoutButton) {
        return null;
    }
    if (withoutButton && withFilters && withLists) {
        return buildFilterButton();
    }

    return buildMainButton();
};

export const NavigationButtons = React.memo(NavigationButtonsRaw);
