import { Icon } from '@/components/shared/Icon';
import React from 'react';
import {
    ListElementTopMarkingsContainer,
    ListElementTopMarkingsWrapper,
} from '@/components/shared/ListElement/Markings/style';
import { ListElementTopMarkingsProps } from '@/components/shared/ListElement/Markings/types';
import { useMarkingsIcons } from '@/hooks/use-markings-icon';

const ListElementTopMarkings = ({ asset, grantExpression }: ListElementTopMarkingsProps) => {
    const iconsName = useMarkingsIcons(asset, grantExpression);

    const getIcons = () => {
        return iconsName.map((iconName, index) => (
            <ListElementTopMarkingsContainer key={index}>
                <Icon name={iconName} />
            </ListElementTopMarkingsContainer>
        ));
    };
    return <ListElementTopMarkingsWrapper>{getIcons()}</ListElementTopMarkingsWrapper>;
};

export const ListElementTopMarkingsMemo = React.memo(ListElementTopMarkings);
