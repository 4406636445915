import React from 'react';
import { GUTTER } from '@/types/gutter.type';
import { MainText, MAINTEXT_KIND, MAINTEXT_RESPONSIVE_KIND } from '../../MainText';
import { Icon, ICONS } from '../../Icon';
import { StyledPositionedText, StyledLinkText, StyledShowMoreText } from './styles';
import { SliderTitleProps } from './types';
import t from '@/lib/i18n';

export const SliderTitle = ({
    text,
    customData,
    as = 'div',
    fullText,
    responsive,
    gutter = GUTTER.NONE,
}: SliderTitleProps) => {
    const showMoreUrl = customData?.showMoreUrl;
    const showMoreLabel = customData?.showMoreLabel;
    //tylko parametr showListTitle ukrywa tytul, domyslnie pokazyjemy tytul
    const hideTitle = customData?.showListTitle === false;

    if (hideTitle) {
        return null;
    }

    const mode = responsive ? MAINTEXT_RESPONSIVE_KIND.COLLECTION_TITLE : MAINTEXT_KIND.SUBTITLE;
    const mainText = (
        <MainText
            as={as}
            text={text}
            mode={mode}
            fullText={fullText}
            gutter={gutter}
            responsive={responsive}
        />
    );

    if (showMoreUrl) {
        return (
            <StyledShowMoreText href={showMoreUrl}>
                {mainText}
                <StyledLinkText>{showMoreLabel || t('show-more')}</StyledLinkText>
                <Icon name={ICONS.ARROW_RIGHT} />
            </StyledShowMoreText>
        );
    }

    if (customData?.titleAlign) {
        return (
            <StyledPositionedText position={customData.titleAlign}>{mainText}</StyledPositionedText>
        );
    }

    return mainText;
};
