import { Icon } from '@/components/shared/Icon';
import { IconButtonNav } from './styles';
import { IIconButtonProps } from './types';

export const IconButton = ({
    defaultFocused = false,
    onButtonAction,
    navId,
    onNav,
    icon,
    size,
    'data-testing': dataTesting,
    title,
}: IIconButtonProps): JSX.Element => {
    return (
        <IconButtonNav
            onNav={onNav}
            onEnterDown={onButtonAction}
            onClick={onButtonAction}
            navId={navId}
            defaultFocused={defaultFocused}
            autofocusOn={['focus', 'mouseenter']}
            data-testing={dataTesting}
            iconSize={size}
        >
            <button aria-label={title} title={title}>
                <Icon name={icon} />
            </button>
        </IconButtonNav>
    );
};
