import 'core-js/features/object';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import '@formatjs/intl-locale/polyfill';
import 'es7-object-polyfill';
import 'finally-polyfill';
import 'fromentries';
import 'array-flat-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
