import { useCallback } from 'react';

import { useIncrementColumnContext } from '@/contexts/epg-table/increment-column.context';
import { NAVIGATION_KEYS, NavTree, navHorizontal } from 'nav-tree';
import { useTablePageContext } from '@/contexts/epg-table/table-page.context';
import { useColumnContext } from '@/contexts/epg-table/column.context';

export const usePaginationNav = () => {
    const { focusedColIndex, handlePageChange, isVeryLastElementFullyVisible, setFocusedColIndex } =
        useIncrementColumnContext();

    const { firstColumn, lastColumn } = useColumnContext();
    const { currentPage, isDueToPageChange } = useTablePageContext();

    const handleColumnNavigation = useCallback(
        (navKeys: NAVIGATION_KEYS, navTree: NavTree) => {
            if (!isDueToPageChange) {
                const isFirstColFocused = firstColumn.index === focusedColIndex;
                const isLastColFocused = lastColumn.index === focusedColIndex;
                const isFirstPage = currentPage === 1;

                if (
                    isLastColFocused &&
                    navKeys === NAVIGATION_KEYS.RIGHT &&
                    !isVeryLastElementFullyVisible
                ) {
                    handlePageChange(NAVIGATION_KEYS.RIGHT);

                    return null;
                } else if (isFirstColFocused && navKeys === NAVIGATION_KEYS.LEFT && !isFirstPage) {
                    handlePageChange(NAVIGATION_KEYS.LEFT);
                    return null;
                } else {
                    return navHorizontal(navKeys, navTree);
                }
            }

            return null;
        },
        [isDueToPageChange, focusedColIndex],
    );

    const setActiveColumn = useCallback(
        (colIndex: number) => (isActive: boolean) => {
            isActive && setFocusedColIndex(colIndex);
        },
        [],
    );

    return { setActiveColumn, handleColumnNavigation };
};
