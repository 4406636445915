import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const NavButtons = styled(Nav as any)`
    display: flex;
`;

export const ButtonContainer = styled.div`
    padding: 0 0.8rem;
    white-space: nowrap;
`;
