import { useGetPaymentCards } from '@/api/payments/use-get-payment-cards';
import { PlayableButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { Message } from '@/components/shared/Message';
import { ChoosePaymentCards } from '@/components/shared/PaymentCard';
import { PurchaseButton } from '@/components/shared/PurchaseButton';
import { usePaymentsData } from '@/hooks/payments/use-payments-data';
import { PAYMENT_STEPS } from '@/interfaces/payments.interface';
import t from '@/lib/i18n';
import { PaymentCardModel } from '@/models/payments/payment-card.model';
import { OfferOptionModel } from '@/models/products/offer-option.model';
import { Router } from '@/routing';
import { navVertical } from 'nav-tree';
import React, { useEffect, useState } from 'react';
import {
    AnnotationsContainer,
    ButtonContainer,
    MessageContainer,
    PaymentMethodContainer,
} from './styles';
import { PaymentMethodStepProps } from './types';
import { MESSAGE_SIZE } from '@/components/shared/Message/styles';

export const PaymentMethodStep = ({
    onSetStepsVisibility,
    onSetProductCardVisibility,
    product,
    onNextStep,
}: PaymentMethodStepProps) => {
    const {
        selectedOfferId,
        selectedOfferPricingPlanId,
        selectedOptionId,
        updatePaymentsData,
        clearPaymentsData,
        selectedPaymentCardId,
    } = usePaymentsData();
    const [paymentCardId, setPaymentCardId] = useState<string>();

    const [isPaymentCardsViewVisible, setPaymentCardsViewVisibility] = useState(false);
    const [isPaymentCardsError, setPaymentCardsError] = useState(false);

    const [paymentCard, setPaymentCard] = useState<PaymentCardModel>();
    const { data: paymentCards } = useGetPaymentCards();

    const selectedOffer = product.findOffer(selectedOfferId);
    const options = selectedOffer?.getOptions() ?? [];

    useEffect(() => {
        clearPaymentsData(PAYMENT_STEPS.STEP_2);
    }, []);

    useEffect(() => {
        const isOptionExists = options.some((option) => option.getId() === selectedOptionId);
        const firstOptionId = options[0]?.getId();

        if (!isOptionExists && firstOptionId) {
            updatePaymentsData({ selectedOptionId: firstOptionId });
        }
    }, [selectedOptionId, options]);

    useEffect(() => {
        const defaultPaymentCard = paymentCards.find((paymentCard) => paymentCard.isDefault());

        if (defaultPaymentCard) {
            setPaymentCardId(defaultPaymentCard.getId());
            setPaymentCard(defaultPaymentCard);
        }
    }, [paymentCards]);

    const showPaymentCardsView = () => {
        setPaymentCardsViewVisibility(true);
        onSetStepsVisibility(false);
        onSetProductCardVisibility(false);
    };

    const hidePaymentCardsView = () => {
        setPaymentCardsViewVisibility(false);
        onSetStepsVisibility(true);
        onSetProductCardVisibility(true);
    };

    const showPaymentCardsError = () => {
        setPaymentCardsError(true);
        onSetStepsVisibility(false);
    };

    const hidePaymentCardsError = () => {
        setPaymentCardsError(false);
        onSetStepsVisibility(true);
    };

    const buildAnnotations = () => {
        const accessDuration = selectedOffer?.getAccessText();
        const trialDuration = selectedOffer?.getTrialDuration();

        if (accessDuration && trialDuration) {
            return t('payment-steps.trial-annotation', { accessDuration, trialDuration });
        }
    };
    const onNext = () => {
        const selectedOption = selectedOffer?.findOption(selectedOptionId);

        if (!selectedOffer || !selectedOption) {
            return;
        }
        if (selectedOffer.isCyclic() && selectedOption.isDotpayCard() && !selectedPaymentCardId) {
            showPaymentCardsError();
        }
        onNextStep();
    };

    const onSelect = (option: OfferOptionModel) => {
        const optionId = option.getId();
        const paymentCardIdFromOption = option.isDotpayCard() ? paymentCardId : undefined;

        updatePaymentsData({
            selectedOptionId: optionId,
            selectedPaymentCardId: paymentCardIdFromOption,
        });
        if (option.isDotpayCard()) {
            if (!paymentCard) {
                return showPaymentCardsError();
            }
        }
        onNext();
    };

    const onChangePaymentCard = () => {
        showPaymentCardsView();
    };

    const onChosenPaymentCard = (card: PaymentCardModel) => {
        setPaymentCard(card);
        setPaymentCardId(card.getId());
        hidePaymentCardsView();
    };

    const onCancelPaymentCard = () => {
        hidePaymentCardsView();
    };

    const onPaymentCardsErrorConfirm = () => {
        if (options.length > 1) {
            hidePaymentCardsError();
            return;
        }

        Router.back();
    };

    if (isPaymentCardsError) {
        return (
            <MessageContainer>
                <Message
                    messageSize={MESSAGE_SIZE.LG}
                    icon={ICONS.WARNING_CIRCLE}
                    title={t('components.purchases.no-payment-cards-header', {
                        targetName: process.env.name,
                    })}
                    description={t('components.purchases.no-payment-cards-subtitle', {
                        targetLink: process.env.url,
                    })}
                />
                <ButtonContainer>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={onPaymentCardsErrorConfirm}
                        defaultFocused
                    />
                </ButtonContainer>
            </MessageContainer>
        );
    }

    if (isPaymentCardsViewVisible) {
        return (
            <ChoosePaymentCards
                cards={paymentCards}
                chosenCard={onChosenPaymentCard}
                handleCancel={onCancelPaymentCard}
            />
        );
    }

    return (
        <PaymentMethodContainer func={navVertical}>
            <MainText
                mode={MAINTEXT_KIND.PAYMENT_STEP_TITLE}
                text={t('payment-steps.choose-payment-method')}
                fullText
            />

            {options.map((option, index) => {
                const label = option.getName();
                const priceLabel = selectedOfferPricingPlanId
                    ? option.getMinPriceTextForPricingPlan()
                    : option.getMinPriceText();
                let rightSideLabel, description;

                if (option.isDotpayCard() && paymentCard) {
                    rightSideLabel = t('payment-steps.change-card');
                    description = `${paymentCard.getMaskedNumber()}, ${paymentCard.getExpirationText()}`;
                }

                return (
                    label &&
                    priceLabel && (
                        <PurchaseButton
                            key={index}
                            label={label}
                            priceLabel={priceLabel}
                            rightSideLabel={rightSideLabel}
                            description={description}
                            defaultFocused={index === 0}
                            onButtonAction={() => onSelect(option)}
                            onRightSideAction={onChangePaymentCard}
                        />
                    )
                );
            })}

            <AnnotationsContainer>{buildAnnotations()}</AnnotationsContainer>
        </PaymentMethodContainer>
    );
};
