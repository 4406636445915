import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Slider } from '@/components/shared/Slider';
import { LiveCollectionOwnProps } from './types';
import { TitleWrapper } from '@/components/shared/CollectionsList/CollectionTitle';
import { LiveCollectionTitle } from './LiveCollectionTitle';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { useGetCollectionLives } from '@/components/views/Live/use-get-collection-lives.hook';
import { PageContext } from '@/contexts/page/page.context';
import { RecoContext } from '@/contexts/reco/reco.context';
import { LoaderContext } from '@/contexts/loader/loader.context';

const LiveCollectionRaw = ({
    rowIndex,
    filter,
    onLoadedData,
    onActiveItem,
    'data-testing': dataTesting,
}: LiveCollectionOwnProps) => {
    const { value, type, name } = filter;
    const [loadNav, setLoadNav] = useState<boolean>(false);
    const { refocusPage } = useContext(PageContext);
    const { setActiveRow } = useContext(RecoContext);
    const { setShowLoader } = useContext(LoaderContext);

    const params = {
        filters: [{ name, type, value }],
    };

    const { livesCollection, isFetching } = useGetCollectionLives(params);
    const livesLength = livesCollection?.lives.length || 0;
    const hasData = !!livesLength;

    useEffect(() => {
        if (!isFetching && !refocusPage) {
            if (hasData) {
                onLoadedData(true, rowIndex);
                setActiveRow((prev) => (prev === null ? rowIndex : prev));
                setLoadNav(true);
            } else {
                onLoadedData(false, rowIndex);
                setShowLoader(false);
            }
        }
    }, [isFetching, refocusPage]);

    const handleSliderNav = useCallback((activeItem: ListElementInterface) => {
        onActiveItem(activeItem);
        setActiveRow(rowIndex);
    }, []);

    const sliderConfig = useMemo(
        () => ({
            rowIndex,
            total: livesLength,
            defaultFocused: true,
            fetchedAll: true,
            collectionLength: livesLength,
        }),
        [rowIndex, livesLength],
    );

    if (!loadNav || !livesCollection) return null;

    return (
        <>
            <TitleWrapper>
                <LiveCollectionTitle live={livesCollection} />
            </TitleWrapper>
            <Slider
                collectionElements={livesCollection.lives}
                onActiveItem={handleSliderNav}
                config={sliderConfig}
                data-testing={dataTesting}
            />
        </>
    );
};

export const LiveCollection = React.memo(LiveCollectionRaw);
