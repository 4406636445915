import { ElementInnerWrapperProps, ImageContainerProps, PlaceholderWrapperProps } from './types';
import styled, { css } from 'styled-components';
import { Z_INDEX } from '@/targets/default/theme';
import { fillIcon } from '@/helpers/fill-icon.helper';

export const ElementWrapper = styled.div`
    margin: 1.5rem 0.8rem;
    width: 100%;
    height: 100%;
    display: inline-block;
    padding-bottom: 2rem;
`;

export const ElementInnerWrapper = styled.div<ElementInnerWrapperProps>(
    ({ active, $width, theme }) => {
        const { sx, sy } = theme.listElement.activeTransform;

        const activeStyles =
            active &&
            css`
                transform: scale3d(${sx}, ${sy}, 1);
                top: 2px;
                z-index: ${theme.listElement.activeZIndex};

                ${ImageContainer} {
                    box-shadow: 1.2rem 1.2rem 0.7rem rgba(0, 0, 0, 0.5);

                    > div:first-child {
                        border: ${theme.listElement.activeBorder};
                        border-radius: 0.7rem;
                    }
                }
            `;

        return css`
            width: ${$width};
            position: relative;
            z-index: ${theme.listElement.nonActiveZIndex};
            ${activeStyles};
        `;
    },
);

export const ImageContainer = styled.div<ImageContainerProps>(({ $height, allowFocus }) => {
    const allowFocusStyles =
        allowFocus &&
        css`
            cursor: pointer;
        `;

    return css`
        position: relative;
        height: ${$height};
        ${allowFocusStyles};
    `;
});

export const ImageWrapper = styled.div`
    position: relative;
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;

    // Required for Placeholder in Image.tsx

    > div:first-child {
        height: 100%;
        padding-top: 0;
    }
`;

export const PlaceholderContainer = styled.div`
    position: relative;
    z-index: ${Z_INDEX.LIST_ELEMENT};
`;

export const PlaceholderWrapper = styled.div<PlaceholderWrapperProps>`
    margin: 1.5rem 0.8rem;
    height: 100%;
    display: inline-block;
    padding-bottom: 2rem;

    ${({ $width }) => css`
        width: ${$width};
    `};
`;

export const IconWrapper = styled.div<{ isActive: boolean }>(({ isActive }) => {
    const activeStyles =
        isActive &&
        css`
            width: 6.3rem;
            height: 6.3rem;
        `;
    return css`
        width: 6rem;
        height: 6rem;
        ${({ theme }) => fillIcon(theme.text.color.highlight)};
        ${activeStyles};
    `;
});

export const CtaButtonWrapper = styled.div<{ isActive: boolean }>(({ isActive }) => {
    const activeStyles =
        isActive &&
        css`
            font-size: 2.4rem;
            color: ${({ theme }) => theme.text.color.highlight};
        `;
    return css`
        font-size: 2.1rem;
        text-align: center;
        margin-top: 1.8rem;
        ${activeStyles};
    `;
});
export const DataWrapper = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 2;
`;
