export enum BUTTON_ID {
    BASKET = 'basket',
    CATALOG_BTN = 'catalog-btn',
    CLOCK_REDO = 'clock-redo',
    DAY_PICKER_BTN = 'day-picker-btn',
    DISNEY_ACTIVATE = 'disney-activate',
    HEART = 'heart',
    INFO = 'info',
    PLAY = 'play',
    PLAYER_SIDEBAR_PANEL_AUDIO_BTN = 'player-sidebar-panel-audio-btn',
    PLAYER_SIDEBAR_PANEL_SETTINGS_BTN = 'player-sidebar-panel-settings-btn',
    PLUS = 'plus',
    QUICK_REGISTRATION_CHECK_EMAIL = 'quick-registration-check-email',
    BACK = 'back',
    UP = 'up',
    DOWN = 'down',
    LEFT = 'left',
    RIGHT = 'right',
    CC_BACK = 'cc-back',
    CC_CONTACT_LINK = 'cc-contact-link',
    CC_EXIT = 'cc-exit',
    TRAILER = 'trailer',
    RESIGN = 'resign',
    SEND_AGAIN = 'send-again',
    CTA_BTN = 'cta-btn',
}
