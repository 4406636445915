import { useGetPossessions } from '@/api/payments/use-get-possessions';
import { PlayableButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { Message } from '@/components/shared/Message';
import {
    compareProducts,
    isChannelTv,
    isLive,
    isPacket,
    isVodOrMovie,
} from '@/helpers/product.helper';
import t from '@/lib/i18n';
import { NAV_SCROLL_DIRECTION, navVertical } from 'nav-tree';
import React, { useContext, useEffect, useState } from 'react';
import { RelatedProduct } from './RelatedProduct';
import { SingleOffer } from './SingleOffer';
import { ButtonContainer, ButtonsGroup, CenterContainer, OfferContainer } from './styles';
import { OfferStepProps } from './types';
import { StatsContext } from '@/contexts/stats/stats.context';
import { usePaymentsData } from '@/hooks/payments/use-payments-data';
import { useDefaultSelectedOfferData } from '@/hooks/payments/use-default-selected-offer-data';
import { PAYMENT_STEPS } from '@/interfaces/payments.interface';
import { DATA_LAYER_PAYMENT_STEPS } from '@/constants/dataLayer';

export const OfferStep = ({
    product,
    relatedProducts,
    onSelectRelatedProduct,
    onSetStepsVisibility,
    onExitPaymentPath,
    onNextStep,
}: OfferStepProps) => {
    const { selectedOfferId, updatePaymentsData, clearPaymentsData } = usePaymentsData();
    const statsModule = useContext(StatsContext);
    const [isPossessionMessageVisible, setPossessionMessageVisibility] = useState(false);

    const productId = product.getProductId();
    const { data: possessions, isFetching: isPossessionFetching } = useGetPossessions();
    const possession = possessions?.find((possession) =>
        compareProducts(possession.getProductId(), productId),
    );
    const hasPossessionActive = possession?.getStatus() === 'active';

    const offers = product.getOffers();
    const cyclicOffers = offers.filter((offer) => offer.isCyclic());
    const otherOffers = offers.filter((offer) => !offer.isCyclic());
    const isCyclicOffersExists = cyclicOffers.length > 0;
    const isOtherOffersExists = otherOffers.length > 0;
    const isRelatedProductsExists = relatedProducts.length > 0;

    const isOtherOffersPriceLabelExists = otherOffers
        .map((offer) => offer.getMinPriceText())
        .some((price) => price !== undefined);

    useEffect(() => {
        clearPaymentsData(PAYMENT_STEPS.STEP_1);
    }, []);

    useEffect(() => {
        if (!isRelatedProductsExists && offers.length) {
            const offer = offers.find((productOffer) => productOffer.getId() === selectedOfferId);

            statsModule.hitPaymentPathStep(DATA_LAYER_PAYMENT_STEPS.OFFER, {
                id: product.getId(),
                name: product.getTitle(),
                category: product.getProductId().subType,
                price: offer?.getDataLayerPrice() || 0.01,
            });
        }
    }, [isRelatedProductsExists]);

    useEffect(() => {
        if (hasPossessionActive && !isPossessionFetching) {
            setPossessionMessageVisibility(true);
            onSetStepsVisibility(false);
        }
    }, [hasPossessionActive, isPossessionFetching]);

    const onSelectOffer = (offerId: string, offerPricingPlanId?: string) => {
        updatePaymentsData({
            selectedOfferId: offerId,
            selectedOfferPricingPlanId: offerPricingPlanId,
        });
        onNextStep();
    };
    const buildPossessionMessageTitle = () => {
        switch (true) {
            case isLive(productId) || isChannelTv(productId):
                return t('payment-steps.live-possession-message-title');

            case isPacket(productId):
                return t('payment-steps.packet-possession-message-title');

            case isVodOrMovie(productId):
            default:
                return t('payment-steps.vod-possession-message-title');
        }
    };
    const defaultSelectedOfferData = useDefaultSelectedOfferData(offers);
    useEffect(() => {
        const isSelectedOfferExists = offers.some((offer) => offer.getId() === selectedOfferId);

        if (!isSelectedOfferExists && defaultSelectedOfferData) {
            updatePaymentsData(defaultSelectedOfferData);
        }
    }, [selectedOfferId, offers, defaultSelectedOfferData, updatePaymentsData]);

    if (isPossessionMessageVisible) {
        return (
            <CenterContainer>
                <Message
                    icon={ICONS.INFO_CIRCLE}
                    title={buildPossessionMessageTitle()}
                    description={t('payment-steps.possession-message-description')}
                />
                <ButtonContainer func={navVertical}>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={() => {
                            setPossessionMessageVisibility(false);
                            onSetStepsVisibility(true);
                        }}
                    />
                    <PlayableButton
                        text={t('cancel-button-label')}
                        onButtonAction={onExitPaymentPath}
                        defaultFocused
                    />
                </ButtonContainer>
            </CenterContainer>
        );
    }

    return (
        <OfferContainer
            func={navVertical}
            scrollOptions={{
                isScrollable: true,
                direction: NAV_SCROLL_DIRECTION.VERTICAL,
            }}
        >
            {isCyclicOffersExists && (
                <ButtonsGroup>
                    <MainText
                        mode={MAINTEXT_KIND.PAYMENT_STEP_TITLE}
                        text={t('payment-steps.cyclic-access')}
                        fullText
                    />

                    <MainText
                        mode={MAINTEXT_KIND.PAYMENT_STEP_DESCRIPTION}
                        text={t('payment-steps.cyclic-access-description')}
                        fullText
                    />

                    {cyclicOffers.map((offer, index) => {
                        const defaultFocused = index === 0;

                        return (
                            <SingleOffer
                                product={product}
                                key={index}
                                offer={offer}
                                defaultFocused={defaultFocused}
                                onSelectOffer={onSelectOffer}
                            />
                        );
                    })}
                </ButtonsGroup>
            )}

            {isOtherOffersExists && (
                <ButtonsGroup>
                    {isOtherOffersPriceLabelExists && (
                        <MainText
                            mode={MAINTEXT_KIND.PAYMENT_STEP_TITLE}
                            text={t('payment-steps.one-time-access')}
                            fullText
                        />
                    )}

                    {otherOffers.map((offer, index) => {
                        const defaultFocused = !isCyclicOffersExists && index === 0;

                        return (
                            <SingleOffer
                                product={product}
                                key={index}
                                offer={offer}
                                defaultFocused={defaultFocused}
                                onSelectOffer={onSelectOffer}
                            />
                        );
                    })}
                </ButtonsGroup>
            )}

            {isRelatedProductsExists && (
                <ButtonsGroup>
                    <MainText
                        mode={MAINTEXT_KIND.PAYMENT_STEP_TITLE}
                        text={t('payment-steps.access-in-packet')}
                        fullText
                    />

                    {relatedProducts.map((relatedProduct, index) => {
                        const defaultFocused =
                            !isOtherOffersExists && !isCyclicOffersExists && index === 0;

                        return (
                            <RelatedProduct
                                key={index}
                                relatedProduct={relatedProduct}
                                defaultFocused={defaultFocused}
                                onSelectRelatedProduct={onSelectRelatedProduct}
                            />
                        );
                    })}
                </ButtonsGroup>
            )}
        </OfferContainer>
    );
};
