import React from 'react';
import t from '@/lib/i18n';
import BackgroundGoodbye from '@/public/assets/shared/goodbye-background.jpg';
import { Message } from '@/components/shared/Message';
import { ICONS } from '@/components/shared/Icon';
import { GoodbyeFullscreenContainer, CenteredMessage, QRCodeContainer, Logo } from './styles';
import QRCode from 'react-qr-code';
import { GUTTER } from '@/types/gutter.type';
import { MESSAGE_SIZE } from '@/components/shared/Message/styles';
import logo from '@/public/assets/polsat-box-go/logo.png';

const qrCodeUrl = 'https://polsatboxgo.pl';

const goodbyeTextParams = {
    tvName: 'Polsat',
    packetName: 'Polsat Box Go Start',
    closePortalName: t('domain'),
    newPortalName: 'Polsat Box Go',
    period: t('goodbye-page.accessTime'),
    link: ' https://polsatgo.pl/promocja/odbieram-prezent',

    dateTo: '30.09.2023',
    showGiftInformation: false,
};

export const Goodbye = () => {
    const description = t('goodbye-page.description-1', {
        tvName: goodbyeTextParams.tvName,
        packetName: goodbyeTextParams.packetName,
    });
    const logoImage = `<img alt="Polsat Box Go" style="max-width: 219px; vertical-align: middle" src="${logo}" />`;
    const descriptionWithLogo = `<abbr>${description}</abbr> ${logoImage}`;

    return (
        // TODO wdrożenie next/images dla backgroundSrc
        <GoodbyeFullscreenContainer
            backgroundSrc={BackgroundGoodbye.src || (BackgroundGoodbye as unknown as string)}
        >
            <CenteredMessage>
                <Message
                    icon={ICONS.INFO_CIRCLE}
                    messageSize={MESSAGE_SIZE.LG}
                    title={t('goodbye-page.header', {
                        closePortalName: goodbyeTextParams.closePortalName,
                    })}
                    innerHtmlDescription={descriptionWithLogo}
                    gutter={GUTTER.MEDIUM}
                />
                {goodbyeTextParams.showGiftInformation && (
                    <>
                        <Message
                            subtitle={t('goodbye-page.description-2', {
                                closePortalName: goodbyeTextParams.closePortalName,
                            })}
                            messageSize={MESSAGE_SIZE.LG}
                            innerHtmlDescription={t('goodbye-page.standalone.description-3', {
                                packetName: goodbyeTextParams.packetName,
                                link: `<span>${goodbyeTextParams.link}</span>`,
                                period: goodbyeTextParams.period,
                                newPortalName: goodbyeTextParams.newPortalName,
                            })}
                            gutter={GUTTER.MEDIUM}
                        />
                        <Message
                            description={t('goodbye-page.date-to-get-gift', {
                                dateTo: goodbyeTextParams.dateTo,
                            })}
                            gutter={GUTTER.NONE}
                            messageSize={MESSAGE_SIZE.LG_SMALL_DESCRIPTION}
                        />
                    </>
                )}
            </CenteredMessage>
            <QRCodeContainer>
                <QRCode value={qrCodeUrl} size={280} />
            </QRCodeContainer>
        </GoodbyeFullscreenContainer>
    );
};
