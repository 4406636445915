import { ZONES, ROUTES } from '../../routing/types';
import { HOME_MENU_STATIC_ICONS } from '../../types/home-menu-static-icons.type';
import { GM_APPLICATION, GM_DEVICE_TYPE, GM_OS, GM_PLAYER } from '../../types/platform.type';
import { TargetConfig, StandaloneTargetConfig } from '../../types/target-config.type';
import { DeepPartial } from '../../types/deep-partial.type';
import { AUTH_PROVIDERS } from '../../types/auth-providers.type';
import { ICONS } from '../../components/shared/Icon/types';
import defaultConfig from '../default/config';
import merge from 'lodash/merge';

const targetConfig: DeepPartial<StandaloneTargetConfig> = {
    portal: 'netiago2',
    name: 'Netia Go',
    url: 'www.netiaonline.pl',
    analytics: {
        gtmId: '',
    },
    defaultUserData: {
        userAgentData: {
            portal: 'netiago2',
            deviceType: GM_DEVICE_TYPE.Smart_TV,
            application: GM_APPLICATION.Native,
            player: GM_PLAYER.HTML5,
            build: 1,
            os: GM_OS.Tizen,
            osInfo: '',
        },
        ua: 'www_iplatv/12345',
        clientId: 'd5efb74e-37dc-4378-90cb-a9582c14fc3d',
    },
    rules: {
        displayUrl: 'go.netia.pl/panel/regulaminy',
    },
    authProviders: [AUTH_PROVIDERS.NETIA],
    redirectRouteZones: [ZONES.AUTH, ZONES.ACCOUNT, ZONES.CONTENT, ZONES.LOGIN_TO_CONTINUE],
    pages: {
        catalog: {
            initialLimit: 100,
            increaseBy: 100,
        },
    },
    playableButton: {
        isTransparentOnInactive: true,
    },
    slider: {
        initialLimit: 30,
        height: {
            promobox: 134,
            thumbnails: {
                simple: 410,
                withMeta: 430,
            },
            posters: {
                simple: 410,
                withMeta: 430,
            },
        },
    },
    grid: {
        col: 4,
        postersCol: 7,
        height: {
            thumbnails: {
                simple: 281,
                withMeta: 310,
            },
            posters: {
                simple: 369,
                withMeta: 389,
            },
        },
    },
    imageSize: {
        thumbnails: {
            width: 411,
            height: 231,
        },
        posters: {
            width: 227,
            height: 319,
        },
    },
    player: {
        bodyClass: 'transparent',
        transition: {
            idleDelay: 3000,
        },
        ridOfAdsButtonVisible: false,
        error: {
            helpPageUrl: 'https://www.netia.pl/pl/pomoc/netiago',
            helpPageUrlLabel: 'https://www.netia.pl/pl/pomoc/netiago',
            showQRCode: true,
            qrCodePath: ICONS.QR_CODE_POLSATGO,
        },
    },
    menu: {
        pre: [HOME_MENU_STATIC_ICONS.SEARCH, HOME_MENU_STATIC_ICONS.HOME],
    },
    spatialNavigation: true,
    paymentsPath: {
        supportedPaymentMethods: [
            'dotpay-blik',
            'dotpay-card',
            'payu-blik',
            'sms',
            'plusbill',
            'cpwallet',
            'netia',
            'plusbank-blik',
        ],
    },
    contacts: {
        helpdeskPage: {
            label: 'netia.pl/pl/pomoc/netiago',
            url: 'https://netia.pl/pl/pomoc/netiago',
        },
        helpdeskEmail: {
            label: 'netiago@netia.pl',
            url: 'netiago@netia.pl',
        },
        companyName: 'Netia S.A.',
        addressFirstLine: 'ul. Ostrobramska 77',
        addressSecondLine: '04-175 Warszawa',
        krs: 'KRS  388899',
        nip: 'NIP 1130054762',
    },
    excludedAccountMenuItems: [
        ROUTES.ACCOUNT_MY_PURCHASES,
        ROUTES.CONSENTS_LIST,
        ROUTES.PLUS_ZONE,
        ROUTES.ACCOUNT_RESTORE_SETTINGS,
    ],

    supportedExitAppConfirmationOS: [
        GM_OS.Tizen,
        GM_OS.WebOS,
        GM_OS.PANASONIC,
        GM_OS.VIDAA,
        GM_OS.WHALE,
        GM_OS.SAPHI,
    ],
    supportedVirtualKeyboardDeviceType: [GM_DEVICE_TYPE.TV_Hisense, GM_DEVICE_TYPE.TV_Panasonic],
    iod: {
        email: 'daneosobowe@netia.com.pl',
    },
    isChangeEmailAvailable: false,
    isDeleteAccountAvailable: false,
    isChangePasswordAvailable: false,
    showWelcomeLogo: true,
    logoAltText: 'Netia GO',
    footerCategoriesUrl:
        'https://redirector.redefine.pl/versions/footer-categories-data-netiago.json',
    isCustomerCare: false,
};

const config: TargetConfig = merge(defaultConfig, targetConfig);

export default config;
