import { GetProductOut } from '@/interfaces/from-schemas/navigation/getProductOut';
import { MinPriceSchema } from '@/interfaces/from-schemas/payments/getPrePurchaseDataOut';
import { MediaDetailsModel } from '@/models/media/media-details.model';
import { AccessProductModel } from '@/models/payments/access-product-model';
import { GTM_EVENTS } from '@/stats/types';

export interface IPaymentOfferEvent {
    id: string;
    name: string;
    category: string;
    price: number;
}

export interface ITransactionEvent extends IPaymentOfferEvent {
    orderId: string;
}

export interface IPossessedPackets {
    product: {
        id: string;
        validFrom?: string;
        validTo?: string;
        daysToExpire: number;
    };
}

export enum DATA_LAYER_PAYMENT_STEPS {
    OFFER = 'offer',
    METHOD = 'method',
    CHECKOUT = 'checkout',
}

export enum GTM_SESSION_KEYS {
    HIT_TRANSACTION_DATA = 'gtm_hit_transaction_data',
    DETAILS = 'gtm_details',
}

export const DATA_LAYER_REGISTRATION_EVENT = { event: 'rejestracja-konta' };

export const DATA_LAYER_PAYMENT_OFFER_EVENT = ({
    id,
    name,
    category,
    price,
}: IPaymentOfferEvent) => {
    return {
        event: GTM_EVENTS.EECHECKOUT,
        ecommerce: {
            checkout: {
                actionField: { step: 1, option: 'Sposób dostępu' },
                products: [
                    {
                        sku: id,
                        name: name,
                        category: category,
                        price: price,
                        quantity: 1,
                    },
                ],
            },
        },
    };
};

export const DATA_LAYER_PAYMENT_METHOD_EVENT = {
    event: GTM_EVENTS.EECHECKOUT,
    ecommerce: {
        checkout: {
            actionField: { step: 2, option: 'Metoda płatności' },
        },
    },
};

export const DATA_LAYER_PAYMENT_CHECKOUT_EVENT = {
    event: GTM_EVENTS.EECHECKOUT,
    ecommerce: {
        checkout: {
            actionField: { step: 3, option: 'Podsumowanie zakupu' },
        },
    },
};

export const DATA_LAYER_CHECK_POSSESSED_PACKETS_EVENT = (
    data: AccessProductModel[],
): Record<string, unknown> => {
    const possessedPackets: IPossessedPackets[] = data.map((item) => ({
        product: {
            id: item.possession.getProduct().id,
            validFrom: item.possession.getValidFrom(),
            validTo: item.possession.getValidTo(),
            daysToExpire: calculateDaysToExpire(item.possession.getValidTo()),
        },
    }));
    return {
        event: GTM_EVENTS.GET_PACKECTS_ON_INIT,
        zeta: {
            possessedPackets: possessedPackets,
        },
    };
};

export const DATA_LAYER_TRANSACTION_EVENT = ({
    orderId,
    price,
    name,
    id,
    category,
}: ITransactionEvent) => {
    return {
        event: GTM_EVENTS.EETRANSACTION,
        ecommerce: {
            purchase: {
                actionField: {
                    id: orderId,
                    revenue: price,
                },
                products: [
                    {
                        name: name,
                        id: id,
                        price: price,
                        category: category,
                        quantity: 1,
                        item_category: 'Apparel',
                    },
                ],
            },
        },
    };
};

export const DATA_LAYER_VIEW_ITEM_EVENT = (media: MediaDetailsModel) => ({
    event: GTM_EVENTS.VIEW_ITEM,
    ...buildItemEventTemplate(media),
});

export const DATA_LAYER_ADD_TO_CART_EVENT = (media: MediaDetailsModel) => ({
    event: GTM_EVENTS.ADD_TO_CART,
    ...buildItemEventTemplate(media),
});

export const DATA_LAYER_VIEW_PACKET_EVENT = (packet: GetProductOut) => ({
    event: GTM_EVENTS.VIEW_ITEM,
    ecommerce: {
        currency: (packet.minPrice?.currency || 'PLN') as MinPriceSchema['currency'],
        value: packet.minPrice?.value,
        items: [
            {
                item_name: packet.name,
                item_category: '',
            },
        ],
    },
});

function calculateDaysToExpire(validTo: string | undefined): number {
    if (!validTo) return 0;
    const today = new Date();
    const validToDate = new Date(validTo);
    const timeDiff = validToDate.getTime() - today.getTime();
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return dayDiff;
}

function buildItemEventTemplate(media: MediaDetailsModel) {
    return {
        ecommerce: {
            currency: (media.currency || 'PLN') as MinPriceSchema['currency'],
            value: media.minPrice,
            items: [
                {
                    item_name: media.title,
                    item_category: media.getCategory?.name || '',
                },
            ],
        },
    };
}
