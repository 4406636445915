import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const ExitAppConfirmationContainer = styled(Nav as any)`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
`;

export const ButtonsContainer = styled(Nav as any)`
    width: 44rem;
    padding: 4rem 0;
`;
