import { useMemo } from 'react';
import { useGetProducts } from '../navigation/use-get-products';
import { useGetPossessions } from './use-get-possessions';
import { useGetCommonlyAccessiblePackets } from '@/api/navigation/use-get-commonly-accessible-packets';
import { buildMyPurchasesList } from '@/helpers/payments/build-my-purchases-list.helper';

export const useGetMyPurchases = () => {
    const {
        data: accessiblePackets,
        isFetching: isFetchingAccessiblePackets,
        error: accessiblePacketsError,
    } = useGetCommonlyAccessiblePackets();
    const {
        data: possessionsData,
        isFetching: isFetchingPossessions,
        error: possessionsDataError,
    } = useGetPossessions();

    const productsParams = useMemo(() => {
        const accessiblePacketsProducts = accessiblePackets?.map((el) => el.getProduct()) || [];
        const possessionProducts = possessionsData?.map((el) => el.getProduct()) || [];
        const products = [...accessiblePacketsProducts, ...possessionProducts];

        return products.length > 0 ? { products } : false;
    }, [accessiblePackets, possessionsData]);

    const {
        data: products,
        isFetching: isFetchingProducts,
        error: productsError,
    } = useGetProducts(productsParams);

    const gridElements = useMemo(() => {
        if (!isFetchingProducts) {
            const myPurchasesList = buildMyPurchasesList(products);
            return myPurchasesList;
        }

        return [];
    }, [isFetchingProducts, products]);

    return {
        data: gridElements,
        isFetching: isFetchingProducts || isFetchingPossessions || isFetchingAccessiblePackets,
        error: accessiblePacketsError || possessionsDataError || productsError,
    };
};
