import { ImageSourceSchema } from '@/interfaces/from-schemas/navigation/getStaffRecommendationsListItemsOut';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { CustomDataInterface } from '@/interfaces/custom-data.interface';
import {
    StaffRecoListLayout,
    StaffRecoListSchema,
} from '@/interfaces/from-schemas/navigation/getStaffRecommendationsListsOut';
import { DATA_POSITION, TITLE_TYPE } from '@/types/list-element-metadata.type';
import { ROUTES } from '@/routing/types';
import { OFFER_LIST_TYPE } from '@/types/offer-list.type';

export class StaffRecommendationListModel {
    private readonly id: string = '';
    private readonly type: string = '';
    private readonly name: string = '';
    private readonly description: string = '';
    private readonly layout: StaffRecoListLayout = {
        type: '',
        value: '',
        customData: {},
    };
    private readonly validFrom: string = '';
    private readonly validTo: string = '';
    private static readonly defaultMaxLines = 1;

    constructor(definition: StaffRecoListSchema) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });
        }
    }

    public getId(): string {
        return this.id;
    }

    public getType(): string {
        return this.type;
    }

    public getName(): string {
        return this.name;
    }

    public getDescription(): string | undefined {
        const customData = this.getCustomData();
        const showListDescription = customData?.showListDescription;
        if (showListDescription) {
            return this.description;
        }
        return undefined;
    }

    public getLayout(): StaffRecoListLayout {
        return this.layout;
    }

    public getValidFrom(): string {
        return this.validFrom;
    }

    public getValidTo(): string {
        return this.validTo;
    }

    public getCustomData(): CustomDataInterface {
        return this.layout.customData;
    }
    public getCtaButtonLabel(): string | undefined {
        const customData = this.getCustomData();
        if (customData) {
            return customData.ctaButtonLabel as string;
        }
    }

    public getCtaButtonUrl(): ROUTES {
        const customData = this.getCustomData();
        return customData?.ctaButtonUrl as ROUTES;
    }

    public goToDetails(): void {
        const route = this.getCtaButtonUrl();
        window.location.href = route;
    }

    public getRowCount(): number | undefined {
        const customData = this.getCustomData();
        if (customData && 'rowCount' in customData) {
            return customData.rowCount as number;
        }
    }

    public hasScroll(): boolean {
        const customData = this.getCustomData();
        if (customData && 'listScroll' in customData) {
            return customData.listScroll !== 'none';
        }

        return true;
    }

    public hasHover(): boolean {
        const customData = this.getCustomData();
        if (customData && 'hover' in customData) {
            return !!customData.hover;
        }

        return true;
    }

    public hasClick(): boolean {
        const customData = this.getCustomData();
        if (customData && 'notClickable' in customData) {
            return !customData.notClickable;
        }

        return true;
    }

    public getDataPosition(): DATA_POSITION | undefined {
        const customData = this.getCustomData();
        const titlePosition = customData && customData.listElementTitlePosition;

        if (titlePosition === 'hidden') {
            return DATA_POSITION.HIDDEN;
        }

        if (!this.hasScroll()) {
            return DATA_POSITION.TOP_CENTER;
        }

        switch (titlePosition) {
            case 'on':
                return DATA_POSITION.INSIDE;

            case 'bottom':
                return DATA_POSITION.UNDER;
        }
    }

    public getTitle(): string | undefined {
        const customData = this.getCustomData();
        const showListTitle = customData?.showListTitle;
        if (showListTitle) {
            return this.name;
        }
        return undefined;
    }

    public getTitleType(): TITLE_TYPE {
        const customData = this.getCustomData();
        const titleType = customData?.titleType;

        switch (titleType) {
            case 'own':
                return TITLE_TYPE.OWN;

            case 'shortened':
                return TITLE_TYPE.SHORTENED;

            case 'full':
            default:
                return TITLE_TYPE.FULL;
        }
    }

    public getTitleMaxLines(): number {
        const customData = this.getCustomData();
        const defaultMaxLines = StaffRecommendationListModel.defaultMaxLines;
        const maxLines = Number(customData?.listElementTitleLines) || defaultMaxLines;

        return maxLines;
    }

    public getImageDisplayMode(): IMAGE_DISPLAY_MODE | null {
        const customData = this.getCustomData();
        const imageDisplayMode = customData && customData.imageDisplayMode;

        switch (imageDisplayMode) {
            case 'thumbnails':
                return IMAGE_DISPLAY_MODE.THUMBNAILS;

            case 'posters':
                return IMAGE_DISPLAY_MODE.POSTERS;

            default:
                return null;
        }
    }

    public isPromobox(): boolean {
        return this.layout.value === 'promobox';
    }

    public isOfferListType() {
        return Object.values(OFFER_LIST_TYPE).includes(this.layout.value as OFFER_LIST_TYPE);
    }

    public getOfferListType(): OFFER_LIST_TYPE | any {
        return this.layout.value;
    }

    public getListBackground(): ImageSourceSchema | undefined {
        const collectionBcgArray = this.getCustomData()?.background;
        if (Array.isArray(collectionBcgArray)) {
            return collectionBcgArray.find((el) => el.src);
        }
    }

    public getListBackgroundColor(): string | undefined {
        const customData = this.getCustomData();
        const collectionBcgColor = customData && customData.backgroundColor;

        if (typeof collectionBcgColor === 'string') {
            return collectionBcgColor;
        }
    }
}
