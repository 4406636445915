import { PlayableButton } from '@/components/shared/Buttons';
import { usePlaybackOptions } from '@/hooks/player/use-playback-options';
import { usePlayerInstance } from '@/hooks/player/use-player-instance';
import t from '@/lib/i18n';
import { BUTTON_ID } from '@/types/button-id.type';
import { Nav, NAVIGATION_KEYS, NavTree, navVertical } from 'nav-tree';
import { useCallback } from 'react';
import { PlaybackOption } from '../shared/PlaybackOption';
import { OptionGroupHeader } from '../shared/styles';
import { ITextAudioPanelProps } from './types';

export const TextAudioPanel = ({ onClose }: ITextAudioPanelProps) => {
    const player = usePlayerInstance();
    const { hasAnyOptions, hasAudioOptions, hasTextOptions, playbackOptions } =
        usePlaybackOptions();

    const setPlaybackOptions = useCallback(
        (options: IPlayer.PlaybackSelectedOptions) => {
            player.user.setPlaybackOptions(options);
        },
        [player],
    );

    const handleFunc = useCallback(
        (key: NAVIGATION_KEYS, navTree: NavTree) => {
            if (key === NAVIGATION_KEYS.LEFT) {
                onClose(BUTTON_ID.PLAYER_SIDEBAR_PANEL_AUDIO_BTN);
            }

            return navVertical(key, navTree);
        },
        [onClose],
    );

    if (!hasAnyOptions) {
        return null;
    }
    const defaultTextIndex = playbackOptions?.text?.findIndex(({ value: { active } }) => !active);
    const defaultAudioIndex =
        defaultTextIndex !== undefined
            ? undefined
            : playbackOptions?.text?.findIndex(({ value: { active } }) => !active);

    return (
        // @ts-ignore
        <Nav func={handleFunc} style={{ position: 'relative' }}>
            {hasTextOptions && (
                <>
                    <OptionGroupHeader>
                        {t('player.playback-options.text-header')}
                    </OptionGroupHeader>
                    {playbackOptions?.text.map((opt, index) => (
                        <PlaybackOption
                            key={opt.label}
                            defaultFocused={index === defaultTextIndex}
                            active={!!opt.value.active}
                            label={opt.label}
                            onSelect={() =>
                                setPlaybackOptions({
                                    text: opt.value,
                                })
                            }
                        />
                    ))}
                </>
            )}

            {hasAudioOptions && (
                <>
                    <OptionGroupHeader>
                        {t('player.playback-options.audio-header')}
                    </OptionGroupHeader>
                    {playbackOptions?.audio.map((opt, index) => (
                        <PlaybackOption
                            key={opt.label}
                            defaultFocused={index === defaultAudioIndex && !hasTextOptions}
                            active={!!opt.value.active}
                            label={opt.label}
                            onSelect={() =>
                                setPlaybackOptions({
                                    audio: opt.value,
                                })
                            }
                        />
                    ))}
                </>
            )}
            <PlayableButton
                style={{ position: 'absolute', bottom: 0, width: '100%' }}
                text={'OK'}
                onButtonAction={() => onClose(BUTTON_ID.PLAYER_SIDEBAR_PANEL_AUDIO_BTN)}
            />
        </Nav>
    );
};
