import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { hasPermission } from '@/services/grant-expression/grant-expression.service';
import { AssetInterface } from '@/interfaces/asset.interface';

export enum MARKINGS_ICONS {
    THUMBNAILS_7 = 'thumbnails-7',
    THUMBNAILS_12 = 'thumbnails-12',
    THUMBNAILS_16 = 'thumbnails-16',
    THUMBNAILS_18 = 'thumbnails-18',
    THUMBNAILS_BASKET = 'thumbnails-basket',
}

export const useMarkingsIcons = (
    asset: AssetInterface,
    grantExpression?: string,
): MARKINGS_ICONS[] => {
    const markings = [];
    const userModule = useUserModule();
    const currentTimestamp = new Date().getTime();

    const hasAccess = hasPermission(
        userModule.getAccessGroups() ?? [],
        grantExpression,
        currentTimestamp,
    );

    if (asset.getAgeGroup?.()) {
        markings.push(`thumbnails-${asset.getAgeGroup()}` as MARKINGS_ICONS);
    }

    if (!hasAccess && userModule.isLogged()) {
        markings.push(MARKINGS_ICONS.THUMBNAILS_BASKET);
    }
    return markings;
};
