import { useState } from 'react';
import { ErrorMessageProps } from './types';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { Wrapper, Link, IconWrapper } from './styles';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { Icon, ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
const MAX_DESCRIPTION_LENGTH = 140;

export const ErrorMessageShowMore = ({ description, errorCode, articleId }: ErrorMessageProps) => {
    const [activeLink, setActiveLink] = useState<boolean>(false);
    return (
        <Wrapper>
            <MainText text={description} mode={MAINTEXT_KIND.DESCRIPTION} />
            {description.length > MAX_DESCRIPTION_LENGTH && (
                <Link
                    onNav={(isActive: any) => setActiveLink(isActive)}
                    active={activeLink}
                    autofocusOn={['focus', 'mouseenter']}
                    defaultFocused
                    onEnterDown={() =>
                        Router.pushRoute(ROUTES.CUSTOMER_CARE_FULL_ERROR_PAGE, {
                            errorCode: errorCode,
                            articleId: articleId,
                        })
                    }
                >
                    {t('pages.customer-care.show-more')}
                    <IconWrapper>
                        <Icon name={ICONS.ARROW_RIGHT} />
                    </IconWrapper>
                </Link>
            )}
        </Wrapper>
    );
};
