import {
    CategorySchema,
    ImageSourceSchema,
    MediaListItemSchema,
    ProductIdSchema,
} from '@/interfaces/from-schemas/navigation/getMediaListOut';
import { MEDIA_TYPES, CPID, PRODUCT_SUBTYPES, PRODUCT_TYPES } from '@/types/media.type';
import { formatDate } from '@/helpers/date.helper';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { AssetInterface, goToDetailsOptionsType } from '@/interfaces/asset.interface';
import { CustomDataInterface } from '@/interfaces/custom-data.interface';
import { coverUtil } from '@/utils/cover.util';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { backgroundUtil } from '@/utils/background.utils';
import { ListElementImageInterface } from '@/interfaces/list-element.interface';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import slug from 'slug';
import { redirectToFirstPaymentStep } from '@/helpers/payment.helper';
import { ProductIdInterface } from '@/interfaces/product-id-interface';
import { redirectToWatchUrl } from '@/helpers/watch.helpers';
import { protectURL } from '@/helpers/rewrite-protocol.helper';
import { getBestImageMatch } from '@/utils/get-best-image-match';
import { ACCESSIBILITY_FEATURES, UNDERAGE_CLASSIFICATION } from '@/types/additional-signs.type';
import { SECONDS_IN_MINUTE } from '@/constants/default-time';
import { GetMediaOut, MediaSubtitleSchema } from '@/interfaces/from-schemas/navigation/getMediaOut';
import _ from 'lodash';
import { isOnAir } from '@/helpers/epg/date.helper';

export class MediaListItemModel implements AssetInterface {
    public imageDisplayMode = IMAGE_DISPLAY_MODE.THUMBNAILS;

    private readonly ageGroup: number = 0;
    private readonly cast?: string[];
    private readonly category: CategorySchema | null = null;
    private readonly categoryBackgroundSrc?: string;
    private readonly cinematographers?: string[];
    private readonly composers?: string[];
    private readonly countries?: string[];
    private readonly cpid: CPID = CPID.VOD_OR_MOVIE;
    private readonly customData: MediaListItemSchema['customData'];
    private readonly description: string = '';
    private readonly directors?: string[];
    private readonly duration?: number;
    private readonly episodeNumber: number = 0;
    private readonly episodeTitle: string = '';
    private readonly genres: string[] = [];
    private readonly grantExpression: string = '';
    private readonly id: string = '';
    private readonly licenseEndDate: string = '';
    private readonly licenseLocationText: string = '';
    private readonly longDescription: string = '';
    private readonly mediaType: MEDIA_TYPES = MEDIA_TYPES.CATEGORY;
    private readonly originalTitle?: string;
    private readonly platforms: string[] = [];
    private readonly posters: ImageSourceSchema[] = [];
    private readonly product: ProductIdSchema | null = null;
    private readonly publicationDate: string = '';
    private readonly published: boolean = false;
    private readonly releaseYear?: number;
    private readonly reporting: MediaListItemSchema['reporting'] = {};
    private readonly screenwriters?: string[];
    private readonly seasonNumber: number = 0;
    private readonly serializationType: string = '';
    private readonly shortTitle: string = '';
    private readonly sound?: string[];
    private readonly subtitles?: MediaSubtitleSchema[];
    private readonly thumbnails: ImageSourceSchema[] = [];
    private readonly title: string = '';
    private readonly vote: string = '';
    private readonly categoryName: string = '';
    private readonly underageClassification?: UNDERAGE_CLASSIFICATION[];
    private readonly accessibilityFeatures?: ACCESSIBILITY_FEATURES[];
    private readonly gallery: GetMediaOut['gallery'];
    private readonly staffCollections: any;

    constructor(definition: MediaListItemSchema) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });
        }

        if (this.category) {
            this.categoryName = this.category.name;

            if (this.category.thumbnails) {
                this.categoryBackgroundSrc = backgroundUtil(this.category.thumbnails)?.src;
            }
        }
    }

    public getCategory(): CategorySchema | null {
        return this.category;
    }

    public getKeyCategoryId(): number | undefined {
        return this.category?.keyCategoryId;
    }

    public getMediaType(): MEDIA_TYPES {
        return this.mediaType;
    }

    public getProduct(): ProductIdSchema | null {
        return this.product;
    }

    public getProductId(): ProductIdInterface | null {
        // TODO: Przydałby się helper do konwertowania ProductIdSchema na ProductIdInterface
        return (
            this.product && {
                id: this.product.id,
                type: this.product.type as PRODUCT_TYPES,
                subType: this.product.subType as PRODUCT_SUBTYPES,
            }
        );
    }

    public getAgeGroup(): number {
        return this.ageGroup;
    }

    public getUnderageClassification() {
        return this.underageClassification;
    }

    public getAccessibilityFeatures() {
        return this.accessibilityFeatures;
    }

    public getThumbnails(): ImageSourceSchema[] {
        return this.thumbnails;
    }

    public getSeasonThumbnails(): ImageSourceSchema[] | undefined {
        return this.category?.thumbnails;
    }

    public getPosters(): ImageSourceSchema[] {
        return this.posters;
    }

    public getGenres(): string {
        return this.genres.join(', ');
    }

    public getTitle(): string {
        return this.title;
    }

    public getShortTitle(): string {
        return this.shortTitle;
    }

    public getDescription(): string {
        return this.description;
    }

    public getLongDescription(): string {
        return this.longDescription;
    }

    public getAvatars(): ImageSourceSchema[] {
        if (this.isPostersExist()) {
            return this.getPosters();
        }

        if (this.isThumbnailsExists()) {
            return this.getThumbnails();
        }

        return [];
    }

    public getType(): MEDIA_TYPES {
        return this.mediaType;
    }

    public getIsMovie(): boolean {
        return this.getType() === MEDIA_TYPES.MOVIE;
    }

    public getIsLive(): boolean {
        return this.getType() === MEDIA_TYPES.LIVE;
    }

    public getOriginalTitle(): string | undefined {
        return this.originalTitle;
    }

    public getReleaseYear(): number | undefined {
        return this.releaseYear;
    }

    public getCountries(): string | undefined {
        if (this.countries) {
            return this.countries.join(', ');
        }
    }

    public getDuration(): number | undefined {
        // TODO: helper do obliczania czasu
        if (this.duration !== undefined) {
            return Math.floor(this.duration / 60);
        }
    }

    /**
     * Checks IDs of element's category.
     * `Category` contains only `id`.
     * `Subcategory` contains `id` and `keyCategoryId`
     * @return `True` if element is part of subcategory, `False` otherwise.
     */
    public isSubcategory(): boolean {
        return !!this.category?.id && !!this.category.keyCategoryId;
    }

    public isPostersExist(): boolean {
        return this.getPosters().length > 0;
    }

    public isThumbnailsExists(): boolean {
        return this.getThumbnails().length > 0;
    }

    public isPublished(): boolean {
        return this.published;
    }

    public getPublicationDate(): string {
        return this.publicationDate;
    }

    public getIsOnAir(): boolean {
        const isLive = this.getIsLive();

        //* Only live media items can be on air
        if (!isLive) {
            return false;
        }

        return isOnAir(this.publicationDate);
    }

    public getLicenseEndDate(mode: FORMAT_DATE_MODE): string {
        return formatDate(this.licenseEndDate, mode);
    }

    public getLicenseLocationText(): string {
        return this.licenseLocationText;
    }

    public getMediaKey(): string {
        return `${this.id}|${this.cpid}`;
    }

    public getPlatforms(): string[] {
        return this.platforms;
    }

    public getPath(): string {
        const urlTitle = this.title.split(' ').join('-');

        // potrzebny helper do budowania urli
        return `${urlTitle}/${this.cpid}/${this.id}`;
    }

    public getId(): string {
        return this.id;
    }

    public getCpid(): CPID {
        return this.cpid;
    }

    public getMetadata(): string | string[] {
        const isLive = this.getIsLive();
        const metadata = [];

        if (isLive) {
            const hours = formatDate(this.publicationDate, FORMAT_DATE_MODE.HOURS);
            const liveArabicFormat = formatDate(
                this.getPublicationDate(),
                FORMAT_DATE_MODE.LIVE_ARABIC_FORMAT,
            );
            const genres = this.getGenres();
            // (fjanusz) maksymalna długość genres która pozwoli na wyświetlenie całości h2
            const isDayTagVisible = genres.length < 17;

            if (!isDayTagVisible || this.getIsOnAir()) {
                metadata.push(hours, this.getGenres());
            } else {
                metadata.push(hours, liveArabicFormat, this.getGenres());
            }
        } else {
            metadata.push(this.getGenres());
        }

        // TODO: Kanał TV - zakres czasowy aktualnego materiału - jeszcze nie wspierane przez GM

        return metadata;
    }

    public getDetailedMetadata(): string {
        const type = this.getType();
        const mainCategory = this.category?.categoryNamesPath?.[1];
        const genres = this.getGenres();
        const keyCategory = this.categoryName;
        const metadata = [mainCategory];

        if (type === MEDIA_TYPES.VOD) {
            if (keyCategory && mainCategory !== keyCategory) {
                metadata.push(genres);
                metadata.push(keyCategory);
            }
        }

        if (type === MEDIA_TYPES.MOVIE) {
            metadata.push(genres);
        }

        return metadata.filter(Boolean).join(', ');
    }

    public getImage(): ListElementImageInterface {
        const images =
            this.imageDisplayMode === IMAGE_DISPLAY_MODE.POSTERS
                ? this.getPosters()
                : this.getThumbnails();
        const image = coverUtil(images, this.imageDisplayMode);

        return {
            // TODO: brakuje funkcji do pobrania placeholdera
            src: image ? image.src : '',
            displayMode: this.imageDisplayMode,
        };
    }

    public getSubcategoryName(): string | undefined {
        if (this.isSubcategory()) {
            return this.categoryName;
        }
    }

    public hasSeason() {
        return !!this.category && this.category.categoryPath.length >= 4;
    }

    public hasFullCategory(): boolean {
        const categoryName = this.category?.categoryNamesPath?.[2];
        const categoryId = this.category?.categoryPath?.[2];
        return typeof categoryName !== 'undefined' && typeof categoryId !== 'undefined';
    }

    public buildMovieRouteParams() {
        return {
            title: slug(this.title),
            id: this.id,
        };
    }

    public buildVodWithSeasonRouteParams() {
        const categoryNamesPath = this.category?.categoryNamesPath;
        const seasonName = categoryNamesPath?.[3] ? slug(categoryNamesPath[3]) : undefined;
        return {
            ...this.buildVodRouteParams(),
            seasonName,
            seasonId: this.category?.categoryPath[3],
        };
    }

    public buildVodRouteParams() {
        const categoryNamesPath = this.category?.categoryNamesPath;
        const categoryPath = this.category?.categoryPath;
        const mainCategory = categoryNamesPath?.[1] ? slug(categoryNamesPath[1]) : undefined;
        const vodCategoryTitle = categoryNamesPath?.[2] ? slug(categoryNamesPath[2]) : undefined;
        const vodCategoryId = categoryPath?.[2] ? categoryPath[2] : undefined;

        return {
            mainCategory,
            vodCategoryTitle,
            vodCategoryId,
            vodTitle: slug(this.title),
            vodId: this.id,
        };
    }

    public buildVodWatchRouteParams() {
        if (this.hasFullCategory()) {
            return this.buildVodRouteParams();
        }

        return {
            vodTitle: slug(this.title),
            vodId: this.id,
        };
    }

    public buildEventRouteParams() {
        return {
            eventTitle: slug(this.title),
            eventId: this.id,
        };
    }

    public buildChannelRouteParams() {
        return {
            channelTitle: slug(this.title),
            channelId: this.id,
        };
    }

    public buildSubcategoryRouteParams(subcategoryId: number) {
        return {
            categoryId: this.category?.id ?? '',
            subcategoryId,
        };
    }

    public getDetailsRoute(): ROUTES {
        switch (this.getType()) {
            case MEDIA_TYPES.MOVIE:
                return ROUTES.VOD_MOVIE_DETAILS;
            case MEDIA_TYPES.VOD:
                return this.hasSeason()
                    ? ROUTES.VOD_EPISODE_WITH_SEASON_DETAILS
                    : ROUTES.VOD_EPISODE_DETAILS;
            case MEDIA_TYPES.LIVE:
                return ROUTES.LIVE_DETAILS;
            case MEDIA_TYPES.TV:
                return ROUTES.CHANNEL_TV_DETAILS;
            default:
                return ROUTES.NOT_FOUND;
        }
    }

    public getFullDetailsRoute(isEpg?: boolean): ROUTES {
        switch (this.getType()) {
            case MEDIA_TYPES.MOVIE:
                return ROUTES.VOD_MOVIE_FULL_DETAILS;
            case MEDIA_TYPES.VOD:
                return this.hasSeason()
                    ? ROUTES.VOD_EPISODE_WITH_SEASON_FULL_DETAILS
                    : ROUTES.VOD_EPISODE_FULL_DETAILS;
            case MEDIA_TYPES.LIVE:
                return ROUTES.LIVE_FULL_DETAILS;
            case MEDIA_TYPES.TV:
                return isEpg ? ROUTES.CHANNEL_TV_EPG_FULL_DETAILS : ROUTES.CHANNEL_TV_FULL_DETAILS;
            default:
                return ROUTES.NOT_FOUND;
        }
    }

    public getWatchRoute(): ROUTES {
        switch (this.getType()) {
            case MEDIA_TYPES.MOVIE:
                return ROUTES.VOD_MOVIE_WATCH;
            case MEDIA_TYPES.VOD:
                if (this.hasSeason()) {
                    return ROUTES.VOD_EPISODE_WITH_SEASON_WATCH;
                }

                if (this.hasFullCategory()) {
                    return ROUTES.VOD_EPISODE_WATCH;
                }

                return ROUTES.VOD_WATCH;
            case MEDIA_TYPES.LIVE:
                return ROUTES.LIVE_WATCH;
            case MEDIA_TYPES.TV:
                return ROUTES.CHANNEL_TV_WATCH;
            default:
                return ROUTES.NOT_FOUND;
        }
    }

    public getRouteParams(isWatch?: boolean): { [key: string]: any } {
        switch (this.getType()) {
            case MEDIA_TYPES.MOVIE:
                return this.buildMovieRouteParams();
            case MEDIA_TYPES.VOD:
                if (this.hasSeason()) {
                    return this.buildVodWithSeasonRouteParams();
                }

                if (isWatch) {
                    return this.buildVodWatchRouteParams();
                }

                return this.buildVodRouteParams();
            case MEDIA_TYPES.LIVE:
                return this.buildEventRouteParams();
            case MEDIA_TYPES.TV:
                return this.buildChannelRouteParams();
            // Nieobsługiwany mediaType

            default:
                return {};
        }
    }

    public getSubCategoryRouteData(subcategoryId: number): { [key: string]: any } {
        return {
            route: ROUTES.SUBCATEGORY,
            params: this.buildSubcategoryRouteParams(subcategoryId),
        };
    }

    public goToDetails(options?: goToDetailsOptionsType): void {
        const { trimPurchaseHistory, replaceRoute } = options || {};

        const route = this.getDetailsRoute();
        const params = this.getRouteParams();

        if (trimPurchaseHistory) {
            Object.assign(params, { trimPurchaseHistory });
        }

        replaceRoute ? Router.replaceRoute(route, params) : Router.pushRoute(route, params);
    }

    public getFullDetailsLinkParams() {
        const route = this.getFullDetailsRoute();
        const params = this.getRouteParams();
        return { route, params };
    }

    public goToFullDetails(): void {
        const route = this.getFullDetailsRoute();
        const params = this.getRouteParams();
        Router.pushRoute(route, params);
    }

    public getWatchParams() {
        const route = this.getWatchRoute();
        const params = this.getRouteParams(true);

        return { route, params };
    }

    public goToWatch(): void {
        const route = this.getWatchRoute();
        const params = this.getRouteParams(true);

        redirectToWatchUrl(route, params, true);
    }

    public goToSubcategory(subcategoryId: number) {
        const { route, params } = this.getSubCategoryRouteData(subcategoryId);
        Router.pushRoute(route, params);
    }

    public getPaymentsPath() {
        const productId = this.getProductId();

        if (productId) {
            return redirectToFirstPaymentStep({ productId, withNext: true, extractParams: true });
        }
    }

    public goToPaymentsPath(): void {
        const productId = this.getProductId();

        if (productId) {
            redirectToFirstPaymentStep({ productId, withNext: true });
        }
    }

    public getProgress(): number {
        // TODO: fmc
        return 0;
    }

    public getCustomData(): CustomDataInterface {
        return this.customData;
    }

    public getCategoryName(): string | undefined {
        return this.categoryName;
    }

    public getBackgroundSrc(imgWidth?: number, imgHeight?: number): string {
        if (imgWidth !== undefined) {
            return protectURL(getBestImageMatch(this.thumbnails, imgWidth, imgHeight).src || '');
        }

        return backgroundUtil(this.thumbnails)?.src || '';
    }

    public getCategoryBackgroundSrc(): string | undefined {
        return this.categoryBackgroundSrc;
    }

    public getBottomMetadata(limited = false): string[] {
        const metadataArray = [];
        if (!limited) {
            if (this.originalTitle) {
                const data = `${t('original-title')}: ${this.originalTitle}`;
                metadataArray.push(data);
            }
        }
        if (this.countries) {
            const data = `${t('production')}: ${this.getCountries()}`;
            metadataArray.push(data);
        }
        if (this.releaseYear) {
            const data = `${t('year-of-production')}: ${this.releaseYear}`;
            metadataArray.push(data);
        }

        if (this.duration && this.getDuration()) {
            const data = `${t('duration.label')}:${t('duration.minutes', {
                minutes: this.getDuration(),
            })}`;

            metadataArray.push(data);
        }

        if (!limited) {
            if (this.directors) {
                const data = `${t('directors')}: ${this.directors.join(', ')}`;
                metadataArray.push(data);
            }
            if (this.screenwriters) {
                const data = `${t('screenwriters')}: ${this.screenwriters.join(', ')}`;
                metadataArray.push(data);
            }

            if (this.cast) {
                const data = `${t('cast')}: ${this.cast.join(', ')}`;
                metadataArray.push(data);
            }
            if (this.composers) {
                const data = `${t('composers')}: ${this.composers.join(', ')}`;
                metadataArray.push(data);
            }
            if (this.cinematographers) {
                const data = `${t('cinematographers')}: ${this.cinematographers.join(', ')}`;
                metadataArray.push(data);
            }

            if (this.sound) {
                const data = `${t('sound')}: ${this.sound}`;
                metadataArray.push(data);
            }
            if (this.subtitles) {
                const subtitles = this.subtitles.map((subtitle) => subtitle.name.toUpperCase());
                const data = `${t('subtitles')}: ${_.uniq(subtitles).join(', ')}`;
                metadataArray.push(data);
            }
        }
        return metadataArray;
    }

    public getDurationWithText(): string {
        let data = '';
        if (this.duration !== undefined) {
            if (this.duration < SECONDS_IN_MINUTE) {
                data = t('duration.seconds', { seconds: this.duration });
            } else {
                data = t('duration.minutes', { minutes: this.getDuration() });
            }
        }
        return data;
    }

    public getEpisodeNumber(): number {
        return this.episodeNumber;
    }

    public getEpisodeTitle(): string {
        return this.episodeTitle;
    }

    public getSeasonNumber(): number {
        return this.seasonNumber;
    }

    public getGallery() {
        return this.gallery;
    }

    public getStaffCollections() {
        return this.staffCollections;
    }

    public getStaffCollectionId(): number | undefined {
        return this.staffCollections?.length ? this.staffCollections[0].id : undefined;
    }

    public getStaffCollectionName(): string | undefined {
        return this.staffCollections?.length ? this.staffCollections[0].name : undefined;
    }

    public getStaffCollectionsGallery() {
        return this.staffCollections?.length ? this.staffCollections[0].gallery : undefined;
    }

    public getGrantExpression(): string {
        return this.grantExpression;
    }
}
