import { useMemo } from 'react';
import useGetChannelsCurrentProgram from '@/api/navigation/channels/use-get-channel-list-current-program';
import useSearchContent from '@/api/navigation/use-search-content';
import { buildElementsList } from '@/helpers/build-elements-list.helper';
import { getProgramParams } from '@/helpers/get-program-params.helper';

const { resultsLimit } = process.env.pages.search;

export const useSearchResults = (query: string) => {
    const { data, isFetching: isFetchingSearchContent } = useSearchContent({
        query,
        limit: resultsLimit,
    });

    const currentProgramParams = useMemo(() => {
        if (data) {
            return getProgramParams(data);
        }

        return false;
    }, [data]);

    const { data: channelsProgram, isFetching: isFetchingProgram } =
        useGetChannelsCurrentProgram(currentProgramParams);

    const gridElements = useMemo(() => {
        const channelsProgramsList = channelsProgram.getResults();

        if (data) {
            return buildElementsList(data, channelsProgramsList, { showDetailedMetadata: true });
        }

        return [];
    }, [data, channelsProgram]);

    return {
        gridElements,
        isFetching: isFetchingSearchContent || isFetchingProgram,
    };
};
