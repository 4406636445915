import React, { useMemo } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { buildCollectionsListConfig } from '@/helpers/pages/build-collections-list-config.helper';
import { RecommendationView } from '@/components/views/common';
import { useExitAppConfirmation } from '@/hooks/use-exit-app-confirmation';
import { useGetCollectionsList } from '@/api/navigation/recommendations/use-get-collections-list';

const Live = () => {
    const live = process.env.pages.live;
    const { placeType } = live;
    const listsParams = { place: { type: placeType, value: '' } };
    const collectionsConfig = useMemo(() => buildCollectionsListConfig(live), [live]);
    const { collectionsList, isFetching: isFetchingCollectionsList } = useGetCollectionsList(
        collectionsConfig,
        listsParams,
    );
    useExitAppConfirmation(true);

    return (
        <BaseLayout>
            <RecommendationView
                config={collectionsConfig}
                params={listsParams}
                collectionsList={collectionsList}
                isFetchingCollectionsList={isFetchingCollectionsList}
            />
        </BaseLayout>
    );
};

export default Live;
