import { SelectedOfferDataInterface } from '@/interfaces/payments.interface';
import { PrePurchaseOfferModel } from '@/models/payments/pre-purchase-offer.model';
import { ProductOfferModel } from '@/models/products/product-offer.model';
import { useRouter } from '@/routing';
import { useEffect, useState } from 'react';

export const useDefaultSelectedOfferData = (
    offers: (PrePurchaseOfferModel | ProductOfferModel)[],
) => {
    const { query } = useRouter();
    const [defaultSelectedOfferData, setDefaultSelectedOfferData] =
        useState<SelectedOfferDataInterface>();

    useEffect(() => {
        if (!offers) {
            return;
        }

        const { selectOfferId, selectOfferPricingPlanId } = query;
        const offer = offers.find((offer) => offer.getId() === selectOfferId);

        if (offer) {
            const offerId = offer.getId();
            const offerPricingPlan = offer.findOfferPricingPlan(selectOfferPricingPlanId);
            const offerPricingPlanId = offerPricingPlan?.getId();

            setDefaultSelectedOfferData({
                selectedOfferId: offerId,
                selectedOfferPricingPlanId: offerPricingPlanId,
            });

            return;
        }

        const firstOffer = offers[0];

        if (firstOffer) {
            const firstOfferId = firstOffer.getId();
            const firstOfferPricingPlan = firstOffer.getOfferPricingPlans()[0];
            const firstOfferPricingPlanId = firstOfferPricingPlan?.getId();

            setDefaultSelectedOfferData({
                selectedOfferId: firstOfferId,
                selectedOfferPricingPlanId: firstOfferPricingPlanId,
            });
        }
    }, [offers, query]);

    return defaultSelectedOfferData;
};
