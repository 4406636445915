import React, { useEffect, useMemo, useState } from 'react';
import { formatNumericalDuration } from '@/helpers/date.helper';
import { PlaybackTimeContainer } from './styles';
import { IPlaybackTimeProps } from './types';
import t from '@/lib/i18n';
import { usePlayerStatus } from '@/hooks/player/use-player-status';
import { usePlayerTimeMarkers } from '@/hooks/player/use-player-time-markers';
import { useTimeshiftingDelta } from '@/hooks/player/use-timeshifting-delta';
import { usePlayerGuiStateContext } from '@/hooks/player/use-player-gui-state';
import { useDebouncedEffect } from '@/hooks/use-debounced-effect.hook';
import { usePlayerSkipRange } from '@/hooks/player/use-player-skip-range';
import { useIsLive } from '@/hooks/player/use-is-live';

export const AbsolutePlaybackTime = () => {
    const [currentFormatted, setCurrentFormatted] = useState<string>('');
    const [endFormatted, setEndFormatted] = useState<string>('');

    const {
        playback: { duration },
    } = usePlayerStatus();
    const {
        skipRange: { end },
        currentTime,
    } = usePlayerTimeMarkers();
    const { tmpPlaybackCurrentTime } = usePlayerGuiStateContext();

    const endTime = useMemo(() => {
        return end ?? duration;
    }, [end, duration]);

    const withHours = useMemo(() => {
        return endTime >= 60 * 60;
    }, [endTime]);

    useEffect(() => {
        setCurrentFormatted(formatNumericalDuration(tmpPlaybackCurrentTime || 0, withHours));
    }, [tmpPlaybackCurrentTime, withHours]);

    useEffect(() => {
        setCurrentFormatted(formatNumericalDuration(currentTime || 0, withHours));
    }, [currentTime, withHours]);

    useEffect(() => {
        setEndFormatted(formatNumericalDuration(endTime || 0, withHours));
    }, [endTime, withHours]);

    return (
        <PlaybackTimeContainer>
            {currentFormatted} / {endFormatted}
        </PlaybackTimeContainer>
    );
};

export const RelativePlaybackTime = () => {
    const { updatePlaybackTimeDelay } = process.env.player;
    const [stopUpateRealTime, setStopUpdateRealTime] = useState<boolean>(false);
    const { calculateDeltaTime } = useTimeshiftingDelta();
    const { live } = useIsLive();

    const { start, end } = usePlayerSkipRange();
    const [currentTimeData, setCurrentTimeData] = useState<number>(0);
    const { tmpPlaybackCurrentTime } = usePlayerGuiStateContext();

    useEffect(() => {
        setStopUpdateRealTime(true);
        const currentTimeData = Math.abs(start + (end - start - tmpPlaybackCurrentTime));
        setCurrentTimeData(currentTimeData);
    }, [tmpPlaybackCurrentTime]);

    useEffect(() => {
        if (!stopUpateRealTime) {
            setCurrentTimeData(calculateDeltaTime());
        }
    }, [calculateDeltaTime, stopUpateRealTime]);

    useDebouncedEffect(
        () => {
            setStopUpdateRealTime(false);
        },
        { timeout: updatePlaybackTimeDelay },
        [tmpPlaybackCurrentTime],
    );

    return (
        <>
            {currentTimeData > process.env.player.seek.isLiveDelta ? (
                <PlaybackTimeContainer fixedWidth>
                    {'- '}
                    {formatNumericalDuration(currentTimeData || 0, true)}
                </PlaybackTimeContainer>
            ) : (
                <PlaybackTimeContainer fixedWidth live={live}>
                    {t('player.live')}
                </PlaybackTimeContainer>
            )}
        </>
    );
};

export const PlaybackTime = ({ relative, live }: IPlaybackTimeProps) => {
    if (live) {
        return <PlaybackTimeContainer live>{t('player.live')}</PlaybackTimeContainer>;
    }
    if (relative) {
        return <RelativePlaybackTime />;
    }
    return <AbsolutePlaybackTime />;
};
