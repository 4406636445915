import styled from 'styled-components';

export const ListElementTopMarkingsWrapper = styled.div`
    display: flex;
    position: absolute;
    gap: 0.2rem;
    z-index: 1;
    top: 0.9rem;
    left: 0.9rem;
    flex-direction: row;

    svg {
        width: 2.7rem;
        height: 2.6rem;
        transition: fill 0.1s linear;
        background: #070708b2;

        :first-child {
            border-radius: 0.4rem 0 0 0.4rem;
        }

        :last-child {
            border-radius: 0 0.4rem 0.4rem 0;
        }

        :only-child {
            border-radius: 0.4rem;
        }
    }
`;

export const ListElementBottomMarkingsWrapper = styled.div`
    position: absolute;
    background: #ffc700;
    bottom: 0.9rem;
    left: 0.9rem;
    border-radius: 0.4rem;
    padding: 0 0.6rem;
    min-height: 2.6rem;
    line-height: 2.6rem;
    font-family: 'Barlow Condensed', serif;
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
`;
