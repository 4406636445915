import React, { useMemo } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { ChannelsInitialProps } from '@/components/views/common/types';
import { buildCatalogConfig } from '@/helpers/pages/build-catalog-config.helper';
import { useExitAppConfirmation } from '@/hooks/use-exit-app-confirmation';
import { buildCollectionsListConfig } from '@/helpers/pages/build-collections-list-config.helper';
import { ChannelsCombinedView } from './ChannelsCombinedView';
import { useGetCollectionsList } from '@/api/navigation/recommendations/use-get-collections-list';

const Channels = (props: ChannelsInitialProps) => {
    const channels = process.env.pages.channels;
    const { placeType } = channels;
    const collectionsConfig = useMemo(() => buildCollectionsListConfig(channels), [channels]);
    const catalogConfig = useMemo(() => buildCatalogConfig(placeType, {}), [props]);
    const collectionsParams = { place: { type: placeType, value: '' } };
    const { collectionsList, isFetching: isFetchingCollectionsList } = useGetCollectionsList(
        collectionsConfig,
        collectionsParams,
    );
    useExitAppConfirmation(true);

    return (
        <BaseLayout defaultBg>
            <ChannelsCombinedView
                collectionsConfig={collectionsConfig}
                catalogConfig={catalogConfig}
                collectionsParams={collectionsParams}
                collectionsList={collectionsList}
                isFetchingCollectionsList={isFetchingCollectionsList}
            />
        </BaseLayout>
    );
};

export default Channels;
