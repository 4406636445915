import { Nav } from 'nav-tree';
import React, { useContext, useState } from 'react';
import { Icon, ICONS } from '@/components/shared/Icon';
import { ReferrerContext } from '@/contexts/referrer/referrer.context';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { AddProfileCircleWrapper, IconWrapper, AddProfileCircleText } from './styles';

export const AddProfileCircle = () => {
    const { setReferrer } = useContext(ReferrerContext);
    const [active, setActive] = useState<boolean>(false);

    const onAddProfile = () => {
        setReferrer(ROUTES.ACCOUNT_CHANGE_PROFILE);
        Router.pushRoute(ROUTES.PROFILE_ADD);
    };

    return (
        // @ts-ignore
        <Nav
            onEnterDown={onAddProfile}
            onClick={onAddProfile}
            data-testing={'profile_add'}
            autofocusOn={['mouseenter', 'focus']}
            onNav={setActive}
        >
            <AddProfileCircleWrapper $active={active}>
                <IconWrapper>
                    <Icon name={ICONS.PLUS} />
                </IconWrapper>
                <AddProfileCircleText>{t('button.profile.add-profile')}</AddProfileCircleText>
            </AddProfileCircleWrapper>
        </Nav>
    );
};
