import React, { useContext, useEffect, useMemo } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { CombinedView } from '@/components/views/common';
import { useGetCategoryId } from '@/api/navigation/category/use-get-category-id';
import { buildCollectionsListConfig } from '@/helpers/pages/build-collections-list-config.helper';
import { buildCatalogConfig } from '@/helpers/pages/build-catalog-config.helper';
import { useExitAppConfirmation } from '@/hooks/use-exit-app-confirmation';
import { useGetCollectionsList } from '@/api/navigation/recommendations/use-get-collections-list';
import { PageContext } from '@/contexts/page/page.context';
import { RecoContext } from '@/contexts/reco/reco.context';

const CategoryIndex = () => {
    const category = process.env.pages.category;
    const { placeType } = category;
    const { categoryId } = useGetCategoryId();

    const listsParams = !!categoryId && { place: { type: placeType, value: `${categoryId}` } };

    const collectionsConfig = useMemo(() => buildCollectionsListConfig(category), [category]);
    const catalogConfig = useMemo(() => buildCatalogConfig(placeType, { catid: categoryId }), [
        categoryId,
        placeType,
    ]);
    const { collectionsList, isFetching: isFetchingCollectionsList } = useGetCollectionsList(
        collectionsConfig,
        listsParams,
    );

    useExitAppConfirmation(true);

    return (
        <BaseLayout>
            <CombinedView
                collectionsConfig={collectionsConfig}
                catalogConfig={catalogConfig}
                collectionsParams={listsParams}
                showCatalog={!!categoryId}
                collectionsList={collectionsList}
                isFetchingCollectionsList={isFetchingCollectionsList}
            />
        </BaseLayout>
    );
};

export default CategoryIndex;
