import { useConfiguration } from '@/contexts/configuration/configuration.hooks';
import { FmcContext } from '@/contexts/fmc/fmc.context';
import { buildCollectionCatalogCollection } from '@/helpers/pages/build-collection-catalog-collection.helper';
import { updateCatalogParams } from '@/helpers/pages/update-catalog-params.helper';
import { usePaginatedGrid } from '@/hooks/page-catalog/use-paginated-grid.hook';
import { useCatalogParams } from '@/hooks/use-catalog-params.hook';
import {
    StaffCollectionContentResultsModel,
    StaffCollectionContentSchema,
} from '@/models/collection/staff-collection-content-results.model';
import { ListElementModel } from '@/models/list-element/list-element.model';
import { CategoryFetcherConfig } from '@/types/page-catalog.type';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useGetStaffCollectionWithFlatNavigation from './use-get-collection-flat-navigation';
import useGetStaffCollectionContent from './use-get-staff-collection-content';
import { DATA_POSITION } from '@/types/list-element-metadata.type';

const { col, postersCol } = process.env.grid;
const FETCHED_DATA_EFFICIENCY_TRESHOLD = 0.5;

export const useGetCollectionCatalogData = ({
    catalogParams,
    withPagination,
    fallbackMetadataPosition,
}: CategoryFetcherConfig) => {
    const configuration = useConfiguration();

    const movieCategoryId = configuration?.navigation?.movieCategoryId;
    const collectionParams = { collectionId: catalogParams.collectionId as number };
    const { data, isFetching: isFetchingFilterList } =
        useGetStaffCollectionWithFlatNavigation(collectionParams);

    const titleName = useMemo(() => data.getName() || '', [data]);
    const filterLists = useMemo(() => data.getFlatNavigation().filterLists || [], [data]);

    const { followMeContent } = useContext(FmcContext);

    const { params, setParams, clearGrid, setClearGrid } = useCatalogParams({
        filterLists,
        withPagination,
    });
    const [gridElements, setGridElements] = useState<ListElementModel[]>([]);
    const [columns, setColumns] = useState<number>(movieCategoryId ? postersCol : col);

    const paginationParams = { ...collectionParams, filters: params.filters };

    const fetcher = useGetStaffCollectionContent;

    const { data: gridData, isFetching: isFetchingGridData, error } = fetcher(paginationParams);
    const total = useMemo(() => 3, []);
    const fetchedAll = useMemo(() => params.offset + params.limit >= total, [params, total]);
    const categoryData: StaffCollectionContentResultsModel[] = useMemo(
        () =>
            gridData
                .getResults()
                .map(
                    (el: StaffCollectionContentSchema) =>
                        new StaffCollectionContentResultsModel(el),
                ),
        [gridData],
    );
    const shouldIncreaseLimit =
        !fetchedAll && categoryData?.length / params.limit < FETCHED_DATA_EFFICIENCY_TRESHOLD;

    useEffect(() => {
        if (!isFetchingGridData) {
            const dataPosition = (DATA_POSITION.UNDER || fallbackMetadataPosition) ?? undefined;

            const { newCollection, columns } = buildCollectionCatalogCollection(
                gridData,
                dataPosition,
                followMeContent,
            );

            setColumns(columns);

            if (newCollection?.length) {
                const clear = clearGrid || withPagination;
                setGridElements(clear ? newCollection : (prev) => [...prev, ...newCollection]);
            }

            setClearGrid(false);
        }
    }, [isFetchingGridData, gridData]);

    useMemo(() => {
        // odśwież grida pomiędzy widokami subkategorii
        setGridElements([]);
    }, [catalogParams]);

    const onFetchMore = useCallback(
        () => setParams(updateCatalogParams({ params, total, shouldIncreaseLimit })),
        [catalogParams, params, total, shouldIncreaseLimit],
    );

    const paginatedData = usePaginatedGrid({ data: categoryData });
    const isFetching = isFetchingGridData || isFetchingFilterList;
    return {
        gridElements: withPagination ? paginatedData : gridElements,
        columns,
        filterLists,
        isFetching,
        fetchedAll,
        onFetchMore,
        error,
        titleName,
    };
};
