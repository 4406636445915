import React, { useMemo } from 'react';
import { AccountMenuItem } from './AccountMenuItem';
import { accountMenuItems } from './menu-data';
import { AccountMenuContainerStyled, NavContainerStyled } from './styles';
import { AccountMenuProps } from './types';
import { restoreFocusVertical } from '@/helpers/nav-tree/helpers';
import { useExitAppConfirmation } from '@/hooks/use-exit-app-confirmation';
import {
    filterExcludedItems,
    filterPlusZone,
    filterOnetrust,
} from '@/helpers/account/filter-account-items.helper';
import { usePlatform } from '@/contexts/platform/platform.hooks';
import { GM_OS } from '@/types/platform.type';
import { MenuItemData } from '@/types/account-menu-items.type';

const AccountMenuRaw = ({ selectedRoute }: AccountMenuProps) => {
    useExitAppConfirmation(true);
    const { platform } = usePlatform();
    const { os } = platform;
    const filteredMenuItems = useMemo(
        () =>
            accountMenuItems
                .filter(filterExcludedItems)
                .filter(filterPlusZone)
                .filter(
                    (item: MenuItemData) =>
                        (os && [GM_OS.Tizen, GM_OS.WebOS].includes(os)) || filterOnetrust(item),
                ),
        [],
    );

    const focusedNode = useMemo(() => {
        const index = filteredMenuItems.findIndex((item) => item.url === selectedRoute);
        return index > -1 ? `${index + 1}` : undefined;
    }, [filteredMenuItems, selectedRoute]);
    return (
        <AccountMenuContainerStyled>
            <NavContainerStyled
                func={(navKeys: any, navTree: any) =>
                    restoreFocusVertical(navKeys, navTree, focusedNode)
                }
            >
                {filteredMenuItems.map((menuItemData, index) => {
                    return (
                        <AccountMenuItem
                            key={index}
                            label={menuItemData.label}
                            url={menuItemData.url}
                            data-testing={menuItemData['data-testing']}
                            isSelected={menuItemData.url === selectedRoute}
                            handleClickFn={menuItemData.handleClickFn}
                        />
                    );
                })}
            </NavContainerStyled>
        </AccountMenuContainerStyled>
    );
};

export const AccountMenu = React.memo(AccountMenuRaw);
