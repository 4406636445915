import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { botDetector } from '@/helpers/seo/bot-detector.helper';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { useEffect } from 'react';

export const useNotFound = ({ notFound }: { notFound: boolean }) => {
    const { isBot } = botDetector();
    const isDuringAutoLogin = useUserModule().isDuringAutoLogin();

    useEffect(() => {
        if (isDuringAutoLogin) return;

        if (notFound && !isBot) {
            Router.replaceRoute(ROUTES.NOT_FOUND);
        }
    }, [notFound, isDuringAutoLogin]);
};
