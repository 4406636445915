import React from 'react';
import t from '@/lib/i18n';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { IconGroup, Metadata, METADATA_TYPES } from '@/components/shared/Metadata';
import { GUTTER } from '@/types/gutter.type';
import {
    MetadataContainer,
    PlatformsContainer,
    PlatformsMetadataContainer,
    VerticalLine,
    MainTextContainer,
} from './styles';
import { PacketDetailsProps } from './types';
import { ICONS } from '@/components/shared/Icon';

const FULL_DETAILS_TITLE_LINES = process.env.packetDetails.fullDetailsTitleLines;

export const PacketFullDetailsContent = ({ packet }: PacketDetailsProps) => {
    const showVerticalLine =
        packet.platforms.length > 0 && packet.metadata.length > 0 && packet.locationRestrictionText;

    return (
        <>
            <MainTextContainer>
                <Metadata data={t('packet')} type={METADATA_TYPES.PRIMARY} />
                <MainText
                    text={packet.name}
                    mode={MAINTEXT_KIND.TITLE}
                    lines={FULL_DETAILS_TITLE_LINES}
                    gutter={GUTTER.SMALL}
                />
                <MainText
                    text={packet.description}
                    mode={MAINTEXT_KIND.DESCRIPTION}
                    lines={packet.fullDescriptionLines}
                />
            </MainTextContainer>

            <PlatformsMetadataContainer>
                <PlatformsContainer>
                    <IconGroup icon={ICONS.PACKET} iconSize={2.9} noMargin />

                    {packet.platforms.map((icon, index: number) => {
                        const isStbIcon = icon === ICONS.STB;
                        const iconSize = isStbIcon ? 1.8 : 2.9;

                        return <IconGroup key={index} icon={icon} iconSize={iconSize} noMargin />;
                    })}
                </PlatformsContainer>

                <MetadataContainer>
                    {packet.locationRestrictionText && (
                        <Metadata
                            data={packet.locationRestrictionText}
                            type={METADATA_TYPES.SECONDARY}
                        />
                    )}

                    {packet.metadata.length > 0 && !packet.buyAccessText && (
                        <>
                            {showVerticalLine && <VerticalLine />}
                            <Metadata
                                data={packet.metadata()}
                                type={METADATA_TYPES.SECONDARY}
                                isSafeHTML
                            />
                        </>
                    )}
                </MetadataContainer>
            </PlatformsMetadataContainer>
        </>
    );
};
