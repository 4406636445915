import { useHelpConfigurationContext } from '@/contexts/customer-care/helpConfiguration.context';
import { findHelpConfigParamsByRoute } from '@/helpers/pages/customer-care/build-config-help-page-params.helper';
import { useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';
import { useRouterQuery } from '../use-router-query.hook';

export const useGetAccurateParamsForHelpConfig = (currentPage?: ROUTES) => {
    const { helpConfiguration, paymentType } = useHelpConfigurationContext();
    const { route, query } = useRouter();

    const { step: paymentStep }: any = useRouterQuery();

    const id =
        (query.vodId as string) ||
        (query.id as string) ||
        (query.channelId as string) ||
        (query.eventId as string) ||
        (query.tvProgramId as string) ||
        (query.packetId as string) ||
        '';
    const { contextType, contextValue, showButton } = findHelpConfigParamsByRoute(
        currentPage || (route as ROUTES),
        helpConfiguration,
        id, //id konkretnego materiału
        paymentStep,
        paymentType,
    );
    return { contextType, contextValue, showButton };
};
