import { ShadeStyled } from '@/components/shared/BackgroundImage/styles';
import { SHADE_MODE } from '@/components/shared/BackgroundImage/types';
import { PlayableButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { navHorizontal } from 'nav-tree';
import { StartPlayFMCContainer, CloseButtonWrapper, FlexItem, NavFlexBox } from './styles';
import { StartPlayFMCButtonsProps } from './types';

export const StartPlayFMCButtons = ({
    actionKeepWatching,
    actionBeginWatching,
    isVisible,
}: StartPlayFMCButtonsProps) => {
    return (
        <NavFlexBox restoreLastFocus>
            {isVisible && (
                <FlexItem allowOverflow>
                    <CloseButtonWrapper>
                        <PlayableButton
                            icon={ICONS.X}
                            onButtonAction={() => Router.back()}
                            title={t('WCAG.aria-label.close')}
                        />
                    </CloseButtonWrapper>
                </FlexItem>
            )}
            <StartPlayFMCContainer func={navHorizontal}>
                <FlexItem allowOverflow paddingRight="4rem">
                    <PlayableButton
                        text={t('player.keep-watching')}
                        onButtonAction={actionKeepWatching}
                        icon={ICONS.PLAY_FORWARD}
                        defaultFocused
                    />
                </FlexItem>
                <FlexItem allowOverflow>
                    <PlayableButton
                        text={t('player.begin-watching')}
                        onButtonAction={actionBeginWatching}
                        icon={ICONS.SPIN_LEFT}
                    />
                </FlexItem>
            </StartPlayFMCContainer>
            <ShadeStyled mode={isVisible ? SHADE_MODE.FULL : SHADE_MODE.NONE} />
        </NavFlexBox>
    );
};
