import { ProcessingLoader } from '@/components/shared/Loader';
import { useLoginRulesLoader } from '@/hooks/auth/use-login-rules-loader.hook';
import React from 'react';
import { RuleContainer } from './RuleContainer';
import t from '@/lib/i18n';
import { PlayableButton } from '@/components/shared/Buttons';
import { PageContainer } from '@/components/shared/FullscreenNavContainer';
import { Message } from '@/components/shared/Message';
import { ICONS } from '@/components/shared/Icon';
import { NavWrapper } from '@/components/views/Account/styles';
import { navVertical } from 'nav-tree';
import { ROUTES } from '@/routing/types';
import { Router } from '@/routing';

export const LoginRules = ({ obligatory }: { obligatory?: boolean }) => {
    const { rulesExists, currentRule, isFetching, error, onNextRule } =
        useLoginRulesLoader(obligatory);

    if (error) {
        const errorMessage =
            error.getUserMessage() || error.getMessage() || t('pages.login.error-description');

        return (
            <PageContainer>
                <Message
                    icon={ICONS.INFO_CIRCLE}
                    title={t('pages.login.error-header')}
                    description={errorMessage}
                />
                <NavWrapper func={navVertical}>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={() => {
                            Router.pushRoute(ROUTES.WELCOME);
                        }}
                        defaultFocused
                    />
                </NavWrapper>
            </PageContainer>
        );
    }

    if (rulesExists && currentRule) {
        return <RuleContainer rule={currentRule} onNextRule={onNextRule} />;
    }

    return <ProcessingLoader isFetching={isFetching || false} />;
};
