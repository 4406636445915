import { CheckSlowDevice } from '@/helpers/check-slow-device.helper';
import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const TVBottomBarContainer = styled(Nav as any)<{ showEPG: boolean; showChannels: boolean }>`
    position: relative;
    transform: translate(
        0,
        ${({ showEPG, showChannels }) => (showEPG ? '-3rem' : showChannels ? '14rem' : '54rem')}
    );
    transition: ${CheckSlowDevice('transform ease-out 0.2s')};
`;

export const NavButtons = styled(Nav as any)`
    display: flex;
    width: 100%;
    align-items: center;
    visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`;
