import React, { useEffect, useRef } from 'react';
import { Nav, navVertical, NAV_SCROLL_DIRECTION, NAV_SCROLL_MODE } from 'nav-tree';
import { LiveCollectionsMemo } from '@/components/views/Live';
import { CollectionsListOwnProps } from './types';
import { StyledCollectionsList } from './styles';
import { CollectionMemo } from './Collection';
import { usePageRefocus } from '@/hooks/nav-tree/use-page-refocus.hook';

const CollectionsList = ({
    params,
    loadedRowIndex,
    onLoadedData,
    onActiveItem,
    onActiveListItem,
    onActiveRow,
    onNoLists,
    config = {},
    collectionsList,
    isFetchingCollectionsList,
}: CollectionsListOwnProps) => {
    const { defaultFocused = true, singleCollection, liveList, withPackets } = config;
    const noData =
        !collectionsList.length && !liveList && !withPackets && !isFetchingCollectionsList;

    useEffect(() => {
        if (noData) {
            onNoLists?.();
        }
    }, [noData]);

    const focusRef = useRef<Nav>(null);
    usePageRefocus({ focusRef });

    const buildVerticalNavigation = () => {
        const scrollMode = singleCollection ? NAV_SCROLL_MODE.CENTER : NAV_SCROLL_MODE.START;
        const loadLiveList = liveList && loadedRowIndex >= collectionsList.length;

        return (
            <StyledCollectionsList
                ref={focusRef}
                func={navVertical}
                scrollOptions={{
                    isScrollable: true,
                    direction: NAV_SCROLL_DIRECTION.VERTICAL,
                    mode: scrollMode,
                }}
                restoreLastFocus
                defaultFocused={defaultFocused}
            >
                {collectionsList.map(
                    (list, rowIndex) =>
                        loadedRowIndex >= rowIndex && (
                            <CollectionMemo
                                key={list.getId()}
                                params={params}
                                onLoadedData={onLoadedData}
                                onActiveItem={onActiveItem}
                                onActiveListItem={onActiveListItem}
                                onActiveRow={onActiveRow}
                                rowIndex={rowIndex}
                                list={list}
                                config={config}
                            />
                        ),
                )}

                {loadLiveList && (
                    <LiveCollectionsMemo
                        isFetchingLists={isFetchingCollectionsList}
                        collectionsListLength={collectionsList.length}
                        onActiveItem={onActiveItem}
                        onLoadedData={onLoadedData}
                        loadedRowIndex={loadedRowIndex}
                    />
                )}
            </StyledCollectionsList>
        );
    };

    if (noData) return null;

    return buildVerticalNavigation();
};

export const CollectionsListMemo = React.memo(CollectionsList);
