import { ICONS } from '@/components/shared/Icon';
import { AssetInterface, goToDetailsOptionsType } from '@/interfaces/asset.interface';
import {
    GetMultipleProductsOut,
    ImageSourceSchema,
    MinPriceSchema,
    OfferIdSchema,
    OfferListItemSchema,
    ProductIdSchema,
} from '@/interfaces/from-schemas/navigation/getMultipleProductsOut';
import { ListElementImageInterface } from '@/interfaces/list-element.interface';
import { ProductIdInterface } from '@/interfaces/product-id-interface';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { MULTIPLE_PRODUCTS_SUBTYPE, PRODUCT_SUBTYPES, PRODUCT_TYPES } from '@/types/media.type';
import { backgroundUtil } from '@/utils/background.utils';
import { coverUtil } from '@/utils/cover.util';

export class MultipleTypeProductModel implements AssetInterface {
    public imageDisplayMode = IMAGE_DISPLAY_MODE.THUMBNAILS;

    private readonly id: string = '';
    private readonly type: string = PRODUCT_TYPES.MULTIPLE;
    private readonly subType: MULTIPLE_PRODUCTS_SUBTYPE | PRODUCT_SUBTYPES | '' = '';
    private readonly name: string = '';
    private readonly description: string = '';
    private readonly thumbnails: ImageSourceSchema[] = [];
    private readonly posters: ImageSourceSchema[] = [];
    private readonly partners: string[] = [];
    private readonly platforms: string[] = [];
    private readonly licenseEndDate: string = '';
    private readonly licenseLocation: string = '';
    private readonly licenseLocationText: string = '';
    private readonly accessText: string = '';
    private readonly purchaseAvailabilityText: string = '';
    private readonly offers: OfferListItemSchema[] = [];
    private readonly relatedProducts: ProductIdSchema[] = [];
    private readonly minPrice: MinPriceSchema[] = [];
    private readonly minPriceOffers: OfferIdSchema[] = [];
    private readonly backgroundSrc?: string;
    private readonly gallery: GetMultipleProductsOut[0]['gallery'];
    private readonly grantExpression: string = '';

    constructor(definition: GetMultipleProductsOut[number]) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });

            this.backgroundSrc = backgroundUtil(this.thumbnails)?.src;
            // TODO zmiana wycofana ze względów biznesowych, w przyszłości zostanie to usunięte https://jira.polsatc/browse/SWN-4886
            if (definition.posters) {
                this.imageDisplayMode = IMAGE_DISPLAY_MODE.POSTERS;
            }
        }
    }

    public getId(): string {
        return this.id;
    }

    public getType(): string {
        return this.type;
    }

    public getSubType(): MULTIPLE_PRODUCTS_SUBTYPE | PRODUCT_SUBTYPES | '' {
        return this.subType;
    }

    public getTitle(): string {
        return this.name;
    }

    public getDescription(): string {
        return this.description;
    }

    public getMinPrice(): MinPriceSchema[] {
        return this.minPrice;
    }

    public getMinPriceOffers(): OfferIdSchema[] {
        return this.minPriceOffers;
    }

    public getOffers(): OfferListItemSchema[] {
        return this.offers;
    }

    public getPlatforms(): string[] {
        return this.platforms;
    }

    public getThumbnails(): ImageSourceSchema[] {
        return this.thumbnails;
    }

    public getPosters(): ImageSourceSchema[] {
        return this.posters;
    }

    public getPartners(): string[] {
        return this.partners;
    }

    public getLicenseEndDate(): string {
        return this.licenseEndDate;
    }

    public getLicenseLocation(): string {
        return this.licenseLocation;
    }

    public getLicenseLocationText(): string {
        return this.licenseLocationText;
    }

    public getAccessText(): string {
        return this.accessText;
    }

    public getPurchaseAvailabilityText(): string {
        return this.purchaseAvailabilityText;
    }

    public getRelatedProducts(): ProductIdSchema[] {
        return this.relatedProducts;
    }

    public getMetadata(): string {
        return 'Pakiet';
    }

    public getButtonText(): string {
        const type = this.getSubType();

        switch (type) {
            case PRODUCT_SUBTYPES.PACKET:
                return t('partnership-model.register-code.packet-details');
            case PRODUCT_SUBTYPES.LIVE:
                return t('partnership-model.register-code.check');
            default:
                return t('partnership-model.register-code.watch');
        }
    }

    public getMediaIconType(): ICONS {
        const type = this.getSubType();

        switch (type) {
            case PRODUCT_SUBTYPES.PACKET:
                return ICONS.PACKET;
            case PRODUCT_SUBTYPES.LIVE:
                return ICONS.MEDIA_TYPE_LIVE;
            default:
                return ICONS.MEDIA_TYPE_VOD;
        }
    }

    public getImage(): ListElementImageInterface {
        const images =
            this.imageDisplayMode === IMAGE_DISPLAY_MODE.POSTERS
                ? this.getPosters()
                : this.getThumbnails();
        const image = coverUtil(images, this.imageDisplayMode);

        return {
            src: image ? image.src : '',
            displayMode: this.imageDisplayMode,
        };
    }

    public getWatchRoute(): ROUTES {
        switch (this.getSubType()) {
            case PRODUCT_SUBTYPES.LIVE:
                return ROUTES.LIVE_WATCH;
            case PRODUCT_SUBTYPES.MOVIE:
                return ROUTES.VOD_MOVIE_WATCH;
            default:
                return ROUTES.NOT_FOUND;
        }
    }

    public getDetailsRoute(): ROUTES | undefined {
        switch (this.getSubType()) {
            case PRODUCT_SUBTYPES.LIVE:
                return ROUTES.LIVE_DETAILS;
            case PRODUCT_SUBTYPES.MOVIE:
                return ROUTES.VOD_MOVIE_DETAILS;
            case PRODUCT_SUBTYPES.PACKET:
                return ROUTES.PACKET_DETAILS;
            default:
                return;
        }
    }

    public getRouteParams(): { [key: string]: any } {
        const id = this.getId();
        const title = this.getTitle();

        switch (this.getSubType()) {
            case PRODUCT_SUBTYPES.LIVE:
                return {
                    eventTitle: title,
                    eventId: id,
                };
            case PRODUCT_SUBTYPES.MOVIE:
                return { title, id };
            case PRODUCT_SUBTYPES.PACKET:
                return { packetId: id };
            default:
                return {};
        }
    }

    public getDetailsRouteParams() {
        const route = this.getDetailsRoute();

        if (route) {
            const params = this.getRouteParams();
            return { route, params };
        }
    }

    public goToDetails(options?: goToDetailsOptionsType) {
        const { replaceRoute } = options || {};
        const route = this.getDetailsRoute();

        if (route) {
            const params = this.getRouteParams();
            replaceRoute ? Router.replaceRoute(route, params) : Router.pushRoute(route, params);
        }
    }

    public getBackgroundSrc(): string | undefined {
        return this.backgroundSrc;
    }

    public getProductId(): ProductIdInterface {
        const { id, type, subType } = this;

        return {
            id,
            type: type as PRODUCT_TYPES,
            subType: subType as PRODUCT_SUBTYPES,
        };
    }

    public getGallery() {
        return this.gallery;
    }

    public getGrantExpression(): string {
        return this.grantExpression;
    }
}
