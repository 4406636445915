import { IMAGE_DISPLAY_MODE, ImageInterface } from '@/types/cover-utils.type';

export enum LIST_TYPE {
    PROMOBOX = 'promobox',
    SIMPLE = 'simple',
    CAROUSEL_WITH_TITLES = 'carousel_with_titles',
}

export type TitleAlignType = 'left' | 'center' | 'right';

export interface CustomData {
    background?: string;
    backgroundColor?: string;
    ctaButtonLabel?: string;
    cta_button_label?: string;
    imageDisplayMode?: IMAGE_DISPLAY_MODE;
    legal?: string;
    listElementTitleLines?: string;
    listElementTitlePosition?: string;
    logo?: {
        size: {
            width: number;
            height: number;
        };
        src: string;
    };
    showListTitle?: boolean;
    showListDescription?: boolean;
    thumbnails?: ImageInterface[];
    showMoreLabel?: string;
    showMoreUrl?: string;
    titleAlign?: TitleAlignType;
    titleType?: string;
    mainColor?: string;
    description?: string;
    gradient?: boolean;
    h2?: string;
    highlight?: boolean;
    highlightBorder?: boolean;
    price?: string;
    priceDescription?: string;
    displayImage?: boolean;
    sectionImage?: ImageInterface;
}
