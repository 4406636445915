import { ListElementImageInterface } from '@/interfaces/list-element.interface';
import { backgroundUtil } from '@/utils/background.utils';
import { AssetInterface, goToDetailsOptionsType } from '@/interfaces/asset.interface';
import {
    PacketSchema,
    ImageSchema,
    ImageSourceSchema,
} from '@/interfaces/from-schemas/navigation/getStaffRecommendationsListItemsOut';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { CPID } from '@/types/media.type';
import { coverUtil } from '@/utils/cover.util';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GetProductOut } from '@/interfaces/from-schemas/navigation/getProductOut';

export class PacketModel implements AssetInterface {
    public imageDisplayMode = IMAGE_DISPLAY_MODE.THUMBNAILS;

    private readonly id: string = '';
    private readonly cpid: CPID = CPID.PACKET;
    private readonly name: string = '';
    private readonly description: string = '';
    private readonly validFrom: string = '';
    private readonly validTo: string = '';
    private readonly images: ImageSchema[] = [];
    private readonly thumbnails: ImageSourceSchema[] = [];
    private readonly backgroundSrc?: string;
    private readonly gallery?: GetProductOut['gallery'];
    private readonly grantExpression: string = '';

    constructor(definition: PacketSchema) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });
        }

        this.backgroundSrc = backgroundUtil(this.thumbnails)?.src;
    }

    public getId(): string {
        return this.id;
    }

    public getCpid(): CPID {
        return this.cpid;
    }

    public getTitle(): string {
        return this.name;
    }

    public getDescription(): string {
        return this.description;
    }

    public getValidFrom(): string {
        return this.validFrom;
    }

    public getValidTo(): string {
        return this.validTo;
    }

    public getImages(): ImageSchema[] {
        return this.images;
    }

    public getThumbnails(): ImageSourceSchema[] {
        return this.thumbnails;
    }

    public isThumbnailsExists(): boolean {
        return this.getThumbnails().length > 0;
    }

    public getMetadata(): string {
        return 'Pakiet';
    }

    public getImage(): ListElementImageInterface {
        const images = this.getThumbnails();
        const image = coverUtil(images, this.imageDisplayMode);

        return {
            // TODO: brakuje funkcji do pobrania placeholdera
            src: image ? image.src : '',
            displayMode: this.imageDisplayMode,
        };
    }

    public buildPacketDetailsRouteParams() {
        return {
            packetId: this.id,
        };
    }

    public getDetailsRoute(): ROUTES {
        return ROUTES.PACKET_DETAILS;
    }
    public getRouteParams(): any {
        return this.buildPacketDetailsRouteParams();
    }

    public goToDetails(options?: goToDetailsOptionsType): void {
        const { replaceRoute } = options || {};
        const route = this.getDetailsRoute();
        const params = this.getRouteParams();

        replaceRoute ? Router.replaceRoute(route, params) : Router.pushRoute(route, params);
    }

    public getBackgroundSrc(): string | undefined {
        return this.backgroundSrc;
    }

    public getGenres(): string {
        return '';
    }

    public getGallery() {
        return this.gallery;
    }

    public getGrantExpression(): string {
        return this.grantExpression;
    }
}
