import { Nav, navHorizontal, NAVIGATION_KEYS, NavTree } from 'nav-tree';
import React, { useCallback } from 'react';

import { TIME_TO_HOUR } from '@/constants/epg-table';
import { useHourRangeContext } from '@/contexts/epg-table/hour-range.context';
import { formatDate } from '@/helpers/date.helper';
import { focusNavId } from '@/helpers/nav-tree/focus-nav-id.helper';
import { useEPGProgramRightPanel } from '@/hooks/nav-tree/use-epg-program-right-panel.hook';
import t from '@/lib/i18n';
import { BUTTON_ID } from '@/types/button-id.type';
import { FORMAT_DATE_MODE } from '@/types/date.type';

import { isTimeToHourKey } from '../helpers/epg-table.helpers';
import * as Styled from './styles';
import { EPGTableArrows } from './TableArrows';
import { EPGTimeFilterButtons } from './TimeFilterButtons';
import { EPGTableTopButtonsProps, FilterButtonAction } from './types';

const EPGTableTopButtonsRaw = ({ focusRef }: EPGTableTopButtonsProps) => {
    const { initialStartFullHour, setStartFullHour, date } = useHourRangeContext();
    const { showSubcategoriesMenu, handleSubcategoriesOpen } = useEPGProgramRightPanel({
        focusRef,
    });
    const currentDayText = formatDate(date.toISOString(), FORMAT_DATE_MODE.LIVE_ARABIC_FORMAT);
    const isToday = currentDayText === t('date.today');

    const handleFiltersPanel = useCallback(
        (isActive: boolean) => {
            if (isActive && showSubcategoriesMenu) {
                handleSubcategoriesOpen(false);
            }
        },
        [handleSubcategoriesOpen, showSubcategoriesMenu],
    );

    const handleBtnAction = useCallback(
        (action: FilterButtonAction) => {
            if (isTimeToHourKey(action)) {
                setStartFullHour(() => TIME_TO_HOUR[action]);
            } else if (action === 'now') {
                setStartFullHour(() => initialStartFullHour);
            } else if (action === 'dayPicker') {
                handleSubcategoriesOpen(true);
            }
        },
        [handleSubcategoriesOpen, setStartFullHour, initialStartFullHour],
    );

    const handleSubcategoryFocusOrNav = useCallback(
        (navKeys: NAVIGATION_KEYS, navTree: NavTree) => {
            if (showSubcategoriesMenu) {
                focusNavId(navTree, BUTTON_ID.DAY_PICKER_BTN);

                return;
            }

            return navHorizontal(navKeys, navTree);
        },
        [showSubcategoriesMenu],
    );

    return (
        // @ts-ignore
        <Nav func={handleSubcategoryFocusOrNav} navId="topButtonsNav">
            <Styled.TopButtonsWrapper key={`${isToday}`}>
                <EPGTimeFilterButtons
                    isToday={isToday}
                    handleBtnAction={handleBtnAction}
                    handleFiltersPanel={handleFiltersPanel}
                    date={date}
                />

                <EPGTableArrows position="top" />
            </Styled.TopButtonsWrapper>
        </Nav>
    );
};

export const EPGTableTopButtons = React.memo(EPGTableTopButtonsRaw);
