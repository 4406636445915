import React, { useCallback } from 'react';
import { VirtualScrollHorizontalSlider } from '@/components/shared/VirtualScrollHorizontalSlider';
import { ChannelModel } from '@/models/channels/channel.model';
import { EPGChannelTile } from './EPGChannelTile';
import { EPGChannelsSliderPresentationProps, EPGItemProps } from './types';
import { channelToNavId } from './helpers';
import { CHANNELS_SLIDER_WINDOW_WIDTH } from './consts';
import { ChannelTileContainer } from './styles';

const EPGChannelsSliderPresentationRaw = ({
    nowPlayingChannelId,
    navId,
    channels,
    onIsDefault,
    onBuyChannel,
    onInternalFocusedChannelId,
    onHasAccess,
    onNav,
}: EPGChannelsSliderPresentationProps) => {
    const renderItem = useCallback(
        (channel: ChannelModel, props: EPGItemProps) => {
            const onAction = () => {
                if (onHasAccess(channel)) {
                    channel.goToWatch(true);
                } else {
                    onBuyChannel(channel);
                }
            };

            return (
                <ChannelTileContainer
                    {...props}
                    onNav={(focused: any) => {
                        props.onNav(focused);
                        if (focused) {
                            onInternalFocusedChannelId(channel.getId());
                        }
                    }}
                    onEnterDown={onAction}
                    onClick={onAction}
                >
                    <EPGChannelTile
                        channel={channel}
                        nowPlayed={channel.getId() === nowPlayingChannelId}
                        hasAccess={onHasAccess(channel)}
                    />
                </ChannelTileContainer>
            );
        },
        [nowPlayingChannelId, onBuyChannel, onInternalFocusedChannelId, onHasAccess],
    );

    return (
        <VirtualScrollHorizontalSlider<ChannelModel>
            height="22rem"
            isDefault={onIsDefault}
            itemToNavId={channelToNavId}
            navId={navId}
            onNav={onNav}
            items={channels}
            windowWidth={CHANNELS_SLIDER_WINDOW_WIDTH}
            renderItem={renderItem}
        />
    );
};

export const EPGChannelsSliderPresentation = React.memo(EPGChannelsSliderPresentationRaw);
