import { useGetCategoryCatalogData } from '@/api/navigation/category/use-get-category-catalog-data';
import { useGetProductsCatalogData } from '@/api/packets/use-get-products-catalog-data';
import { GET_MEDIA_PLACE_TYPE } from '@/types/get-media-params.type';
import { useFetchFavorites } from '@/components/views/Favorites/use-fetch-favorites';
import { useFetchChannels } from '@/components/views/Channels/use-fetch-channels';
import useFetchPacketCollection from '@/components/browser/shared/PacketDetails/use-fetch-packet-collection.hook';
import { ADDITIONAL_TYPES, CatalogParamsType } from '@/types/page-catalog.type';
import { useGetCollectionCatalogData } from '@/api/navigation/collections/use-get-collection-catalog-data';

export const buildCatalogConfig = (
    placeType: GET_MEDIA_PLACE_TYPE | ADDITIONAL_TYPES,
    catalogParams: CatalogParamsType,
) => {
    const isMutable =
        placeType === GET_MEDIA_PLACE_TYPE.FAVORITES ||
        placeType === GET_MEDIA_PLACE_TYPE.CHANNELS_TV;
    const disableBg = process.env.grid.disableBg;

    const defaultConfig = {
        fetcher: useFetchChannels,
        catalogParams,
        withLists: true,
        withFilters: true,
        withNavButtons: true,
        isMutable,
        disableBg,
    };

    switch (placeType) {
        case GET_MEDIA_PLACE_TYPE.CATEGORY:
            return { ...defaultConfig, fetcher: useGetCategoryCatalogData };

        case GET_MEDIA_PLACE_TYPE.PACKETS:
            return { ...defaultConfig, fetcher: useGetProductsCatalogData };

        case GET_MEDIA_PLACE_TYPE.COLLECTION:
            return { ...defaultConfig, fetcher: useGetCollectionCatalogData };

        case ADDITIONAL_TYPES.PACKET:
            return {
                ...defaultConfig,
                fetcher: useFetchPacketCollection,
                withLists: false,
                withNavButtons: true,
                withPacketCategories: true,
            };

        case GET_MEDIA_PLACE_TYPE.CHANNELS_TV:
            return {
                ...defaultConfig,
                fetcher: useFetchChannels,
            };

        case GET_MEDIA_PLACE_TYPE.FAVORITES:
            return {
                ...defaultConfig,
                fetcher: useFetchFavorites,
                withLists: false,
            };

        default:
            return defaultConfig;
    }
};
