import { getBgMargin } from '@/components/shared/BackgroundImage/get-bg-margin';
import { BackgroundContext } from '@/contexts/background/background.context';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { getBackgroundImage, getBackgroundListImage } from '@/helpers/get-background-image.helper';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { LIST_TYPE } from '@/types/recommendation-list.type';
import React, { useCallback, useContext, useMemo } from 'react';
import { setBgShadeMode } from '../BackgroundImage/set-bg-shade-mode';
import { CollectionsListMemo as CollectionsList } from './CollectionsList';
import { CollectionsListWrapperOwnProps } from './types';
import { useFetchMoreCollections } from './use-fetch-more-collections.hook';
import { ListInterface } from '@/interfaces/list.interface';

const CollectionsListWrapper = ({
    params,
    onActiveItem,
    onListType,
    onNoLists,
    config = {},
    collectionsList,
    isFetchingCollectionsList,
}: CollectionsListWrapperOwnProps) => {
    const { setShadeMode, setBcgMargin, setBcgSrc } = useContext(BackgroundContext);
    const { loadedRowIndex, handleLoadedData, setActiveRow } = useFetchMoreCollections(config);

    const handleActiveItem = useCallback(
        (listType: LIST_TYPE) => (activeItem: ListElementInterface) => {
            batchedUpdates(() => {
                onListType?.(listType);
                onActiveItem?.(activeItem);
                setShadeMode(setBgShadeMode(listType));
                setBcgMargin(getBgMargin(listType));
                const shouldDisableBg = listType === LIST_TYPE.PROMOBOX ? false : config.disableBg;
                getBackgroundImage(activeItem, shouldDisableBg);
                const backgroundSrc = getBackgroundImage(activeItem, shouldDisableBg);
                setBcgSrc(backgroundSrc);
            });
        },
        [],
    );
    const handleActiveListItem = useCallback(
        (listType: LIST_TYPE) => (activeItem: ListInterface) => {
            batchedUpdates(() => {
                onActiveItem?.(activeItem);
                setShadeMode(setBgShadeMode(listType));
                setBcgMargin(getBgMargin(listType));
                getBackgroundListImage(activeItem);
                const backgroundSrc = getBackgroundListImage(activeItem);
                setBcgSrc(backgroundSrc);
            });
        },
        [],
    );

    return useMemo(
        () => (
            <CollectionsList
                params={params}
                loadedRowIndex={loadedRowIndex}
                onLoadedData={handleLoadedData}
                onActiveItem={handleActiveItem}
                onActiveListItem={handleActiveListItem}
                onActiveRow={setActiveRow}
                onNoLists={onNoLists}
                config={config}
                collectionsList={collectionsList}
                isFetchingCollectionsList={isFetchingCollectionsList}
            />
        ),
        [loadedRowIndex, params],
    );
};

export const CollectionsListWrapperMemo = React.memo(CollectionsListWrapper);
