import useGetMedia from '@/api/navigation/use-get-media';
import { CPID } from '@/types/media.type';
import { useGetCategory } from '@/api/navigation/category/get-category';
import { useEffect } from 'react';
import { returnCategoryUrl, returnMediaUrl, returnPacketUrl } from '@/helpers/url.helper';
import { useHistory } from 'react-router-dom';
import { deepLinkParams } from '@/helpers/deep-link-params.helper';
import useGetProduct from '@/api/navigation/use-get-product';
import { GetProductIn } from '@/interfaces/from-schemas/navigation/getProductIn';

export const useRedirectToDeepLink = () => {
    const href = window.location.href;

    // przed # będzie / więc trzeba parsować obiekt od ? do / znajdującego się przed # żeby nie znajdował się później
    // w paramsach podawanych do zapytań bo nie wykona się poprawnie zapytanie
    const parsed = new URLSearchParams(href.slice(href.indexOf('?'), href.indexOf('#') - 1));
    const params = deepLinkParams(parsed);
    const history = useHistory();

    const getMediaParams = params?.deepLinkParamVOD
        ? {
              cpid: CPID.VOD_OR_MOVIE as 1,
              mediaId: params.deepLinkParamVOD,
          }
        : undefined;
    const {
        data: mediaModel,
        isFetching: isMediaFetching,
        error: errorModel,
    } = useGetMedia(getMediaParams);

    const getCategoryParams = params?.deepLinkParamCategory
        ? {
              catid: Number(params?.deepLinkParamCategory),
          }
        : undefined;
    const {
        data: categoryModel,
        isFetching: isCategoryFetching,
        error: errorCategory,
    } = useGetCategory(getCategoryParams);

    const getPacketParams = params?.deepLinkParamPacket
        ? ({
              product: {
                  type: 'multiple',
                  subType: 'packet',
                  id: params.deepLinkParamPacket,
              },
          } as GetProductIn)
        : undefined;
    const {
        data: packetModel,
        isFetching: isPacketFetching,
        error: errorPacket,
    } = useGetProduct(getPacketParams);

    const hasMediaModel = !isMediaFetching && !errorModel && mediaModel.getId();
    const hasCategoryModel = !isCategoryFetching && !errorCategory && categoryModel.getId();
    const hasPacketModel = !isPacketFetching && !errorPacket && packetModel?.getId();

    useEffect(() => {
        if (!window.wasRedirected) {
            if (hasMediaModel) {
                window.wasRedirected = true;
                history.replace(returnMediaUrl(mediaModel));
            }

            if (hasCategoryModel) {
                window.wasRedirected = true;
                history.replace(returnCategoryUrl(categoryModel));
            }

            if (hasPacketModel && !!packetModel) {
                window.wasRedirected = true;
                history.replace(returnPacketUrl(packetModel));
            }
        }
    }, [hasMediaModel, hasCategoryModel, hasPacketModel]);
};
