import { Nav } from 'nav-tree';
import React, { useRef } from 'react';

import { AdditionalSigns } from '@/components/shared/AdditionalSigns/AdditionalSigns';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { BackButton } from '@/components/shared/Buttons/BackButton';
import { FavoritesAlert } from '@/components/shared/FavoritesAlert';
import { SubcategoriesMenu } from '@/components/shared/Filter/SubcategoriesMenu';
import { Icon, ICONS } from '@/components/shared/Icon';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { IconGroup, Metadata, METADATA_TYPES } from '@/components/shared/Metadata';
import ProgressBar from '@/components/shared/ProgressBar';
import { RatingIconWrapper } from '@/components/shared/RatingIconWrapper/styles';
import { AdultContentAgreement } from '@/components/views/AdultContentAgreement';
import { MediaButtons } from '@/components/views/MediaButtons';
import { getIconName } from '@/helpers/get-icon-name.helper';
import { useAgreeToContent } from '@/hooks/media/use-agree-to-content.hook';
import { useMediaBackground } from '@/hooks/media/use-media-background.hook';
import { useMediaDetailsFavorities } from '@/hooks/media/use-media-details-favorities.hook';
import { useMediaDetailsSubcategories } from '@/hooks/media/use-media-details-subcategories.hook';
import t from '@/lib/i18n';
import { ADDITIONAL_SIGNS_TYPES } from '@/types/additional-signs.type';
import { GUTTER } from '@/types/gutter.type';

import {
    ButtonGroupContainer,
    ContentContainer,
    IconWrapper,
    InfoText,
    InfoTextContainer,
    MediaDetailsContainer,
    MetadataContainer,
    MetadataIconWrapper,
    MetadataWrapper,
    ProgressBarContainer,
    ProgressBarFmcWrapper,
    ProgressContainer,
} from './styles';
import { ELEMENT_WIDTH, MediaDetailsProps } from './types';
import {
    GALLERY_BACKGROUND_EFFECT,
    GALLERY_IMAGE_ASPECT,
    useGalleryImage,
} from '@/hooks/use-gallery-image';

export const MediaDetails = ({ media, fmc }: MediaDetailsProps) => {
    const { showAgreeToContentModal, setShowAgreeToContentModal } = useAgreeToContent(
        media.isForAdults,
    );
    const focusRef = useRef<Nav>(null);

    useMediaBackground(media, SHADE_MODE.MEDIA);

    const { showInfo, handleButtonAction } = useMediaDetailsFavorities(media);

    const hasAgeGroup = media.ageGroup > 0;
    const underageClassification = media.underageClassification;
    const accessibilityFeatures = media.accessibilityFeatures;
    const showProgressBar = fmc?.mediaId.id === media.id;
    const metadata = [media.duration, ...(showProgressBar ? [media.followMeContent(fmc)] : [])];

    const {
        data,
        mediaButtons,
        subcategoriesMenuTitle,
        rightPanelOpen,
        setRightPanelOpen,
        handleRightPanel,
    } = useMediaDetailsSubcategories(media, focusRef);

    const isStaffCollection = media.getIsStaffCollections();
    const staffCollectionsId = media.getStaffCollectionId() as number;
    const staffCollectionGallery = media.getStaffCollectionGallery();
    const staffCollectioTitle = media.getStaffCollectionName();

    const config = {
        aspect: GALLERY_IMAGE_ASPECT.WIDESCREEN,
        customParams: {
            backgroundEffect: GALLERY_BACKGROUND_EFFECT.NOT_TRANSPARENT,
        },
    };
    const { image } = useGalleryImage(ELEMENT_WIDTH, staffCollectionGallery, config);

    if (showAgreeToContentModal) {
        return (
            <AdultContentAgreement handleConfirmation={() => setShowAgreeToContentModal(false)} />
        );
    }

    return (
        <MediaDetailsContainer>
            <BackButton />
            <ContentContainer>
                <Metadata data={media.subTitleData} type={METADATA_TYPES.PRIMARY} isSafeHTML />
                <MainText text={media.title} mode={MAINTEXT_KIND.TITLE} gutter={GUTTER.SMALL} />
                <MainText
                    text={media.description}
                    mode={MAINTEXT_KIND.DESCRIPTION}
                    lines={media.shortDescriptionLines}
                />
                <Metadata data={metadata} type={METADATA_TYPES.SECONDARY} />
                {showProgressBar && fmc && (
                    <ProgressBarFmcWrapper>
                        <ProgressBar progress={fmc.lastPercent} />
                    </ProgressBarFmcWrapper>
                )}
                {media.progress !== null && (
                    <ProgressContainer>
                        <Metadata data={[media.minutesLeftInfo]} type={METADATA_TYPES.SECONDARY} />
                        <ProgressBarContainer>
                            <ProgressBar progress={media.progress} />
                        </ProgressBarContainer>
                    </ProgressContainer>
                )}
                <MetadataContainer>
                    <MetadataIconWrapper>
                        {hasAgeGroup && (
                            <RatingIconWrapper ageGroup={media.ageGroup} $height={2.9}>
                                <Icon name={getIconName(media.ageGroup)} />
                            </RatingIconWrapper>
                        )}

                        <AdditionalSigns
                            type={ADDITIONAL_SIGNS_TYPES.UNDERAGE_CLASSIFICATION}
                            signs={underageClassification}
                            $iconSize={2.9}
                        />
                        <AdditionalSigns
                            type={ADDITIONAL_SIGNS_TYPES.ACCESSIBILITY_FEATURES}
                            signs={accessibilityFeatures}
                            $iconSize={2.9}
                        />
                        <IconGroup icon={media.mediaType} iconSize={2.9} noMargin />
                    </MetadataIconWrapper>

                    <MetadataWrapper>
                        <Metadata
                            data={media.accessMetadata}
                            type={METADATA_TYPES.TERTIARY}
                            isSafeHTML
                        />
                    </MetadataWrapper>
                </MetadataContainer>
                <ButtonGroupContainer>
                    {media.buyOnWwwInfo && (
                        <InfoTextContainer largeMargin>
                            <IconWrapper>
                                <Icon name={ICONS.INFO_CIRCLE_FILLED} />
                            </IconWrapper>

                            <InfoText highlight>{media.buyOnWwwInfo}</InfoText>
                        </InfoTextContainer>
                    )}

                    {media.isStartLiveInfoVisible && (
                        <InfoTextContainer>
                            <InfoText>{t('start-transmission')}:</InfoText>
                            <InfoText highlight>{media.startLiveInfo}</InfoText>
                        </InfoTextContainer>
                    )}

                    <MediaButtons
                        buttons={mediaButtons}
                        focusRef={focusRef}
                        onRightPanel={handleRightPanel}
                        onButtonAction={handleButtonAction}
                        imgSrc={image.src}
                        staffCollectionsId={staffCollectionsId}
                        staffCollectioTitle={staffCollectioTitle}
                        isStaffCollection={isStaffCollection}
                    />
                </ButtonGroupContainer>
            </ContentContainer>
            <SubcategoriesMenu
                subcategories={data.getResults()}
                name={subcategoriesMenuTitle}
                isVisible={rightPanelOpen}
                onShowMenu={setRightPanelOpen}
                selectedCategoryId={media.categoryId}
            />

            <FavoritesAlert
                showInfo={showInfo}
                favoritesType={media.favoritesType}
                isInFavorites={media.isInFavorites}
            />
        </MediaDetailsContainer>
    );
};
