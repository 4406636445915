import t from '@/lib/i18n';
import React, { useContext } from 'react';
import { Message } from '@/components/shared/Message';
import { ICONS } from '@/components/shared/Icon';
import { EmptyLiveCollectionWrapper } from '@/components/views/Live/styles';
import { PlayableButton } from '@/components/shared/Buttons';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { PLAYABLE_VARIANT } from '@/components/shared/Buttons/PlayableButton/types';
import { LoaderContext } from '@/contexts/loader/loader.context';
import { BackgroundContext } from '@/contexts/background/background.context';

export const EmptyLiveCollection = () => {
    const { setShowLoader } = useContext(LoaderContext);
    const { setBcgSrc } = useContext(BackgroundContext);

    setShowLoader(false);
    setBcgSrc(' ');

    return (
        <EmptyLiveCollectionWrapper>
            <Message
                icon={ICONS.INFO_CIRCLE}
                title={t('live-collection.empty-live.heading')}
                description={t('live-collection.empty-live.description', {
                    portal: process.env.name,
                })}
            />
            <PlayableButton
                text={t('live-collection.empty-live.button-text')}
                onButtonAction={() => Router.pushRoute(ROUTES.CHANNELS_TV)}
                defaultFocused={true}
                variant={PLAYABLE_VARIANT.PRIMARY}
            />
        </EmptyLiveCollectionWrapper>
    );
};
