import { PlayableDynamicButton } from '@/components/shared/Buttons';
import { CollectionsList } from '@/components/shared/CollectionsList';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { PLACEHOLDER_DISPLAY } from '@/components/shared/FormElement/BaseElements/TextInput/types';
import { ICONS } from '@/components/shared/Icon';
import { SearchProps } from '@/components/views/search/types';
import { CatalogContext } from '@/contexts/catalog/catalog.context';
import { SearchContext } from '@/contexts/search/search.context';
import { usePageRefocus } from '@/hooks/nav-tree/use-page-refocus.hook';
import { useDebouncedState } from '@/hooks/use-debounced-state.hook';
import { useExitAppConfirmation } from '@/hooks/use-exit-app-confirmation';
import t from '@/lib/i18n';
import { GUTTER } from '@/types/gutter.type';
import { Nav, navHorizontal, navVertical } from 'nav-tree';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { SearchResults } from './SearchResults';
import { ContentContainer, InputsContainer, SearchContainer, TextInputContainer } from './styles';
import { useGetCollectionsList } from '@/api/navigation/recommendations/use-get-collections-list';

export const Search = ({ minQueryLength, resultsTimeout, params, config }: SearchProps) => {
    const { setGrid } = useContext(CatalogContext);
    const { inputValue, setInputValue } = useContext(SearchContext);
    const [inputFocus, setInputFocus] = useState<boolean>(false);

    const focusRef = useRef<Nav>(null);
    const trimmedValue = inputValue.trim();
    const enabled = !inputFocus || !trimmedValue.length;

    useExitAppConfirmation(true, enabled);

    usePageRefocus({ focusRef });

    const handleNoData = useCallback(() => {
        setGrid((prev) => ({ ...prev, activeIndex: 0, rowIndex: 0, colIndex: 0 }));
    }, []);

    const query = useDebouncedState(
        trimmedValue,
        (value) => (value === '' ? 0 : resultsTimeout),
        trimmedValue.length >= minQueryLength || trimmedValue.length === 0,
    );
    const { collectionsList, isFetching: isFetchingCollectionsList } = useGetCollectionsList(
        config,
        params,
    );
    return (
        <SearchContainer func={navVertical}>
            <InputsContainer func={navHorizontal} ref={focusRef}>
                <TextInputContainer>
                    <TextInput
                        icon={ICONS.SEARCH}
                        value={inputValue}
                        gutter={GUTTER.NONE}
                        placeholder={t('components.search.search-placeholder')}
                        placeholderDisplay={PLACEHOLDER_DISPLAY.CENTER}
                        onChange={(value: string) => {
                            setInputValue(value);
                            return value;
                        }}
                        onFocus={setInputFocus}
                        defaultFocused
                        data-testing="components.search.search-placeholder"
                        maxLength={256}
                    />
                </TextInputContainer>

                {process.env.features.voiceSearch && (
                    <PlayableDynamicButton
                        icon={ICONS.MICROPHONE}
                        text={t('components.search.voice-search')}
                        onButtonAction={() => {
                            // TODO: wyszukiwanie głosowe
                        }}
                        data-testing="components.search.voice-search"
                    />
                )}
            </InputsContainer>

            <ContentContainer>
                {!query && collectionsList?.length > 0 && (
                    <CollectionsList
                        params={params}
                        config={config}
                        collectionsList={collectionsList}
                        isFetchingCollectionsList={isFetchingCollectionsList}
                    />
                )}
                {query && <SearchResults query={query} onNoData={handleNoData} />}
            </ContentContainer>
        </SearchContainer>
    );
};
