import TagManager from 'react-gtm-module';
import UserModule from '@/modules/user/user.module';
import { ERROR_CODES_GET_MEDIA } from '@/constants/error-codes-get-media';
import { IGetMediaMutateFn } from '@/hooks/use-get-media-api.type';
import { LoginOut } from '@/interfaces/from-schemas/auth/loginOut';
import { RegisterIn } from '@/interfaces/from-schemas/auth/registerIn';
import { RegisterOut } from '@/interfaces/from-schemas/auth/registerOut';
import { Router } from '@/routing';
import { IParams, ROUTES } from '@/routing/types';
import { AUTH_PROVIDERS } from '@/types/auth-providers.type';
import { UserAuthData } from '@/types/user-auth-data.type';
import { checkAccountExistsException } from './check-account-exists-exception.helper';
import { isStbTargets } from '@/constants/portal-recognition';
import { GTM_EVENTS } from '@/stats/types';

export const loginUser = async (
    params: UserAuthData,
    userModule: UserModule,
    login: IGetMediaMutateFn<UserAuthData, LoginOut>,
    register?: IGetMediaMutateFn<RegisterIn, RegisterOut>,
    authProvider?: AUTH_PROVIDERS,
    query?: IParams,
) => {
    userModule.setAuthData(params);
    const deviceId = userModule.getDeviceIdIdent();
    const resultLogin = await login(params);

    const {
        B2C_MEDIA_NOT_FOUND, // 13404
        B2C_EMAIL_CONFIRMATION_REQUIRED, // 13454
        B2C_EMAIL_REQUIRED, // 13456
        GMU_UNACCEPTED_RULES_ERROR, // 12002
    } = ERROR_CODES_GET_MEDIA;

    if (!resultLogin.ok) {
        const errorCode = resultLogin.error.getCode();

        if (authProvider) {
            checkAccountExistsException(resultLogin, authProvider, query);
        }

        if (errorCode === GMU_UNACCEPTED_RULES_ERROR) {
            Router.pushRoute(ROUTES.LOGIN_RULES_OBLIGATORY);
        }

        if (errorCode === B2C_EMAIL_CONFIRMATION_REQUIRED) {
            const queryParam = { changeEmail: true };
            const email = resultLogin.error.getEmail();
            if (email) Object.assign(queryParam, { email });

            Router.pushRoute(ROUTES.EMAIL_ACTIVATION_CHECK_EMAIL, queryParam, queryParam);
        }

        if (errorCode === B2C_EMAIL_REQUIRED) {
            const queryParam = { authProvider, changeEmail: true };
            Router.pushRoute(ROUTES.ADD_ACCOUNT_EMAIL, queryParam, queryParam);
        }

        if (register && errorCode === B2C_MEDIA_NOT_FOUND) {
            const registerParams = { registrationData: { ...params } };
            if (isStbTargets) {
                Object.assign(registerParams.registrationData, { deviceId });
            }
            const resultRegister = await register(registerParams as RegisterIn);

            if (resultRegister.ok) {
                if (authProvider) {
                    await loginUser(params, userModule, login, undefined, authProvider);
                } else {
                    Router.pushRoute(ROUTES.LOGIN_RULES_OBLIGATORY);
                }
                return;
            }

            if (resultRegister.error.getCode() === B2C_EMAIL_REQUIRED) {
                const queryParam = { authProvider };
                Router.pushRoute(ROUTES.ADD_ACCOUNT_EMAIL, queryParam, queryParam);
            }
        }
    }

    if (resultLogin.ok) {
        TagManager.dataLayer({
            dataLayer: {
                event:
                    authProvider === AUTH_PROVIDERS.PLUS ? GTM_EVENTS.PLUS_LOGIN : GTM_EVENTS.LOGIN,
            },
        });
        if (resultLogin.data.status === 0) {
            const filteredParams = Object.fromEntries(
                Object.entries(query || {}).filter(([, value]) => value !== undefined),
            );

            return Router.pushRoute(
                ROUTES.LOGIN_RULES,
                Object.keys(filteredParams).length ? filteredParams : undefined,
            );
        }

        if (resultLogin.data.status === 1) {
            return Router.pushRoute(ROUTES.REGISTRATION_CONFIGURATION);
        }
    }
};
