import { StaffCollectionContentSchema } from './staff-collection-content-results.model';

export class StaffCollectionContentModel {
    private count = 0;
    private results: StaffCollectionContentSchema[] = [];
    private offset = 0;
    private total = 0;
    private name = '';

    constructor(definition: any) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });
        }
    }

    public getResults(): StaffCollectionContentSchema[] {
        return this.results;
    }
    public getTotal(): number {
        return this.total;
    }
    public getOffset(): number {
        return this.offset;
    }
    public getCount(): number {
        return this.count;
    }
    public getName(): string {
        return this.name;
    }
}
