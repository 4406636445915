import { getMediaPromise } from '@/api/navigation/get-media';
import { CPID, MEDIA_TYPES } from '@/types/media.type';
import { PrePurchaseDataModel } from '@/models/payments/pre-purchase-data.model';
import { CheckProductAccessModel } from '@/models/payments/check-product-access.model';
import { IBuildMediaProps, MediaDetailsPageProps } from '@/interfaces/media-details.interface';
import { MediaListItemModel } from '@/models/media-list-item.model';
import { MediaDetailsModel } from '@/models/media/media-details.model';
import { getChannelsCurrentProgramPromise } from '@/api/navigation/channels/use-get-channel-list-current-program';
import { ChannelsCurrentProgramModel } from '@/models/channels/channel-list-current-program.model';
import { getCategoryAutoplayMediaIdPromise } from '@/api/navigation/category/get-category-content-autoplay-media-id';
import { isSessionExpired } from './session-expired.helper';
import { CHANNELS_PROGRAM_LIMIT } from '@/configs/channels.config';
import { MediaListItemSchema } from '@/interfaces/from-schemas/navigation/getMediaListOut';
import { getMediaRelatedContentPromise } from '@/api/navigation/use-get-media-related-content';
import { EnhancedContext } from '@/modules/enhance-context';
import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';
import { TvProgramItemDetailsModel } from '@/models/media/tv-program-item-details.model';
import { fetchMediaPromises } from './fetch-media-promises.helper';
import { generateVodMovieRedirectPath } from './url.helper';
import slug from 'slug';
import { MediaIdInterface } from '@/interfaces/media-id-interface';

export function returnMediaListItemModel(
    media: MediaListItemModel | MediaListItemSchema,
): MediaListItemModel {
    return media instanceof MediaListItemModel ? media : new MediaListItemModel(media);
}

export async function buildMediaFromCategoryIdProps(enhancedContext: EnhancedContext) {
    const { query, smartweb } = enhancedContext;
    const { categoryId, vodCategoryId } = query;

    const { getMediaApiPromise } = smartweb;
    const params = { catid: Number(categoryId || vodCategoryId) };

    const response = await getCategoryAutoplayMediaIdPromise(params, getMediaApiPromise);
    const mediaId = response?.id ?? '';

    return buildMediaProps({ mediaId, cpid: CPID.VOD_OR_MOVIE, enhancedContext });
}

export async function buildMediaProps({
    mediaId,
    cpid,
    fetchProgramTv = false,
    fetchRelatedContent = false,
    enhancedContext,
    title: urlParamTitle,
}: IBuildMediaProps): Promise<MediaDetailsPageProps> {
    const { getMediaApiPromise } = enhancedContext.smartweb;

    let { media, channelsProgram, isChronological } = <MediaDetailsPageProps>{};
    let redirect;
    let redirectPath;
    let sessionExpired;

    if (!mediaId) {
        return {};
    }

    const mediaParams = { mediaId, cpid: cpid as 0 | 1 };
    const mediaResponse = await getMediaPromise(mediaParams, getMediaApiPromise);
    media = mediaResponse?.data;
    sessionExpired = isSessionExpired(mediaResponse?.error?.code);

    if (sessionExpired) {
        return { sessionExpired };
    }

    if (!media) {
        redirect = true;
    }

    if (media) {
        if (urlParamTitle && cpid === CPID.VOD_OR_MOVIE) {
            const isTitleCorrect = slug(media.title) === urlParamTitle;

            if (!isTitleCorrect) {
                redirectPath = generateVodMovieRedirectPath(mediaId, media.title);
                redirect = true;
            }
        }

        if (cpid === CPID.VOD_OR_MOVIE || cpid === CPID.EVENT_OR_CHANNEL) {
            const category = media.category;

            if (category) {
                isChronological = await fetchMediaPromises(
                    category.id,
                    enhancedContext,
                    category.keyCategoryId,
                );
            }
        }
    }

    if (fetchRelatedContent || fetchProgramTv) {
        await getMediaRelatedContentPromise(mediaParams, getMediaApiPromise);
    }

    if (fetchProgramTv) {
        const channelsResponse = await getChannelsCurrentProgramPromise(
            { channelIds: [mediaId], limit: CHANNELS_PROGRAM_LIMIT },
            getMediaApiPromise,
        );
        channelsProgram = channelsResponse?.data;
        sessionExpired = isSessionExpired(channelsResponse?.error?.code);
    }

    return { media, isChronological, channelsProgram, redirect, redirectPath };
}

export function buildMediaDetailsModel(
    data?: MediaDetailsPageProps,
): MediaDetailsModel | TvProgramItemDetailsModel | undefined {
    if (!data) return;
    const { media, prePurchaseData, checkProductAccess, channelsProgram, tvProgramItem, redirect } =
        data;

    if (!media || redirect) {
        return;
    }

    const mediaListItemModel = returnMediaListItemModel(media);

    const checkProductAccessModel = checkProductAccess
        ? checkProductAccess instanceof CheckProductAccessModel
            ? checkProductAccess
            : new CheckProductAccessModel(checkProductAccess)
        : undefined;

    const prePurchaseDataModel = prePurchaseData
        ? prePurchaseData instanceof PrePurchaseDataModel
            ? prePurchaseData
            : new PrePurchaseDataModel(prePurchaseData)
        : undefined;

    const channelsProgramModel = channelsProgram
        ? channelsProgram instanceof ChannelsCurrentProgramModel
            ? channelsProgram
            : new ChannelsCurrentProgramModel(channelsProgram)
        : undefined;

    const tvProgramItemModel = tvProgramItem
        ? tvProgramItem instanceof ChannelProgramTvModel
            ? tvProgramItem
            : new ChannelProgramTvModel(
                  { channelId: tvProgramItem.channelIds[0].id, mediaType: MEDIA_TYPES.TV_PROGRAM },
                  tvProgramItem,
              )
        : undefined;

    const programTvListModel =
        channelsProgramModel && Object.values(channelsProgramModel.getResults())[0];

    if (tvProgramItemModel) {
        return new TvProgramItemDetailsModel(
            mediaListItemModel,
            tvProgramItemModel,
            checkProductAccessModel,
            prePurchaseDataModel,
        );
    }

    return new MediaDetailsModel(
        mediaListItemModel,
        checkProductAccessModel,
        prePurchaseDataModel,
        programTvListModel,
    );
}

export function compareMedia(media1?: MediaIdInterface, media2?: MediaIdInterface): boolean {
    if (!media1 || !media2) {
        return false;
    }

    return media1.id === media2.id && media1.cpid === media2.cpid;
}
