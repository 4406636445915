/*
┌──────────────────────────────────┬──────────────────────────────────────┬─────────────┬─────────────┬────────┬─────────────┬────────────────┐
│                id                │                 name                 │ description │   parent    │ prefix │    type     │     value      │
├──────────────────────────────────┼──────────────────────────────────────┼─────────────┼─────────────┼────────┼─────────────┼────────────────┤
│ 911334ed3f05e9a862d38a6fbd1de339 │ Chromecast                           │ (null)      │ all         │ os     │ os          │ chromecast     │
│ 6fc06d2fb2ec2eb78cc323062f318859 │ Chromecast                           │ (null)      │ all         │ app    │ application │ chromecast     │
│ 7040f473fd18634d5e18741e85df1dc2 │ D46CU (EVOBOX STREAM) - DVB-T2       │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_46      │
│ 4472cea352fe2c36efad431f2649be18 │ TV Toshiba                           │ (null)      │ tv          │ dev    │ device_type │ tv_toshiba     │
│ 41d2d80339c61da66ebe6be293408f3c │ TV Sony                              │ (null)      │ tv          │ dev    │ device_type │ tv_sony        │
│ 42d056fa4e23cbc20ad0881a318f486d │ TV Netrange                          │ (null)      │ tv          │ dev    │ device_type │ tv_netrange    │
│ 5da5b859a5632173e3174deb9ba94444 │ TV Inne                              │ (null)      │ tv          │ dev    │ device_type │ tv_other       │
│ edf50f4c84359e9b75dc844da43d5c8d │ STB Netia                            │ (null)      │ stb         │ dev    │ device_type │ stb_netia      │
│ 31f5d3dacb5f641aa485fd1da61a52f3 │ STB Toya                             │ (null)      │ stb         │ dev    │ device_type │ stb_toya       │
│ 2ba123f82c54965766443f1bd2b72238 │ STB InView                           │ (null)      │ stb         │ dev    │ device_type │ stb_inview     │
│ 6f7db7184c4c421678c25a2b359cd456 │ STB CP                               │ (null)      │ stb         │ dev    │ device_type │ stb_cp         │
│ 6ffc264d2dc595cd8381cbde21383676 │ Playstation 3                        │ (null)      │ console     │ dev    │ device_type │ console_ps3    │
│ 8742919add16ee6f30b827ef5d27cf30 │ STB CP D4                            │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_d4      │
│ c9054e11da9d230754d640959cdc475e │ STB CP D6                            │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_d6      │
│ e2f70da51d7ac90753c3f866c4d1484c │ STB CP D14                           │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_d14     │
│ 49a8fa8b54d0672a9764985ed5353c89 │ Wszystkie                            │ (null)      │ (null)      │ os     │ os          │ all            │
│ 316d1ef517f382f0a1e34f633f69dbf6 │ Windows                              │ (null)      │ all         │ os     │ os          │ windows        │
│ a0c1d5ca90a35e771eab3f05501f82e4 │ Linux                                │ (null)      │ all         │ os     │ os          │ linux          │
│ 224002e2fe863e121c26a3ae2c89f7ff │ OS X                                 │ (null)      │ all         │ os     │ os          │ osx            │
│ dcc0cf55ff5a8b729a340aaa9093a2f5 │ Android                              │ (null)      │ all         │ os     │ os          │ android        │
│ 3c83183f8e5eb778ec2a0ef77ce3407e │ iOS                                  │ (null)      │ all         │ os     │ os          │ ios            │
│ e3389ce2d27fe52d93e28a493134b77b │ Windows Phone                        │ (null)      │ all         │ os     │ os          │ windows_phone  │
│ bbd64bff7bb2f0c501f22c275f1ae29b │ Tizen                                │ (null)      │ all         │ os     │ os          │ tizen          │
│ 2dcd17824b6c98db4ef1d0c4b1f0aad3 │ OperaTV                              │ (null)      │ all         │ os     │ os          │ operatv        │
│ da0d2ba0bd9b0bee9c90c9cbe22832e8 │ SRAF                                 │ (null)      │ all         │ os     │ os          │ sraf           │
│ 9f748ebe7533eb4d63cf992e6db7286d │ Wszystkie                            │ (null)      │ (null)      │ app    │ application │ all            │
│ 68157a85a7258a02ea54691d0e90befe │ Browser                              │ (null)      │ all         │ app    │ application │ browser        │
│ 347a85aba9b27408fbba183971a69480 │ Native                               │ (null)      │ all         │ app    │ application │ native         │
│ d800a8236302a1f2ca0d99297833a438 │ Chrome                               │ (null)      │ browser     │ app    │ application │ chrome         │
│ 69f4a815714bbf9a242a436a543a7042 │ Firefox                              │ (null)      │ browser     │ app    │ application │ firefox        │
│ 65abed169e28438d7ae29c7a90431a50 │ IE                                   │ (null)      │ browser     │ app    │ application │ ie             │
│ f1fcaff0e63655e8183dac4813b4980b │ Opera                                │ (null)      │ browser     │ app    │ application │ opera          │
│ 6ceebfd12eb61f15b6bed4c3d1591bed │ Safari                               │ (null)      │ browser     │ app    │ application │ safari         │
│ 5f737459e7a99b2cdd8f157e1adb5474 │ Wszystkie                            │ (null)      │ (null)      │ player │ player      │ all            │
│ 7e7dfb604b44b3858c3cce5a0ba4b56f │ (null)                               │ (null)      │ all         │ player │ player      │ native         │
│ 26e2eafb6888efaf0f8a7b1efa26927b │ Wszystkie                            │ (null)      │ (null)      │ dev    │ device_type │ all            │
│ 75e89e133511f01f44f4621a039bd279 │ Komputer                             │ (null)      │ all         │ dev    │ device_type │ pc             │
│ 82007eb4238205c75ebcdefc06a01311 │ Mobilne                              │ (null)      │ all         │ dev    │ device_type │ mobile         │
│ 7c63ac2bd41e6bdd9bd591f0cfefd82d │ Smart TV                             │ (null)      │ all         │ dev    │ device_type │ tv             │
│ 344e6780f6242b3f2ac5d8a11be8e01a │ SetTopBOx                            │ (null)      │ all         │ dev    │ device_type │ stb            │
│ 512121919cf4503b10558c6c6879ee11 │ Konsole                              │ (null)      │ all         │ dev    │ device_type │ console        │
│ 067e8badc84602f26b4e5bdfe591e353 │ STB CP HTML                          │ (null)      │ stb         │ dev    │ device_type │ stb_cp_html    │
│ 5e62e219fa58c5716845beec0e3beca6 │ Tablet                               │ (null)      │ mobile      │ dev    │ device_type │ tablet         │
│ cbeadd8d40687b80c0cc5c07b7bc32eb │ Komórka                              │ (null)      │ mobile      │ dev    │ device_type │ phone          │
│ af52051eed185ae8e0867caf1bd05cd9 │ TV Samsung                           │ (null)      │ tv          │ dev    │ device_type │ tv_samsung     │
│ 9fa27d15604c07dfa2b448d940d8f2a6 │ Inne                                 │ (null)      │ all         │ os     │ os          │ other          │
│ 123a1b5ed689fd584cd6f42b497e8d3a │ Inne                                 │ (null)      │ all         │ app    │ application │ other          │
│ d8b70be42ae0bf5f85b8f9abb4729572 │ Inne                                 │ (null)      │ all         │ player │ player      │ other          │
│ ac125aefabc3101cc6bf1595d0b31801 │ Inne                                 │ (null)      │ all         │ dev    │ device_type │ other          │
│ a662c3fcc571122bb3307282dfff6ff4 │ macOS                                │ (null)      │ all         │ os     │ os          │ macos          │
│ 00fce15ff3358c0753a195002d376991 │ STB CP HTML 38                       │ (null)      │ stb_cp_html │ dev    │ device_type │ stb_cp_html_38 │
│ 27b2d11a00be17f1430ca0439873ed0a │ STB CP HTML 42                       │ (null)      │ stb_cp_html │ dev    │ device_type │ stb_cp_html_42 │
│ 80e7a0322b2b80ab7477963e1bc185de │ STB CP 43                            │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_43      │
│ 544a82b79c388f227371fbd47621fc28 │ STB CP HTML 44                       │ (null)      │ stb_cp_html │ dev    │ device_type │ stb_cp_html_44 │
│ 4191d9b2db973ac4b884864c36fcf1b5 │ STB CP 20                            │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_20      │
│ f1d6dc2b3789abc25ce789005ac1661b │ STB CP 24                            │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_24      │
│ 7e4cf2b89fd942b09a68ce23fa0971aa │ STB CP 27                            │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_27      │
│ a7ab6c2161af7e400196e2c22d0fdadd │ STB CP 34                            │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_34      │
│ 4f8621a15db65c3b67efef5a6ef85e7e │ STB CP 28                            │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_28      │
│ 3e3529ba2345a17e94d23474da75ed6b │ STB CP 36                            │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_36      │
│ 8322afd825636ff7e5513cee922da204 │ STB CP HTML 41                       │ (null)      │ stb_cp_html │ dev    │ device_type │ stb_cp_html_41 │
│ d68d63dfbf8956acdbb55bab3e2c00c3 │ STB CP 4                             │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_4       │
│ 912b559335b1bf6c7a3a0e16fe5f36fa │ STB CP 6                             │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_6       │
│ ab56631f9c3ac223efcb82a43f50b38f │ STB CP 14                            │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_14      │
│ 0286fc7873f8d63a83890670276a0e78 │ D61AW (EVOBOX 4K)                    │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_html_48 │
│ 245c9a98d6c35e0c24325a17db123d87 │ TV Hisense                           │ (null)      │ tv          │ dev    │ device_type │ tv_hisense     │
│ 9707ad2427a2cb1adca05cf4643cb29e │ HbbTV                                │ (null)      │ all         │ os     │ os          │ hbbtv          │
│ 993ab76455260c8854ebb6e4a4f407f2 │ Player CP                            │ (null)      │ all         │ player │ player      │ cpplayer       │
│ 9385f7648e6c71af394b3c1389447504 │ Aplikacja natwywna dla klientow plus │ (null)      │ native      │ app    │ application │ native_plus    │
│ 1a93e4c486d32f35a3fb20fb569cc709 │ TV Alliance                          │ (null)      │ tv          │ dev    │ device_type │ tv_alliance    │
│ 17d27a6ca8c7a06be3b065b7b8312993 │ Player HTML5                         │ (null)      │ all         │ player │ player      │ html           │
│ 9236dcb41800466d9d7b667f68195e66 │ Flexi Player                         │ (null)      │ all         │ player │ player      │ flexi          │
│ 6f89e1ea47587dd1cfe33e816688ea8e │ TV LG                                │ (null)      │ tv          │ dev    │ device_type │ tv_lg          │
│ 99086a399b43eded460f88069214bf55 │ TV Panasonic                         │ (null)      │ tv          │ dev    │ device_type │ tv_panasonic   │
│ fd495c8af0ab462d286bcec31ec1d359 │ TV Philips                           │ (null)      │ tv          │ dev    │ device_type │ tv_philips     │
│ d3c6167c0c3b578895ad2de34abb9ce3 │ WebOS                                │ (null)      │ all         │ os     │ os          │ webos          │
│ 1323f70a31cffafe344631ffdaada07f │ Apple TV                             │ (null)      │ tv          │ dev    │ device_type │ appletv        │
│ bf33848c23f55eb547e2bf727ba711d4 │ D46BU (EVOBOX STREAM)                │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_45      │
│ ba665ad2cf546c735eb6aca85ee4dff2 │ XboxOS                               │ (null)      │ windows     │ os     │ os          │ xboxos         │
│ a0ec12fc630567473e4b34c9b043873b │ Edge                                 │ (null)      │ browser     │ app    │ application │ edge           │
│ 7f8f3dc5b4efb6602ece95404deb71a0 │ tvOS                                 │ (null)      │ all         │ os     │ os          │ tvos           │
│ bb795afd549e69542b21dae1614c3bbc │ STB T-Mobile                         │ (null)      │ stb         │ dev    │ device_type │ stb_tmobile    │
│ 800f1ac2e94118057f7dd1bb7eaf2ad0 │ XBOX                                 │ (null)      │ console     │ dev    │ device_type │ console_xbox   │
│ c608ced694137d943d6b59ce3af0a96e │ chromecast                           │ (null)      │ tv_samsung  │ dev    │ device_type │ chromecast     │
│ 5b87b5f9968dfb4c56cf1fba2a83c99e │ D46BP (EVOBOX IP) - Netia            │ (null)      │ stb_cp      │ dev    │ device_type │ stb_cp_html_47 │
└──────────────────────────────────┴──────────────────────────────────────┴─────────────┴─────────────┴────────┴─────────────┴────────────────┘
*/

export enum GM_PLAYER {
    All = 'all',
    Flexi = 'flexi',
    HTML5 = 'html',
    Native = 'native',
    Other = 'other',
    PlayerCP = 'cpplayer',
}

export enum GM_APPLICATION {
    All = 'all',
    Browser = 'browser',
    Chrome = 'chrome',
    Chromecast = 'chromecast',
    Edge = 'edge',
    Firefox = 'firefox',
    IE = 'ie',
    Native = 'native',
    NativePlus = 'native_plus',
    Opera = 'opera',
    Other = 'other',
    Safari = 'safari',
}

export enum GM_OS {
    All = 'all',
    Android = 'android',
    Chromecast = 'chromecast',
    HbbTV = 'hbbtv',
    Linux = 'linux',
    MacOS = 'macos',
    OSX = 'osx',
    OperaTV = 'operatv',
    Other = 'other',
    SRAF = 'sraf',
    Tizen = 'tizen',
    WebOS = 'webos',
    Windows = 'windows',
    WindowsPhone = 'windows_phone',
    XboxOS = 'xboxos',
    iOS = 'ios',
    tvOS = 'tvos',
    UPC = 'upc',
    PANASONIC = 'panasonic',
    VIDAA = 'vidaa',
    WHALE = 'whale',
    SAPHI = 'saphi',
    TIVO = 'tivo',
}

export enum GM_DEVICE_TYPE {
    All = 'all',
    AppleTV = 'appletv',
    Chromecast = 'chromecast',
    Console = 'console',
    Mobile = 'mobile',
    Other = 'other',
    PC = 'pc',
    Phone = 'phone',
    Playstation3 = 'console_ps3',
    STB_CP = 'stb_cp',
    STB_D30_EVOBOX_PVR = 'stb_cp_html_38',
    STB_D46_EVOBOX_IP = 'stb_cp_html_41',
    STB_D47_EVOBOX_HD = 'stb_cp_html_42',
    STB_D56_EVOBOX_LITE = 'stb_cp_html_43',
    STB_D46BU_EVOBOX_STREAM = 'stb_cp_html_45',
    STB_D46CU_EVOBOX_STREAM = 'stb_cp_html_46',
    STB_D46BP_EVOBOX_NETIA = 'stb_cp_html_47',
    STB_D61AW_IRDETO_EVOBOX_4K = 'stb_cp_html_48',
    STB_D61AW_NAGRA_EVOBOX_4K = 'stb_cp_html_49',
    STB_D46BU_WO_DVBT_EVOBOX_STREAM = 'stb_cp_html_50',
    STB_D46DU_EVOBOX_STREAM = 'stb_cp_html_51',
    STB_D61BW_IRDETO_EVOBOX_4K = 'stb_cp_html_52',
    STB_D61BW_NAGRA_EVOBOX_4K = 'stb_cp_html_53',
    STB_D63_IRDETO_POLSAT_BOX_NETIA_LITE = 'stb_cp_html_54',
    STB_D63_NAGRA_POLSAT_BOX_NETIA_LITE = 'stb_cp_html_55',
    STB_D63_IRDETO_POLSAT_BOX_LITE = 'stb_cp_html_56',
    STB_D63_NAGRA_POLSAT_BOX_LITE = 'stb_cp_html_57',
    STB_D64_IRDETO_POLSAT_BOX_LITE = 'stb_cp_html_58',
    STB_D64_NAGRA_POLSAT_BOX_LITE = 'stb_cp_html_59',
    STB_D64_IRDETO_POLSAT_BOX_NETIA_LITE = 'stb_cp_html_60',
    STB_D64_NAGRA_POLSAT_BOX_NETIA_LITE = 'stb_cp_html_61',
    STB_InView = 'stb_inview',
    STB_Netia = 'stb_netia',
    STB_TMobile = 'stb_tmobile',
    STB_Toya = 'stb_toya',
    SetTopBox = 'stb',
    Smart_TV = 'tv',
    TV_Alliance = 'tv_alliance',
    TV_Hisense = 'tv_hisense',
    TV_LG = 'tv_lg',
    TV_Netrange = 'tv_netrange',
    TV_TIVO = 'tv_tivo',
    TV_Other = 'tv_other',
    TV_Panasonic = 'tv_panasonic',
    TV_Philips = 'tv_philips',
    TV_Samsung_2018 = 'tv_samsung_2018',
    TV_Samsung = 'tv_samsung',
    TV_Sony = 'tv_sony',
    TV_Toshiba = 'tv_toshiba',
    Tablet = 'tablet',
    XBOX = 'console_xbox',
    UPC = 'upc',
}
