import TagManager from 'react-gtm-module';
import Stats, { StatsConfig, EVENT, EventParams, ActivityEventsUpdateHandlerParams } from '@/stats';
import { isServer } from '@/helpers/environment.helper';
import {
    DATA_LAYER_ADD_TO_CART_EVENT,
    DATA_LAYER_PAYMENT_CHECKOUT_EVENT,
    DATA_LAYER_PAYMENT_METHOD_EVENT,
    DATA_LAYER_PAYMENT_OFFER_EVENT,
    DATA_LAYER_PAYMENT_STEPS,
    DATA_LAYER_CHECK_POSSESSED_PACKETS_EVENT,
    DATA_LAYER_REGISTRATION_EVENT,
    DATA_LAYER_TRANSACTION_EVENT,
    DATA_LAYER_VIEW_ITEM_EVENT,
    DATA_LAYER_VIEW_PACKET_EVENT,
    IPaymentOfferEvent,
    ITransactionEvent,
} from '@/constants/dataLayer';
import { IActivityEventsUserItemClicked } from '@/types/stats.type';
import { ActivityEventsPlaceParams } from '@/stats/clients/activity-events';
import { Debug } from '@/utils/debug.util';
import { MediaDetailsModel } from '@/models/media/media-details.model';
import { GetProductOut } from '@/interfaces/from-schemas/navigation/getProductOut';
import { AccessProductModel } from '@/models/payments/access-product-model';
import { GTM_EVENTS } from '@/stats/types';

const isStatsDebugEnabled = process.env.stats?.debug || false;
const debug = new Debug('stats');

class StatsModule {
    private stats: Stats | null = null;
    private place!: ActivityEventsPlaceParams;

    public configure(configuration: StatsConfig) {
        this.stats = new Stats(configuration);
    }

    public async hitAppStart() {
        await this.dispatchEvent(EVENT.ACTIVITY_EVENTS_APP_STARTED);
    }

    public async hitAppPaused() {
        await this.dispatchEvent(EVENT.ACTIVITY_EVENTS_APP_PAUSED);
    }

    public async hitAppResumed() {
        await this.dispatchEvent(EVENT.ACTIVITY_EVENTS_APP_RESUMED);
    }

    public async hitAppUserLogged(params: EventParams<EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED>) {
        await this.dispatchEvent(EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED, params);
    }

    public async hitAppUserItemClicked(params: IActivityEventsUserItemClicked) {
        const hitParams: EventParams<EVENT.ACTIVITY_EVENTS_APP_USER_ITEM_CLICKED> = {
            ...params,
            place: this.place,
        };
        await this.dispatchEvent(EVENT.ACTIVITY_EVENTS_APP_USER_ITEM_CLICKED, hitParams);
    }

    public async hitAppUserLoggedOut() {
        await this.dispatchEvent(EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED_OUT);
    }

    public async hitAppUserNavigated(
        params: EventParams<EVENT.ACTIVITY_EVENTS_APP_USER_NAVIGATED>,
    ) {
        this.place = params.place;
        await this.dispatchEvent(EVENT.ACTIVITY_EVENTS_APP_USER_NAVIGATED, params);
    }

    public hitRegistrationSuccess = () => {
        TagManager.dataLayer({ dataLayer: DATA_LAYER_REGISTRATION_EVENT });
    };

    public hitPaymentPathStep = (step: DATA_LAYER_PAYMENT_STEPS, offer?: IPaymentOfferEvent) => {
        switch (step) {
            case DATA_LAYER_PAYMENT_STEPS.OFFER:
                return offer
                    ? TagManager.dataLayer({ dataLayer: DATA_LAYER_PAYMENT_OFFER_EVENT(offer) })
                    : {};
            case DATA_LAYER_PAYMENT_STEPS.METHOD:
                return TagManager.dataLayer({ dataLayer: DATA_LAYER_PAYMENT_METHOD_EVENT });

            case DATA_LAYER_PAYMENT_STEPS.CHECKOUT:
                return TagManager.dataLayer({ dataLayer: DATA_LAYER_PAYMENT_CHECKOUT_EVENT });
        }
    };

    public hitTransactionSuccess = (order: ITransactionEvent) => {
        TagManager.dataLayer({ dataLayer: DATA_LAYER_TRANSACTION_EVENT(order) });
    };

    public updateParams(params: ActivityEventsUpdateHandlerParams) {
        this.stats?.onParamsUpdate.post(params);
    }

    public hit404Error = () => {
        TagManager.dataLayer({ dataLayer: { event: GTM_EVENTS.ERROR_404 } });
    };

    public hitViewItem = (media: MediaDetailsModel) => {
        TagManager.dataLayer({ dataLayer: DATA_LAYER_VIEW_ITEM_EVENT(media) });
    };

    public hitAddToCart = (media: MediaDetailsModel) => {
        TagManager.dataLayer({ dataLayer: DATA_LAYER_ADD_TO_CART_EVENT(media) });
    };

    public hitViewPacket = (packet: GetProductOut) => {
        TagManager.dataLayer({ dataLayer: DATA_LAYER_VIEW_PACKET_EVENT(packet) });
    };

    public hitZetaIsLogged = (isLogged: boolean) => {
        TagManager.dataLayer({
            dataLayer: {
                event: GTM_EVENTS.IS_LOGGED,
                zeta: {
                    isLogged: isLogged,
                },
            },
        });
    };

    public hitZetaUserPackets = (possesions: AccessProductModel[]) => {
        TagManager.dataLayer({ dataLayer: DATA_LAYER_CHECK_POSSESSED_PACKETS_EVENT(possesions) });
    };

    private async dispatchEvent(event: EVENT, params?: any) {
        if (this.stats && !isServer) {
            if (isStatsDebugEnabled) {
                debug.log('Stats module: dispatching event', event, 'with params', params);
            }

            switch (event) {
                case EVENT.ACTIVITY_EVENTS_APP_STARTED:
                    await this.stats.AppStarted();
                    break;
                case EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED:
                    await this.stats.AppUserLogged(params);
                    break;
                case EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED_OUT:
                    await this.stats.AppUserLoggedOut();
                    break;
                case EVENT.ACTIVITY_EVENTS_APP_USER_NAVIGATED:
                    await this.stats.AppUserNavigated(params);
                    break;
                case EVENT.ACTIVITY_EVENTS_APP_PAUSED:
                    await this.stats.AppPaused();
                    break;
                case EVENT.ACTIVITY_EVENTS_APP_RESUMED:
                    await this.stats.AppResumed();
                    break;
                case EVENT.ACTIVITY_EVENTS_APP_USER_ITEM_CLICKED:
                    await this.stats.AppUserItemClicked(params);
                    break;
            }
        }
    }
}

export default StatsModule;
