import { GetSubCategoriesOut } from '@/interfaces/from-schemas/navigation/getSubCategoriesOut';
import SubcategoriesModel from '@/models/category/subcategories.model';

export const getSubcategoryId = (
    subcategories: SubcategoriesModel | GetSubCategoriesOut,
    season: string,
): number | undefined => {
    if (subcategories instanceof SubcategoriesModel) {
        return subcategories
            .getResults()
            .find((subcategory) => subcategory.getTitle() === season)
            ?.getId();
    }

    return subcategories.find((subcategory) => subcategory.name === season)?.id;
};
