import { FilterSchema, FlatFilterListSchema } from '@/interfaces/filters-interface';
import slug from 'slug';

export const getFilterFromSlug = (value: string, filtersList: FilterSchema[]): string => {
    const match = filtersList.find((filter) => filter.slug === value);
    return match?.name || value;
};

export const adaptFilterLists = (flatFilterLists: FlatFilterListSchema[]): FilterSchema[] => {
    if (!Array.isArray(flatFilterLists)) {
        return [];
    }
    return flatFilterLists?.flatMap((filterList) =>
        filterList.filters.map((filter) => ({
            ...filter,
            slug: slug(filter.value),
        })),
    );
};

export const addSlugToFliterLists = (flatFilterLists?: FlatFilterListSchema[]) => {
    return flatFilterLists
        ? flatFilterLists.map((filterList: any) => {
              return {
                  ...filterList,
                  filters: filterList.filters.map((filter: any) => ({
                      ...filter,
                      slug: slug(filter.value),
                  })),
              };
          })
        : [];
};
