import { FORMAT_DATE_MODE, LOCALE, TARGET_LOCALE, TimeLeftTypes } from '@/types/date.type';

import add from 'date-fns/add';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import enUS from 'date-fns/locale/en-US';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import isYesterday from 'date-fns/isYesterday';
import parseISO from 'date-fns/parseISO';
import pl from 'date-fns/locale/pl';
import t from '@/lib/i18n';
import upperFirst from 'lodash/upperFirst';

export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_ONE_DAY = 86400;
export const SECONDS_IN_12H = SECONDS_IN_ONE_DAY / 2;
export const HOURS_IN_ONE_DAY = 24;
export const SECONDS_IN_ONE_MINUTE = 60;

const locales = { [LOCALE.PL]: pl, [LOCALE.EN]: enUS };

const getStyle = (mode: FORMAT_DATE_MODE) => {
    switch (mode) {
        case FORMAT_DATE_MODE.DATE_WITH_YEAR:
            return 'dd.MM.yyyy';
        case FORMAT_DATE_MODE.ISO_8601:
            return 'yyyy-MM-dd';
        case FORMAT_DATE_MODE.DATE:
            return 'dd.MM';
        case FORMAT_DATE_MODE.HOURS:
            return 'HH:mm';
        case FORMAT_DATE_MODE.FULL_DATE:
            return 'dd.MM.yyyy HH:mm';
        case FORMAT_DATE_MODE.LIVE_ARABIC_FORMAT:
            return 'eeee dd.MM';
        case FORMAT_DATE_MODE.LIVE:
            return 'eeee dd MM';
        case FORMAT_DATE_MODE.MINUTES_SECONDS:
            return 'mm:ss';
        case FORMAT_DATE_MODE.HOURS_MINUTES_SECONDS:
            return 'HH:mm:ss';
        case FORMAT_DATE_MODE.LIVE_ARABIC_WITH_MONTH:
            return 'eeee, dd MMMM ';

        default:
            return 'dd.MM.yyyy';
    }
};

const romanNumerals = (input: string) => {
    switch (input) {
        case '01':
            return 'I';
        case '02':
            return 'II';
        case '03':
            return 'III';
        case '04':
            return 'IV';
        case '05':
            return 'V';
        case '06':
            return 'VI';
        case '07':
            return 'VII';
        case '08':
            return 'VIII';
        case '09':
            return 'IX';
        case '10':
            return 'X';
        case '11':
            return 'XI';
        case '12':
            return 'XII';
    }
};

export const weekdayName = (input: number): string => {
    switch (input) {
        case 0:
            return 'sunday';
        case 1:
            return 'monday';
        case 2:
            return 'tuesday';
        case 3:
            return 'wednesday';
        case 4:
            return 'thursday';
        case 5:
            return 'friday';
        case 6:
            return 'saturday';
        default:
            return '';
    }
};

const getLocaleFormat = (targetLocale: TARGET_LOCALE): LOCALE => targetLocale.slice(0, 2) as LOCALE;

export const getTodayLabel = (date: string): string => {
    return isToday(parseISO(date)) ? t('date.today') : '';
};

export const getYesterdayLabel = (date: string): string => {
    return isYesterday(parseISO(date)) ? t('date.yesterday') : '';
};

export const getTomorrowLabel = (date: string): string => {
    return isTomorrow(parseISO(date)) ? t('date.tomorrow') : '';
};

export const formatDate = (date: string, formatStyle: FORMAT_DATE_MODE): string => {
    if (!date) return '';

    const style = getStyle(formatStyle);
    const locale = locales[getLocaleFormat(process.env.locale)];
    const liveFormat = format(parseISO(date), style, {
        locale,
    });
    if (
        formatStyle === FORMAT_DATE_MODE.LIVE ||
        formatStyle === FORMAT_DATE_MODE.LIVE_ARABIC_FORMAT ||
        formatStyle === FORMAT_DATE_MODE.LIVE_ARABIC_WITH_MONTH
    ) {
        const yesterdayLabel = getYesterdayLabel(date);
        const todayLabel = getTodayLabel(date);
        const tomorrowLabel = getTomorrowLabel(date);
        if (todayLabel || tomorrowLabel || yesterdayLabel) {
            const dayLabel = upperFirst(todayLabel || tomorrowLabel || yesterdayLabel);
            const dateLabel = format(parseISO(date), getStyle(FORMAT_DATE_MODE.DATE), {
                locale,
            });

            return `${dayLabel} ${upperFirst(dateLabel)}`;
        }
    }
    if (formatStyle === FORMAT_DATE_MODE.LIVE) {
        return `${liveFormat.slice(0, -2)} ${romanNumerals(liveFormat.slice(-2))}`;
    }
    if (
        formatStyle === FORMAT_DATE_MODE.LIVE_ARABIC_FORMAT ||
        formatStyle === FORMAT_DATE_MODE.LIVE_ARABIC_WITH_MONTH
    ) {
        return `${upperFirst(liveFormat)}`;
    }

    return format(parseISO(date), style, {
        locale,
    });
};

export function minutesLeftFromNow(time: string): number {
    const minutes = differenceInMinutes(new Date(time), new Date());
    return minutes > 0 ? minutes : 0;
}

export const formatNumericalDuration = (timeSeconds: number, withHours = false) =>
    format(
        new Date(0, 0, 0, 0, 0, timeSeconds),
        getStyle(
            withHours ? FORMAT_DATE_MODE.HOURS_MINUTES_SECONDS : FORMAT_DATE_MODE.MINUTES_SECONDS,
        ),
    );

export function convertSecondsToHours(seconds: number): number {
    return seconds / SECONDS_IN_HOUR;
}

export function convertSecondsToDays(seconds: number): number {
    return seconds / SECONDS_IN_ONE_DAY;
}

export function parseToTimestamp(date: string): number {
    return parseISO(date).getTime();
}

export function getTimestampWithDiff(timestampDiff?: number): number | undefined {
    if (typeof timestampDiff === 'undefined') {
        return;
    }

    const clientDate = new Date();
    return clientDate.getTime() + timestampDiff;
}

export const tomorrowDate = add(new Date(), { days: 1 });
export const nextWeekDate = add(new Date(), { weeks: 1 });
export const nextMonthDate = add(new Date(), { months: 1 });

export const getStatsDate = () => {
    const date = new Date();

    // respect timezone offset
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
};

export const calculateTimeDifference = (date: string) => {
    const premiereDate = parseISO(date);
    const currTime = new Date();
    const totalSeconds = (premiereDate.getTime() - currTime.getTime()) / 1000;
    const timeLeft: TimeLeftTypes = {
        totalSeconds,
        hours: Math.floor((totalSeconds / SECONDS_IN_HOUR) % HOURS_IN_ONE_DAY),
        minutes: Math.floor(totalSeconds / SECONDS_IN_ONE_MINUTE) % SECONDS_IN_ONE_MINUTE,
    };
    return timeLeft;
};

export const secondsToMiliseconds = (seconds: number): number => {
    return seconds * 1000;
};

export const milisecondsToSeconds = (miliseconds: number): number => {
    return miliseconds / 1000;
};
