import { Nav, NAVIGATION_KEYS, NavTree, navVertical } from 'nav-tree';
import React, { useCallback } from 'react';

import { useColumnContext } from '@/contexts/epg-table/column.context';

import { EPGTableArrows, EPGTableTopButtons } from './EPGControls';
import { focusProgramInColumn, isTopButtonFocused } from './nav-helpers';
import { EPGPreview } from './Preview/Preview';
import * as Styled from './styles';
import { EPGTableContent } from './TableContent';
import { EPGTableProps } from './types';

const EPGTableRaw = ({ allChannels, focusRef, isFetching }: EPGTableProps) => {
    const { firstColumn } = useColumnContext();

    const handleNavigation = useCallback(
        (navKeys: NAVIGATION_KEYS, navTree: NavTree) => {
            if (!isTopButtonFocused(navKeys, navTree.getFocusedNode(false)) || !navTree.parent) {
                return navVertical(navKeys, navTree);
            }

            const tree = focusRef.current?.getChildContext()?.tree;

            if (tree) {
                focusProgramInColumn(tree, firstColumn.id);

                return null;
            }
        },
        [firstColumn.id, focusRef],
    );

    return (
        <Styled.Table>
            <EPGPreview />
            <Styled.MainNavigation func={handleNavigation} restoreLastFocus>
                <EPGTableTopButtons focusRef={focusRef} />
                {/* @ts-ignore */}
                <Nav restoreLastFocus>
                    {!isFetching && (
                        <EPGTableContent allChannels={allChannels} focusRef={focusRef} />
                    )}
                </Nav>
                <EPGTableArrows position="bottom" />
            </Styled.MainNavigation>
        </Styled.Table>
    );
};

export const EPGTable = React.memo(EPGTableRaw);
