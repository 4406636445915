import { useEffect, useMemo, useState } from 'react';
import { useGetProducts } from '../navigation/use-get-products';
import { useGetPossessions } from './use-get-possessions';
import { AccessProductModel } from '@/models/payments/access-product-model';
import { useGetProductSubscriptions } from './use-get-product-subscriptions';
import { useGetPartners } from '../navigation/use-get-partners';
import { compareProducts } from '@/helpers/product.helper';
import { useGetCommonlyAccessiblePackets } from '../navigation/use-get-commonly-accessible-packets';
import { useGetPrePurchaseDataList } from './use-get-pre-purchase-data';
import { MULTIPLE_PRODUCTS_SUBTYPE, PRODUCT_TYPES } from '@/types/media.type';
import { PartnerModel } from '@/models/system/partner.model';
import { filterPossessions, sortPossessionsByDate } from '@/helpers/possession.helper';

export const useGetMyAccess = () => {
    const [gridElements, setGridElements] = useState<AccessProductModel[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const {
        data: possessionsData,
        isFetching: isFetchingPossessions,
        error: possesionError,
    } = useGetPossessions();

    const {
        data: commonlyAccessiblePackets,
        isFetching: isFetchingCommonlyAccessiblePackets,
        error: commonlyAccessiblePacketsError,
    } = useGetCommonlyAccessiblePackets();
    const getProductsParams = possessionsData
        ? {
              products: possessionsData.map((el) => {
                  return el.getProduct();
              }),
          }
        : false;
    const getCommonlyAccessiblePacketsProductsParams = commonlyAccessiblePackets
        ? {
              products: commonlyAccessiblePackets.map((el) => {
                  return el.getProduct();
              }),
          }
        : false;

    const { data: products, isFetching: isFetchingProducts, error: productsError } = useGetProducts(
        getProductsParams,
    );

    const {
        data: accessiblePacketsProducts,
        isFetching: isFetchingAccessiblePacketsProducts,
        error: accessiblePacketsProductsError,
    } = useGetProducts(getCommonlyAccessiblePacketsProductsParams);

    const {
        data: productSubscriptions,
        isFetching: isFetchingProductSubscriptions,
        error: productSubscriptionsError,
    } = useGetProductSubscriptions();

    const getPrePurchaseDataListParams = useMemo(
        () =>
            products?.map((packet) => ({
                id: packet.getId(),
                type: PRODUCT_TYPES.MULTIPLE,
                subType: MULTIPLE_PRODUCTS_SUBTYPE.PACKET,
            })),
        [products],
    );

    const {
        data: packetsPrePurchaseData,
        isFetching: isFetchingPacketsPrePurchaseData,
        error: packetsPrePurchaseDataError,
    } = useGetPrePurchaseDataList({
        products: getPrePurchaseDataListParams,
        withoutMinPriceProducts: true,
    });
    const { data: partners, isFetching: isFetchingPartners, error: partnersError } = useGetPartners(
        {},
    );

    const isFetchingData = [
        isFetchingPossessions,
        isFetchingProducts,
        isFetchingProductSubscriptions,
        isFetchingPartners,
        isFetchingAccessiblePacketsProducts,
        isFetchingCommonlyAccessiblePackets,
        isFetchingPacketsPrePurchaseData,
    ].some((isFetching) => isFetching === true);

    const error =
        possesionError ||
        commonlyAccessiblePacketsError ||
        productsError ||
        accessiblePacketsProductsError ||
        productSubscriptionsError ||
        packetsPrePurchaseDataError ||
        partnersError;

    useEffect(() => {
        const purchaseElements: AccessProductModel[] = [];

        if (!isFetchingData) {
            if (possessionsData && possessionsData.length > 0) {
                const sortedPossessions = possessionsData.sort(sortPossessionsByDate);
                const filteredPossessions = filterPossessions(sortedPossessions);

                filteredPossessions.forEach((possession) => {
                    const product = products.find((el) => {
                        return compareProducts(possession.getProductId(), el.getProductId());
                    });

                    const subscription = productSubscriptions?.find((el) => {
                        return el.getProduct().id === product?.getId();
                    });

                    const packetsPrePurchase = packetsPrePurchaseData?.find((el) => {
                        return el.getProduct().id === product?.getId();
                    });

                    const partnerId = possession.getPartnerId() || '';
                    const partner = partners[partnerId] && new PartnerModel(partners[partnerId]);

                    const newProduct = new AccessProductModel(
                        possession,
                        product,
                        packetsPrePurchase,
                        subscription,
                        partner,
                    );

                    purchaseElements.push(newProduct);
                });
            }

            commonlyAccessiblePackets?.forEach((accessiblePacket) => {
                const possession = accessiblePacket;

                const product = accessiblePacketsProducts.find((el) => {
                    return compareProducts(possession.getProductId(), el.getProductId());
                });

                const newProduct = new AccessProductModel(possession, product);

                purchaseElements.push(newProduct);
            });

            if (purchaseElements.length) {
                setGridElements(purchaseElements);
            }

            setIsFetching(false);
        }
    }, [isFetchingData]);

    return {
        data: gridElements,
        isFetching,
        error,
    };
};
