import React from 'react';
import { IMPERATIVE_SCROLL_DIRECTION, NAV_SCROLL_DIRECTION, navVertical } from 'nav-tree';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import { Icon, ICONS } from '@/components/shared/Icon';
import {
    IconWrapper,
    OptionListContainer,
    OptionListWrapper,
} from '@/components/views/Account/AccountOption/AccountOptionList/styles';
import { DeleteOption } from '@/components/views/Account/AccountOption/DeleteOption';
import { EmailOption } from '@/components/views/Account/AccountOption/EmailOption';
import { EuropeanUnionOption } from '@/components/views/Account/AccountOption/EuropeanUnionOption';
import { PasswordOption } from '@/components/views/Account/AccountOption/PasswordOption';
import { useNavScroll } from '@/hooks/nav-tree/use-nav-scroll.hook';
import { useGetCurrentAuthSession } from '@/hooks/use-get-current-auth-session';
import { AUTH_PROVIDERS } from '@/types/auth-providers.type';

const isEuVerificationAvailable = process.env.isEuVerificationAvailable;

export const AccountOptionList = () => {
    const isChangePasswordAvailable = process.env.isChangePasswordAvailable;
    const isDeleteAccountAvailable = process.env.isDeleteAccountAvailable;
    const { data, error } = useGetCurrentAuthSession();
    const isVerified = data?.isUserVerified() ?? false;
    const hasVerifiedMsisdn = data?.getUser().hasVerifiedMsisdn() ?? false;
    const isNativeAccount = data?.getUser().getAccountTypes().includes(AUTH_PROVIDERS.NATIVE);

    const { navRef, scrollOptions, autofocusOn, scrollState, onScrollCallback, imperativeScroll } =
        useNavScroll(NAV_SCROLL_DIRECTION.VERTICAL);

    const renderErrors = () =>
        error ? (
            <AlertMessage
                type={MESSAGE_TYPE.ERROR}
                userMessage={error.getUserMessage() as string}
            />
        ) : null;

    return (
        <OptionListContainer>
            <IconWrapper onClick={() => imperativeScroll(IMPERATIVE_SCROLL_DIRECTION.UP)}>
                {scrollState.isScrollableUp && <Icon name={ICONS.ARROW_UP} />}
            </IconWrapper>
            {data && (
                <OptionListWrapper
                    ref={navRef}
                    func={navVertical}
                    scrollOptions={scrollOptions}
                    onNavScroll={onScrollCallback}
                    autofocusOn={autofocusOn}
                >
                    <EmailOption email={data.getUser().getEmail() ?? ''} />
                    {isNativeAccount && isChangePasswordAvailable && <PasswordOption />}
                    {isEuVerificationAvailable && (
                        <EuropeanUnionOption confirmed={hasVerifiedMsisdn || isVerified} />
                    )}
                    {isNativeAccount && isDeleteAccountAvailable && <DeleteOption />}
                </OptionListWrapper>
            )}
            <IconWrapper onClick={() => imperativeScroll(IMPERATIVE_SCROLL_DIRECTION.DOWN)}>
                {scrollState.isScrollableDown && <Icon name={ICONS.ARROW_DOWN} />}
            </IconWrapper>
            {renderErrors()}
        </OptionListContainer>
    );
};
