import { Avatar, AVATAR_SIZE } from '@/components/shared/Avatar';
import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import t from '@/lib/i18n';
import { Router, useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';
import { Nav } from 'nav-tree';
import React, { useContext, useRef, useState } from 'react';
import {
    ProfileLabelStyled,
    SubLabelStyled,
    MenuProfileItemIconStyled,
    MenuProfileItemStyled,
    MenuProfileLabelWrapper,
} from './styles';
import { FocusRefType, MenuProfileItemProps } from './types';
import { useFocusPath } from './use-focus-path.hook';
import { truncateString } from '@/helpers/truncate-string.helper';
import { useSessionProfile } from '@/contexts/profile/profile.hooks';

const profilesEnabled = process.env.user.profilesEnabled;

const MenuProfileItem = ({ isMenuActive, onFocusPath }: MenuProfileItemProps) => {
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);
    const router = useRouter();
    const [sessionProfile] = useSessionProfile();
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const onItemEnter = () => {
        const route = profilesEnabled ? ROUTES.ACCOUNT_CHANGE_PROFILE : ROUTES.ACCOUNT;

        Router.pushRoute(route);
    };

    const focusRef = useRef<Nav>(null) as FocusRefType;

    useFocusPath({
        focusRef,
        onFocusPath,
        enabled: !isMenuActive && router.asPath.includes(`/${t('home-menu.url.panel')}/`),
    });

    const profileAvatar = sessionProfile?.avatarUrl;
    const profileName = sessionProfile?.name;

    return (
        // @ts-ignore
        <Nav
            ref={focusRef}
            navId={t(`home-menu.url.panel`)}
            onNav={setIsFocused}
            onEnterDown={onItemEnter}
            onClick={onItemEnter}
            autofocusOn={isMagicMouseVisible ? ['mouseenter'] : undefined}
            tabIndex={0}
            title={`${t('home-menu.profile')} ${profileName}`}
            aria-label={`${t('home-menu.profile')} ${profileName}`}
        >
            <MenuProfileItemStyled>
                <MenuProfileItemIconStyled>
                    <Avatar $size={AVATAR_SIZE.SMALL} url={profileAvatar} />
                </MenuProfileItemIconStyled>
                {isMenuActive && (
                    <MenuProfileLabelWrapper>
                        <ProfileLabelStyled active={isFocused}>
                            {t('home-menu.profile')}
                        </ProfileLabelStyled>
                        {profilesEnabled && profileName && (
                            <SubLabelStyled>
                                {truncateString(profileName, process.env.user.maxProfileNameLength)}
                            </SubLabelStyled>
                        )}
                    </MenuProfileLabelWrapper>
                )}
            </MenuProfileItemStyled>
        </Nav>
    );
};

export const MenuProfileItemMemo = React.memo(MenuProfileItem);
