import { Icon, ICONS } from '@/components/shared/Icon';
import { focusNavId } from '@/helpers/nav-tree/focus-nav-id.helper';
import { useNavScroll } from '@/hooks/nav-tree/use-nav-scroll.hook';
import { BUTTON_ID } from '@/types/button-id.type';
import {
    IMPERATIVE_SCROLL_DIRECTION,
    NAV_SCROLL_DIRECTION,
    navVertical,
    NAVIGATION_KEYS,
    NavTree,
    navHorizontal,
} from 'nav-tree';
import React, { useCallback, useEffect } from 'react';
import {
    FilterListRootContainer,
    FilterListWrapperStyledComponent,
    IconWrapper,
    RightOpenFilterStyled,
} from '../FilterList/styles';
import { SubcategoryMenuTitle } from './styles';
import { RightPanelProps } from './types';

// TODO(aprzychodzien) ujednolicić kod aby RightPanel obejmował też listę filtrów
export const RightPanel = ({
    selectedId,
    focused,
    menuItems,
    name,
    onShowMenu,
}: RightPanelProps) => {
    const { navRef, scrollOptions, autofocusOn, scrollState, onScrollCallback, imperativeScroll } =
        useNavScroll(NAV_SCROLL_DIRECTION.VERTICAL);

    useEffect(() => {
        if (selectedId) {
            navRef.current?.imperativeFocus([`${focused}`]);
            navRef.current?.scrollToOffScreenFocusedElement();
        }
    }, []);

    const func = useCallback((navKeys: NAVIGATION_KEYS, navTree: NavTree) => {
        if (navKeys === NAVIGATION_KEYS.LEFT) {
            focusNavId(navTree, BUTTON_ID.CATALOG_BTN);

            return;
        }

        return navHorizontal(navKeys, navTree);
    }, []);

    return (
        <>
            <RightOpenFilterStyled />
            <FilterListRootContainer func={func} onMouseLeave={() => onShowMenu(false)}>
                <IconWrapper onClick={() => imperativeScroll(IMPERATIVE_SCROLL_DIRECTION.UP)}>
                    {scrollState.isScrollableUp && <Icon name={ICONS.ARROW_UP} />}
                </IconWrapper>
                <FilterListWrapperStyledComponent
                    ref={navRef}
                    func={navVertical}
                    scrollOptions={scrollOptions}
                    autofocusOn={autofocusOn}
                    onNavScroll={onScrollCallback}
                    defaultFocused={true}
                    restoreLastFocus
                >
                    {name && <SubcategoryMenuTitle>{name}</SubcategoryMenuTitle>}
                    {menuItems}
                </FilterListWrapperStyledComponent>
                <IconWrapper onClick={() => imperativeScroll(IMPERATIVE_SCROLL_DIRECTION.DOWN)}>
                    {scrollState.isScrollableDown && <Icon name={ICONS.ARROW_DOWN} />}
                </IconWrapper>
            </FilterListRootContainer>
        </>
    );
};
