import { findEpgStartProgramTimeByCurrentTime } from '@/helpers/player.helper';
import { usePlayerJump } from '@/hooks/player/use-player-jump';
import { usePlayerTimeMarkers } from '@/hooks/player/use-player-time-markers';
import { useWalkingEPG } from '@/hooks/player/use-walking-epg';
import { useCallback } from 'react';
import { IconButton } from '../../../Buttons/PlayableButton/IconButton';
import { ICONS } from '../../../Icon';
import t from '@/lib/i18n';

interface IGoToPositionStartButtonProps {
    channelId?: string;
}

export const GoToPositionStartButton = ({ channelId = '' }: IGoToPositionStartButtonProps) => {
    const {
        currentTime,
        skipRange: { start },
    } = usePlayerTimeMarkers();
    const jump = usePlayerJump();

    const { programs } = useWalkingEPG({
        refTime: start * 1000,
        channelId,
    });

    const onIconBtnHandler = useCallback(() => {
        let startTime = findEpgStartProgramTimeByCurrentTime(programs, currentTime * 1000);

        if (!startTime) {
            startTime = start;
        }

        jump(startTime / 1000);
    }, [programs, currentTime, start]);

    return (
        <IconButton
            icon={ICONS.SPIN_LEFT}
            onButtonAction={onIconBtnHandler}
            title={t('WCAG.aria-label.go-to-position-start-button')}
        />
    );
};
