import { createContext, useState } from 'react';
import { GuiStateContextInterface, GuiStateContextProviderProps } from './types';
import { IPlayerSeekingParams } from '@/interfaces/player.interface';

export const GuiStateContext = createContext<GuiStateContextInterface>({
    tmpPlaybackCurrentTime: 0,
    setTmpPlaybackCurrentTime: () => null,
    pendingSeek: null,
    setPendingSeek: () => null,
    seekHandlePosition: 0,
    setSeekHandlePosition: () => null,
});

export const GuiStateContextProvider = ({ children }: GuiStateContextProviderProps) => {
    const [tmpPlaybackCurrentTime, setTmpPlaybackCurrentTime] = useState(0);
    const [pendingSeek, setPendingSeek] = useState<IPlayerSeekingParams | null>(null);
    const [seekHandlePosition, setSeekHandlePosition] = useState(0);

    return (
        <GuiStateContext.Provider
            value={{
                tmpPlaybackCurrentTime,
                setTmpPlaybackCurrentTime,
                pendingSeek,
                setPendingSeek,
                seekHandlePosition,
                setSeekHandlePosition,
            }}
        >
            {children}
        </GuiStateContext.Provider>
    );
};
