import styled, { css } from 'styled-components';
import {
    ButtonContentProps,
    ActiveProps,
    StyledButtonContentProps,
    IconButtonNavProps,
    PLAYABLE_VARIANT,
} from './types';
import { Nav } from 'nav-tree';
import { CheckSlowDevice } from '@/helpers/check-slow-device.helper';
import { fillIcon } from '@/helpers/fill-icon.helper';

const buildCommonButtonContent = ({
    active,
    theme,
    transparentOnInactive,
    variant,
}: StyledButtonContentProps) => {
    return css`
        font-size: ${active ? '2.4rem' : '2.1rem'};
        font-weight: 500;
        transition: ${CheckSlowDevice(theme.button.transitionValue)};
        ${buildStyles(theme, transparentOnInactive, variant, active)};
        border-radius: inherit;
    `;
};

const buildStyles = (
    theme: any,
    transparentOnInActive?: boolean,
    variant?: PLAYABLE_VARIANT,
    active?: boolean,
) => {
    const { primary } = theme.button.playable;

    switch (variant) {
        case PLAYABLE_VARIANT.PRIMARY:
            return css`
                background-color: ${active ? primary.activeBgColor : primary.bgColor};
            `;
    }

    if (!active) {
        if (transparentOnInActive) {
            return css`
                background-color: transparent;
            `;
        }

        return css`
            background-color: ${theme.button.bgColor};
        `;
    }
};

export const StyledIconWrapper = styled.span<ActiveProps & { iconSize?: number }>((props) => {
    const { active, iconSize = 2, theme } = props;
    const iconSizeWithRem = `${iconSize}rem`;

    return css`
        display: inline-block;
        height: ${iconSizeWithRem};
        line-height: 1;
        min-width: ${iconSizeWithRem};
        vertical-align: middle;
        width: ${iconSizeWithRem};
        ${active ? fillIcon(theme.button.iconBgColor) : fillIcon(theme.text.color.secondary)};
    `;
});

export const StyledDynamicButtonContent = styled.div<ButtonContentProps>`
    display: flex;
    align-items: center;
    height: 5.4rem;
    padding: ${({ active }) => (active ? '.2rem 2.4rem' : '.2rem 1.8rem')};
    width: ${({ isFullyVisible, active }) => (active || isFullyVisible ? `auto` : '5.4rem')};

    ${(props) => buildCommonButtonContent(props)};
`;

export const StyledButtonContent = styled.div<StyledButtonContentProps>`
    overflow: hidden;
    align-items: center;
    display: flex;
    height: 5.4rem;
    justify-content: center;
    margin: auto;
    padding: ${({ onlyIcon }) => (onlyIcon ? '.2rem 1.6rem' : '0.2rem 4.2rem')};
    width: ${({ onlyIcon }) => onlyIcon && '5.4rem'};

    ${({ active, theme }) => !active && fillIcon(theme.text.color.secondary)};

    ${(props) => buildCommonButtonContent(props)};
`;

export const StyledText = styled.span`
    vertical-align: middle;
    padding-left: 0.8rem;
`;

export const StyledDynamicText = styled(StyledText)<ActiveProps>`
    overflow: hidden;
    white-space: nowrap;
    float: left;
    width: ${({ active }) => !active && '0rem'};
`;

export const IconButtonNav = styled(Nav as any)<IconButtonNavProps>`
    ${({ iconSize }) =>
        iconSize === 'small'
            ? `width: 6rem;
        height: 6rem;`
            : `width: 10rem;
        height: 10rem;`}

    button {
        cursor: pointer;
        outline: 0;
        border-radius: 50%;
        height: 100%;
        width: 100%;
        border: 0.2rem solid ${({ theme }) => theme.button.primaryBorderColor};
        background-color: ${({ theme }) => theme.button.primaryBgColor};
        ${({ theme }) => fillIcon(theme.text.color.primary)};
        transition: all 0.2s linear;
    }

    svg {
        vertical-align: middle;
        width: 3rem;
        height: 3rem;
    }

    &.nav-focused {
        button {
            background-color: ${({ theme }) => theme.button.activeBgColor};
            border-width: 0;
            ${({ theme }) => fillIcon(theme.button.iconBgColor)};
            transform: scale(1.1);
        }
    }
`;
