export type PLAYER_STATE =
    | IPlayer.PLAYER_STATE
    | 'BUFFERING_PLAYING_LINEAR_AD'
    | 'BUFFERING_PAUSED_LINEAR_AD'
    | 'BUFFERING_PLAYING_CONTENT'
    | 'BUFFERING_PAUSED_CONTENT';

export enum PlayerErrorGroup {
    UNKNOWN = 'UNKNOWN',
    NETWORK = 'NETWORK',
    ACCESS = 'ACCESS',
    CONCURRENCY_LIMIT = 'CONCURRENCY_LIMIT',
    DRM = 'DRM',
    DRM_UNAVAILABLE = 'DRM_UNAVAILABLE',
    AD = 'AD',
    AD_BLOCKER_DETECTED = 'AD_BLOCKER_DETECTED',
}

export enum SEEKING_DIR {
    BACK = 'back',
    FORWARD = 'forward',
}

export interface IPlayerSeekingParams {
    step: number;
    desiredTime: number;
    dir: SEEKING_DIR;
    refTime: {
        maxTime: number;
        minTime: number;
        duration: number;
    };
}
export interface BackendInfoParams {
    errorCode: number;
    message: string;
    messageID: string;
    method: string;
    url: string;
    userMessage: string;
}
export interface IPlayerStatus {
    error?: {
        group: PlayerErrorGroup;
        description?: string;
        code?: number;
        backendInfo?: BackendInfoParams;
    };
    playback: {
        duration: number;
        adBreaks: number[];
    };
    playbackOptions: IPlayer.PlaybackOptions | null;
    recomendations: IPlayer.MediaInfo[] | null;
    areRecomendationsShown: boolean;
    state: PLAYER_STATE;
    buffering: boolean;
    phase: IPlayer.PLAYER_PHASE;
    ad?: IPlayer.Ad;
    castStatus?: IPlayer.CastStatus;
    castSessionState?: IPlayer.CAST_SESSION_STATE;
    isReadyToPlayNext?: boolean;
    isAllowedToSkipZone?: boolean;
}

export interface IPlayerTimeMarkers {
    currentTime: number;
    bufferedTime: number;
    adTimeLeft: number;
    skipRange: { start: number; end: number };
    bandwidth?: number;
}

export enum ChannelSwitchPhase {
    INITIAL = 'INITIAL',
    AWAITING_BUFFERING = 'AWAITING_BUFFERING',
    BUFFERING = 'BUFFERING',
    DONE = 'DONE',
}

export enum ChannelSwitchAction {
    BUFFERING_START,
    BUFFERING_END,
    CHANNEL_CHANGED,
}

export interface SubtitlesOptionsInterface {
    size: {
        label: string;
        value: number;
        active?: boolean;
    }[];
}

export type SubtitlesSelectedOptions = Partial<{
    [key in keyof SubtitlesOptionsInterface]: SubtitlesOptionsInterface[key][0]['value'];
}>;

export interface PlayerContainerProps {
    subtitlesOptions?: SubtitlesSelectedOptions;
    isHighContrast?: boolean;
}

export enum PLAYER_MEDIA_TYPE {
    VOD = 'vod',
    LIVE = 'live',
    MOVIE = 'movie',
}

export enum PLAYER_OVERLAY {
    TERAVOLT = 'teravolt',
}
