import { useMemo, useState } from 'react';
import { Nav } from 'nav-tree';
import t from '@/lib/i18n';
import { useDebouncedEffect } from '@/hooks/use-debounced-effect.hook';
import { ChannelModel } from '@/models/channels/channel.model';
import { EPGCandidateSlider } from '../EPG/EPGCandidateSlider';
import { EPGChannelsSlider } from '../EPG/EPGChannelsSlider';
import { EPGSlider } from '../EPG/EPGSlider';
import { PlaybackTime } from '../shared/PlaybackTime';
import { PlayerProgressBar } from '../shared/PlayerProgressBar';
import { FlexBox, FlexItem, MediaSubtitle } from '../shared/styles';
import { TVBottomBarContainer } from './styles';
import { TVTitleBar } from './TVTitleBar';
import { ITVBottomBarProps } from './types';
import useGetChannelList from '@/api/navigation/channels/use-get-channel-list';
import { useChannelsAccessMap } from '@/hooks/player/use-channels-access-map';
import { Icon, ICONS } from '@/components/shared/Icon';
import { useTimeshiftingSupported } from '@/hooks/player/use-timeshifting-supported';

export const TVBottomBar = ({
    awaitingChange,
    nowPlayingChannelId,
    channelTitle,
    showEPG,
    showChannels,
    onChangeShowEPG,
    onChangeShowChannels,
    onChangeChannelToBuy,
}: ITVBottomBarProps) => {
    const { data } = useGetChannelList({});
    const channels = useMemo(() => data.getResults(), [data]);
    const { channelsAccessMap } = useChannelsAccessMap(channels);
    const [candidateChannel, setCandidateChannel] = useState<ChannelModel | null>(null);
    const [isProgramTv, setIsProgramTv] = useState<boolean>(true);
    const isTimeshiftingSupported = useTimeshiftingSupported();
    const restoreCurrentEpg = !showEPG && !showChannels;

    useDebouncedEffect(
        () => {
            if (restoreCurrentEpg) {
                setCandidateChannel(null);
            }
        },
        { timeout: 500 },
        [restoreCurrentEpg],
    );

    const epgChannelTitle = candidateChannel?.getChannelTitle() ?? channelTitle;
    const epgChannelId = candidateChannel?.getId() ?? nowPlayingChannelId;

    return (
        <TVBottomBarContainer showEPG={showEPG} showChannels={showChannels}>
            {/* @ts-ignore */}
            <Nav>
                <FlexBox horizontalMargin="11rem" gutter="5rem">
                    <TVTitleBar channelId={nowPlayingChannelId} />
                </FlexBox>
            </Nav>
            <FlexBox horizontalMargin="11rem" gutter="3.5rem">
                <FlexItem grow shrink allowOverflow>
                    <PlayerProgressBar seekable={isTimeshiftingSupported} />
                </FlexItem>
                <FlexItem paddingLeft="4rem">
                    <PlaybackTime relative live={!isTimeshiftingSupported} />
                </FlexItem>
            </FlexBox>
            <FlexBox>
                <EPGChannelsSlider
                    channels={channels}
                    onNav={onChangeShowChannels}
                    nowPlayingChannelId={nowPlayingChannelId}
                    focusedChannelId={epgChannelId}
                    onFocusChannel={setCandidateChannel}
                    onBuyChannel={onChangeChannelToBuy}
                />
            </FlexBox>
            <FlexBox horizontalMargin="11rem" gutter="0rem">
                <MediaSubtitle>
                    {isProgramTv
                        ? [t('player.tv-program'), epgChannelTitle].join(' - ')
                        : t('player.epg.no-program-tv', { channelTitle: epgChannelTitle })}
                    {!channelsAccessMap[epgChannelId] && <Icon name={ICONS.LOCK_FILLED} />}
                </MediaSubtitle>
            </FlexBox>
            <FlexBox gutter="1rem">
                {candidateChannel &&
                (candidateChannel.getId() !== nowPlayingChannelId || awaitingChange) ? (
                    <EPGCandidateSlider
                        channel={candidateChannel}
                        onNav={onChangeShowEPG}
                        onBuyChannel={onChangeChannelToBuy}
                        setIsProgramTv={setIsProgramTv}
                    />
                ) : (
                    <EPGSlider
                        onNav={onChangeShowEPG}
                        channelId={epgChannelId}
                        timeshiftingDuration={channels
                            .find((channel) => channel.getId() === epgChannelId)
                            ?.getTimeShiftingDuration()}
                        onIsProgramTv={setIsProgramTv}
                    />
                )}
            </FlexBox>
        </TVBottomBarContainer>
    );
};
