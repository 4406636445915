import logo from '@/public/assets/shared/logo.png';
import React from 'react';
import { LogoWrapper } from './styles';

export const WelcomeLogo = () => {
    const altText = process.env.logoAltText;
    return process.env.showWelcomeLogo ? (
        <LogoWrapper>
            {/* // TODO wdrożenie next/images  */}
            <img src={logo.src || (logo as unknown as string)} alt={altText} />
        </LogoWrapper>
    ) : null;
};
