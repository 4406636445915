/**
 * Algorytm transpilowany na podstawie implementacji po stronie GetMedia (Java)
 * Źródło: http://gitlab.vm.redefine.pl/getmedia/hemoroid2/-/blob/master/src/main/java/tv/ipla/hemoroid/lib/GrantExpression.java
 *
 * Docelowo poszukiwane jest rozwiązanie, aby kod algorytmu był jeden (wspólny) i był automatycznie tłumaczony na różne języki (Kotlin, JS, Python, etc.)
 * Aktualne rozwiązanie oparte jest na testach jednostkowych - wszystkie środowiska (GM, Mobile, WWW) powinny mieć rozpisane przypadki zgodnie z wytycznymi (te same przypadki testowe)
 *
 * Więcej informacji na cyfropedii: https://cyfropedia.polsatc/display/PPI/GrantExpression+w+aplikacjach+klienckich
 */

const isTimeGrantExpressionElement = (grantExpressionElement?: string): boolean => {
    if (!grantExpressionElement || grantExpressionElement?.length === 0) {
        return false;
    }

    if (grantExpressionElement.length >= 5 && grantExpressionElement.slice(0, 5) === 'time:') {
        return true;
    }

    return grantExpressionElement.length >= 6 && grantExpressionElement.slice(0, 6) === '!time:';
};

const isTimestampGrantExpressionElement = (grantExpressionElement?: string): boolean => {
    if (grantExpressionElement == null || grantExpressionElement?.length === 0) {
        return false;
    }

    if (grantExpressionElement.charAt(0) == 'T') return true;

    return grantExpressionElement.length >= 2 && grantExpressionElement.slice(0, 2) === '!T';
};

const checkTimestamp = (timestamp: number, grantExpressionMultiElements: string[]): boolean => {
    let elementTimestamp;
    for (const element of grantExpressionMultiElements) {
        if (element && !(element.length === 0) && element.charAt(0) == 'T') {
            try {
                elementTimestamp = Number.parseInt(element.slice(1));
            } catch (e) {
                return false;
            }
            if (timestamp < elementTimestamp) return false;
        } else if (element.length >= 2 && element.slice(0, 2) === '!T') {
            try {
                elementTimestamp = Number.parseInt(element.slice(2));
            } catch (e) {
                return false;
            }
            if (timestamp >= elementTimestamp) return false;
        }
    }

    return true;
};

const getPrefixGroupNumericValues = (prefix: string, group: string[]): number[] => {
    const result: number[] = [];
    for (const groupElement of group) {
        if (groupElement.startsWith(prefix)) {
            const strValue = groupElement.substring(prefix.length + 1);
            try {
                const intValue = Number.parseInt(strValue);
                if (intValue >= 0) {
                    result.push(intValue);
                }
            } catch (e) {
                // Do nothing
            }
        }
    }

    return result;
};

const isElementFulfilledByGroupWithNumericOperator = (
    prefix: string,
    operator: string,
    value: string,
    group: string[],
): boolean => {
    let intValue;
    try {
        intValue = Number.parseInt(value);
    } catch (e) {
        return false;
    }

    const prefixGroupNumericValues = getPrefixGroupNumericValues(prefix, group);

    if (operator === 'gt') {
        for (const groupValue of prefixGroupNumericValues) {
            if (groupValue > intValue) {
                return true;
            }
        }
    } else if (operator === 'gte') {
        for (const groupValue of prefixGroupNumericValues) {
            if (groupValue >= intValue) {
                return true;
            }
        }
    } else if (operator === 'lt') {
        for (const groupValue of prefixGroupNumericValues) {
            if (groupValue < intValue) {
                return true;
            }
        }
    } else if (operator === 'lte') {
        for (const groupValue of prefixGroupNumericValues) {
            if (groupValue <= intValue) {
                return true;
            }
        }
    }
    return false;
};

const isElementFulfilledByGroupWithOperator = (
    prefix: string,
    operator: string,
    value: string,
    group: string[],
): boolean => {
    if (operator === 'eq') {
        return group.includes(`${prefix}:${value}`);
    } else if (operator === 'neq') {
        return !group.includes(`${prefix}:${value}`);
    }
    return isElementFulfilledByGroupWithNumericOperator(prefix, operator, value, group);
};

const isElementFulfilledByGroup = (element: string, group: string[]): boolean => {
    const elementTokens: string[] = element.split(':');

    if (elementTokens.length == 3) {
        const prefix = elementTokens[0];
        const operator = elementTokens[1];
        const value = elementTokens[2];
        return isElementFulfilledByGroupWithOperator(prefix, operator, value, group);
    }
    return group.includes(element);
};

const isElementMatchesGroup = (element: string, group: string[]): boolean => {
    if (element && !(element.length === 0) && element.charAt(0) == '!') {
        return !isElementFulfilledByGroup(element.slice(1), group);
    }
    return isElementFulfilledByGroup(element, group);
};

export const hasPermission = (
    accessGroups: string[],
    grantExpression?: string,
    // @ts-ignore
    timestamp: number,
): boolean => {
    if (!grantExpression || grantExpression?.length === 0) {
        return true;
    }

    // @ts-ignore INFO(ksyrytczyk): Timestamp w js podawany jest w milisekundach, a GE operuje na sekundach
    timestamp = (timestamp / 1000) | 0;

    const grantExpressionSumElements: string[] = grantExpression.split('+');

    for (const grantExpressionSumElement of grantExpressionSumElements) {
        const grantExpressionMultiElements: string[] = grantExpressionSumElement.split('*');
        let partialResult = true;

        for (const grantExpressionMultiElement of grantExpressionMultiElements) {
            if (isTimestampGrantExpressionElement(grantExpressionMultiElement)) {
                if (!checkTimestamp(timestamp, [grantExpressionMultiElement])) {
                    partialResult = false;
                    break;
                }
            } else if (isTimeGrantExpressionElement(grantExpressionMultiElement)) {
                const timeAccessGroup = `time:${timestamp}`;
                if (!isElementMatchesGroup(grantExpressionMultiElement, [timeAccessGroup])) {
                    partialResult = false;
                    break;
                }
            } else {
                if (!isElementMatchesGroup(grantExpressionMultiElement, accessGroups)) {
                    partialResult = false;
                    break;
                }
            }
        }
        if (partialResult) {
            return true;
        }
    }
    return false;
};
