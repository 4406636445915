import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { LoadingWrapper } from '@/components/shared/Loader';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { ConsentsList } from '@/components/views/Account/Consents/ConsentsList';
import { Rule } from '@/components/views/Account/Consents/types';
import { PageContainer } from '@/components/views/Account/styles';
import { useError } from '@/contexts/error/error.context';
import { useAccountConsents } from '@/hooks/consent/use-account-consents.hook';
import { useFadingState } from '@/hooks/use-fading-state.hook';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { NavScrollDataInterface, navVertical } from 'nav-tree';
import React, { useCallback, useEffect, useState } from 'react';

const ConsentsListPage = () => {
    const { setError } = useError();
    const [topShade, setTopShade] = useState<boolean>(false);
    const [bottomShade, setBottomShade] = useState<boolean>(true);

    const { sortedOptionalRulesWithStatus, isFetching, error, handleToggleRule } =
        useAccountConsents();
    const [fadedErrorMessage] = useFadingState(error?.getUserMessage());

    const onFilterScroll = useCallback(
        ({ isScrollableUp, isScrollableDown }: NavScrollDataInterface) => {
            setTopShade(isScrollableUp);
            setBottomShade(isScrollableDown);
        },
        [setTopShade, setBottomShade],
    );

    const handleMore = useCallback((rule: Rule) => {
        Router.pushRoute(ROUTES.CONSENT, { ruleId: rule.id });
    }, []);

    useEffect(() => {
        if (error) {
            setError(error);
        }
    }, [error]);

    return (
        <BaseLayout defaultBg topShade={topShade} bottomShade={bottomShade}>
            <AccountMenu selectedRoute={ROUTES.CONSENTS_LIST} />
            <PageContainer func={navVertical}>
                <LoadingWrapper isFetching={isFetching} keepChildrenVisible>
                    <ConsentsList
                        rulesWithStatus={sortedOptionalRulesWithStatus}
                        onToggle={handleToggleRule}
                        onFilterScroll={onFilterScroll}
                        onMore={handleMore}
                    />
                </LoadingWrapper>

                {fadedErrorMessage && (
                    <AlertMessage userMessage={fadedErrorMessage} type={MESSAGE_TYPE.ERROR} />
                )}
            </PageContainer>
        </BaseLayout>
    );
};

export default ConsentsListPage;
