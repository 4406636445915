import React, { useContext, useState } from 'react';
import { Nav } from 'nav-tree';
import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import { FilterMenuItem } from '../styles';
import { PacketCategoriesMenuItemProps } from './types';

const PacketCategoriesMenuItemRaw = ({
    subcategory,
    onAction,
    selected,
}: PacketCategoriesMenuItemProps) => {
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);

    const [active, setActive] = useState(false);

    const onItemEnter = () => {
        onAction?.(subcategory.value);
    };

    return (
        // @ts-ignore
        <Nav
            navId={`${subcategory.value}`}
            onNav={setActive}
            onEnterDown={onItemEnter}
            onClick={onItemEnter}
            autofocusOn={isMagicMouseVisible ? ['mouseenter', 'focus'] : ['focus']}
        >
            <FilterMenuItem active={active} selected={selected}>
                {subcategory.name}
            </FilterMenuItem>
        </Nav>
    );
};

export const PacketCategoriesMenuItem = React.memo(PacketCategoriesMenuItemRaw);
