import { ImgGeneratorCDNConfigSchema } from '@/interfaces/from-schemas/system/getConfigurationOut';
import { protectURL } from './rewrite-protocol.helper';
import { Base64, HmacSHA1 } from 'jscrypto';
import { GALLERY_IMAGE_ASPECT, IUseGalleryImage } from '@/hooks/use-gallery-image';
import { SyntheticEvent } from 'react';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';

// wymagania dot. generowania url: https://cyfropedia.polsatc/pages/viewpage.action?pageId=378865029
export const generateCdnSrc = (
    url: string,
    width: number,
    configuration: ImgGeneratorCDNConfigSchema,
) => {
    const widthKeyword = `${width}x-`;
    const { key, cdnUrl } = configuration;

    if (!key || !cdnUrl) {
        return url;
    }

    return protectURL(
        `${cdnUrl}${Base64.stringify(HmacSHA1(`${widthKeyword}${url}`, key))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, ',')
            .substring(0, 12)}/${widthKeyword}${url}`,
    );
};

export const errorHandler = (
    e: SyntheticEvent<HTMLImageElement, Event>,
    image: IUseGalleryImage['image'],
    thumbnailsSrc?: string,
) => {
    if (image.fallbackSrc && image.fallbackSrc !== e.currentTarget.src) {
        e.currentTarget.src = image.fallbackSrc;
    } else if (thumbnailsSrc && thumbnailsSrc !== e.currentTarget.src) {
        e.currentTarget.src = thumbnailsSrc;
    } else {
        return true;
    }

    return false;
};

export const getAspectByImageDisplayMode = (
    displayMode?: IMAGE_DISPLAY_MODE,
): GALLERY_IMAGE_ASPECT => {
    // TODO
    // temporaryPostersRatio do usunięcia w momencie kiedy wiekszość posterów (najlepiej wszystkie) będą zwracały ratio GALLERY_IMAGE_ASPECT.VERTICAL
    // Zmiana wprowadzona, aby poprawić prezentacje obrazków na aktualnej bazie danych.
    // Musimy dostawać obrazki o takim samym ratio, inaczej będzie ucinana góra, albo boki obrazka.
    const temporaryPostersRatio = 231 / 325; // nieobsługiwane przez galerię ratio 0.71

    switch (displayMode) {
        case IMAGE_DISPLAY_MODE.POSTERS:
            return temporaryPostersRatio || GALLERY_IMAGE_ASPECT.VERTICAL;
        default:
            return GALLERY_IMAGE_ASPECT.WIDESCREEN;
    }
};
