import { Nav } from 'nav-tree';
import styled from 'styled-components';
import { IIconGridRootContainer } from './types';

export const IconGridRootContainer = styled(Nav as any)<IIconGridRootContainer>`
    display: flex;
    flex-flow: row wrap;
    align-content: baseline;
    padding-bottom: 5rem;
    ${({ width }) => width && `width: ${width}`};
    ${({ $withoutMargin }) => !$withoutMargin && 'margin: auto'};
`;

export const IconGridElementWrapper = styled(Nav as any)<{ width: number }>`
    position: relative;
    width: ${(props) => `${props.width}%`};
`;
