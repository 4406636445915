import {
    formatDate,
    getTodayLabel,
    getTomorrowLabel,
    parseToTimestamp,
    weekdayName,
} from '@/helpers/date.helper';
import { ListElementImageInterface } from '@/interfaces/list-element.interface';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { backgroundUtil } from '@/utils/background.utils';
import { AssetInterface, goToDetailsOptionsType } from '@/interfaces/asset.interface';
import {
    TvChannelProgramListItemSchema,
    ImageSchema,
    ImageSourceSchema,
} from '@/interfaces/from-schemas/navigation/getChannelsCurrentProgramOut';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { CPID, MEDIA_TYPES, METADATA_TYPE } from '@/types/media.type';
import { coverUtil } from '@/utils/cover.util';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import slug from 'slug';
import { ACCESSIBILITY_FEATURES, UNDERAGE_CLASSIFICATION } from '@/types/additional-signs.type';
import t from '@/lib/i18n';
import { buildHighlightSpan } from '@/helpers/highlight-translation.helper';
import format from 'date-fns/format';

export class ChannelProgramTvModel implements AssetInterface {
    public imageDisplayMode = IMAGE_DISPLAY_MODE.THUMBNAILS;

    private readonly title: string = '';
    private readonly genre: string = '';
    private readonly description: string = '';
    private readonly date: string = '';
    private readonly startTime: string = '';
    private readonly live?: boolean;
    private readonly images?: ImageSchema[];
    private readonly thumbnails?: ImageSourceSchema[];
    private readonly posters?: ImageSourceSchema[];
    private readonly tags?: string[];
    private readonly catid?: number;
    private readonly endTime?: string;
    private readonly mediaId?: {
        cpid: 0 | 1 | 11;
        id: string;
    };
    private readonly cpid: number = 0;
    private readonly id: string = '';
    private readonly channelId: string = '';
    private readonly backgroundSrc?: string;
    private readonly publicationDate: string = '';
    private readonly startTimestamp: number;
    private readonly endTimestamp?: number;
    private readonly channelIds?: { cpid: number; id: string };
    private readonly underageClassification?: UNDERAGE_CLASSIFICATION[];
    private readonly accessibilityFeatures?: ACCESSIBILITY_FEATURES[];
    private readonly mediaType: MEDIA_TYPES = MEDIA_TYPES.CATEGORY;
    private readonly gallery: TvChannelProgramListItemSchema['gallery'];
    private readonly grantExpression: string = '';

    constructor(
        { channelId, mediaType }: { channelId: string; mediaType: MEDIA_TYPES },
        definition: TvChannelProgramListItemSchema,
        nextDefinition?: TvChannelProgramListItemSchema,
    ) {
        this.channelId = channelId;
        this.mediaType = mediaType;
        Object.keys(definition).forEach((name: string) => {
            // @ts-ignore
            this[name] = definition[name];
        });

        this.backgroundSrc = backgroundUtil(this.getThumbnails())?.src;
        this.endTime = nextDefinition?.startTime;
        this.startTimestamp = parseToTimestamp(this.startTime);
        this.endTimestamp = this.endTime ? parseToTimestamp(this.endTime) : undefined;
    }

    public getUnderageClassification() {
        return this.underageClassification;
    }

    public getAccessibilityFeatures() {
        return this.accessibilityFeatures;
    }

    public getTitle(): string {
        return this.title;
    }

    public getGenre(): string {
        return this.genre;
    }

    public getGenres(): string {
        return this.genre;
    }

    public getDescription(): string {
        return this.description;
    }

    public getDate(): string {
        return this.date;
    }

    public getStartTimeTimestamp(): number {
        return this.startTimestamp;
    }

    public getEndTimeTimestamp(): number | undefined {
        return this.endTimestamp;
    }

    public getStartTime(mode?: FORMAT_DATE_MODE): string {
        return mode ? formatDate(this.startTime, mode) : this.startTime;
    }

    public getEndTime(mode?: FORMAT_DATE_MODE): string {
        if (!this.endTime) {
            return '';
        }
        return mode ? formatDate(this.endTime, mode) : this.endTime;
    }

    public getProgress(): number {
        const now = new Date().getTime();
        const start = this.startTimestamp;
        const end = this.endTimestamp;
        if (!end || !start || now < start || now >= end) {
            return 0;
        }
        return (now - start) / (end - start);
    }

    public getThumbnails(): ImageSourceSchema[] {
        return this.thumbnails || [];
    }

    public getPosters(): ImageSourceSchema[] {
        return this.posters || [];
    }

    public isPostersExist(): boolean {
        return this.getPosters().length > 0;
    }

    public isThumbnailsExists(): boolean {
        return this.getThumbnails().length > 0;
    }

    public getCpid(): CPID {
        return this.cpid;
    }

    public getIsLive(): boolean {
        return !!this.live;
    }

    public isTimeIn(refTimestamp: number): boolean {
        const start = this.startTimestamp;
        const end = this.endTimestamp;
        const refDate = new Date(refTimestamp);
        const startDate = new Date(start);
        const endDate = end ? new Date(end) : null;

        if (!end) {
            // jeśli nie ma czasu zakończenia, sprawdzamy, czy czas rozpoczęcia jest w tym samym dniu co refTimestamp i start jest mniejszy lub równy refTimestamp
            return startDate.getDate() === refDate.getDate() && start <= refTimestamp;
        } else if (!endDate || startDate.getDate() !== endDate.getDate()) {
            // jeśli program trwa przez północ, powinien zwrócić true, jeśli refTimestamp jest po czasie rozpoczęcia lub przed czasem zakończenia
            return refTimestamp >= start && (!end || refTimestamp <= end);
        } else {
            // jeśli program nie trwa przez północ, powinien również sprawdzić, czy refTimestamp jest w tym samym dniu.
            return (
                refTimestamp >= start &&
                refTimestamp < end &&
                startDate.getDate() === refDate.getDate()
            );
        }
    }

    public getHasPassed(): boolean {
        const start = this.startTimestamp;
        const end = this.endTimestamp;
        const now = new Date().getTime();

        if (!end) {
            // jeśli nie ma czasu zakończenia, sprawdzamy, czy czas rozpoczęcia jest w przeszłości
            return now > start;
        }

        return now > end;
    }

    public getIsOnAir(): boolean {
        return this.isTimeIn(new Date().getTime());
    }

    public getId(): string {
        return this.id;
    }

    public getImage(): ListElementImageInterface {
        const images =
            this.imageDisplayMode === IMAGE_DISPLAY_MODE.POSTERS
                ? this.getPosters()
                : this.getThumbnails();
        const image = coverUtil(images, this.imageDisplayMode);

        return {
            // TODO: brakuje funkcji do pobrania placeholdera
            src: image ? image.src : '',
            displayMode: this.imageDisplayMode,
        };
    }

    public getDetailsRoute(): ROUTES {
        return ROUTES.FUTURE_EPG;
    }

    public getRouteParams(): { [key: string]: any } {
        return {
            tvProgramTitle: slug(this.title),
            tvProgramId: this.channelId,
        };
    }

    public getChannelId(): string {
        return this.channelId;
    }

    public goToDetails(options?: goToDetailsOptionsType): void {
        const { replaceRoute } = options || {};
        const route = this.getDetailsRoute();
        const params = this.getRouteParams();
        replaceRoute ? Router.replaceRoute(route, params) : Router.pushRoute(route, params);
    }

    public goToChannelDetails(): void {
        const route = ROUTES.CHANNEL_TV_DETAILS;
        const params = {
            channelTitle: slug(this.title),
            channelId: this.channelId,
        };

        Router.pushRoute(route, params);
    }

    public getBackgroundSrc(): string | undefined {
        return this.backgroundSrc;
    }

    public getPublicationDate(): string {
        return this.publicationDate;
    }

    public get epgMetadataSsr(): string[] {
        const data: string[] = [];

        const { HOURS, LIVE_ARABIC_FORMAT } = FORMAT_DATE_MODE;
        const genre = this.getGenre();
        const programGenre = genre.charAt(0).toUpperCase() + genre.slice(1);
        const date = formatDate(this.getDate(), LIVE_ARABIC_FORMAT);
        const programStartTime = this.getStartTime(HOURS);
        const programEndTime = this.getEndTime(HOURS);
        data.push(programGenre, `${date}, ${programStartTime} - ${programEndTime}`);

        if (this.getIsLive()) {
            data.push(t('models.media-details.live'));
        }

        if (this.getIsOnAir()) {
            data.push(
                t('models.media-details.on-air-highlighted', buildHighlightSpan('text-danger')),
            );
        }

        return data.filter((item) => item.length);
    }

    public get epgMetaData(): string[] {
        const data: string[] = [];

        const { HOURS } = FORMAT_DATE_MODE;
        const genre = this.getGenre();
        const programGenre = genre.charAt(0).toUpperCase() + genre.slice(1);
        const programStartTime = this.getStartTime(HOURS);
        const programEndTime = this.getEndTime(HOURS);
        data.push(programGenre, `${programStartTime} - ${programEndTime}`);

        if (this.getIsLive()) {
            data.push(t('models.media-details.live'));
        }

        if (this.getIsOnAir()) {
            data.push(
                t('models.media-details.on-air-highlighted', buildHighlightSpan('text-danger')),
            );
        }

        return data.filter((item) => item.length);
    }

    public getMetadata(type: METADATA_TYPE): string[] | string {
        if (this.mediaType === MEDIA_TYPES.TV_PROGRAM) {
            const metadata = [];
            const day = format(new Date(this.startTime), 'dd.MM', {});
            const hours = format(new Date(this.startTime), 'HH:mm', {});

            const todayOrTomorrowLabel = this.getTodayOrTomorrowLabel();
            const dayTag = this.getDayTag();
            const dateLabel = `${todayOrTomorrowLabel || dayTag} ${day}`;
            const genres = this.getGenres();
            const isDayTagVisible = genres.length < 15;

            switch (type) {
                case METADATA_TYPE.MAIN:
                    metadata.push(genres, dateLabel, hours);
                    break;
                case METADATA_TYPE.LIST_ELEMENT:
                    if (!isDayTagVisible && this.getIsOnAir()) {
                        metadata.push(hours, genres);
                    } else {
                        metadata.push(hours, dateLabel, genres);
                    }
                    break;
            }

            return metadata;
        }

        return '';
    }

    public getGallery() {
        return this.gallery;
    }

    private getDayTag() {
        return t(`date.${weekdayName(new Date(this.startTime).getDay())}`);
    }

    private getTodayOrTomorrowLabel() {
        return getTodayLabel(this.startTime) || getTomorrowLabel(this.startTime);
    }

    public getGrantExpression(): string {
        return this.grantExpression;
    }
}
