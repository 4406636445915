import React, { useCallback, useEffect, useRef, useState } from 'react';
import ScrollableContainer from '@/components/shared/ScrollableContainer';
import { Message } from '@/components/shared/Message';
import { GUTTER } from '@/types/gutter.type';

import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import {
    AbsolutePositionDynamicButton,
    ContactLinkWrapper,
    FullDetailsContentContainer,
    MainMetadataContainer,
    ScrollableContactLinkWrapper,
    ShortMessageContent,
    ShortMessageWrapper,
} from './styles';
import { FullMessageProps } from './types';
import { ContactLink } from '@/components/shared/customer-care';
import { Nav, navHorizontal, navVertical } from 'nav-tree';
import { ScrollPosition } from '@/components/shared/ScrollableContainer/types';
import { BUTTON_ID } from '@/types/button-id.type';
import { focusNavId } from '@/helpers/nav-tree/focus-nav-id.helper';

const MIN_FULL_DETAILS_CONTENT_HEIGHT = 700;

const FullMessage = ({
    data,
    scrollPosition,
    setScrollPosition,
    showExitCC,
    onExitCC,
    showBackCC,
    onBackToCC,
    errorCode,
    contextValue,
    contextType,
}: FullMessageProps) => {
    const [contentHeight, setContentHeight] = useState(0);
    const containerRef = useRef<Nav>(null);

    const contentRef = useCallback((node: any) => {
        if (node !== null) {
            setContentHeight(node.getBoundingClientRect().height - 100);
        }
    }, []);
    const withScroll = contentHeight > MIN_FULL_DETAILS_CONTENT_HEIGHT;
    const isScrollOnBottom = scrollPosition === ScrollPosition.BOTTOM;
    const isScrollOnTop = scrollPosition === ScrollPosition.TOP;

    useEffect(() => {
        const tree = containerRef.current?.getChildContext().tree;
        if (isScrollOnBottom) {
            tree && focusNavId(tree, BUTTON_ID.CC_CONTACT_LINK);
        }
        if (isScrollOnTop) {
            tree && focusNavId(tree, BUTTON_ID.CC_EXIT);
        }
    }, [isScrollOnBottom, isScrollOnTop]);
    return (
        <FullDetailsContentContainer withScroll={withScroll}>
            <MainMetadataContainer ref={contentRef} withScroll={withScroll}>
                {withScroll ? (
                    <ScrollableContainer
                        onScroll={setScrollPosition}
                        showExitCC={showExitCC}
                        onExitCC={onExitCC}
                        showBackCC={showBackCC}
                        onBackToCC={onBackToCC}
                        shouldIncludeCCContactLink={isScrollOnBottom}
                        containerRef={containerRef}
                    >
                        <Message title={data.title} gutter={GUTTER.BIG} icon={ICONS.INFO_CIRCLE} />
                        {data && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data?.description,
                                }}
                            />
                        )}

                        <ScrollableContactLinkWrapper>
                            <ContactLink
                                errorCode={errorCode}
                                contextValue={contextValue}
                                contextType={contextType}
                            />
                        </ScrollableContactLinkWrapper>
                    </ScrollableContainer>
                ) : (
                    // @ts-ignore
                    <Nav func={navVertical}>
                        <ShortMessageWrapper func={navHorizontal}>
                            {showBackCC && onBackToCC && (
                                <AbsolutePositionDynamicButton
                                    onButtonAction={onBackToCC}
                                    icon={ICONS.ARROW_LEFT}
                                    style={{ left: '5rem', right: 'auto' }}
                                    text={t('pages.customer-care.back-to-main-page')}
                                    data-testing="back-cc-button-label"
                                />
                            )}
                            <AbsolutePositionDynamicButton
                                defaultFocused
                                onButtonAction={onExitCC}
                                icon={ICONS.X}
                                text={t('pages.customer-care.close-button-label')}
                                data-testing="exit-cc-button-label"
                            />
                            <Message
                                title={data.title}
                                gutter={GUTTER.BIG}
                                icon={ICONS.INFO_CIRCLE}
                            />
                            {data && (
                                <ShortMessageContent
                                    dangerouslySetInnerHTML={{ __html: data?.description }}
                                />
                            )}
                        </ShortMessageWrapper>
                        <ContactLinkWrapper>
                            <ContactLink
                                errorCode={errorCode}
                                contextValue={contextValue}
                                contextType={contextType}
                            />
                        </ContactLinkWrapper>
                    </Nav>
                )}
            </MainMetadataContainer>
        </FullDetailsContentContainer>
    );
};
export default FullMessage;
