import t from '@/lib/i18n';
import { GUTTER } from '@/types/gutter.type';
import { Router } from '@/routing';
import { PlayableButton } from '../../../Buttons';
import { ICONS } from '../../../Icon';
import { GetRidOfAdsButtonContainer } from './styles';
import { ROUTES } from '@/routing/types';
import { useConfiguration } from '@/contexts/configuration/configuration.hooks';

export const GetRidOfAdsButton = () => {
    const configuration = useConfiguration();
    return (
        <GetRidOfAdsButtonContainer>
            <PlayableButton
                gutter={GUTTER.NONE}
                onButtonAction={() => {
                    const packetId = configuration?.payments?.noAdsProduct?.id;
                    if (packetId) {
                        Router.pushRoute(ROUTES.PACKET_DETAILS, {
                            packetId,
                        });
                    } else {
                        Router.pushRoute(ROUTES.PACKETS);
                    }
                }}
                defaultFocused
                icon={ICONS.X}
                text={t('player.ads.get-rid-label')}
            />
        </GetRidOfAdsButtonContainer>
    );
};
