import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import React, { useContext, useRef, useState } from 'react';
import { Nav } from 'nav-tree';
import { useRouter } from '@/routing';
import { Icon } from '../Icon';
import { MenuItemIconStyled, MenuItemStyled, StyledIcon } from './styles';
import { FocusRefType, MenuItemProps } from './types';
import { useFocusPath } from './use-focus-path.hook';
import { usePrepareMenuUrl } from '@/hooks/menu/use-prepare-menu-url.hook';
import { checkItemActive } from '@/helpers/home-menu/check-item-active.helper';

const MenuItem = ({
    item,
    isMenuActive,
    onFocusPath,
    onRedirect,
    'data-testing': dataTesting,
}: MenuItemProps) => {
    const label = item.getName();
    const url = item.getUrl?.();
    const iconUrl = item.getIconUrl?.();
    const icon = item.getIcon?.();
    const place = item.getPlace?.();

    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);
    const router = useRouter();
    const routeData = usePrepareMenuUrl(place);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const isItemActive = checkItemActive(router, label, routeData);

    const onItemEnter = () => {
        if (url) {
            onRedirect(url);
            return;
        }

        if (routeData) {
            onRedirect(routeData.route, routeData.params, routeData.query);
            return;
        }
    };

    const focusRef = useRef<Nav>(null) as FocusRefType;

    useFocusPath({
        focusRef,
        onFocusPath,
        enabled: isItemActive,
    });

    return (
        // @ts-ignore
        <Nav
            ref={focusRef}
            navId={label}
            onNav={setIsFocused}
            onEnterDown={onItemEnter}
            onClick={onItemEnter}
            autofocusOn={isMagicMouseVisible ? ['mouseenter'] : undefined}
            data-testing={dataTesting}
            title={label}
            tabIndex={0}
        >
            <MenuItemStyled focused={isFocused} active={isItemActive} title={label}>
                <MenuItemIconStyled>
                    {icon && <Icon name={icon} />}
                    {iconUrl && (
                        <StyledIcon icon={iconUrl} active={isItemActive} focused={isFocused} />
                    )}
                </MenuItemIconStyled>
                {isMenuActive && label}
            </MenuItemStyled>
        </Nav>
    );
};

export const MenuItemMemo = React.memo(MenuItem);
