import { useStatsConfiguration } from '@/hooks/stats/use-stats-configuration.hook';
import { useSingleRef } from '@/hooks/use-single-ref.hook';
import StatsModule from '@/modules/stats/stats.module';
import React, { createContext, useContext, useEffect } from 'react';
import { useConfiguration } from '../configuration/configuration.hooks';
import { StatsContextProviderProps } from './types';
import { useUserModule } from '../user-module/user-module.hooks';
import { useGetMyAccess } from '@/api/payments/use-get-my-access';
import { gtmId } from '@/configs/analytics.config';
import TagManager from 'react-gtm-module';
import enableGtm from '@/helpers/seo/enable-gtm.helper';

export const StatsContext = createContext<StatsModule>({} as StatsModule);

const StatsContextProvider = ({ children }: StatsContextProviderProps) => {
    const statsModule = useSingleRef(() => new StatsModule());
    const configuration = useConfiguration();
    const isLogged = useUserModule().isLogged();

    const { data, isFetching } = useGetMyAccess();

    useEffect(() => {
        if (enableGtm()) {
            TagManager.initialize({ gtmId });
        }
    }, []);

    useEffect(() => {
        statsModule.hitZetaIsLogged(isLogged);
    }, [isLogged]);

    useEffect(() => {
        if (isLogged && data && !isFetching) {
            statsModule.hitZetaUserPackets(data);
        }
    }, [isLogged, isFetching, data]);

    useEffect(() => {
        if (configuration?.reporting?.activityEvents) {
            const jwt = configuration?.reporting?.activityEvents.authToken;

            statsModule.updateParams({
                jwt,
            });
        }
    }, [configuration]);

    useStatsConfiguration((config) => {
        statsModule.configure(config);
        statsModule.hitAppStart();
    });

    return <StatsContext.Provider value={statsModule}>{children}</StatsContext.Provider>;
};

export default StatsContextProvider;

export const useStatsModule = () => useContext(StatsContext);
