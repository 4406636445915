import { GM_APPLICATION, GM_OS } from '@/types/platform.type';
import { getMatch } from './helpers';
import { MatchMap } from './types';

const applicationDetectionMap: MatchMap<GM_APPLICATION> = [
    [/panasonic/i, GM_APPLICATION.Native],
    [/vidaa/i, GM_APPLICATION.Native],
    [/WhaleTV/i, GM_APPLICATION.Native],
    [/philips/i, GM_APPLICATION.Native],
    [/googlebot/i, GM_APPLICATION.Other],
    [/opera/i, GM_APPLICATION.Opera],
    [/opr\/|opios/i, GM_APPLICATION.Opera],
    [/coast/i, GM_APPLICATION.Opera],
    [/opt\/\d+(?:.?_?\d+)+/i, GM_APPLICATION.Opera],
    [/msie|trident/i, GM_APPLICATION.IE],
    [/\sedg\//i, GM_APPLICATION.Edge],
    [/edg([ea]|ios)/i, GM_APPLICATION.Edge],
    [/firefox|iceweasel|fxios/i, GM_APPLICATION.Firefox],
    [/tivo/i, GM_APPLICATION.Native],
    [/chromium/i, GM_APPLICATION.Chrome],
    [/chrome|crios|crmo/i, GM_APPLICATION.Chrome],
    [/safari|applewebkit/i, GM_APPLICATION.Safari],
    [GM_OS.Tizen, GM_APPLICATION.Native],
    [GM_OS.WebOS, GM_APPLICATION.Native],
    [GM_OS.UPC, GM_APPLICATION.Native],
    [/xbox/i, GM_APPLICATION.Native],
];

export const recognizeApplication = (userAgent: string, os: GM_OS) => {
    if ([GM_OS.Tizen, GM_OS.WebOS, GM_OS.UPC].includes(os)) {
        return GM_APPLICATION.Native;
    }
    return getMatch(applicationDetectionMap, userAgent, os) ?? GM_APPLICATION.Other;
};
