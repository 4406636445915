import { useInterval } from '@/hooks/use-interval.hook';
import { useState } from 'react';
import { Nav } from 'nav-tree';
import { Button, BUTTON_TYPE } from '..';
import { Icon } from '../../Icon';
import { StyledDynamicButtonContent, StyledDynamicText, StyledIconWrapper } from './styles';
import { PlayableDynamicButtonProps } from './types';

export const PlayableDynamicButton = ({
    func,
    onButtonAction,
    onNav,
    text,
    navId,
    icon,
    isFullyVisible,
    enableLongPress,
    defaultFocused = false,
    className,
    iconSize,
    style,
    'data-testing': dataTesting,
}: PlayableDynamicButtonProps): JSX.Element => {
    const [btnActive, setBtnActive] = useState<boolean>(false);
    const [isMouseDown, setMouseDown] = useState(false);

    const onButtonNav = (isActive: boolean) => {
        setBtnActive(isActive);

        if (onNav) {
            onNav(isActive);
        }
    };

    const onBtnEnter = () => {
        onButtonAction();
    };

    useInterval(onBtnEnter, isMouseDown ? 200 : null);

    const buildIcon = () => (
        <StyledIconWrapper active={btnActive} iconSize={iconSize}>
            <Icon name={icon} />
        </StyledIconWrapper>
    );

    const buildDynamicButton = () => (
        <Button fullWidth btnType={BUTTON_TYPE.PRIMARY} active={btnActive}>
            <StyledDynamicButtonContent active={btnActive} isFullyVisible={isFullyVisible}>
                {buildIcon()}
                <StyledDynamicText active={!!isFullyVisible || btnActive}>{text}</StyledDynamicText>
            </StyledDynamicButtonContent>
        </Button>
    );

    return (
        // @ts-ignore
        <Nav
            className={className}
            defaultFocused={defaultFocused}
            navId={navId}
            func={func}
            autofocusOn={['focus', 'mouseenter']}
            onNav={onButtonNav}
            onEnterDown={onBtnEnter}
            onClick={onBtnEnter}
            style={style}
            onMouseDown={() => {
                if (enableLongPress) setMouseDown(true);
            }}
            onMouseUp={() => {
                if (enableLongPress) setMouseDown(false);
            }}
            data-testing={dataTesting}
        >
            {buildDynamicButton()}
        </Nav>
    );
};
