import { ListElementPlaceholder } from '@/components/shared/ListElement/ListElementPlaceholder';
import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { useInitialRefocus } from '@/hooks/nav-tree/use-initial-refocus.hook';
import { useNavScroll } from '@/hooks/nav-tree/use-nav-scroll.hook';
import { useLazyImages } from '@/hooks/use-lazy-images.hook';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { DATA_POSITION } from '@/types/list-element-metadata.type';
import {
    IMPERATIVE_SCROLL_DIRECTION,
    NAV_SCROLL_DIRECTION,
    navHorizontal,
    NAVIGATION_KEYS,
} from 'nav-tree';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { CollectionTitle } from '../CollectionsList/CollectionTitle';
import { getVisibleElements, shouldIncreaseLimit } from './helpers';
import { SliderButtons } from './SliderButtons';
import { SliderElements } from './SliderElements';
import { StyledSlider, StyledSliderElements } from './styles';
import { SliderContext, SliderOwnProps } from './types';
import { StaffRecommendationListModel } from '@/models/recommendations/staff-recommendation-list.model';

export const SliderRaw = ({
    collectionElements,
    onFetchMore,
    config,
    lockNav,
    onActiveItem,
    onActiveListItem,
    onSliderNav,
    loadNextSlicePage,
    showPlaceholders = false,
    'data-testing': dataTesting,
}: SliderOwnProps) => {
    const {
        slider: { initialLimit, height },
        lazyLoadingImages: { enabled: lazyLoading },
    } = process.env;
    const { list, fetchedAll, rowIndex, defaultFocused, collectionLength, withPackets } = config;
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);

    const sliderRef = useRef<HTMLDivElement>(null);
    const sliderWidthInRem = (sliderRef.current && sliderRef.current.offsetWidth / 10) || 0;
    const isPromobox = useMemo(() => list?.isPromobox(), [list]);
    const imageDisplayMode = useMemo(
        () => list?.getImageDisplayMode() || IMAGE_DISPLAY_MODE.THUMBNAILS,
        [list],
    );

    const getListBackground = list?.getListBackground()?.src;
    const { loadRow, activeRow, setActiveRow, collectionActiveIndex, setCollectionActiveIndex } =
        useContext(SliderContext);

    const isActiveCollection = useMemo(() => activeRow === rowIndex, [activeRow, rowIndex]);
    const visibleElements = useMemo(() => getVisibleElements(list), []);
    const enabledScrollIndex = useMemo(
        () => Math.ceil((visibleElements + 1) / 2),
        [visibleElements],
    );

    const { navRef, scrollOptions, scrollState, onScrollCallback, imperativeScroll } = useNavScroll(
        NAV_SCROLL_DIRECTION.HORIZONTAL,
    );

    const navigation = navRef.current;

    useEffect(() => {
        const shouldFetchMore = shouldIncreaseLimit(
            collectionActiveIndex,
            visibleElements,
            collectionLength,
        );

        if (shouldFetchMore && isActiveCollection) {
            onFetchMore?.(initialLimit);
        }
    }, [
        collectionActiveIndex,
        collectionElements,
        collectionLength,
        initialLimit,
        isActiveCollection,
        visibleElements,
        onFetchMore,
    ]);

    useLazyImages({
        ref: sliderRef,
        visibleElements,
        actualIndex: collectionActiveIndex,
        enabled: lazyLoading && !isPromobox,
        deps: [collectionElements, loadRow],
    });

    const handleActiveElement = useCallback(
        (itemData: ListElementInterface, index?: number) => {
            batchedUpdates(() => {
                if (index !== undefined) {
                    setCollectionActiveIndex(index);
                }
                onActiveItem?.(itemData);
            });
        },
        [onActiveItem, setCollectionActiveIndex],
    );
    const handleActiveListElement = useCallback(
        (itemData: StaffRecommendationListModel, index?: number) => {
            batchedUpdates(() => {
                if (index !== undefined) {
                    setCollectionActiveIndex(index);
                }
                onActiveListItem?.(itemData);
            });
        },
        [onActiveItem, setCollectionActiveIndex],
    );

    useEffect(() => {
        if (isMagicMouseVisible) {
            navigation?.scrollToOffScreenFocusedElement();
        }
    }, [collectionActiveIndex, isMagicMouseVisible, navigation]);

    const handleSliderNav = useCallback(
        (isActive: boolean) => {
            onSliderNav?.(isActive);
        },
        [onSliderNav],
    );

    const imperativeFocus = (index: number) => {
        navigation?.imperativeFocus([`${index}`]);
    };

    const isFullyLoaded = fetchedAll
        ? collectionActiveIndex < collectionElements.length
        : collectionActiveIndex + enabledScrollIndex <= collectionElements.length;

    const enabled = !!defaultFocused && isActiveCollection && isFullyLoaded;

    useInitialRefocus({ focusRef: navRef, enabled });

    const elementsPlaceholders = useMemo(
        () =>
            Array.from({ length: visibleElements }, (v, index) => (
                <ListElementPlaceholder key={index} />
            )),
        [visibleElements],
    );

    const elements = useMemo(
        () => (
            <SliderElements
                collectionElements={collectionElements}
                onActiveElement={handleActiveElement}
                onActiveListElement={handleActiveListElement}
                config={config}
            />
        ),
        [collectionElements, config, handleActiveElement],
    );

    const sliderElements = useMemo(
        () => (
            <StyledSliderElements
                hide={Boolean(lockNav)}
                imageDisplayMode={imageDisplayMode}
                ref={sliderRef}
            >
                {showPlaceholders ? elementsPlaceholders : elements}
            </StyledSliderElements>
        ),
        [elementsPlaceholders, elements, lockNav, imageDisplayMode, showPlaceholders],
    );

    const promoboxElements = useMemo(
        () => (
            <StyledSliderElements
                activeItemId={collectionActiveIndex}
                width={sliderWidthInRem}
                ref={sliderRef}
                noPadding={true}
            >
                {showPlaceholders ? elementsPlaceholders : elements}
            </StyledSliderElements>
        ),
        [elementsPlaceholders, elements, collectionActiveIndex, sliderWidthInRem, showPlaceholders],
    );

    const mode = list?.getDataPosition() === DATA_POSITION.UNDER ? 'withMeta' : 'simple';

    const sliderHeight = useMemo(
        () => (isPromobox ? height?.promobox : height?.[imageDisplayMode][mode]),
        [isPromobox, height, imageDisplayMode, mode],
    );

    const scrollLeft = () => {
        const index = Math.max(collectionActiveIndex - (visibleElements - 2) + 1, 1);

        imperativeScroll(IMPERATIVE_SCROLL_DIRECTION.LEFT);
        imperativeFocus(index);
    };

    const scrollRight = () => {
        const index = Math.min(
            collectionActiveIndex + (visibleElements - 1),
            collectionElements.length,
        );

        imperativeScroll(IMPERATIVE_SCROLL_DIRECTION.RIGHT);
        imperativeFocus(index);
        loadNextSlicePage?.(index);
    };

    const sliderFunc = useCallback((key: any, navTree: any) => {
        // Potrzebne dla ustawienia fokusa na przyciskach na karcie pakietu
        if (withPackets && rowIndex === 0 && key === NAVIGATION_KEYS.UP) {
            setActiveRow(-1);
        }

        return navHorizontal(key, navTree);
    }, []);

    return (
        <>
            {loadRow && list && <CollectionTitle list={list} rowIndex={rowIndex} />}
            <SliderButtons
                isVisible={isMagicMouseVisible && loadRow && !isPromobox}
                sliderHeight={sliderHeight}
                isScrollableLeft={scrollState.isScrollableLeft}
                isScrollableRight={scrollState.isScrollableRight}
                onScrollLeft={scrollLeft}
                onScrollRight={scrollRight}
            />
            {/* TODO:tło dla całej listy */}{' '}
            <StyledSlider
                ref={navRef}
                onNav={handleSliderNav}
                func={sliderFunc}
                scrollOptions={{
                    ...scrollOptions,
                    isScrollable: !isPromobox,
                }}
                lockNavInside={lockNav}
                restoreLastFocus
                height={sliderHeight}
                onNavScroll={onScrollCallback}
                data-testing={dataTesting}
                backgroundSrc={getListBackground}
            >
                {loadRow ? (isPromobox ? promoboxElements : sliderElements) : null}
            </StyledSlider>
        </>
    );
};
