import {
    MediaListItemSchema,
    CategorySchema,
    PacketSchema,
    TvChannelProgramListItemSchema,
    StaffCollectionSchema,
} from '@/interfaces/from-schemas/navigation/getStaffRecommendationsListItemsOut';
import { CategoryModel } from '@/models/category/category.model';
import { MediaListItemModel } from '@/models/media-list-item.model';
import { PacketModel } from '@/models/packets/packet.model';
import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';
import { StaffRecommendationListItem } from '@/models/recommendations/staff-recommendation-lists-item.model';
import { MEDIA_TYPES } from '@/types/media.type';
import { CollectionModel } from '@/models/collection/collection.model';

export const setModelToRecommendationObject = (definition: StaffRecommendationListItem) => {
    switch (definition.type) {
        case 'vod':
        case 'channel':
            return new MediaListItemModel(definition.object as MediaListItemSchema);
        case 'category':
            return new CategoryModel(definition.object as CategorySchema);
        case 'packet':
            return new PacketModel(definition.object as PacketSchema);
        case 'tv_program':
            return new ChannelProgramTvModel(
                { channelId: definition.value, mediaType: definition.type as MEDIA_TYPES },
                definition.object as TvChannelProgramListItemSchema,
            );
        case 'url':
            return undefined;
        case 'collection':
            return new CollectionModel(definition.object as StaffCollectionSchema);
        default:
            console.error('Nieobsługiwany typ');
            return;
    }
};
