import { isFuture, isToday } from 'date-fns';
import t from '@/lib/i18n';
import { calculateTimeDifference, formatDate, SECONDS_IN_ONE_DAY } from '@/helpers/date.helper';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { MEDIA_TYPES } from '@/types/media.type';

const MEDIA_TYPES_WITH_PREMIERE_MARKINGS = [
    MEDIA_TYPES.VOD,
    MEDIA_TYPES.MOVIE,
    MEDIA_TYPES.CATEGORY,
];

export const getPremiereMarkings = (
    publicationDate: string | undefined,
    mediaType: MEDIA_TYPES | undefined,
) => {
    if (!publicationDate || !mediaType || !MEDIA_TYPES_WITH_PREMIERE_MARKINGS.includes(mediaType)) {
        return undefined;
    }
    if (publicationDate && mediaType && MEDIA_TYPES_WITH_PREMIERE_MARKINGS.includes(mediaType)) {
        const parsedDate = Date.parse(publicationDate);

        if (isToday(parsedDate)) return t('markings.premiere-today');
        if (isFuture(parsedDate)) {
            const timeLeft = calculateTimeDifference(publicationDate);

            return timeLeft.totalSeconds < SECONDS_IN_ONE_DAY
                ? t('markings.premiere-in-12-hours', {
                      hours: timeLeft.hours,
                      minutes: timeLeft.minutes,
                  })
                : t('markings.premiere-known', {
                      date: formatDate(publicationDate, FORMAT_DATE_MODE.LIVE_ARABIC_FORMAT),
                  });
        }
    }

    return undefined;
};
