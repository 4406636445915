import { PossessionModel } from '@/models/payments/possession.model';
import { compareProducts } from './product.helper';

export function filterPossessions(possessions: PossessionModel[]): PossessionModel[] {
    const filteredPossessions: PossessionModel[] = [];

    possessions.forEach((possession) => {
        const possessionInFilteredListIndex = filteredPossessions.findIndex((filteredPossession) =>
            compareProducts(filteredPossession.getProductId(), possession.getProductId()),
        );
        const possessionInFilteredList = filteredPossessions[possessionInFilteredListIndex];

        if (!possessionInFilteredList) {
            filteredPossessions.push(possession);
            return;
        }

        if (
            possessionInFilteredList.getStatus() !== 'active' &&
            possession.getStatus() === 'active'
        ) {
            filteredPossessions[possessionInFilteredListIndex] = possession;
            return;
        }
    });

    return filteredPossessions;
}

export function sortPossessionsByDate(a: PossessionModel, b: PossessionModel): number {
    const aValidTo = a.getValidTo();
    const bValidTo = b.getValidTo();

    if (bValidTo && aValidTo) {
        return new Date(bValidTo).getTime() - new Date(aValidTo).getTime();
    }

    return 1;
}
