import { createContext, useContext, useEffect, useState } from 'react';
import { RodoContextProps, RodoContextProviderProps } from '@/contexts/rodo/types';
import { useCookieService } from '@/contexts/cookies/cookies.hooks';
import {
    COOKIE_RODO_MODAL_NAME,
    COOKIE_RODO_REMINDER_NAME,
} from '@/contexts/cookies/cookies.context';
import { useGetConfiguration } from '@/api/system/use-get-configuration';
import { useReminder } from '@/hooks/rodo/use-reminder.hook';
import { useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';
import { tomorrowDate } from '@/helpers/date.helper';
import { modalBlackList } from '@/configs/rodo.config';
import { translateRoute } from '@/routing/helpers';
import config from 'target/config';

export const RodoContext = createContext<RodoContextProps>({} as RodoContextProps);

const RodoContextProvider = ({ children }: RodoContextProviderProps) => {
    const router = useRouter();
    const route = router.route.slice(1) as ROUTES;

    const promotionUrl = translateRoute('promotion-take-gift');
    const goodbyeUrl = translateRoute('goodbye');

    const isMagicLink =
        router.query.flowContext === 'promotion' ||
        router.asPath.includes(promotionUrl) ||
        router.asPath.includes(goodbyeUrl);

    const cookieService = useCookieService();
    const rodoCookie = cookieService.get(COOKIE_RODO_MODAL_NAME);
    const reminderCookie = cookieService.get(COOKIE_RODO_REMINDER_NAME);

    const { data, isFetching, refetch: refetchConfiguration } = useGetConfiguration();
    const externalCookiesEnabled = data?.rodo.externalCookiesEnabled;
    const isPageExcluded = modalBlackList.includes(route);

    const [isRodoAccepted, setIsRodoAccepted] = useState<boolean>();
    const [showModal, setShowModal] = useState<boolean>(false);

    const { showReminder, setShowReminder } = useReminder({
        externalCookiesEnabled,
        disable: isFetching || !!reminderCookie,
        show: !isRodoAccepted && !showModal,
    });

    useEffect(() => {
        if (isPageExcluded || isMagicLink || config.isTargetClosed || config.onetrust.isEnabled) {
            return;
        }

        if (!rodoCookie) {
            setShowModal(!externalCookiesEnabled);
        }
    }, [externalCookiesEnabled, rodoCookie, isPageExcluded, isMagicLink]);

    useEffect(() => {
        if (!isFetching) {
            setIsRodoAccepted(externalCookiesEnabled);
        }
    }, [isFetching, externalCookiesEnabled, setIsRodoAccepted]);

    const handleShowReminder = (state: boolean) => {
        cookieService.set(COOKIE_RODO_REMINDER_NAME, '1', {
            expires: tomorrowDate,
        });

        setShowReminder(state);
    };

    return (
        <RodoContext.Provider
            value={{
                setIsRodoAccepted,
                showReminder,
                handleShowReminder,
                showModal,
                setShowModal,
                refetchConfiguration,
            }}
        >
            {children}
        </RodoContext.Provider>
    );
};

export const useRodoContext = () => useContext(RodoContext);

export default RodoContextProvider;
