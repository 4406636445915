import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { StaffCollectionContentModel } from '@/models/collection/staff-collection-content.model';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useMemo } from 'react';

const useGetStaffCollectionContent = (params?: any) => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.FETCH_STAFF_COLLECTION_CONTENT,
        params,
    );
    const { data, isFetching, error } = useGetMediaApi(keyHook, { appendAuthData: true });
    return {
        data: useMemo(() => new StaffCollectionContentModel(data), [data]),
        isFetching,
        error,
    };
};

export default useGetStaffCollectionContent;
