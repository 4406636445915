import { useEffect } from 'react';
import { Nav } from 'nav-tree';
import t from '@/lib/i18n';
import { useFacebookGetDeviceCode } from '@/api/facebook/use-facebook-device-login';
import {
    FBCancelButton,
    FBCodeContainer,
    FBContainer,
    FBIconContainer,
    FBInstructionContainer,
} from './styles';
import { useFacebookDeviceLoginStatus } from '@/api/facebook/use-facebook-device-login-status';
import { Icon, ICONS } from '../Icon';
import { ISignInWithFacebookModalProps } from './types';

const SignInWithFacebookModal = ({
    appId,
    clientToken,
    permissions,
    isOpen,
    onSuccess,
    onCancel,
}: ISignInWithFacebookModalProps) => {
    const {
        mutate: getDeviceCode,
        data: deviceCodeData,
        reset: resetGetDeviceCode,
    } = useFacebookGetDeviceCode({
        appId,
        clientToken,
        permissions,
    });

    useFacebookDeviceLoginStatus({
        appId,
        clientToken,
        onSuccess,
        interval: deviceCodeData?.interval && deviceCodeData.interval * 1000,
        code: deviceCodeData?.code,
    });

    useEffect(() => {
        if (isOpen) {
            getDeviceCode();
        } else {
            resetGetDeviceCode();
        }
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    return (
        <FBContainer>
            <FBIconContainer>
                <Icon name={ICONS.FACEBOOK} />
            </FBIconContainer>
            <FBCodeContainer>{deviceCodeData?.user_code}</FBCodeContainer>
            <FBInstructionContainer>
                <p>{t('signInWithFacebook.instruction-1')}</p>
                <p>
                    <strong>{t('signInWithFacebook.or')}</strong>
                </p>
                <p>
                    {t('signInWithFacebook.instruction-2-1')} <strong>facebook.com/device</strong>{' '}
                    {t('signInWithFacebook.instruction-2-2')}
                </p>
            </FBInstructionContainer>
            {/* @ts-ignore */}
            <Nav defaultFocused onEnterDown={onCancel}>
                <FBCancelButton onClick={onCancel}>{t('cancel-button-label')}</FBCancelButton>
            </Nav>
        </FBContainer>
    );
};

export default SignInWithFacebookModal;
