/* eslint-disable @typescript-eslint/no-empty-function */
import format from 'date-fns/format';
import { PossessionModel } from '@/models/payments/possession.model';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import AuthSessionModel from '@/models/auth-session.model';
import { CheckProductAccessModel } from '../payments/check-product-access.model';
import { ProductModel } from '@/models/products/product.model';
import { PrePurchaseDataModel } from '../payments/pre-purchase-data.model';
import { redirectToFirstPaymentStep } from '@/helpers/payment.helper';
import { PacketButtonInterface } from '@/interfaces/packet-button.interface';
import { ProductIdInterface } from '@/interfaces/product-id-interface';
import { PRODUCT_SUBTYPES, PRODUCT_TYPES } from '@/types/media.type';
import { PACKET_ACCESS_STATUS } from '@/types/packet-access.type';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { ProductSubscriptionModel } from '../payments/product-subscription.model';
import { ExternalUpgradeDataModel } from '../payments/external-upgrade-data.model';
import { ImageSourceSchema } from '@/interfaces/from-schemas/navigation/getPacketContentOut';
import { buildHighlightSpan } from '@/helpers/highlight-translation.helper';
import { protectURL } from '@/helpers/rewrite-protocol.helper';
import { getBestImageMatch } from '@/utils/get-best-image-match';
import { BUTTON_ID } from '@/types/button-id.type';
import { backgroundUtil } from '@/utils/background.utils';
import { sortPossessionsByDate as sortPossessionsByDate } from '@/helpers/possession.helper';
import { ProductSubscriptionsStatus } from '@/interfaces/product-subscriptions.interface';
import { PURCHASE_AVAILABILITY_STATUS } from '@/interfaces/pre-purchase-data.interface';
import { GetPartnersOut } from '@/interfaces/from-schemas/system/getPartnersOut';
import { PartnerModel } from '../system/partner.model';

export class PacketDetailsModel {
    private accessType: string;
    private currentPacketPossession?: PossessionModel;
    private currentSubscription?;
    private _externalUpgrade?: ExternalUpgradeDataModel;
    private readonly thumbnails: ImageSourceSchema[] = [];

    constructor(
        private packetItemModel: ProductModel,
        private checkProductAccessModel: CheckProductAccessModel,
        private prePurchaseDataModel?: PrePurchaseDataModel,
        private possessionsModel?: PossessionModel[],
        private productSubscriptionsModel?: ProductSubscriptionModel[],
        private authSessionModel?: AuthSessionModel,
        private isSsr?: boolean,
        private isLogged?: boolean,
    ) {
        this.currentPacketPossession = this.findCurrentPacketPossession();
        this.currentSubscription = this.findCurrentSubscription();
        this.accessType = this.checkAccessType();
        this.thumbnails = this.packetItemModel.getThumbnails();
    }

    public get externalUpgrade(): ExternalUpgradeDataModel | undefined {
        return this._externalUpgrade;
    }

    public set externalUpgrade(externalUpgrade: ExternalUpgradeDataModel | undefined) {
        this._externalUpgrade = externalUpgrade;
    }

    private get activateDisneyPlusButton(): PacketButtonInterface {
        const upgradeData = this.externalUpgrade;

        const disneyPlusButton = {
            id: BUTTON_ID.DISNEY_ACTIVATE,
            icon: ICONS.CHECK_WITH_DASH,
            text: t('button.packet.disney-activate'),
            onButtonAction: () => upgradeData?.fetchActivationData(),
            highlight: true,
            disableTimeout: 60 * 3, // in seconds
            disabledMessage: t('button.packet.disney-disabled'),
        };

        return disneyPlusButton;
    }

    private get hasDisneyActivation(): boolean {
        return this.checkProductAccessModel.hasDisneyActivation();
    }

    public static backButtonAction(): void {
        Router.back();
    }

    public getBackgroundSrc(imgWidth?: number, imgHeight?: number): string {
        if (imgWidth !== undefined) {
            return protectURL(getBestImageMatch(this.thumbnails, imgWidth, imgHeight).src || '');
        }

        return backgroundUtil(this.thumbnails)?.src || '';
    }

    public get buttons(): PacketButtonInterface[] {
        const currentSubscriptionStatus = this.currentSubscription?.getStatus();
        switch (this.accessType) {
            case PACKET_ACCESS_STATUS.HAS_ACCESS:
            case PACKET_ACCESS_STATUS.HAS_ACCESS_CYCLIC_CANCELLED:
                return this.hasAccessOrUnpaidButtons;
            case PACKET_ACCESS_STATUS.HAS_ACCESS_CYCLIC:
                return this.hasAccessCyclic(currentSubscriptionStatus);
            case PACKET_ACCESS_STATUS.HAS_ACCESS_WITH_UPGRADE:
                return this.upgradeButtons;
            case PACKET_ACCESS_STATUS.HAS_ACCESS_PLUS:
                return this.hasAccessPlusButtons;
            case PACKET_ACCESS_STATUS.NO_ACCESS_PLUS:
                return this.noAccessPlusButtons;
            case PACKET_ACCESS_STATUS.NO_ACCESS_UNPAID:
                return this.hasAccessOrUnpaidButtons;
            case PACKET_ACCESS_STATUS.NO_ACCESS:
            case PACKET_ACCESS_STATUS.NO_ACCESS_SSR_LOGGED:
            case PACKET_ACCESS_STATUS.NO_ACCESS_SSR:
                return this.noAccessButtons;
            default:
                return this.defaultButtons;
        }
    }

    public get buyAccessText(): string | undefined {
        if (!this.isAvailableToBuy() && !this.isPlusPacket()) {
            return t('pages.packet.details.buy-on');
        }
    }

    public get buyAccessTextSsr(): string | undefined {
        const text = this.isLogged ? 'buy-in-app' : 'buy-in-app-or-sign-in';

        if (!this.isPlusPacket()) {
            if (!this.hasAccess() && !this.isAvailableToBuy()) {
                return t(`pages.packet.details.${text}`, buildHighlightSpan());
            }
        }

        return;
    }

    public get id(): string {
        return this.packetItemModel.getId();
    }

    public get name(): string {
        return this.packetItemModel.getTitle();
    }

    public get description(): string {
        return this.packetItemModel.getDescription();
    }

    public get fullDescriptionLines(): number {
        return process.env.packetDetails.fullDescriptionLines;
    }

    public metadata(currentPartner?: PartnerModel): string[] {
        if (currentPartner) {
            return this.partnerText(currentPartner);
        }

        switch (this.accessType) {
            case PACKET_ACCESS_STATUS.HAS_ACCESS_PLUS:
                return this.hasAccessPlusMetadata;
            case PACKET_ACCESS_STATUS.HAS_ACCESS:
            case PACKET_ACCESS_STATUS.HAS_ACCESS_UNAVAILABLE:
            case PACKET_ACCESS_STATUS.HAS_ACCESS_CYCLIC_CANCELLED:
            case PACKET_ACCESS_STATUS.HAS_ACCESS_WITH_UPGRADE:
                return this.hasAccessMetadata;
            case PACKET_ACCESS_STATUS.HAS_ACCESS_CYCLIC:
                return this.hasAccessCyclicMetadata;
            case PACKET_ACCESS_STATUS.NO_ACCESS_UNPAID:
                return this.noAccessUnpaidMetadata;
            default:
                return [];
        }
    }
    public partnerText(currentPartner: PartnerModel) {
        const subscriptionStatus = this.currentSubscription?.getStatus();
        const currentPacketPossessionStatus = this.currentPacketPossession?.getStatus();
        const partnerName = currentPartner instanceof PartnerModel && currentPartner?.getName();
        const accessText = [];

        const isSubscriptionActiveOrBlocked =
            subscriptionStatus === 'active' || subscriptionStatus === 'blocked';

        if (currentPacketPossessionStatus === 'active') {
            accessText.push(
                t('pages.packet.details.partner.active-access', {
                    ...buildHighlightSpan(),
                }),
            );
        } else if (currentPacketPossessionStatus === 'blocked') {
            accessText.push(
                t('pages.packet.details.partner.blocked-info', {
                    partnerName,
                    ...buildHighlightSpan(),
                }),
            );
            if (subscriptionStatus === 'active') {
                accessText.push(
                    t('pages.packet.details.partner.blocked-access', {
                        ...buildHighlightSpan(),
                    }),
                );
            } else if (subscriptionStatus === 'blocked') {
                accessText.push(
                    t('pages.packet.details.partner.blocked-service', {
                        ...buildHighlightSpan(),
                    }),
                );
            }
        }

        return [accessText.join('')];
    }

    public get platforms(): ICONS[] {
        const icons = this.packetItemModel.getPlatforms().map((platform: string) => {
            switch (platform) {
                case 'mobile':
                    return ICONS.MOBILE;

                case 'pc':
                    return ICONS.PC;

                case 'tv':
                    return ICONS.TV;

                case 'stb':
                    return ICONS.STB;

                default:
                    return null;
            }
        });

        return <ICONS[]>icons.filter((icon) => icon !== null);
    }

    private checkAccessType(): string {
        const hasAccess = this.hasAccess();
        const isAvailableToBuy = this.isAvailableToBuy();
        const isCyclic = this.currentPacketPossession?.isCyclic();
        const isUnpaid = this.isUnpaid();
        const isPlusPacket = this.isPlusPacket();
        const isSsr = this.isSsr;
        const isLogged = this.isLogged;
        const isUpgradeAvailable = this.prePurchaseDataModel?.isUpgradeAvailable();

        if (isPlusPacket) {
            if (hasAccess) return PACKET_ACCESS_STATUS.HAS_ACCESS_PLUS;

            return PACKET_ACCESS_STATUS.NO_ACCESS_PLUS;
        }

        if (hasAccess && isUpgradeAvailable) {
            return PACKET_ACCESS_STATUS.HAS_ACCESS_WITH_UPGRADE;
        }

        if (hasAccess) {
            if (!isAvailableToBuy) return PACKET_ACCESS_STATUS.HAS_ACCESS_UNAVAILABLE;

            if (isCyclic) {
                if (!this.currentSubscription) {
                    return PACKET_ACCESS_STATUS.HAS_ACCESS_CYCLIC_CANCELLED;
                }

                return PACKET_ACCESS_STATUS.HAS_ACCESS_CYCLIC;
            }

            return PACKET_ACCESS_STATUS.HAS_ACCESS;
        } else {
            if (!isAvailableToBuy) return PACKET_ACCESS_STATUS.NO_ACCESS_UNAVAILABLE;
            if (isUnpaid) return PACKET_ACCESS_STATUS.NO_ACCESS_UNPAID;

            if (isSsr) {
                if (isLogged) return PACKET_ACCESS_STATUS.NO_ACCESS_SSR_LOGGED;

                return PACKET_ACCESS_STATUS.NO_ACCESS_SSR;
            }

            return PACKET_ACCESS_STATUS.NO_ACCESS;
        }
    }

    public findCurrentPacketPossession() {
        if (!this.possessionsModel) return;

        const findCurrentPacketPossession = (possession: PossessionModel) => {
            if (possession.getProduct().id === this.id) {
                return possession;
            }
        };

        const currentPacketPossession = this.possessionsModel
            .sort(sortPossessionsByDate)
            .find(findCurrentPacketPossession);

        return currentPacketPossession;
    }

    private findCurrentSubscription() {
        if (!this.currentPacketPossession || !this.productSubscriptionsModel) return;

        const currentSubscription = this.productSubscriptionsModel.find(
            (subscription: ProductSubscriptionModel) =>
                subscription.getOffer().id === this.currentPacketPossession?.getOffer()?.id,
        );

        return currentSubscription;
    }

    public get productId(): ProductIdInterface | null {
        return (
            this.packetItemModel && {
                id: this.packetItemModel.getId(),
                type: this.packetItemModel.getType() as PRODUCT_TYPES,
                subType: this.packetItemModel.getSubType() as PRODUCT_SUBTYPES,
            }
        );
    }

    public goToPaymentsPath(): void {
        const productId = this.productId;

        if (productId) {
            redirectToFirstPaymentStep({ productId, withNext: true });
        }
    }

    public checkPurchaseStatus(purchaseAvailabilityStatus: PURCHASE_AVAILABILITY_STATUS): boolean {
        return (
            this.prePurchaseDataModel?.getPurchaseAvailability().status ===
            purchaseAvailabilityStatus
        );
    }

    private isAvailableToBuy(): boolean {
        return this.checkPurchaseStatus(PURCHASE_AVAILABILITY_STATUS.PURCHASE_AVAILABLE);
    }

    private hasAccess(): boolean {
        return this.checkProductAccessModel.hasAccess();
    }

    private isPlusPacket(): boolean {
        return this.id === 'plusfree';
    }

    private isUnpaid(): boolean {
        return !!(this.currentSubscription?.getStatus() === 'blocked');
    }

    // metadatas
    private get accessSuspendedText(): string {
        return t('pages.packet.details.access-suspended');
    }

    private get accessToText(): string {
        let accessToText = '';
        const accessToDateISO = this.currentPacketPossession?.getValidTo();

        if (accessToDateISO) {
            const accessToDate = format(new Date(accessToDateISO), 'dd.MM.yyyy HH:mm');
            const accessToTranslation = this.isSsr
                ? this.externalUpgrade?.showDisneyAccessInfo(this.hasDisneyActivation)
                    ? 'pages.packet.details.access-to-disney-ssr'
                    : 'pages.packet.details.access-to-ssr'
                : 'pages.packet.details.access-to';

            accessToText = t(accessToTranslation, {
                accessToDate,
                ...buildHighlightSpan(),
            });
        }

        return accessToText;
    }

    private get hasAccessMetadata() {
        const hasAccessMetadata = [this.accessToText];
        if (!this.isSsr) return [...hasAccessMetadata, this.renewAccessText];

        return hasAccessMetadata;
    }

    private get hasAccessPlusMetadata() {
        return this.accessToText ? [this.accessToText] : [];
    }

    private get hasAccessCyclicMetadata() {
        const hasAccessCyclicMetadata = [this.accessToText, this.nextPaymentText];

        return hasAccessCyclicMetadata;
    }

    private get noAccessUnpaidMetadata() {
        const noAccessUnpaidMetadata = [this.accessSuspendedText, this.paymentFailedText];
        if (!this.isSsr) {
            return [...noAccessUnpaidMetadata, this.contactCscText, this.cancelSubscriptionText];
        } else {
            return [...noAccessUnpaidMetadata, this.cancelSubscriptionText, this.resumeAccessText];
        }
    }

    // single texts

    private get cancelSubscriptionText(): string {
        return t('pages.packet.details.cancel-subscription', buildHighlightSpan());
    }

    private get contactCscText(): string {
        return t('pages.packet.details.contact-csc');
    }

    public get locationRestrictionText(): string {
        return this.packetItemModel.getLicenseLocationText();
    }

    public metadataWithRestrictionText(currentPartner?: PartnerModel) {
        return [this.locationRestrictionText, ...this.metadata(currentPartner)];
    }

    private get minPriceText(): string {
        return (
            this.prePurchaseDataModel?.getMinPricePurchaseText() || t('models.media-details.buy')
        );
    }

    public get nextPaymentText(): string {
        let nextPaymentText = '';
        const nextPaymentDateISO = this.currentSubscription?.getNextPaymentDate();
        const subscriptionStatus = this.currentSubscription?.getStatus();
        const currentPacketPossessionStatus = this.currentPacketPossession?.getStatus();

        if (nextPaymentDateISO) {
            const nextPaymentDate = format(new Date(nextPaymentDateISO), 'dd.MM.yyyy');

            nextPaymentText = t(
                `pages.packet.details.${subscriptionStatus}-subscription.${currentPacketPossessionStatus}`,
                {
                    date: nextPaymentDate,
                    ...buildHighlightSpan(),
                },
            );
        }

        return nextPaymentText;
    }

    private get paymentFailedText(): string {
        const translation = this.isSsr
            ? 'pages.packet.details.payment-failed-ssr'
            : 'pages.packet.details.payment-failed';

        return translation;
    }

    private get renewAccessText(): string {
        return t('pages.packet.details.renew-access', buildHighlightSpan());
    }

    private get resumeAccessText(): string {
        return t('pages.packet.details.resume-access', buildHighlightSpan());
    }

    // buttons

    private get defaultButtons(): PacketButtonInterface[] {
        const defaultButtons = [this.fullDetailsButton];

        if (!this.isSsr) {
            defaultButtons.push(this.packetContentButton);
        }

        if (this.externalUpgrade?.showActivateDisneyPlusButton(this.hasDisneyActivation)) {
            defaultButtons.unshift(this.activateDisneyPlusButton);
        }

        return [...defaultButtons];
    }

    private get hasAccessOrUnpaidButtons(): PacketButtonInterface[] {
        return [this.renewButton(), ...this.defaultButtons];
    }

    private get upgradeButtons(): PacketButtonInterface[] {
        return [this.upgradeButton, ...this.defaultButtons];
    }

    private get hasAccessPlusButtons(): PacketButtonInterface[] {
        return [...this.defaultButtons];
    }

    private get noAccessButtons(): PacketButtonInterface[] {
        if (!this.checkPurchaseStatus(PURCHASE_AVAILABILITY_STATUS.PURCHASE_AVAILABLE)) {
            return [...this.defaultButtons];
        }

        return [this.buyButton, ...this.defaultButtons];
    }

    private get noAccessPlusButtons(): PacketButtonInterface[] {
        return [this.addPhoneNumberButton, ...this.defaultButtons];
    }

    private hasAccessCyclic(
        currentSubscriptionStatus: ProductSubscriptionsStatus | undefined,
    ): PacketButtonInterface[] {
        if (currentSubscriptionStatus === 'active') {
            return [this.resignButton, ...this.defaultButtons];
        }
        if (currentSubscriptionStatus === 'blocked') {
            return [this.renewButton(true), this.resignButton, ...this.defaultButtons];
        }
        return [...this.defaultButtons];
    }

    // single buttons

    private get addPhoneNumberButton(): PacketButtonInterface {
        const addPhoneNumberButton = {
            id: BUTTON_ID.PLUS,
            icon: ICONS.PLUS,
            text: t('pages.plus-zone.add-phone-number'),
            onButtonAction: () => Router.pushRoute(ROUTES.PLUS_ZONE),
            highlight: true,
        };

        return addPhoneNumberButton;
    }

    private get buyButton(): PacketButtonInterface {
        const buyButton = {
            id: BUTTON_ID.BASKET,
            icon: ICONS.BASKET,
            text: this.minPriceText,
            onButtonAction: () => this.goToPaymentsPath(),
            highlight: true,
        };

        return buyButton;
    }

    private get fullDetailsButton(): PacketButtonInterface {
        const fullDetailsButton = {
            id: BUTTON_ID.INFO,
            icon: ICONS.INFO,
            text: t('more-button-label'),
            onButtonAction: () => {
                Router.pushRoute(ROUTES.PACKET_FULL_DETAILS, {
                    packetId: this.id,
                });
            },
        };

        return fullDetailsButton;
    }

    private renewButton(isResume = false): PacketButtonInterface {
        const text = t(isResume ? 'button.packet.resume' : 'button.packet.renew');

        const renewButton = {
            id: BUTTON_ID.CLOCK_REDO,
            icon: ICONS.CLOCK_REDO,
            text,
            onButtonAction: () => this.goToPaymentsPath(),
            highlight: true,
        };

        return renewButton;
    }

    private get resignButton(): PacketButtonInterface {
        const isPartnerPacket = !!this.packetItemModel.getPartnerOffers()[0].isPartnerOffer();
        const isDisneyPacket = this.hasDisneyActivation;
        const resignButton = {
            id: BUTTON_ID.RESIGN,
            icon: ICONS.CLOSE,
            text: t('product-access.resign-button-text'),
            onButtonAction: () => {
                Router.pushRoute(ROUTES.MY_ACCESS_RESIGNATION_CONFIRM, {
                    subscriptionId: this.findCurrentSubscription()?.getId(),
                    possessionName: this.packetItemModel.getTitle(),

                    validTo: this?.currentPacketPossession?.getValidTo() || '',
                    ...((isPartnerPacket || isDisneyPacket) && {
                        productId: this.currentPacketPossession?.getId(),
                        offerId: (this.currentPacketPossession as PossessionModel)?.getOfferId(),
                    }),
                });
            },
        };
        return resignButton;
    }

    private get upgradeButton(): PacketButtonInterface {
        let text = t('button.packet.renew');

        const externalUpgrade = this.externalUpgrade;
        const currentSubscription = this.findCurrentSubscription();
        const subscriptionStatus = currentSubscription?.getStatus();

        if (externalUpgrade) {
            text = externalUpgrade.getRenewButtonText(
                this.currentPacketPossession,
                subscriptionStatus,
            );
        }

        const upgradeButton = {
            id: BUTTON_ID.CLOCK_REDO,
            icon: ICONS.CLOCK_REDO,
            text,
            onButtonAction: () => this.goToPaymentsPath(),
            highlight: true,
        };

        return upgradeButton;
    }

    private get packetContentButton(): PacketButtonInterface {
        const packetContentButton = {
            id: BUTTON_ID.CATALOG_BTN,
            icon: ICONS.CATALOG_BTN,
            text: t('pages.packet.details.packet-content'),
            onButtonAction: () => {
                Router.pushRoute(ROUTES.PACKET_DETAILS, {
                    packetId: this.id,
                });
            },
        };

        return packetContentButton;
    }
}
