import { PlaceSchema } from '@/interfaces/from-schemas/navigation/getHomeMenuOut';
import SubcategoriesModel from '@/models/category/subcategories.model';
import { RouteInterface, ROUTES } from '@/routing/types';
import { buildNextUrl } from '../url.helper';

const { id: offerCategoryId } = process.env.offerCategory;

export const prepareMenuUrl = (
    place?: PlaceSchema,
    subcategories?: SubcategoriesModel,
): RouteInterface | undefined => {
    if (!place) {
        return undefined;
    }

    const buildCategory = () => {
        const category = subcategories?.getSubcategoryName(place.value);
        const isOfferCategory = offerCategoryId && place.value === offerCategoryId?.toString();

        if (isOfferCategory) {
            return {
                route: ROUTES.OFFER,
            };
        }

        if (!category) {
            return undefined;
        }

        return {
            route: ROUTES.VOD,
            params: { category },
        };
    };
    const next = buildNextUrl();

    switch (place.type) {
        case 'category':
            return buildCategory();

        case 'packet':
            return {
                route: ROUTES.PACKET_DETAILS,
                params: { packetId: place.value },
            };

        case 'packets':
            return { route: ROUTES.PACKETS };

        case 'live_channels':
            return { route: ROUTES.LIVE };

        case 'tv_channels':
            return { route: ROUTES.CHANNELS_TV };

        case 'favorites':
            return { route: ROUTES.MENU_MY_LIST };

        case 'search':
            return { route: ROUTES.SEARCH };

        case 'purchase_code':
            return { route: ROUTES.HAVE_CODE };

        case 'epg':
            return { route: ROUTES.CHANNELS_TV, query: { epg: true } };

        case 'collection':
            return { route: ROUTES.COLLECTION, params: { collectionId: place.value, next } };

        default:
            return undefined;
    }
};
