import {
    GetStaffRecommendationsListItemsOut,
    ImageSourceSchema,
} from '@/interfaces/from-schemas/navigation/getStaffRecommendationsListItemsOut';
import { CustomDataInterface } from '@/interfaces/custom-data.interface';
import { ListElementImageInterface } from '@/interfaces/list-element.interface';
import { setModelToRecommendationObject } from '@/helpers/set-model-to-recommendation-object.helper';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { coverUtil } from '@/utils/cover.util';
import { backgroundUtil } from '@/utils/background.utils';
import { CategoryModel } from '../category/category.model';
import { MediaListItemModel } from '../media-list-item.model';
import { PacketModel } from '../packets/packet.model';
import { ChannelProgramTvModel } from '../channels/channel-program-tv.model';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { getBestImageMatch } from '@/utils/get-best-image-match';
import { protectURL } from '@/helpers/rewrite-protocol.helper';
import { goToDetailsOptionsType } from '@/interfaces/asset.interface';
import { CollectionModel } from '../collection/collection.model';

export type StaffRecommendationListItem = GetStaffRecommendationsListItemsOut['results'][number];
export type StaffRecommendationListItemIndex = number | undefined;

export class StaffRecommendationListsItemModel {
    public imageDisplayMode = IMAGE_DISPLAY_MODE.THUMBNAILS;

    private readonly type: string = '';
    private readonly value: string = '';
    private readonly name: string = '';
    private readonly description: string = '';
    private readonly thumbnails: ImageSourceSchema[] = [];
    private readonly posters: ImageSourceSchema[] = [];
    private readonly url: string = '';
    private readonly validFrom: string = '';
    private readonly validTo: string = '';
    private readonly customData: {
        [k: string]: unknown;
    } = {};
    private readonly backgroundSrc?: string;
    private readonly listItemIndex?: number;
    private object?:
        | CategoryModel
        | MediaListItemModel
        | PacketModel
        | ChannelProgramTvModel
        | CollectionModel;
    private readonly gallery: StaffRecommendationListItem['gallery'];

    constructor(
        definition: StaffRecommendationListItem,
        listItemIndex?: StaffRecommendationListItemIndex,
    ) {
        Object.keys(definition).forEach((name: string) => {
            if (name === 'object') {
                if (definition?.gallery && definition?.object) {
                    //wymuszenie podmiany obiektu gallery jeżeli przychodzi nadpisany obrazek z galerii
                    definition.object.gallery = definition.gallery;
                }
                this.object = setModelToRecommendationObject(definition);
            } else {
                // @ts-ignore
                this[name] = definition[name];
            }
        });

        this.backgroundSrc = backgroundUtil(this.thumbnails)?.src;
        this.listItemIndex = listItemIndex;
    }

    public getListItemIndex(): StaffRecommendationListItemIndex {
        return this.listItemIndex;
    }

    public getType(): string {
        return this.type;
    }

    public getValue(): string {
        return this.value;
    }

    public getTitle(): string {
        return this.name;
    }

    public getDescription(): string {
        return this.description;
    }

    public getThumbnails(): ImageSourceSchema[] {
        return this.thumbnails;
    }

    public getPosters(): ImageSourceSchema[] {
        return this.posters;
    }

    public isPostersExist(): boolean {
        return this.getPosters().length > 0;
    }

    public isThumbnailsExists(): boolean {
        return this.getThumbnails().length > 0;
    }

    public getUrl(): string {
        return this.url;
    }

    public getValidFrom(): string {
        return this.validFrom;
    }

    public getValidTo(): string {
        return this.validTo;
    }

    public getCustomData(): CustomDataInterface {
        return this.customData;
    }

    public getObject():
        | CategoryModel
        | MediaListItemModel
        | PacketModel
        | ChannelProgramTvModel
        | CollectionModel
        | undefined {
        if (this.isTypeUrl()) return;

        return this.object;
    }

    public getImage(): ListElementImageInterface {
        const images =
            this.imageDisplayMode === IMAGE_DISPLAY_MODE.POSTERS
                ? this.getPosters()
                : this.getThumbnails();
        const image = coverUtil(images, this.imageDisplayMode);

        return {
            src: image ? image.src : '',
            displayMode: this.imageDisplayMode,
        };
    }

    public getResizedImage(targetWidth: number): ListElementImageInterface {
        let posters: ImageSourceSchema[] = [];
        let thumbnails: ImageSourceSchema[] = [];

        if (this.getPosters().length) {
            posters = this.getPosters();
        } else if (this.object && this.object instanceof MediaListItemModel) {
            posters = this.object.getPosters();
        }

        if (this.getThumbnails().length) {
            thumbnails = this.getThumbnails();
        } else if (this.object) {
            thumbnails = this.object.getThumbnails();
        }

        const images = this.imageDisplayMode === IMAGE_DISPLAY_MODE.POSTERS ? posters : thumbnails;
        const imageMatch = getBestImageMatch(images, targetWidth);
        const src = protectURL(imageMatch.src);

        return {
            src,
            displayMode: this.imageDisplayMode,
        };
    }

    public setImageDisplayMode(imageDisplayMode: IMAGE_DISPLAY_MODE): void {
        this.imageDisplayMode = imageDisplayMode;

        if (this.object) {
            this.object.imageDisplayMode = imageDisplayMode;
        }
    }

    public getBackgroundSrc(): string | undefined {
        if (this.backgroundSrc) {
            return protectURL(this.backgroundSrc);
        }
        return this.object?.getBackgroundSrc();
    }

    public getBackgroundSrcBrowser(targetWidth: number): string {
        const thumbnails = this.thumbnails.length ? this.thumbnails : this.object?.getThumbnails();

        if (thumbnails) {
            const imageMatch = getBestImageMatch(thumbnails, targetWidth);
            return protectURL(imageMatch.src);
        }

        return '';
    }

    public getMetadata(): string {
        return '';
    }

    public getDetailsRoute(): ROUTES {
        return this.url as ROUTES;
    }
    public getRouteParams(): any {
        return {};
    }

    public goToDetails(options?: goToDetailsOptionsType): void {
        const { replaceRoute } = options || {};
        const route = this.getDetailsRoute();
        replaceRoute ? Router.replaceRoute(route) : Router.pushRoute(route);
    }

    public isTypeUrl(): boolean {
        return this.getType() === 'url';
    }

    public getGallery() {
        return this.gallery;
    }

    public getGrantExpression(): string {
        return this.getObject()?.getGrantExpression() || '';
    }
}
