import { FlatNavigationSchema } from '@/interfaces/from-schemas/navigation/getCategoryWithFlatNavigationOut';

export class StaffCollectionWithFlatNavigationModel {
    private cpid = 0;
    private id = 0;
    private name = '';
    private type = '';
    private description = '';

    private flatNavigation: FlatNavigationSchema = {};

    constructor(definition: any) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });
        }
    }

    public getFlatNavigation(): FlatNavigationSchema {
        return this.flatNavigation;
    }

    public getName(): string {
        return this.name;
    }
    public getType(): string {
        return this.type;
    }
}
