import { ROUTES_CONFIG } from '@/routing/routes';
import { ROUTES } from '@/routing/types';

export const ROUTES_LIST_STANDALONE = [
    ROUTES.CUSTOMER_CARE_HELP,
    ROUTES.CUSTOMER_CARE_HELP_ARTICLE,
    ROUTES.CUSTOMER_CARE_FULL_ERROR_PAGE,
    ROUTES.CUSTOMER_CARE_ERROR_PAGE,
    ROUTES.CUSTOMER_CARE_FORM_SUCCESS_PAGE,
    ROUTES.ACCOUNT,
    ROUTES.ACCOUNT_ACTIVATION,
    ROUTES.ACCOUNT_CHANGE_EMAIL,
    ROUTES.ACCOUNT_CHANGE_EMAIL_SUCCESS,
    ROUTES.ACCOUNT_CHANGE_PASSWORD,
    ROUTES.ACCOUNT_SET_PASSWORD,
    ROUTES.ACCOUNT_CHANGE_PASSWORD_SUCCESS,
    ROUTES.ACCOUNT_CHANGE_PROFILE,
    ROUTES.ACCOUNT_DELETE,
    ROUTES.ACCOUNT_DELETE_SUCCESS,
    ROUTES.ACCOUNT_EXISTS,
    ROUTES.ACCOUNT_HELP,
    ROUTES.ACCOUNT_LOGOUT,
    ROUTES.ACCOUNT_MY_ACCOUNT,
    ROUTES.ACCOUNT_MY_PURCHASES,
    ROUTES.ACCOUNT_RESTORE_SETTINGS,
    ROUTES.ADD_ACCOUNT_EMAIL,
    ROUTES.CHANNELS_TV,
    ROUTES.CHANNEL_TV_DETAILS,
    ROUTES.CHANNEL_TV_FULL_DETAILS,
    ROUTES.CHANNEL_TV_WATCH,
    ROUTES.CONSENT,
    ROUTES.CONSENTS_LIST,
    ROUTES.CONTACT,
    ROUTES.CONTACT_IOD,
    ROUTES.EMAIL_ACTIVATION,
    ROUTES.EMAIL_ACTIVATION_CHECK_EMAIL,
    ROUTES.EU_ACCESS,
    ROUTES.EU_VERIFICATION_SUCCESS,
    ROUTES.EXIT_APP_CONFIRMATION,
    ROUTES.GOODBYE,
    ROUTES.HAVE_CODE,
    ROUTES.HOME,
    ROUTES.LIVE,
    ROUTES.LIVE_DETAILS,
    ROUTES.LIVE_FULL_DETAILS,
    ROUTES.LIVE_WATCH,
    ROUTES.LOGIN_FACEBOOK,
    ROUTES.LOGIN_IPOLSATBOX,
    ROUTES.LOGIN_NATIVE,
    ROUTES.LOGIN_BY_CODE,
    ROUTES.LOGIN_BY_REMOTE,
    ROUTES.LOGIN_RULES,
    ROUTES.LOGIN_RULES_OBLIGATORY,
    ROUTES.MENU_MY_LIST,
    ROUTES.MY_ACCESS,
    ROUTES.NOT_FOUND,
    ROUTES.OFFER,
    ROUTES.PACKETS,
    ROUTES.PACKET_CONTENT,
    ROUTES.PACKET_DETAILS,
    ROUTES.PACKET_FULL_DETAILS,
    ROUTES.PLUS_ZONE,
    ROUTES.PLUS_ZONE_ADD_PHONE,
    ROUTES.PLUS_ZONE_ADD_PHONE_SUCCESS,
    ROUTES.PLUS_ZONE_CHANGE_PHONE,
    ROUTES.PLUS_ZONE_CHANGE_PHONE_SUCCESS,
    ROUTES.PLUS_ZONE_REMOVE_PHONE,
    ROUTES.PLUS_ZONE_REMOVE_PHONE_SUCCESS,
    ROUTES.PRIVACY_POLICY,
    ROUTES.PROFILE,
    ROUTES.PROFILE_ADD,
    ROUTES.PROFILE_CHANGE_ICON,
    ROUTES.PROFILE_CHANGE_NAME,
    ROUTES.PROFILE_PARENT_CONTROL_AGE,
    ROUTES.PROFILE_PARENT_CONTROL_PASSWORD,
    ROUTES.PROFILE_REMOVE,
    ROUTES.PROFILE_REMOVE_DEFAULT,
    ROUTES.PROFILE_REMOVE_SUCCESS,
    ROUTES.PROFILE_SELECT_TO_EDIT,
    ROUTES.PROFILE_WATCH_AS,
    ROUTES.PURCHASE,
    ROUTES.PURCHASE_OFFER_RULES,
    ROUTES.PURCHASE_PRICING_PLAN_RULES,
    ROUTES.PURCHASE_STATUS,
    ROUTES.REGISTER_PAYMENT_CARD,
    ROUTES.REGISTER_PAYMENT_CARD_STATUS,
    ROUTES.REGISTRATION_CONFIGURATION,
    ROUTES.RESET_PASSWORD,
    ROUTES.RESET_PASSWORD_CHECK_EMAIL,
    ROUTES.RESET_PASSWORD_REQUEST,
    ROUTES.RESOLVE_CATEGORY,
    ROUTES.RESOLVE_MEDIA,
    ROUTES.RULES,
    ROUTES.SEARCH,
    ROUTES.SETTINGS,
    ROUTES.SUBCATEGORY,
    ROUTES.VOD,
    ROUTES.FUTURE_EPG,
    ROUTES.FUTURE_EPG_FULL_DETAILS,
    ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY,
    ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY_DETAILS,
    ROUTES.VOD_EPISODE_DETAILS,
    ROUTES.VOD_EPISODE_FULL_DETAILS,
    ROUTES.VOD_EPISODE_WATCH,
    ROUTES.VOD_EPISODE_WITH_SEASON_DETAILS,
    ROUTES.VOD_EPISODE_WITH_SEASON_FULL_DETAILS,
    ROUTES.VOD_EPISODE_WITH_SEASON_WATCH,
    ROUTES.VOD_MOVIE_DETAILS,
    ROUTES.VOD_MOVIE_FULL_DETAILS,
    ROUTES.VOD_MOVIE_WATCH,
    ROUTES.VOD_WATCH,
    ROUTES.WELCOME,
    ROUTES.CONTACT_WITH_US,
    ROUTES.COLLECTION,
];

export const ROUTES_CONFIG_STANDALONE = ROUTES_CONFIG.filter(({ name }) =>
    ROUTES_LIST_STANDALONE.includes(name),
);
