import { useCallback, useContext } from 'react';
import { SCRIPT_ID } from '@/types/script-id.type';
import { findScriptById } from '@/helpers/find-script-by-id.helper';
import { PlayerLoaderContext } from '@/contexts/player/player-loader.context';

export const useLoadPlayerScript = () => {
    const { setPlayerScriptLoaded } = useContext(PlayerLoaderContext);

    const loadPlayerScript = useCallback(() => {
        const load = () =>
            window.playerLoader.loadScript((isPlayerLoaded: boolean) => {
                setPlayerScriptLoaded(isPlayerLoaded);
            });

        if (window.playerLoader) {
            load();
            return;
        }

        const loaderScript = findScriptById(SCRIPT_ID.LOADER);

        if (loaderScript) {
            loaderScript.onload = () => load();
        }
    }, []);

    return { loadPlayerScript };
};
