import { Icon } from '@/components/shared/Icon';
import { AdditionalSigns } from '@/components/shared/AdditionalSigns/AdditionalSigns';
import { getIconName } from '@/helpers/get-icon-name.helper';
import { usePlayerMediaDetails } from '@/hooks/player/use-player-media-details';
import React from 'react';
import {
    MediaLabel,
    MediaTitleContainer,
    MediaTitleIconsContainer,
    MediaTitleInnerContainer,
    MediaTitleTextContainer,
    PlayerRatingIconWrapper,
} from './styles';
import { MediaTitleProps } from './types';
import { ADDITIONAL_SIGNS_TYPES } from '@/types/additional-signs.type';

export const MediaTitle = ({ label }: MediaTitleProps) => {
    const { title, ageGroup, underageClassification, accessibilityFeatures } =
        usePlayerMediaDetails();
    const hasAgeGroup = ageGroup > 0;

    return (
        <MediaTitleContainer>
            {label && <MediaLabel>{label}</MediaLabel>}

            <MediaTitleInnerContainer>
                <MediaTitleTextContainer>{title}</MediaTitleTextContainer>

                <MediaTitleIconsContainer>
                    {hasAgeGroup && (
                        <PlayerRatingIconWrapper ageGroup={ageGroup}>
                            <Icon name={getIconName(ageGroup)} />
                        </PlayerRatingIconWrapper>
                    )}

                    <AdditionalSigns
                        type={ADDITIONAL_SIGNS_TYPES.UNDERAGE_CLASSIFICATION}
                        signs={underageClassification}
                        $iconSize={2.2}
                    />
                    <AdditionalSigns
                        type={ADDITIONAL_SIGNS_TYPES.ACCESSIBILITY_FEATURES}
                        signs={accessibilityFeatures}
                        $iconSize={2.2}
                    />
                </MediaTitleIconsContainer>
            </MediaTitleInnerContainer>
        </MediaTitleContainer>
    );
};
