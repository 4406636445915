import _bowser from 'bowser';
import { isServer } from '../environment.helper';
import { useMemo } from 'react';

export interface BowserAPI {
    desktop?: boolean;
    mobile?: boolean;
    tablet?: boolean;
    tv?: boolean;

    msie?: boolean;
    msedge?: boolean;
    chrome?: boolean;
    firefox?: boolean;
    opera?: boolean;
    safari?: boolean;

    name?: string;
    version?: number;

    windows?: boolean;
    linux?: boolean;
    mac?: boolean;
    android?: boolean;
    ios?: boolean;
    webos?: boolean;
    tizen?: boolean;
    stb?: boolean;
    whaleos?: boolean;
    philips?: boolean;
    panasonic?: boolean;
    hisense?: boolean;
    chromecast?: boolean;
    firetv?: boolean;
    appletv?: boolean;
    androidtv?: boolean;
    console?: boolean;

    osname?: string;
    osversion?: string;
    appmobilename?: string;
    consolename?: string;
}

export const useBowser = (): BowserAPI => {
    const parseResult = useMemo(() => {
        if (!isServer) {
            const parseResult = _bowser.getParser(window.navigator.userAgent);
            return parseResult;
        }
    }, []);

    const isMAC = () => {
        return parseResult?.is('macOS') && !isIOS();
    };

    const isIOS = () => {
        if (parseResult?.is('ios')) {
            return true;
        }

        /**
         * Reguła dla safari na ipad 5 które przedstawia się jako macos
         */
        if (
            parseResult?.is('macOS') &&
            parseResult?.is('safari') &&
            window.navigator.maxTouchPoints
        ) {
            return true;
        }

        return false;
    };

    const isStb = () => !isServer && typeof window['stb'] === 'object';
    const isWhaleOS = () => !isServer && /whaletv/i.test(window.navigator.userAgent);
    const isPhilips = () => !isServer && /philips|nettv/i.test(window.navigator.userAgent);
    // https://stackoverflow.com/questions/20872923/how-do-i-detect-a-chromecast-device-in-javascript
    const isChromecast = () => !isServer && /crkey/i.test(window.navigator.userAgent);
    // https://stackoverflow.com/questions/41356245/how-to-identify-amazon-fire-tv-and-fire-stick-in-code-level-html-javascript
    const isFireTV = () => !isServer && /aftb|afts|aftm|aftt/i.test(window.navigator.userAgent);
    const isAppleTV = () => !isServer && /apple.?tv/i.test(window.navigator.userAgent);
    const isAndroidTV = () => !isServer && /android.?tv/i.test(window.navigator.userAgent);
    const isConsole = () =>
        !isServer && /playstation|xbox|nintendo/i.test(window.navigator.userAgent);
    const isPanasonic = () => !isServer && /panasonic/i.test(window.navigator.userAgent);
    const isHisense = () => !isServer && /hisense/i.test(window.navigator.userAgent);
    // Bowser nie radzi sobie z wykrywaniem SmartTV i trzeba mu pomóc
    const isTV = () => {
        return (
            parseResult?.is('tv') ||
            parseResult?.is('tizen') ||
            parseResult?.is('webos') ||
            isWhaleOS() ||
            isPhilips() ||
            isFireTV() ||
            isAppleTV() ||
            isAndroidTV() ||
            isChromecast() ||
            isPanasonic() ||
            isHisense()
        );
    };

    const getConsoleName = (): string => {
        if (isServer) return '';

        const userAgent = window.navigator.userAgent;
        const isPlayStation3 = /playstation 3/i.test(userAgent);
        const isPlayStation4 = /playstation 4/i.test(userAgent);
        const isPlayStation5 = /playstation 5/i.test(userAgent);
        const isPlayStationVita = /playstation Vita/i.test(userAgent);
        const isPlayStation = /playstation/i.test(userAgent);
        const isNintendoSwitch = /nintendo switch/i.test(userAgent);
        const isNintendoWii = /nintendo wii/i.test(userAgent);
        const isNintendo3DS = /nintendo 3ds/i.test(userAgent);
        const isNintendo = /nintendo/i.test(userAgent);
        const isXbox360 = /xbox 360/i.test(userAgent);
        const isXbox = /xbox/i.test(userAgent);

        switch (true) {
            case isPlayStation5:
                return 'PlayStation 5';
            case isPlayStation4:
                return 'PlayStation 4';
            case isPlayStation3:
                return 'PlayStation 3';
            case isPlayStationVita:
                return 'PlayStation Vita';
            case isPlayStation:
                return 'PlayStation';
            case isNintendoSwitch:
                return 'Nintendo Switch';
            case isNintendoWii:
                return 'Nintendo Wii';
            case isNintendo3DS:
                return 'Nintendo 3DS';
            case isNintendo:
                return 'Nintendo';
            case isXbox360:
                return 'Xbox 360';
            case isXbox:
                return 'Xbox';
            default:
                return '';
        }
    };

    /**
     * Pobiera nazwę aplikacji mobilnej w której załadowana jest strona internetowa
     */
    const getAppMobileName = (): string => {
        if (isServer || (!parseResult?.is('mobile') && !parseResult?.is('tablet'))) return '';

        try {
            const userAgent = window.navigator.userAgent;
            const splitUA = userAgent.split('/');
            const phrase = splitUA[splitUA.length - 2];
            const phraseArray = phrase.split(' ');
            phraseArray.shift();

            const appName = phraseArray.join(' ');

            const additionalCheck = splitUA[splitUA.length - 1].indexOf('(');

            return additionalCheck > -1 && appName ? appName : '';
        } catch (e) {
            return '';
        }
    };

    return {
        desktop: parseResult?.is('desktop'),
        mobile: parseResult?.is('mobile'),
        tablet: parseResult?.is('tablet'),
        tv: isTV(),

        msie: parseResult?.is('Internet Explorer'),
        msedge: parseResult?.is('Microsoft Edge'),
        chrome: parseResult?.is('chrome'),
        firefox: parseResult?.is('firefox'),
        opera: parseResult?.is('opera'),
        safari: parseResult?.is('safari'),
        name: parseResult?.getBrowserName(),
        version: parseFloat(parseResult?.getBrowserVersion() || ''),

        windows: parseResult?.is('windows'),
        linux: parseResult?.is('linux'),
        mac: isMAC(),
        android: parseResult?.is('android'),
        ios: isIOS(),
        webos: parseResult?.is('webos'),
        tizen: parseResult?.is('tizen'),
        stb: isStb(),
        whaleos: isWhaleOS(),
        philips: isPhilips(),
        panasonic: isPanasonic(),
        hisense: isHisense(),
        chromecast: isChromecast(),
        firetv: isFireTV(),
        appletv: isAppleTV(),
        androidtv: isAndroidTV(),
        console: isConsole(),

        osname: parseResult?.getOSName(),
        osversion: parseResult?.getOSVersion(),
        appmobilename: getAppMobileName(),
        consolename: getConsoleName(),
    };
};
