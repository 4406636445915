import { LoaderContext } from '@/contexts/loader/loader.context';
import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Nav } from 'nav-tree';
import { ElementInnerWrapper, ElementWrapper, ImageContainer, ImageWrapper } from './styles';
import { ListElementOwnProps } from './types';
import { Image } from '../Image';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { convertPxToRem } from '@/helpers/convert-px-to-rem.helper';
import { ListElementProgressBar, PositionedMetadata } from '.';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { useStatsModule } from '@/contexts/stats/stats.context';
import { Router } from '@/routing';
import useCheckProductAccess from '@/api/drm/use-check-product-access';
import { ProductIdSchema } from '@/interfaces/from-schemas/navigation/getProductOut';
import {
    ListElementBottomMarkings,
    ListElementTopMarkings,
} from '@/components/shared/ListElement/Markings';
import { getPremiereMarkings } from '@/helpers/get-premiere-markings';

const ListElement = ({
    itemData,
    defaultFocused,
    setActiveElement,
    lazy,
    focusedRow,
    'data-testing': dataTesting,
    index = 0,
    onItemClickedHandler,
    isChronological,
}: ListElementOwnProps) => {
    const [active, setActive] = useState(false);
    const { setShowLoader } = useContext(LoaderContext);
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);
    const statsModule = useStatsModule();
    const grantExpression = itemData.getGrantExpression();
    const premiereMarking = getPremiereMarkings(itemData);

    const [product, setProduct] = useState<ProductIdSchema | null>();

    const image = useMemo(() => itemData.getImage(), [itemData]);

    useEffect(() => {
        if (isChronological) {
            setProduct(itemData.getAsset()?.getProduct?.());
        }
    }, [isChronological, itemData]);

    const { data: checkProductAccessData } = useCheckProductAccess(
        product ? { product } : undefined,
    );
    const hasAccess = checkProductAccessData?.hasAccess() || false;

    const imageSize = useMemo(
        () =>
            image.displayMode === IMAGE_DISPLAY_MODE.POSTERS
                ? process.env.imageSize.posters
                : process.env.imageSize.thumbnails,
        [image],
    );
    const heightInRem = useMemo(() => convertPxToRem(imageSize.height), [imageSize]);
    const widthInRem = useMemo(() => convertPxToRem(imageSize.width), [imageSize]);
    const { route: watchRouteData, params: watchRouteParams } = itemData.getWatchRouteData();

    const onItemNav = (isActive: boolean) => {
        batchedUpdates(() => {
            setShowLoader(false);
            if (isActive) {
                setActiveElement?.(itemData, index);
            }
            setActive(isActive);
        });
    };

    const onItemClicked = useCallback(() => {
        onItemClickedHandler(itemData, index);
        itemData.goToDetails();
    }, [itemData, index, statsModule]);

    const onItemGotoToWatch = useCallback(() => {
        Router.pushRoute(watchRouteData, watchRouteParams);
    }, [watchRouteData, watchRouteParams]);

    return useMemo(
        () => (
            // @ts-ignore
            <Nav
                onNav={onItemNav}
                onEnterDown={hasAccess ? onItemGotoToWatch : onItemClicked}
                onClick={hasAccess ? onItemGotoToWatch : onItemClicked}
                defaultFocused={defaultFocused}
                autofocusOn={focusedRow && isMagicMouseVisible ? ['mouseenter'] : undefined}
                data-testing={dataTesting}
            >
                <ElementWrapper>
                    <ElementInnerWrapper active={active} $width={widthInRem}>
                        <ImageContainer
                            $height={heightInRem}
                            allowFocus={focusedRow}
                            title={itemData.getTitle()}
                            aria-label={itemData.getTitle()}
                            tabIndex={0}
                        >
                            <ImageWrapper>
                                {image.src && (
                                    <>
                                        <ListElementTopMarkings
                                            asset={itemData.getAsset()}
                                            grantExpression={grantExpression}
                                        />

                                        <Image
                                            src={image.src}
                                            width={'100%'}
                                            height={'100%'}
                                            lazy={lazy}
                                            withPlaceholder={image.displayMode}
                                            alt={itemData.getTitle()}
                                        />

                                        {premiereMarking && (
                                            <ListElementBottomMarkings marking={premiereMarking} />
                                        )}
                                    </>
                                )}
                            </ImageWrapper>
                            <ListElementProgressBar itemData={itemData} />
                        </ImageContainer>
                        <PositionedMetadata active={active} itemData={itemData} />
                    </ElementInnerWrapper>
                </ElementWrapper>
            </Nav>
        ),
        [active, itemData, onItemNav, focusedRow],
    );
};

export const ListElementMemo = React.memo(ListElement);
