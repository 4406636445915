import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { useColumnContext } from '@/contexts/epg-table/column.context';
import { useFocusedEPGContext } from '@/contexts/epg-table/focused-epg.context';
import { useIncrementColumnContext } from '@/contexts/epg-table/increment-column.context';
import { Nav } from 'nav-tree';
import { useTablePageContext } from '@/contexts/epg-table/table-page.context';
import { focusNodeInFocusedColumnById } from '@/helpers/epg-table/focus-node-by-id.helper';
import { focusProgramInColumn } from '@/components/shared/EPGTable/nav-helpers';
import { PageContext } from '@/contexts/page/page.context';

export const useFocusCell = (colIndex: number, channelId: string) => {
    const navRef = useRef<Nav | null>(null);
    const { focusedEPG } = useFocusedEPGContext();
    const { isVeryLastElementFullyVisible, focusedColIndex } = useIncrementColumnContext();
    const { lastColumn, firstColumn } = useColumnContext();
    const { pageDirection, isDueToPageChange, setIsDueToPageChange } = useTablePageContext();
    const { refocusPage } = useContext(PageContext);

    const focusedStartHour = useMemo(() => {
        const d = focusedEPG ? new Date(focusedEPG.getStartTimeTimestamp()) : new Date();
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    }, [focusedEPG]);

    const focusAfterPageChange = useCallback(
        (tree: any) => {
            if (isDueToPageChange) {
                const parent = tree?.parent?.parent;
                if (!parent) return;

                return pageDirection === 'left' && channelId === firstColumn.id
                    ? focusProgramInColumn(parent, firstColumn.id)
                    : pageDirection === 'right' &&
                          ((channelId !== lastColumn.id && isVeryLastElementFullyVisible) ||
                              channelId === lastColumn.id) &&
                          focusProgramInColumn(parent, channelId);
            }
        },
        [pageDirection, lastColumn.id, isDueToPageChange],
    );

    const focusNode = useCallback(
        (tree: any) => {
            if (focusedColIndex === colIndex) {
                const parent = tree?.parent?.parent;
                if (parent) focusNodeInFocusedColumnById(parent, colIndex, focusedStartHour);
            }
        },
        [focusedColIndex, colIndex],
    );

    useEffect(() => {
        const tree = navRef.current?.getChildContext().tree;

        if (focusAfterPageChange(tree)) {
            setIsDueToPageChange(false);
        }

        focusNode(tree);
    }, [focusAfterPageChange, refocusPage, focusNode]);

    return { navRef };
};
