import useGetMedia from '@/api/navigation/use-get-media';
import { MEDIA_TYPES } from '@/types/media.type';
import {
    buildCategoryRouteParams,
    buildChannelRouteParams,
    buildEventRouteParams,
    buildMovieRouteParams,
    buildPacketRouteParams,
    buildVodRouteParams,
    buildVodWithSeasonRouteParams,
    returnPacketUrlWithQueryParams,
} from '../helpers/url.helper';
import useGetProduct from '@/api/navigation/use-get-product';
import { useGetCategory } from '@/api/navigation/category/get-category';
import { ROUTES } from '@/routing/types';
import { MediaListItemModel } from '@/models/media-list-item.model';
import {
    DeepLinkParams,
    getMediaParams,
    getCategoryParams,
    getPacketParams,
    getOfferParams,
} from '@/helpers/deeplink/get-params-to-deeplink.helper';

export enum CMD {
    START_CHANNEL = 'startchan',
    START_VOD = 'startvod',
    START_LIVE = 'startlive',
    GO_TO_PACKET = 'gotopack',
    GO_TO_CATEGORY = 'gotocat',
    START_PROGRAM = 'startprogram',
    SHOW_OFFER = 'showoffer',
    START_CODE_ACTIVATION = 'startcodeactivation',
}

function parseDeepLink(deeplink: string): any {
    // Wyrażenie regularne do wyciągnięcia wartości cmd
    const cmdMatch = deeplink.match(/cmd-cmd=([^&]+)/);
    const cmdValue = cmdMatch ? cmdMatch[1] : '';

    // Usuwamy 'pbg://cmd-cmd=' z początku, aby uzyskać tylko parametry do URLSearchParams
    const paramsString = deeplink.replace(/^pbg:\/\/cmd-cmd=[^&]*&?/, '');
    const urlParams = new URLSearchParams(paramsString);

    // Lista możliwych parametrów
    const possibleParams = ['chanid', 'id', 'packid', 'catid', 'token', 'offerid'];

    // Typujemy `result` jako Record<string, string | null>, by dynamicznie dodawać właściwości
    const result: DeepLinkParams = { cmd: cmdValue };
    // Dodajemy tylko istniejące parametry
    possibleParams.forEach((param: string) => {
        const value = urlParams.get(param);
        if (value) {
            result[param as keyof DeepLinkParams] = value;
        }
    });

    return result;
}
const routeMap: { [key: string]: ROUTES } = {
    [MEDIA_TYPES.MOVIE]: ROUTES.VOD_MOVIE_DETAILS,
    [MEDIA_TYPES.VOD]: ROUTES.VOD_EPISODE_WITH_SEASON_DETAILS,
    [MEDIA_TYPES.LIVE]: ROUTES.LIVE_DETAILS,
    [MEDIA_TYPES.TV]: ROUTES.CHANNEL_TV_DETAILS,
};
const paramsMap: { [key: string]: (media: any) => any } = {
    [MEDIA_TYPES.MOVIE]: (media) => buildMovieRouteParams(media),
    [MEDIA_TYPES.VOD]: (media) => buildVodRouteParams(media),
    [MEDIA_TYPES.LIVE]: (media) => buildEventRouteParams(media),
    [MEDIA_TYPES.TV]: (media) => buildChannelRouteParams(media),
};
function returnRouteParams(media: MediaListItemModel) {
    let route = routeMap[media.getType()] || ROUTES.NOT_FOUND;
    let params = paramsMap[media.getType()]?.(media);
    if (media.hasSeason()) {
        route = ROUTES.VOD_EPISODE_WITH_SEASON_DETAILS;
        params = buildVodWithSeasonRouteParams(media);
    }
    return { route, params };
}

export const useConvertDeeplinkToUrl = (deeplink: string) => {
    const params: DeepLinkParams = parseDeepLink(deeplink);
    const cmd = params.cmd;
    let route: ROUTES;
    let paramsUrl;
    let queryParams;

    const { data: mediaModel } = useGetMedia(getMediaParams(params));
    const { data: categoryModel } = useGetCategory(getCategoryParams(params));
    const { data: packetModel, isFetching: isPacketFetching } = useGetProduct(
        getPacketParams(params) || getOfferParams(params),
    );

    switch (cmd) {
        case CMD.START_CHANNEL:
        case CMD.START_VOD:
        case CMD.START_LIVE:
            route = returnRouteParams(mediaModel).route;
            paramsUrl = returnRouteParams(mediaModel).params;
            break;

        case CMD.GO_TO_PACKET:
            if (!isPacketFetching && packetModel) {
                route = ROUTES.PACKET_DETAILS;
                paramsUrl = buildPacketRouteParams(packetModel);
                break;
            }
            route = ROUTES.NOT_FOUND;
            paramsUrl = {};
            break;
        case CMD.GO_TO_CATEGORY:
        case CMD.START_PROGRAM:
            route = ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY;
            paramsUrl = buildCategoryRouteParams(categoryModel);
            break;
        case CMD.SHOW_OFFER:
            if (!isPacketFetching && packetModel) {
                route = ROUTES.PACKET_DETAILS;
                paramsUrl = returnPacketUrlWithQueryParams(packetModel, params?.offerid);
                queryParams = { category: params?.offerid };
                break;
            }
            route = ROUTES.NOT_FOUND;
            paramsUrl = {};
            break;
        case CMD.START_CODE_ACTIVATION:
            route = ROUTES.HAVE_CODE;
            queryParams = { token: params?.token };
            break;
        default:
            route = ROUTES.NOT_FOUND;
            paramsUrl = {};
            break;
    }
    return { route, params: paramsUrl, queryParams };
};
