import { Nav } from 'nav-tree';
import React, { useState } from 'react';
import { Router, useRouter } from '@/routing';
import { Icon, ICONS } from '../../../shared/Icon';
import { AccountMenuItemStyled, MenuItemIconStyled, MenuItemLabelStyled } from './styles';
import { MenuItemProps } from './types';
import { ROUTES } from '@/routing/types';

const AccountMenuItemRaw = ({
    label,
    url,
    isSelected,
    'data-testing': dataTesting,
    handleClickFn,
}: MenuItemProps) => {
    const { route } = useRouter();
    const [active, setActive] = useState(false);

    const onItemEnter = () => {
        if (handleClickFn) {
            handleClickFn();
            return;
        }
        Router.pushRoute(url);
    };

    // strona /help nie zawiera elementów nawigacyjnych
    const defaultFocused = isSelected && route === ROUTES.ACCOUNT_HELP;

    return (
        // @ts-ignore
        <Nav
            onNav={setActive}
            onEnterDown={onItemEnter}
            onClick={onItemEnter}
            autofocusOn={['focus', 'mouseenter']}
            defaultFocused={defaultFocused}
            tabIndex={0}
            title={label}
            aria-label={label}
        >
            <AccountMenuItemStyled active={active} data-testing={dataTesting}>
                <MenuItemIconStyled>
                    {isSelected && <Icon name={ICONS.CHECK_MARK} />}
                </MenuItemIconStyled>
                <MenuItemLabelStyled active={active}>{label}</MenuItemLabelStyled>
            </AccountMenuItemStyled>
        </Nav>
    );
};

export const AccountMenuItem = React.memo(AccountMenuItemRaw);
