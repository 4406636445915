import React, { useContext, useState } from 'react';

import { MainText, MAINTEXT_KIND, MAINTEXT_RESPONSIVE_KIND } from '@/components/shared/MainText';

import t from '@/lib/i18n';

import { GUTTER } from '@/types/gutter.type';

import {
    CallToActionWrapper,
    CollectionItem,
    ImageItem,
    CollectionItemBg,
    CollectionBg,
    TextWrapper,
} from './styles';
import { StaffCollectionCallToActionCallToActionProps } from './types';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { BUTTON_ID } from '@/types/button-id.type';


export const StaffCollectionCallToAction = ({
    imgSrc,
    collectionId,
    staffCollectioTitle,
}: StaffCollectionCallToActionCallToActionProps) => {
    const [ctaActive, setCtaActive] = useState<boolean>(false);

    const onStaffCollectionNav = (isActive: boolean) => {
        setCtaActive(isActive);
    };
  
    const onStaffCollectionEnter = () => {
        
        Router.pushRoute(ROUTES.COLLECTION, {
            collectionId,
        });
    };

    return (
        <CallToActionWrapper
            onNav={onStaffCollectionNav}
            onEnterDown={onStaffCollectionEnter}
            onClick={onStaffCollectionEnter}
            active={ctaActive}
            navId={BUTTON_ID.CTA_BTN}
        >
            <CollectionItem>
                <CollectionItemBg imgSrc={imgSrc} />
                <CollectionBg />
                <TextWrapper>
                    <MainText
                        text={t('all-of-collection')}
                        mode={MAINTEXT_KIND.NEW_COLLECTION_TITLE}
                        gutter={GUTTER.SMALL}
                    ></MainText>
                    <MainText
                        text={staffCollectioTitle}
                        mode={MAINTEXT_KIND.NEW_COLLECTION_SUBTITLE}
                        gutter={GUTTER.SMALL}
                        lines={4}
                    ></MainText>
                </TextWrapper>
            </CollectionItem>
            <ImageItem imgSrc={imgSrc}></ImageItem>
        </CallToActionWrapper>
    );
};
