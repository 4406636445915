import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

import { COL_WIDTH } from '@/constants/epg-table';

const CHANNEL_IMAGE_HEIGHT = 13;

export const ChannelTile = styled.img`
    height: 100%;
`;

const columnWrapper = css`
    position: relative;
    flex: 0 0 ${COL_WIDTH}rem;
    max-width: ${COL_WIDTH}rem;
`;

export const ColumnWrapper = styled.div`
    ${columnWrapper}
`;

export const NavColumnWrapper = styled(Nav as any)(({ theme }) => {
    const { mainBorderColor } = theme.epgTable;

    return css`
        ${columnWrapper}
        border-left: 0.1rem solid ${mainBorderColor};
        height: 100%;

        &:last-child {
            border-right: 0.1rem solid ${mainBorderColor};
        }
    `;
});

export const ChannelImageWrapper = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.epgTable.mainBorderColor};
    height: ${CHANNEL_IMAGE_HEIGHT}rem;
    position: relative;
    text-align: center;
    z-index: -1;

    & > span {
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
    }
`;
