import { PlayableButton } from '@/components/shared/Buttons';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { OptionLayout } from '@/components/views/Account/AccountOption/OptionLayout';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';

export const DeleteOption = () => {
    const content = (
        <MainText
            text={t('pages.account.option.header.delete')}
            mode={MAINTEXT_KIND.PAGE_SUBTITLE}
            gutter={GUTTER.NONE}
        />
    );

    const button = (
        <PlayableButton
            text={t('button.account.delete')}
            onButtonAction={() => Router.pushRoute(ROUTES.ACCOUNT_DELETE)}
            transparentOnInactive
            gutter={GUTTER.NONE}
        />
    );

    return <OptionLayout left={content} right={button} />;
};
