import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTimeout } from '@/hooks/use-timeout.hook';
import bgPromo from '@/public/assets/shared/bg-promo.png';
import { BackgroundContext } from '@/contexts/background/background.context';
import { ShadeStyled, StyledBcgImage } from './styles';
import { BACKGROUND_MARGIN, BackgroundImageProps, IMAGE_MODE, SHADE_MODE } from './types';

const BackgroundImageRaw = ({ defaultBg, bgShade = SHADE_MODE.OVERLAY }: BackgroundImageProps) => {
    const { slowDevice } = process.env;
    const { timeout } = process.env.pages;

    const { bcgSrc, shadeMode, bcgMargin } = useContext(BackgroundContext);

    const [showImg, setShowImg] = useState<boolean>(false);

    const handleShow = useCallback(() => setShowImg(true), []);
    const handleHide = useCallback(() => setShowImg(false), []);

    const newImg = useTimeout(bcgSrc, timeout);
    const prevImg = useTimeout(bcgSrc, timeout * 2);

    const shade = useMemo(() => <ShadeStyled mode={shadeMode} />, [shadeMode]);

    const prevBackground = useMemo(
        () => <StyledBcgImage src={prevImg} onLoad={handleShow} margin={bcgMargin} alt="" />,
        [prevImg],
    );

    // TODO wdrożenie obsługi next/image dla bgPromo
    const typedBgPromo = bgPromo.src || (bgPromo as unknown as string);
    const newBackground = useMemo(
        () => (
            <StyledBcgImage
                src={newImg || typedBgPromo}
                margin={bcgMargin}
                onLoad={handleHide}
                showImg={showImg}
                mode={IMAGE_MODE.NEW}
                alt=""
            />
        ),
        [newImg, showImg, bcgMargin, handleHide],
    );

    const slowDeviceBackground = useMemo(
        () => <StyledBcgImage src={bcgSrc || typedBgPromo} margin={bcgMargin} alt="" />,
        [bcgSrc, bcgMargin],
    );

    if (defaultBg) {
        return (
            <>
                <StyledBcgImage src={typedBgPromo} margin={BACKGROUND_MARGIN.NONE} alt="" />
                <ShadeStyled mode={bgShade} />
            </>
        );
    }

    if (slowDevice) {
        return (
            <>
                {slowDeviceBackground}
                {shade}
            </>
        );
    }

    return (
        <>
            {prevBackground}
            {newBackground}
            {shade}
        </>
    );
};

export const BackgroundImage = React.memo(BackgroundImageRaw);
