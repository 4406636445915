import t from '@/lib/i18n';
import { useBowser } from './bowser';

const joinNameParts = (parts: string[], separator = ' '): string => {
    return parts.filter((s) => s).join(separator);
};

export const useGetDeviceName = () => {
    const { ...bowser } = useBowser();
    // Format: <typ urządzenia> <system operacyjny>
    // np. Telewizor AndroidTV
    const getCacNameTV = (): string => {
        switch (true) {
            case bowser.philips:
                return joinNameParts([t('device-name.tv'), 'Philips']);
            case bowser.whaleos:
                return joinNameParts([t('device-name.tv'), 'WhaleOS']);
            case bowser.webos:
                return joinNameParts([t('device-name.tv'), 'LG']);
            case bowser.tizen:
                return joinNameParts([t('device-name.tv'), 'Samsung Tizen']);
            case bowser.chromecast:
                return joinNameParts([t('device-name.tv'), 'Chromecast']);
            case bowser.firetv:
                return joinNameParts([t('device-name.tv'), 'Amazon FireTV']);
            case bowser.androidtv:
                return joinNameParts([t('device-name.tv'), 'AndroidTV']);
            case bowser.appletv:
                return joinNameParts([t('device-name.tv'), 'AppleTV']);
            case bowser.panasonic:
                return joinNameParts([t('device-name.tv'), 'Panasonic']);
            case bowser.hisense:
                return joinNameParts([t('device-name.tv'), 'Hisense']);
            default:
                return t('device-name.tv-unknown');
        }
    };

    // Format: <typ urządzenia> <nazwa urządzenia>
    // np. Konsola Playstation 5
    const getCacNameConsole = (): string => {
        return bowser.consolename
            ? joinNameParts([t('device-name.console'), bowser.consolename])
            : t('device-name.console-unknown');
    };

    // Format: <typ urządzenia> <nazwa urządzenia?> (<system operacyjny>, <nazwa przeglądarki?> <wersja przeglądarki?>)
    // np. Komputer PC (Linux, Chrome 113)
    const getCacNameDesktop = (): string => {
        const deviceName = t('device-name.desktop');
        const osName = bowser.mac ? `${bowser.osname} ${bowser.osversion}` : bowser.osname;
        const browserNameWithVersion =
            bowser.name && bowser.version ? `${bowser.name} ${bowser.version}` : '';
        const osWithBrowserInfo = joinNameParts([osName || '', browserNameWithVersion], ', ');

        if (!bowser.osname) {
            return t('device-name.desktop');
        }

        return `${deviceName} (${osWithBrowserInfo})`;
    };

    // Format: <typ urządzenia> <nazwa urządzenia?> <wersja systemu operacyjnego|nazwa systemu operacyjnego> (<nazwa przeglądarki> <wersja przeglądarki>)
    // np. Tablet iPad 11 (Safari 3)
    const getCacNameTablet = (): string => {
        const deviceOrOS = bowser.ios ? 'iPad' : bowser.osname;
        const browser = bowser.name && bowser.version ? `(${bowser.name} ${bowser.version})` : '';

        return deviceOrOS
            ? joinNameParts([t('device-name.tablet'), deviceOrOS, bowser.osversion || '', browser])
            : t('device-name.tablet-unknown');
    };

    // Format: <typ urządzenia> <nazwa urządzenia|nazwa systemu operacyjnego> <wersja systemu operacyjnego> (<nazwa przeglądarki> <wersja przeglądarki>)
    // np. Telefon iPhone 13 (Chrome)
    const getCacNameMobile = (): string => {
        const deviceOrOS = bowser.ios ? 'iPhone' : bowser.osname;
        const browser = bowser.name && bowser.version ? `(${bowser.name} ${bowser.version})` : '';

        return deviceOrOS
            ? joinNameParts([t('device-name.mobile'), deviceOrOS, bowser.osversion || '', browser])
            : t('device-name.mobile-unknown');
    };

    const getCacName = (): string => {
        // Kolejność warunków ważna ze względu na pokrywające się warunki!
        switch (true) {
            case bowser.tv:
                return getCacNameTV();
            case bowser.console:
                return getCacNameConsole();
            case bowser.mobile:
                return getCacNameMobile();
            case bowser.tablet:
                return getCacNameTablet();
            case bowser.desktop:
                return getCacNameDesktop();
            default:
                return t('device-name.device-unknown');
        }
    };
    return {
        deviceName: getCacName(),
    };
};
