import { GoToLiveButton } from '../shared/GoToLiveButton';
import { GoToPositionStartButton } from '../shared/GoToPositionStartButton';
import { PlayPauseButton } from '../shared/PlayPauseButton';
import { useIsLive } from '@/hooks/player/use-is-live';
import { FlexItem } from '../shared/styles';
import { TVProgramTitle } from './TVProgramTitle';
import { ITVTitleBarProps } from './types';
import { useTimeshiftingSupported } from '@/hooks/player/use-timeshifting-supported';
import { NavButtons } from './styles';

export const TVTitleBar = ({ channelId }: ITVTitleBarProps) => {
    const { live } = useIsLive();
    const isTimeshiftingSupported = useTimeshiftingSupported();

    return (
        <NavButtons restoreLastFocus>
            <FlexItem allowOverflow>
                <PlayPauseButton defaultFocused />
            </FlexItem>
            {isTimeshiftingSupported && (
                <FlexItem allowOverflow paddingLeft="3rem">
                    <GoToPositionStartButton channelId={channelId} />
                </FlexItem>
            )}
            <FlexItem paddingLeft="6rem" grow shrink>
                <TVProgramTitle channelId={channelId} />
            </FlexItem>
            {isTimeshiftingSupported && !live && (
                <FlexItem allowOverflow paddingLeft="3rem">
                    <GoToLiveButton />
                </FlexItem>
            )}
        </NavButtons>
    );
};
