import { fillIcon } from '@/helpers/fill-icon.helper';
import { device, responsiveFonts } from '@/targets/default/mediaQuery';
import styled, { ThemeProps, css } from 'styled-components';
import { IGutterProps } from '@/types/gutter.type';
import { THEME_OPTION } from '@/types/theme.type';
import { buildMediaQuery } from '@/helpers/media-query.helper';

export const MessageContainer = styled.div<IGutterProps>`
    text-align: center;
    font-weight: 400;
    margin-bottom: ${({ gutter }) => gutter}rem;
    max-width: 100%;

    #portal-span {
        white-space: nowrap;
    }
`;

export enum MESSAGE_SIZE {
    DEFAULT = 'default',
    LG = 'lg',
    LG_SMALL_DESCRIPTION = 'lg-small-description',
    GOODBYE_RESPONSIVE = 'goodbye-responsive',
    SMALL_DESCRIPTION_RESPONSIVE = 'small-description-responsive',
}

interface IIconContainer extends ThemeProps<any> {
    size?: MESSAGE_SIZE;
}

export const IconContainer = styled.div<IIconContainer>(({ theme }) => {
    return css`
        width: ${theme.message.iconSize};
        height: ${theme.message.iconSize};
        margin: auto;
        ${fillIcon(theme.message.iconColor)};
    `;
});

interface ITitleContainer extends ThemeProps<any> {
    size?: MESSAGE_SIZE;
    themeOption?: THEME_OPTION;
}

export const TitleContainer = styled.div<ITitleContainer>(({ themeOption, size, theme }) => {
    const MessageSizeLgStyles = css`
        line-height: 6.2rem;

        &:not(:last-of-type) {
            margin-bottom: 2.5rem;
        }
    `;

    return css`
        color: ${({ theme }) => theme.message.titleColor};
        font-size: 2.4rem;
        margin-top: 1.5rem;
        white-space: pre-line;
        line-height: 1.4;

        &:not(:last-of-type) {
            margin-bottom: 0.8rem;
            @media ${device.tablet} {
                margin-bottom: 1.5rem;
            }
        }

        @media ${device.tablet} {
            font-size: ${themeOption === THEME_OPTION.MAGIC_LINK
                ? '4.6rem'
                : theme.message.titleSize};
        }
        font-weight: ${themeOption === THEME_OPTION.MAGIC_LINK ? 700 : 300};

        ${size === MESSAGE_SIZE.LG && MessageSizeLgStyles}
    `;
});

interface ISubtitleContainer extends ThemeProps<any> {
    size?: MESSAGE_SIZE;
}

export const SubtitleContainer = styled.div<ISubtitleContainer>(({ theme, size }) => {
    const MessageSizeLgStyles = css`
        margin-bottom: 2rem;
        margin-top: 4rem;
        @media ${device.tablet} {
            font-size: 3rem;
        }
    `;

    return css`
        white-space: pre-line;
        color: ${theme.message.titleColor};
        font-size: 1.8rem;

        ${size === MESSAGE_SIZE.LG && MessageSizeLgStyles}
        ${
            size === MESSAGE_SIZE.GOODBYE_RESPONSIVE &&
            css`
                ${buildMediaQuery('font-size', responsiveFonts.goodbye.subtitle)}
                margin-bottom: 1.6rem;
            `
        }
    }
    `;
});

export const DescriptionContainer = styled.div<{
    errorTextColor?: boolean;
    themeOption?: THEME_OPTION;
    size?: MESSAGE_SIZE;
}>(({ errorTextColor, themeOption, size }) => {
    const DescriptionContainerMagicLinkStyles = css`
        font-size: 1.4rem;

        @media ${device.tablet} {
            font-size: 2.4rem;
        }

        span {
            color: ${({ theme }) => theme.text.color.primary};
        }
    `;
    const MessageSizeLgStyles = css`
        @media ${device.tablet} {
            line-height: 4.6rem !important;
        }
    `;

    return css`
        font-size: 1.3rem;
        line-height: ${({ theme }) => theme.message.descriptionLineHeight};
        color: ${({ theme }) =>
            errorTextColor ? theme.text.color.error : theme.message.descriptionColor};
        white-space: pre-line;

        @media ${device.tablet} {
            font-size: ${({ theme }) => theme.message.descriptionSize};
            line-height: normal;
        }
        ${themeOption === THEME_OPTION.MAGIC_LINK && DescriptionContainerMagicLinkStyles}
        ${size === MESSAGE_SIZE.LG && MessageSizeLgStyles} ${size ===
            MESSAGE_SIZE.LG_SMALL_DESCRIPTION &&
        css`
            font-size: 2.2rem !important;
        `} ${size === MESSAGE_SIZE.SMALL_DESCRIPTION_RESPONSIVE &&
        css`
            font-size: 1.1rem !important;
        `}
    `;
});

export const Logo = styled.img`
    display: inline;
    max-width: 112px;
    margin: 0 auto;
    @media ${device.tablet} {
        position: relative;
        top: -1.5rem;
        left: 11.5rem;
    }
`;
