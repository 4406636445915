import { PlayableDynamicButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import { usePlaybackOptions } from '@/hooks/player/use-playback-options';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { BUTTON_ID } from '@/types/button-id.type';
import { FlexItem } from '../shared/styles';
import { SIDEBAR_TYPE } from '../shared/types';
import { NavButtons } from './styles';

interface ITVTopBarProps {
    setOpenSidebar: (setter: (v: SIDEBAR_TYPE) => SIDEBAR_TYPE) => void;
    hideElements?: boolean;
}

export const TVTopBar = ({ setOpenSidebar, hideElements }: ITVTopBarProps) => {
    const { hasVideoOptions, hasTextOptions } = usePlaybackOptions();
    const isCustomerCareButton = process.env.isCustomerCare;

    return (
        <NavButtons restoreLastFocus hidden={hideElements}>
            <FlexItem allowOverflow>
                <PlayableDynamicButton
                    defaultFocused
                    icon={ICONS.ARROW_LEFT}
                    text={t('back-button-label')}
                    onButtonAction={() => Router.back()}
                    data-testing="back-button-label"
                />
            </FlexItem>
            {(hasVideoOptions || hasTextOptions) && (
                <FlexItem paddingLeft="1rem" allowOverflow>
                    <PlayableDynamicButton
                        icon={ICONS.SETTINGS}
                        navId={BUTTON_ID.PLAYER_SIDEBAR_PANEL_SETTINGS_BTN}
                        text={t('settings-button-label')}
                        onButtonAction={() =>
                            setOpenSidebar((visible) =>
                                visible === SIDEBAR_TYPE.SETTINGS
                                    ? SIDEBAR_TYPE.NONE
                                    : SIDEBAR_TYPE.SETTINGS,
                            )
                        }
                    />
                </FlexItem>
            )}
            {isCustomerCareButton && (
                <FlexItem allowOverflow paddingLeft="1rem">
                    <PlayableDynamicButton
                        icon={ICONS.QUESTION_MARK}
                        text={t('home-menu.url.help')}
                        onButtonAction={() =>
                            Router.pushRoute(
                                ROUTES.CUSTOMER_CARE_HELP,
                                {},
                                {
                                    contextType: 'player_offline',
                                    contextValue: '',
                                },
                            )
                        }
                        data-testing="help-button-label"
                    />
                </FlexItem>
            )}
        </NavButtons>
    );
};
